/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Button, Form, Modal } from 'react-bootstrap'
import { Select } from '@mui/material'

const CSVIntegration = () => {
    const options =[
        {value:"empty",label:"Empty"},
        {value:"id",label:"Order ID"},
{value:"created",label:"Order Date"},
{value:"orderNumber",label:"Order Number"},
{value:"shippingService",label:"Shipping Service Selected"},
{value:"orderGroup",label:"Order Group"},
{value:"length",label:"Package Length"},
{value:"width",label:"Package Width"},
{value:"height",label:"Package Height"},
{value:"weight",label:"Order Weight"},
{value:"shipping_total",label:"Shipping Total"},
{value:"poNumber",label:"PO Number"},
{value:"printOrder",label:"Print Order"},
{value:"shipperReference",label:"Shipper Reference"},
{value:"shipperReference2",label:"Shipper Reference 2"},
{value:"departmentNumber",label:"Department Number"},
{value:"originPrefix",label:"Sender Prefix"},
{value:"originName",label:"Sender Name"},
{value:"originFirstName",label:"Sender First Name"},
{value:"originLastName",label:"Sender Last Name"},
{value:"originCompany",label:"Sender Company"},
{value:"originAddress1",label:"Sender Address Line 1"},
{value:"originAddress2",label:"Sender Address Line 2"},
{value:"originAddress3",label:"Sender Address Line 3"},
{value:"originCity",label:"Sender City"},
{value:"originState",label:"Sender State"},
{value:"originZip",label:"Sender Zip"},
{value:"originCountry",label:"Sender Country Code"},
{value:"originEmail",label:"Sender Email"},
{value:"originPhone",label:"Sender Phone"},
{value:"destinationPrefix",label:"Receiver Prefix"},
{value:"destinationName",label:"Receiver Name"},
{value:"destinationFirstName",label:"Receiver First Name"},
{value:"destinationLastName",label:"Receiver Last Name"},
{value:"destinationCompany",label:"Receiver Company"},
{value:"destinationAddress1",label:"Receiver Address Line 1"},
{value:"destinationAddress2",label:"Receiver Address Line 2"},
{value:"destinationAddress3",label:"Receiver Address Line 3"},
{value:"destinationCity",label:"Receiver City"},
{value:"destinationState",label:"Receiver State"},
{value:"destinationZip",label:"Receiver Zip"},
{value:"destinationCountry",label:"Receiver Country Code"},
{value:"destinationEmail",label:"Receiver Email"},
{value:"destinationPhone",label:"Receiver Phone"},
{value:"returnToPrefix",label:"Return Prefix"},
{value:"returnToName",label:"Return Name"},
{value:"returnToFirstName",label:"Return First Name"},
{value:"returnToLastName",label:"Return Last Name"},
{value:"returnToCompany",label:"Return Company"},
{value:"returnToAddress1",label:"Return Address Line 1"},
{value:"returnToAddress2",label:"Return Address Line 2"},
{value:"returnToAddress3",label:"Return Address Line 3"},
{value:"returnToCity",label:"Return City"},
{value:"returnToState",label:"Return State"},
{value:"returnToZip",label:"Return Zip"},
{value:"returnToCountry",label:"Return Country Code"},
{value:"returnToEmail",label:"Return Email"},
{value:"returnToPhone",label:"Return Phone"},
{value:"itemsProductId",label:"Product ID"},
{value:"itemsSku",label:"Product SKU"},
{value:"itemsTitle",label:"Product Title"},
{value:"itemsPrice",label:"Product Price"},
{value:"itemsQuantity",label:"Product Quantity"},
{value:"itemsShippingWeight",label:"Product Shipping Weight"},
{value:"itemsShippingTotalOnOrder",label:"Product Total On Order"},
{value:"itemsItemAttributes",label:"Product Attributes"},
{value:"itemsEccn",label:"Product ECCN"},
{value:"itemsHtsNumber",label:"Product HTS Number"},
{value:"itemsAttributesProductAttributeName",label:"Product Variant Name"},
{value:"itemsAttributesProductAttributeValue",label:"Product Variant Value"},
{value:"senderVatTaxId",label:"VAT/IOSS"},
{value:"senderVatTaxIssuerCountry",label:"VAT/IOSS Tax ID Issuer Country Code"},
    ]
    const [integrationName, setIntegrationName] = useState(null)
    const [sendConfirmationEmail, setSendConfirmationEmail] = useState(null)
    const [isActive, setIsActive] = useState(null)
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null)
    const [skipAddressPreVerification, setSkipAddressPreVerification] = useState(false)
    const [packageSlipConfirmationMail, setPackageSlipConfirmationMail] = useState(false)
    const [sendEmailNotificationByDefault, setSendEmailNotificationByDefault] = useState(false)
    const [headerRow, setHeaderRow] = useState(false)
    const [europeanFormat, setEuropeanFormat] = useState(false)
    const [blankOrderId, setBlankOrderId] = useState(false)
    const [columns, setColumns] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectBoxes, setSelectBoxes] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOpt,setSelectedOpt] = useState([])
    const navigate = useNavigate()
    // const [id,setId] = useState(null)
    const { id } = useParams();

    const getExcelColumnName = (num) => {
        let ret = '';
        while (num >= 0) {
            ret = String.fromCharCode(num % 26 + 65) + ret;
            num = Math.floor(num / 26) - 1;
        }
        return ret;
    };
    // Function to add a new select box with trash icon
    const addSelectBox = () => {
        const newSelectBoxes = [...selectBoxes];
        const newIndex = newSelectBoxes.length;
        const selectBoxLabel = getExcelColumnName(newIndex);
        newSelectBoxes.push(
            <div style={{ display: 'flex', gap: '10px', marginBottom: 10 }} key={newIndex}>
                <p>{selectBoxLabel}.</p>
                <select className="form-control" onChange={(event) => handleSelectChange(event, newIndex)}>
                    <option value="">Choose one...</option>
                    <option value="empty">Ignore</option>
                    <option value="id">Order ID</option>
                    <option value="created">Order Date</option>
                    <option value="orderNumber">Order Number</option>
                    <option value="shippingService">Shipping Service Selected</option>
                    <option value="orderGroup">Order Group</option>
                    <option value="length">Package Length</option>
                    <option value="width">Package Width</option>
                    <option value="height">Package Height</option>
                    <option value="weight">Order Weight</option>
                    <option value="shipping_total">Shipping Total</option>
                    <option value="poNumber">PO Number</option>
                    <option value="printOrder">Print Order</option>
                    <option value="shipperReference">Shipper Reference</option>
                    <option value="shipperReference2">Shipper Reference 2</option>
                    <option value="departmentNumber">Department Number</option>
                    <option value="originPrefix">Sender Prefix</option>
                    <option value="originName">Sender Name</option>
                    <option value="originFirstName">Sender First Name</option>
                    <option value="originLastName">Sender Last Name</option>
                    <option value="originCompany">Sender Company</option>
                    <option value="originAddress1">Sender Address Line 1</option>
                    <option value="originAddress2">Sender Address Line 2</option>
                    <option value="originAddress3">Sender Address Line 3</option>
                    <option value="originCity">Sender City</option>
                    <option value="originState">Sender State</option>
                    <option value="originZip">Sender Zip</option>
                    <option value="originCountry">Sender Country Code</option>
                    <option value="originEmail">Sender Email</option>
                    <option value="originPhone">Sender Phone</option>
                    <option value="destinationPrefix">Receiver Prefix</option>
                    <option value="destinationName">Receiver Name</option>
                    <option value="destinationFirstName">Receiver First Name</option>
                    <option value="destinationLastName">Receiver Last Name</option>
                    <option value="destinationCompany">Receiver Company</option>
                    <option value="destinationAddress1">Receiver Address Line 1</option>
                    <option value="destinationAddress2">Receiver Address Line 2</option>
                    <option value="destinationAddress3">Receiver Address Line 3</option>
                    <option value="destinationCity">Receiver City</option>
                    <option value="destinationState">Receiver State</option>
                    <option value="destinationZip">Receiver Zip</option>
                    <option value="destinationCountry">Receiver Country Code</option>
                    <option value="destinationEmail">Receiver Email</option>
                    <option value="destinationPhone">Receiver Phone</option>
                    <option value="returnToPrefix">Return Prefix</option>
                    <option value="returnToName">Return Name</option>
                    <option value="returnToFirstName">Return First Name</option>
                    <option value="returnToLastName">Return Last Name</option>
                    <option value="returnToCompany">Return Company</option>
                    <option value="returnToAddress1">Return Address Line 1</option>
                    <option value="returnToAddress2">Return Address Line 2</option>
                    <option value="returnToAddress3">Return Address Line 3</option>
                    <option value="returnToCity">Return City</option>
                    <option value="returnToState">Return State</option>
                    <option value="returnToZip">Return Zip</option>
                    <option value="returnToCountry">Return Country Code</option>
                    <option value="returnToEmail">Return Email</option>
                    <option value="returnToPhone">Return Phone</option>
                    <option value="itemsProductId">Product ID</option>
                    <option value="itemsSku">Product SKU</option>
                    <option value="itemsTitle">Product Title</option>
                    <option value="itemsPrice">Product Price</option>
                    <option value="itemsQuantity">Product Quantity</option>
                    <option value="itemsShippingWeight">Product Shipping Weight</option>
                    <option value="itemsShippingTotalOnOrder">Product Total On Order</option>
                    <option value="itemsItemAttributes">Product Attributes</option>
                    <option value="itemsEccn">Product ECCN</option>
                    <option value="itemsHtsNumber">Product HTS Number</option>
                    <option value="itemsAttributesProductAttributeName">Product Variant Name</option>
                    <option value="itemsAttributesProductAttributeValue">Product Variant Value</option>
                    <option value="senderVatTaxId">VAT/IOSS</option>
                    <option value="senderVatTaxIssuerCountry">VAT/IOSS Tax ID Issuer Country Code</option>
                    {/* Add options if needed */}
                </select>
                <button style={{ backgroundColor: "transparent", border: 0 }} onClick={() => removeSelectBox(newIndex)}>
                    <i className="fa-solid fa-trash"></i>
                </button>
            </div>
        );
        setSelectBoxes(newSelectBoxes);
    };

    const handleSelectChange = (event, index) => {
        console.log(selectedOptions,"selectedOptions=>")
        const updatedOptions = [...selectedOptions];
        console.log(updatedOptions,"updatedOptions1")
        updatedOptions[index] = event.target.value;
        console.log(updatedOptions,"updatedOptions=>")
        setSelectedOptions(updatedOptions);
    };

    const removeSelectBox = (indexToRemove) => {
        const newSelectBoxes = selectBoxes.filter((_, index) => index !== indexToRemove);
        const newSelectedOptions = selectedOptions.filter((_, index) => index !== indexToRemove);
        setSelectBoxes(newSelectBoxes);
        setSelectedOptions(newSelectedOptions);
    };

 

    useEffect(() => {
        const convertedArray = selectedOptions.map((col, index) => ({ col, index: getExcelColumnName(index) }));
        setSelectedOpt(convertedArray);
    }, [selectedOptions]);
    useEffect(() => {
        console.log(id,"id=>")
        if(id) {
            getIntegrationDetails()
        }
    },[id])
    const handleSubmit = async (e) => {
        const bodyData = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
            email: Cookies.get('email')
        }
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/change-password`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                    // You may need to include additional headers such as authorization headers
                },
                body: JSON.stringify(bodyData),
            });



            const data = await response.json();
            if (data.status) {
                setMessage(data.message)
            }
            else {
                setError(data.message)
            }
            console.log(data, "dta"); // Log the response data
            // Handle success, e.g., update state or show a success message
        } catch (error) {
            setError(error)
            // Handle other errors, e.g., network error
            console.error(error);
        }
    };
    const saveIntegration = async (e) => {
        const bodyData = {
            integrationName: integrationName,
            sendConfirmationEmail: sendConfirmationEmail,
            isActive: isActive,
            skipAddressPreVerification:skipAddressPreVerification,
            packageSlipConfirmationMail:packageSlipConfirmationMail,
            sendEmailNotificationByDefault:sendEmailNotificationByDefault,
            headerRow:headerRow,
            europeanFormat:europeanFormat,
            blankOrderId:blankOrderId,
            columns:selectedOpt,
            user:Cookies.get('id')
            
        }

       
        e.preventDefault();
        if(id) {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-general-setting/${id}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status == true) {
                    setMessage(data.message)
                    navigate('/csv-integration-list')
                }
                else {
                    setError(data.message)
                }
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-ecomm-csv`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                    navigate('/csv-integration-list')
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };
    const getIntegrationDetails = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };

        


       
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ecomm-csv-details/${id}`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data;
                setIntegrationName(res.integrationName)
                setSendConfirmationEmail(res.sendConfirmationEmail)
                setIsActive(res.isActive)
                setBlankOrderId(res.blankOrderId)
                setSelectedOpt(res.columns)
                setEuropeanFormat(res.europeanFormat)
                setSkipAddressPreVerification(res.skipAddressPreVerification)
                setPackageSlipConfirmationMail(res.packageSlipConfirmationMail)

                setSkipAddressPreVerification(skipAddressPreVerification)

                
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    
   
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error])
    return (

        <div>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="index.html">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="settings-menu-links">
                        <ul className="nav nav-tabs menu-tabs">
                            <li className="nav-item active">
                                <Link className="nav-link " to="/settingschangepassword">
                                    Security
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/billing-address">
                                    Billing Address
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                                <Link className="nav-link" to="/localization">
                                Localization
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/paymentsetting">
                                    Payment Settings
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link className="nav-link" to="/commodities">
                                    Commodities
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/custome-package">
                                    Custom Package Types
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/paperless-signature">
                                    Paperless Signature
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/importer-setting">
                                    Importer Of Record
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/notification-setting">
                                    Notifications
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/other-setting">
                                    Other Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/csv-integration-list">
                                    Add CSV Integration
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {message && <p style={{ color: 'green' }}>{message}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <div className="row">
                        <div >
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-12 col-md-8 col-xl-9">
                                            <div>
                                                <p style={{ fontWeight: 'bold' }}>General Setting</p>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Integration Name:</label>
                                                            <input type="text" className="form-control" required value={integrationName} onChange={(e) => setIntegrationName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Send Confirmation Email

                                                                <input type="checkbox" className="form-check-input" checked={sendConfirmationEmail} onChange={() => setSendConfirmationEmail(!sendConfirmationEmail)} />
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Active

                                                                <input type="checkbox" className="form-check-input" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Include packing slips in confirmation email



                                                                <input type="checkbox" className="form-check-input" checked={packageSlipConfirmationMail} onChange={() => setPackageSlipConfirmationMail(!packageSlipConfirmationMail)} />
                                                            </label>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                            <div>
                                                <p style={{ fontWeight: 'bold' }}>CSV Setting</p>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                Skip header row on import

                                                                <input type="checkbox" className="form-check-input" checked={headerRow} onChange={() => setHeaderRow(!headerRow)} />
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Use European format (semicolon delimited)


                                                                <input type="checkbox" className="form-check-input" checked={europeanFormat} onChange={() => setEuropeanFormat(!europeanFormat)} />
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Auto generate unique order ID if not provided



                                                                <input type="checkbox" className="form-check-input" checked={blankOrderId} onChange={() => setBlankOrderId(!blankOrderId)} />
                                                            </label>
                                                        </div>


                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                Do address verification even for CSVs larger than 500 rows

                                                                <input type="checkbox" className="form-check-input" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Column Mapping</label>
                                                            <button onClick={() => setShowModal(true)}>Edit Columns Mapping</button>
                                                            <ul style={{ listStyleType: 'none' }}  >
                                                            {console.log(selectedOpt,"seec")}
                                                        {selectedOpt.length ?
                                                            selectedOpt.map(ele => (

                                                                <li><b>{ele.index}.</b> {options.find(element => element.value == ele.col ).label}</li>
                                                            ))
                                                            :''
                                                        }
                                                        </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                           
                                                            <button onClick={(e) => saveIntegration(e)}
                                                            >Save Integration</button>
                                                        
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <Modal show={showModal} >
                                            <Modal.Header>
                                                <Modal.Title>Save Shipment</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Modal.Body>
                                                    {console.log(selectBoxes,"selectBoxes=>")}
                                                    {selectBoxes.map((selectBox, index) => (
                                                        <div key={index}>{selectBox}</div>
                                                    ))}
                                                </Modal.Body>
                                              
                                            </Modal.Body>
                                            <Modal.Footer>

                                                <button className="btn btn-secondary" onClick={addSelectBox} >
                                                    Add Column
                                                </button>
                                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                                    Close
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                        <div className="col-12 col-md-4 col-xl-3">
                                            <div>
                                                <p style={{ fontWeight: 'bold' }}>Sender Address</p>
                                                <p>First we look to see if a sender address is included with the order. If nothing is specified, we will use the overrides configured below. If neither are specified, we will default to this user's sender address.</p>
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                <input type="checkbox" className="form-check-input" />
                                                                Override Company and Name


                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                <input type="checkbox" className="form-check-input" />
                                                                Override Address


                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                <input type="checkbox" className="form-check-input" />
                                                                Override Phone




                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>
                                                                <input type="checkbox" className="form-check-input" />
                                                                Override Email




                                                            </label>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                            <div>
                                                <p style={{ fontWeight: 'bold' }}>Return To Address</p>
                                                <p>First we look to see if a return address is included with the order. If nothing is specified, we will use the overrides configured below. If neither are specified, we will default to this user's return address.</p>
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>

                                                                Override Return To Address

                                                                <input type="checkbox" className="form-check-input" />
                                                            </label>
                                                        </div>

                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Content */}
             
            </div>


        </div>
    )
}

export default CSVIntegration

/* eslint-disable */
import React, { useEffect, useState } from "react";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Select from "react-select";
// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import {
    imagesend,
    morning_img_01,
    // profile_add,
    // scissor,
    // user001,
} from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";

const CustomePackageType = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  
    const [inputFields, setInputFields] = useState([{}]);
    const [packageType, setPackageType] = useState(null)
    const [packageName, setPackage] = useState(null)
    const [length, setLength] = useState(null)

    const [height, setHeight] = useState(null)
    const [width, setWidth] = useState(null)
    const [additionalWeight, setAdditionalWeight] = useState(null)
    const customPackages = [
        { value: "21", label: "UPS Express Box" },
        { value: "01", label: "UPS Letter" },
        { value: "04", label: "UPS Pak" },
        { value: "03", label: "UPS Tube" },
        { value: "2a", label: "UPS Small Box" },
        { value: "2b", label: "UPS Medium Box" },
        { value: "2c", label: "UPS Large Box" },
        { value: "02", label: "Customer Supplied Package" },
        { value: "Mws_UPS_Box_10kg", label: "Amazon Merchant Fulfillment UPS 10kg Box" },
        { value: "Mws_UPS_Box_25kg", label: "Amazon Merchant Fulfillment UPS 25kg Box" },
        { value: "Mws_UPS_Express_Box", label: "Amazon Merchant Fulfillment UPS Express Box" },
        { value: "Mws_UPS_Express_Box_Large", label: "Amazon Merchant Fulfillment UPS Large Express Box" },
        { value: "Mws_UPS_Express_Box_Medium", label: "Amazon Merchant Fulfillment UPS Medium Express Box" },
        { value: "Mws_UPS_Express_Box_Small", label: "Amazon Merchant Fulfillment UPS Small Express Box" },
        { value: "Mws_UPS_Express_Envelope", label: "Amazon Merchant Fulfillment UPS Express Envelope" },
        { value: "Mws_UPS_Express_Hard_Pak", label: "Amazon Merchant Fulfillment UPS Express Hard Pak" },
        { value: "Mws_UPS_Express_Legal_Envelope", label: "Amazon Merchant Fulfillment UPS Express Legal Envelope" },
        { value: "Mws_UPS_Express_Pak", label: "Amazon Merchant Fulfillment UPS Express Pak" },
        { value: "Mws_UPS_Express_Tube", label: "Amazon Merchant Fulfillment UPS Express Tube" },
        { value: "Mws_UPS_Laboratory_Pak", label: "Amazon Merchant Fulfillment UPS Laboratory Pak" },
        { value: "Mws_UPS_Pad_Pak", label: "Amazon Merchant Fulfillment UPS Pad Pak" },
        { value: "Mws_UPS_Pallet", label: "Amazon Merchant Fulfillment UPS Pallet" },

    ]
    const [response, setResponse] = useState([])
    const [customPackageId, setCustomPackageId] = useState(null)

    const handleShow = (id,ele) => {
        if (id && ele) {
            console.log("ele.packageType:", ele.packageType);
            console.log("typeof(ele.packageType):", typeof(ele.packageType));
    
            const getPackageType = customPackages.find(pkg => pkg.value === String(ele.packageType));
            console.log("getPackageType:", getPackageType);
    
            setPackageType(getPackageType); // Assuming setPackageType is defined elsewhere
            setPackage(ele.package);
            setLength(ele.length);
            setHeight(ele.height);
            setWidth(ele.width);
            setCustomPackageId(id);
            setAdditionalWeight(ele.additionalWeight);
        }
        setShow(true);
    };
    const fetchSavedPackageDetails = async () => {
        const headersList = {

            "Content-Type": "application/json",
            "Authorization":`Bearer ${Cookies.get('token')}`
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-all-package`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {

                setResponse(data.data);
                if (data.profitMargin) {
                    setProfitMargin(data.profitMargin)
                }
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    };
    useEffect(() => {
        // Fetch saved package details from the API
       

        fetchSavedPackageDetails();
    }, []);



    const handleSubmit = async (id) => {
        if (id) {
            const requestData = {
                package: packageName,
                length: length,
                width: width,
                height: height,
                additionalWeight: additionalWeight,
                packageType: packageType?.value,
                weight: additionalWeight,
                user:Cookies.get('id')
            }
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-package/${id}`;
                fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data, "dataa=>")
                        fetchSavedPackageDetails()
                        handleClose()
                    })
                    .catch(error => {
                        console.error('Error while calling API:', error);
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                console.log(err)
            }
        }
        else {
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-package`;
                const requestData = {
                    package: packageName,
                    length: length,
                    width: width,
                    height: height,
                    additionalWeight: additionalWeight,
                    packageType: packageType?.value,
                    weight: additionalWeight,
                    user:Cookies.get('id')
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data, "dta==")
                        // Handle successful response here
                        handleClose();
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error while calling API:', error);
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                // Handle any synchronous errors
                console.error('Caught synchronous error:', err);
            }


        }

    }
      
   

  

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Custom Package Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Package :</label>
                            <input onChange={(e) => setPackage(e.target.value)} type="text" value={packageName} className="form-control" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Length:</label>
                            <input onChange={(e) => setLength(e.target.value)} type="text" value={length} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Width:</label>
                            <input onChange={(e) => setWidth(e.target.value)} type="text" value={width} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Height:</label>
                            <input onChange={(e) => setHeight(e.target.value)} type="text" value={height} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Additional Weight:</label>
                            <input onChange={(e) => setAdditionalWeight(e.target.value)} type="text" value={additionalWeight} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Package Type :</label>
                            <Select
                                value={packageType} // Ensure this matches one of the options
                                onChange={(selectedOption) => setPackageType(selectedOption)}
                                options={customPackages}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {console.log(customPackageId, "customPackageId=>>>>")}
                    <Button variant="primary" onClick={() => handleSubmit(customPackageId)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header />
            <Sidebar
                id="menu-item"
                id1="menu-items"
                activeClassName="admin-dashboard"
            />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">CustomPackage Types </Link>
                                        </li>
                                        {/* <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Admin Dashboard</li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        {/* /Page Header */}
                        <div className="good-morning-blk">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="morning-user">
                                        <h2>
                                            <span><i className="fa fa-cube" />Get Started with Custom Package Type</span>
                                        </h2>
                                        {/* <p>When you start booking shipments, data about them will display here</p> */}
                                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => handleShow()}>Add Custom Package Type</button>
                                    </div>
                                </div>
                                <div className="col-md-6 position-blk">
                                    <div className="morning-img">
                                        <img src={morning_img_01}
                                            alt="#" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Package Type Name</th>
                                        <th>Length (in)</th>
                                        <th>Width (in)</th>
                                        <th>Height (in)</th>
                                        <th>Additional Weight (lb)</th>
                                        <th>Package Type</th>
                                        {/* <th>Description</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {response && response.length && response.map(ele => (
                                        <tr key={ele.package}>
                                            <td>{ele.package}</td>
                                            <td>{ele.length}</td>
                                            <td>{ele.width}</td>
                                            <td>{ele.height}</td>
                                            <td>{ele.additionalWeight}</td>
                                        {console.log(typeof(ele.packageType),customPackages,"==>>")}

                                        <td>
                                            {console.log(customPackages.find(element => element.value === ele.packageType),"customPackages.find(element => element.value === ele.packageType)?.label")}
                {(customPackages.length && ele.packageType) ? 
                    (customPackages.find(element => Number(element.value) === ele.packageType)?.label || '') 
                    : 
                    ''
                }
            </td>
                                            <td>
                                                <Button variant="primary" onClick={() => handleShow(ele?._id,ele)}>Edit</Button>{' '}
                                                <Button variant="danger" onClick={() => handleDelete(commodity.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={imagesend} alt="#" width={50} height={46} />
                                    <h3>Are you sure want to delete this ?</h3>
                                    <div className="m-t-20">
                                        {" "}
                                        <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                            Close
                                        </Link>
                                        <button type="submit" className="btn btn-danger">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <img src={imagesend} alt="#" width={50} height={46} />
                                        <h3>Are you sure want to delete this ?</h3>
                                        <div className="m-t-20">
                                            {" "}
                                            <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                                Close
                                            </Link>
                                            <button type="submit" className="btn btn-danger">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </>
    );
}
export default CustomePackageType
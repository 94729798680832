/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import Cookies from 'js-cookie'
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { settingicon01 } from '../../imagepath';
import html2pdf from 'html2pdf.js';
import "../../../assets/css/pdf.css";
import Swal from 'sweetalert2';
import axios from 'axios';
const History = () => {
    const printRef = useRef();
  const [shipData, setShipData] = useState([]);
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [ship, setShip] = useState(null)
  const navigate = useNavigate()
  const [base64Image, setBase64Image] = useState('');
  const [importerOfRecord,setImporterOfRecord] = useState(null)
  const [signature,setSignature] = useState('')
  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${signature?.image}`);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      };
    if(signature?.image) {
        fetchData();
    }
   

  
  }, [signature]);
    const getSignature = async () => {
        try{
            const headersList = {
                "Authorization": `Bearer ${Cookies.get('token')}`,
                "Content-Type": "application/json"
              };
            const getSignature = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-signature`,{
                headers:headersList
            })

            if(getSignature?.data) {
               setSignature(getSignature?.data); console.log(getSignature?.data,"getSignature")
            }
        }
        catch(e) {
            if(e) {
                console.log(e,"err")
                
            }
        }
    }
  useEffect(() => {
    const headersList = {
      "Authorization": `Bearer ${Cookies.get('token')}`,
      "Content-Type": "application/json"
    };
    // Fetch ship data from API
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ship`, {
      method: "GET", // Use "GET" method for sending parameters in URL
      headers: headersList
    })
      .then(response => response.json())
      .then(data => setShipData(data.data))
      .catch(error => console.error('Error fetching ship data:', error));
      getSignature()
  }, []);
  const handleDownloadPdf = async (ship) => {
    setShip(ship)
    const element = printRef.current;
    const opt = {
        margin: 0.5,    
        filename: 'invoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
};
  console.log(shipData, "shipDaata")
  const handleLabelRecovery = async (id) => {
    const userId = Cookies.get('id')
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/label-recovery`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
          // You may need to include additional headers such as authorization headers
        },
        body: JSON.stringify({
          shipId: id,
          user: userId,
          isAdmin:true
        }),
      });

      const data = await response.json();

      console.log(data,"dataa===")
      if (data?.status == false) {
        setError(data.err)
      }
      if (data?.data?.LabelResults) {
        // setAmount(data?.data?.amount)
        // setLabelUrl(data?.data?.LabelResults.LabelImage.URL)
        // setReceiptUrl(data?.data?.LabelResults.Receipt.Image.GraphicImage)
        // setBase64Label(data?.data?.LabelResults.LabelImage.GraphicImage)
        // console.log(data?.amount,"data?.data?.amount")
        // await saveInvoice(data?.amount)
      }
      if (data?.message) {
        console.log("call data?.message")
        setMessage(data?.message)
        // setBase64Label(data?.data?.encodedLabel)
        // setAmount(data?.data?.amount)
      }
      // Handle success, e.g., update state or show a success message
    } catch (error) {
      // Handle other errors, e.g., network error
      console.error(error);
      if (error) {
        setError('Network Error!')
      }
    }


  };

  
  // Function to display error message using SweetAlert
  const showErrorAlert = (errorMessage) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage
    });
  };

  // Function to display success message using SweetAlert
  const showSuccessAlert = (successMessage) => {
    Swal.fire({
      icon: 'success',
      title: successMessage
    });
  };

    const generateInvoicePDF = (shipmentData) => {
   
  
      // Set up basic info
      doc.setFontSize(12);
      doc.text('Commercial Invoice', 105, 10, { align: 'center' });
  
      doc.text(`Invoice Number: ${'12effferetertre'}`, 20, 30);
      doc.text(`Date: ${'12/05/2020'}`, 20, 40);
      doc.text(`Shipper: ${'Test User'}`, 20, 50);
      doc.text(`Consignee: ${'Test'}`, 20, 60);
  
      doc.text('Description of Goods:', 20, 80);
      let goods = [
        { description:'test' },
        { description:'test' },
        { description:'test' },
        { description:'test' }




      ]
      let yPosition = 90;
      goods.forEach((item, index) => {
        doc.text(`${index + 1}. ${'test'}`, 20, yPosition);
        doc.text(`Quantity: ${5}`, 140, yPosition);
        doc.text(`Unit Price: ${10}`, 180, yPosition);
        yPosition += 10;
      });
  
      doc.text(`Total Value: ${500}`, 20, yPosition + 10);
      
      // Save the PDF
      doc.save('Commercial_Invoice.pdf');
    };

    const shipmentData = {
        invoiceNumber: 'INV-123456',
        date: '2024-05-28',
        shipperName: 'ABC Logistics',
        consigneeName: 'XYZ Retail',
        goods: [
          { description: 'Laptop', quantity: 2, unitPrice: 1200 },
          { description: 'Smartphone', quantity: 5, unitPrice: 800 },
        ],
        totalValue: 6000,
      };
      

  const handleVoidShipment = async (id) => {
    const userId = Cookies.get('id')
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/void-ship`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
          // You may need to include additional headers such as authorization headers
        },
        body: JSON.stringify({
          shipId: id,
          user: userId,
          isAdmin:true
        }),
      });

      const data = await response.json();
      if (data?.status == false) {
        console.log(data.err[0].message,"data.err[0].message")
        setError(data.err[0].message)
      }
      if(data?.message) {
        console.log("call data?.message 2")
        setMessage(data.message)
      }
      // Handle success, e.g., update state or show a success message
    } catch (error) {
      // Handle other errors, e.g., network error

      if (error) {
        setError('Network Error!')
      }
    }
  };
  return (
    <>
      <Header />
      <Sidebar
                id="menu-item"
                id1="menu-items"
                activeClassName="history"
            />
      <>
      <div style={{ display:'none'}}>
      <div ref={printRef}>
        <div className="letterhead">
          <img className="headerimg" />
        </div>

        <div className="invoice">
          <h1 className="gray">Commercial Invoice</h1>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Date:</strong>
              <span>{new Date().toLocaleDateString('en-GB')}</span>
            </div>
            <div className="box">
              <strong>Destination Country:</strong>
              <span>{ ship?.shipReceiver?.Address?.CountryCode?.value}</span>
            </div>
          </div>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Shipper:</strong>
              <span>{ship?.shipSender?.name}</span>
            </div>
            <div className="box">
              <strong>Consignee:</strong>
              <span>{ship?.shipReceiver?.company}</span>
            </div>
          </div>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Contact:</strong>
              <span>{ship?.shipSender?.name}</span>
            </div>
            <div className="box">
              <strong>Contact:</strong>
              <span>{ship?.shipReceiver?.name}</span>
            </div>
          </div>

          <div className="row">
            <div className="address-box" style={{ paddingLeft:'20px'}}>
              <div><strong>Shipper Address:</strong></div>
              {ship?.shipSender?.Address.AddressLine1 ? <div>{ship?.shipSender?.Address.AddressLine1}</div> : ''}
              {ship?.shipSender?.Address.AddressLine2 ? <div>{ship?.shipSender?.Address.AddressLine2}</div> : ''}
              {ship?.shipSender?.Address.AddressLine3 ? <div>{ship?.shipSender?.Address.AddressLine3}</div> : ''}
             
              <div></div>
              <div></div>
              <div>{ship?.shipSender?.Address.city}, {ship?.shipSender?.Address.state} {ship?.shipSender?.Address.pincode}</div>
              <div>{ship?.shipSender?.CountryCode?.value}</div>
              <div>{ship?.shipSender?.number}</div>
              <div>{ship?.shipSender?.email}</div>
              <br />
              <div><strong>Sender Tax ID/VAT NO:</strong> </div>
            </div>

            <div className="address-box">
              <div><strong>Receiver Address:</strong></div>
              {ship?.shipReceiver?.Address.AddressLine1 ? <div>{ship?.shipReceiver?.Address.AddressLine1}</div> : ''}
              {ship?.shipReceiver?.Address.AddressLine2 ? <div>{ship?.shipReceiver?.Address.AddressLine2}</div> : ''}
              {ship?.shipReceiver?.Address.AddressLine3 ? <div>{ship?.shipReceiver?.Address.AddressLine3}</div> : ''}
             
              <div></div>
              <div></div>
              <div>{ship?.shipReceiver?.Address.city}, {ship?.shipReceiver?.Address.state} {ship?.shipReceiver?.Address.pincode}</div>
              <div>{ship?.shipReceiver?.CountryCode?.value}</div>
              <div>{ship?.shipReceiver?.number}</div>
              <div>{ship?.shipReceiver?.email}</div>
              <br />
              <div><strong>Receiver Tax ID/VAT NO:</strong> </div>
            </div>

            <div className="address-box">
              <div><strong>Importer of Record:</strong></div>

              {ship?.shipReceiver?.Address.AddressLine1 ? <div>{ship?.shipReceiver?.Address.AddressLine1}</div> : ''}
              {ship?.shipReceiver?.Address.AddressLine2 ? <div>{ship?.shipReceiver?.Address.AddressLine2}</div> : ''}
              {ship?.shipReceiver?.Address.AddressLine3 ? <div>{ship?.shipReceiver?.Address.AddressLine3}</div> : ''}
             
              <div></div>
              <div></div>
              <div>{ship?.shipReceiver?.Address.city}, {ship?.shipReceiver?.Address.state} {ship?.shipReceiver?.Address.pincode}</div>
              <div>{ship?.shipReceiver?.CountryCode?.value}</div>
              <div>{ship?.shipReceiver?.number}</div>
              <div>{ship?.shipReceiver?.email}</div>

             
            </div>
          </div>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Carrier:</strong>
              <span>{ship?.isFedEx ? 'FedEx' : 'UPS' }</span>
            </div>
            <div className="box">
              <strong>Tracking Number:</strong>
              <span>{ship?.output?.transactionShipments[0].masterTrackingNumber ? ship?.output?.transactionShipments[0].masterTrackingNumber : ship?.shipResult?.PackageResults.TrackingNumber}</span>
            </div>
            <div className="box">
              <strong>Shipper Ref:</strong>
              <span>ps:701-3620445-8660260</span>
            </div>
          </div>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Number of Pieces:</strong>
              <span>1</span>
            </div>
            <div className="box">
              <strong>Total Weight:</strong>
              <span>{ship?.package?.PackageWeight?.totalWeight?.weight} </span>
            </div>
            <div className="box">
              <strong>ITN#:</strong>
              <span></span>
            </div>
            <div className="box">
              <strong>Terms of Trade:</strong>
              <span></span>
            </div>
          </div>

          <div className="row">
            <div className="box" style={{ paddingLeft:'20px'}}>
              <strong>Package Marks:</strong>
              <span></span>
            </div>
            <div className="box">
              <strong>Shipment Contents:</strong>
              <span></span>
            </div>
          </div>

          <div className="items">
            <table>
              <thead>
                <tr>
                  <th className="text-left">Description of Merchandise</th>
                  <th>HTS#</th>
                  <th>Country of Origin</th>
                  <th>Qty</th>
                  <th>Unit</th>
                  <th>Unit Value</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">Fresh Water Tank</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>0.00 USD</td>
                  <td>0.00 USD</td>
                </tr>
                <tr>
                  <th colspan="6" style={{ textAlign: 'right' }}>Total</th>
                  <td>0.00 USD</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="tarrif-statement">
            I hereby certify that the goods covered by this 
            shipment qualifies as an originating good for the purposes of 
            preferential tariff treatment under USMCA/T-MEC/CUSMA.
          </div>

          <div className='signature'>
            <span>Signature:</span>
            <img  className='paperless' style={{ height: '100%',
    maxHeight: '100px'}} src={base64Image}/>
            <span>Date:  {new Date(signature?.createdAt).toLocaleDateString('en-GB')} </span>
          </div>
        </div>
      </div>
      </div>
        <div className="page-wrapper">

          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-between">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">History</li>
                  </ul>
                  
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Good Morning, <span>Dr.Smith Wayne</span>
                    </h2>
                    <p>Have a nice day at work</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_02} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="doctor-list-blk">
            {error && showErrorAlert(error)}
            {message && showSuccessAlert(message)}

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Ship Name</th>
                    <th>Commercial Invoice</th>
                    {/* <th>User Email</th>
                    <th>Ship To Address</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <th>Date</th>
                    <th>Receiver Country</th> */}
                    {/* <th>Label Recovery</th> */}
                   
                  </tr>
                </thead>
                <tbody>
                  {shipData?.length ? shipData?.map(ship => (
                    <tr key={ship._id}>
                      {console.log(ship, "ship==")}
                      <td>{ship?.description}</td>
                      {/* <td>{ship?.userData?.email}</td> */}
                      {/* <td>{`${ship?.shipSender?.Address?.AddressLine1},${ship?.shipSender?.Address?.city},${ship?.shipSender?.Address?.state},${ship?.shipSender?.Address?.pincode} `}</td> */}
                      {/* <td>{ ship?.invoiceData[0]?.amount ? `$${ship?.invoiceData[0]?.amount}` : '' }</td> */}
                      {/* <td style={{ textTransform:'capitalize', fontWeight:'bold'}}>{ ship?.invoiceData[0]?.status }</td> */}
                      {/* <td>{ ship?.invoiceData[0]?.date ? new Date(ship?.invoiceData[0].date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '' }</td> */}
                      {/* <td style={{ textTransform:'capitalize'}}>{  ship.shipReceiver?.Address?.CountryCode?.label.toLowerCase() }</td> */}
                      <td>
                        <Button onClick={() => handleDownloadPdf(ship)} variant="primary">Commercial Invoice</Button>
                      </td>
                      {/* <td>
                        <Button onClick={() => handleLabelRecovery(ship._id)} variant="primary">Label Recovery</Button>
                      </td> */}
                      
                    </tr>
                  ))
                  : <h1 style={{ color:"red", marginBottom:"10px"}}>Ship Data Not Found!</h1>
                  }
                </tbody>
              </Table>
            </div>
            
          </div>
        </div>
      </>
    </>
  );


};

export default History;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
import { login02, loginlogo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";

import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { loginUser } from "../../../slices/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

// import ReactPasswordToggleIcon from 'react-password-toggle-icon';



const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
console.log(process.env.REACT_APP_BACKEND_URL,"REACT_APP_BACKEND_URL")
  const handleClick = async (e) => {
    e.preventDefault();

    try {

      if (!email || !password) {
        setError("Please fill in all fields.");
        return;
      }

      // Dispatch the loginUser thunk with email and password
      const data = await dispatch(loginUser({ email, password }));
      Cookies.set('token', data.payload.data.token);
      Cookies.set('email', data.payload.data.email);
      Cookies.set('companyName', data.payload.data.companyName);
      Cookies.set('id', data.payload.data._id)
      console.log(data.payload.data.token, "dt")

      const token = Cookies.get('token');
      if (token) {
        console.log(token, "token")
        navigate("/");

      }
      // Redirect to the desired page (e.g., dashboard)
      console.log('Before navigation');
      console.log('After navigation');
    } catch (error) {
      // Handle registration error (e.g., display an error message)
      console.error('Registration failed:', error.message);
      setError(error.message)

    }
  };




  // let inputRef = useRef();
  // const showIcon = () => <i className="feather feather-eye" aria-hidden="true">
  //   <FeatherIcon icon="eye" />
  // </i>;
  // const hideIcon = () => <i className="feather feather-eye-slash" aria-hidden="true">
  //   <FeatherIcon icon="eye-off" />
  // </i>
  return (
    <>

      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="#"
                  />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/">
                          <img width={'200px'} src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <form >
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordVisible ? 'password' : ''}
                            className="form-control pass-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                          </span>
                        </div>
                        {/* <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">Forgot Password?</Link>
                        </div> */}
                        <div className="form-group login-btn">
                          {/* <Link to="/"
                            className="btn btn-primary btn-block"

                          > */}
                          <button className="btn btn-primary btn-block" type="submit" onClick={handleClick}>
                            Login
                          </button>
                          {error && <div className="text-danger">{error}</div>}
                          {/* </Link> */}
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account? <Link to="/signup">Sign Up</Link>
                        </p>
                        {/* Social Login */}
                        {/* <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01} alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon02}alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03}  alt="#"/>
                          </Link>
                        </div> */}
                        {/* /Social Login */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { morning_img_01,  imagesend,
     } from "../../imagepath";
import { useState } from "react";
import AddOrderModal from "../../modal/AddOrderModal";
const Batch = () => {
    const [openOrderModal,setOpenOrderModal] = useState(false)
    return (
        <>
          <Header />
          <Sidebar
            id="menu-item"
            id1="menu-items"
            activeClassName="batch"
          />
          {openOrderModal &&
            <AddOrderModal />

          }
          <>
            <div className="page-wrapper">
              <div className="content">
                {/* Page Header */}
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Batch </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="good-morning-blk">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="morning-user">
                        <h2>
                          <span><i className="fa fa-cube" /> Get Started Shipping</span>
                        </h2>
                        <p>When you start booking shipments, data about them will display here</p>
                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => setOpenOrderModal(true)}>Add Your First Manual Order</button>
                      </div>
                    </div>
                    <div className="col-md-6 position-blk">
                      <div className="morning-img">
                        <img src={morning_img_01}
                          alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
           
              </div>
              <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <img src={imagesend} alt="#" width={50} height={46} />
                      <h3>Are you sure want to delete this ?</h3>
                      <div className="m-t-20">
                        {" "}
                        <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                          Close
                        </Link>
                        <button type="submit" className="btn btn-danger">
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        <img src={imagesend} alt="#" width={50} height={46} />
                        <h3>Are you sure want to delete this ?</h3>
                        <div className="m-t-20">
                          {" "}
                          <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                            Close
                          </Link>
                          <button type="submit" className="btn btn-danger">
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      );
}
export default Batch
/* eslint-disable */
import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { callicon1, callicon2, callicon3 } from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState,useRef } from "react";
import Select from "react-select";
import Cookies from "js-cookie";
import { ButtonGroup, Card, Form, Modal, Toast, ToggleButton, Button, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "../../../slices/userSlice";
import { selectUserProfile } from "../../../slices/userProfileSlice";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import statesData from '../../../states.json'
import { Autocomplete, TextField } from "@mui/material";
const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};
const Ship = () => {
    const user = useSelector(selectUserProfile)

  
    const options = [
        { value: 1, label: 'in / lb / oz' },
        { value: 2, label: 'cm / kg / g' }
    ]
    const batteriesOptions = [
        {value:'none',label:'None'},
        { value: 'lithiumion', label: 'Lithium ion' },
        { value: 'lithium metal', label: 'Lithium metal' },
    ]
    const alcoholRecipientTypeOptions = [
        {value:"",label:"N/A"},
{value:"BAG",label:"Bag"},
{value:"BARREL",label:"Barrel"},
{value:"BASKET",label:"Basket"},
{value:"BOX",label:"Box"},
{value:"BUCKET",label:"Bucket"},
{value:"BUNDLE",label:"Bundle"},
{value:"CARTON",label:"Carton"},
{value:"CASE",label:"Case"},
{value:"CONTAINER",label:"Container"},
{value:"CRATE",label:"Crate"},
{value:"CYLINDER",label:"Cylinder"},
{value:"DRUM",label:"Drum"},
{value:"ENVELOPE",label:"Envelope"},
{value:"HAMPER",label:"Hamper"},
{value:"OTHER",label:"Other"},
{value:"PAIL",label:"Pail"},
{value:"PALLET",label:"Pallet"},
{value:"PIECE",label:"Piece"},
{value:"REEL",label:"Reel"},
{value:"ROLL",label:"Roll"},
{value:"SKID",label:"Skid"},
{value:"TANK",label:"Tank"}

    ]
    const physicalPackagingTypeOptions = [
        { value:"",label:"N/A"},
        { value:"BAG",label:"Bag"},
        { value:"BARREL",label:"Barrel"},
        { value:"BASKET",label:"Basket"},
        { value:"BOX",label:"Box"},
        { value:"BUCKET",label:"Bucket"},
        { value:"BUNDLE",label:"Bundle"},
        { value:"CARTON",label:"Carton"},
        { value:"CASE",label:"Case"},
        { value:"CONTAINER",label:"Container"},
        { value:"CRATE",label:"Crate"},
        { value:"CYLINDER",label:"Cylinder"},
        { value:"DRUM",label:"Drum"},
        { value:"ENVELOPE",label:"Envelope"},
        { value:"HAMPER",label:"Hamper"},
        { value:"OTHER",label:"Other"},
        { value:"PAIL",label:"Pail"},
        { value:"PALLET",label:"Pallet"},
        { value:"PIECE",label:"Piece"},
        { value:"REEL",label:"Reel"},
        { value:"ROLL",label:"Roll"},
        { value:"SKID",label:"Skid"},
        { value:"TANK",label:"Tank"},
        { value:"TUBE",label:"Tube"},
    ]
    const insuranceOptions = [
        { value: 'general', label: 'General' },
        { value: 'laptoplcdtv', label: 'Laptop,LCD,TV' },
        { value: 'glassware', label: 'Ceramics' },
        { value: 'jewelry', label: 'Jewelry' }

    ]

    const billingPartyOption = [
        { value: "receiver", label: "Receiver" },
        { value: "sender", label: 'Sender' },
        { value: "thirdparty", label: 'Third Party' },

    ]
    const invoiceTypeOptions = [
        { value: "commercial", label: "Commercial" },
        { value: "proforma", label: "Proforma" },
    ]
    const paperlessOptions = [
        { value:false, label: "No" },
        { value: true, label: "Yes" },
       
    ]

   
 
    // const recordImporterOption = [
    //     { value: "receiver", label: "Receiver" },
    //     { value: "121", label: "Ups NRI" },
    //     { value: "109", label: "Gulay Cevher" },
    // ]
    const termsOfTradeOptions = [
        { value: "DAP", label: "DAP - Delivered at Place" },
        { value: "CIP", label: "CIP - Costs, Insurance Paid" },
        { value: "CPT", label: "CPT - Carriage Paid To" },
        { value: "DAT", label: "DAT - Delivered at Terminal" },
        { value: "DDP", label: "DDP - Delivered Duty Paid" },
        { value: "EXW", label: "EXW - Ex Works" },
        { value: "FCA", label: "FCA - Free Carrier" },
    ]
    const shipperTINTypeOptions = [
        { value:"BUSINESS_NATIONAL",label:"Business: National"},
{ value:"BUSINESS_STATE",label:"Business: State"},
{ value:"BUSINESS_UNION",label:"Business: Union"},
{ value:"PERSONAL_NATIONAL",label:"Personal: National"},
{ value:"PERSONAL_STATE",label:"Personal: State"},
    ]
    const ftrCodeOptions = [
        { value: "30.37(a)", label: "30.37(a)" },
        { value: "30.36", label: "30,36" },
        { value: "30.37(b)", label: "30.37(b)" },
        { value: "30.37(f)", label: "30.37(f)" },
        { value: "30.37(g)", label: "30.37(g)" },
        { value: "30.37(h)", label: "30.37(h)" },
        { value: "30.37(i)", label: "30.37(i)" },
        { value: "30.37(j)", label: "30.37(j)" },
        { value: "30.37(k)", label: "30.37(k)" },
        { value: "30.37(o)", label: "30.37(o)" },
        { value: "30.37(q)", label: "30.37(q)" },
        { value: "30.37(r)", label: "30.37(r)" },
        { value: "30.37(s)", label: "30.37(s)" },
        { value: "30.37(t)", label: "30.37(t)" },
        { value: "30.37(u)", label: "30.37(u)" },
        { value: "30.37(w)", label: "30.37(w)" },
        { value: "30.37(x)", label: "30.37(x)" },
        { value: "30.37(y)", label: "30.37(y)" },
        { value: "30.39", label: "30,39" },
        { value: "30.40(a)", label: "30.40(a)" },
        { value: "30.40(b)", label: "30.40(b)" },
        { value: "30.40(c)", label: "30.40(c)" },
        { value: "30.40(d)", label: "30.40(d)" },
        { value: "30.2(d)(2)", label: "30.2(d)(2)" },
    ]
    const navigate = useNavigate()
    const [billingParty, setBillingParty] = useState( { value: "sender", label: 'Sender' });
    const [isResidential, setIsResidential] = useState(false);
    const [termsOfTrade, setTermsOfTrade] = useState({ value: "DAP", label: "DAP - Delivered at Place" })
    const [dutiableCountry, setDutiableCountry] = useState(null);
    const [customerPackageOptions,setCustomerPackageOptions] = useState([])
    const [customePackageDetails,setCustomPackageDetails] = useState([])
    const [selectedCustomPackage,setSelectedCustomPackage] = useState(null)
    const [additionlDelivery,setAdditionalDelivery] = useState(false)
    const [nonStandardContainer,setNonStandardContainer] = useState(false)
    const [saturdayPickup,setSaturdayPickup] = useState(false)
    const [selectedBatteries,setSelectedBatteries] = useState(batteriesOptions[0])
    const [contentTypeOptions,setContentTypeOptions] = useState([
        { value: "goods", label: "Goods" },
        { value: "document", label: 'Document' },
    ])
    const [carbonNeutral,setCarbonNeutral] = useState(false)
    const [duatiablePartyOption,setDutiablePartyOptions] = useState([
        { value: "receiver", label: "Receiver" },
       
    ])
    const [signatureOptions,setSignatureOptions] = useState([])
    const [returnService,setReturnService] = useState(false)
    const [originCountry, setOriginCountry] = useState(null)
    const [ftrCode, setFtrCode] = useState(null);
    const [itnNumber, setItnNumber] = useState(null);
    const [taxIdNumber, setTaxIdNumber] = useState(null);
    const [senderDetails, setSenderDetails] = useState(false);
    const [receiverDetails, setReceiverDetails] = useState(false)
    const [senderName, setSenderName] = useState(null);
    const [receiverName, setReceiverName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [receiverCompany, setReceiverCompany] = useState(null);
    const [senderCompany, setSenderCompany] = useState(null);
    const [receiverEmail, setReceiverEmail] = useState(null);
    const [receiverPhone, setReceiverPhone] = useState(null);
    const [senderEmail, setSenderEmail] = useState(null);
    const [senderPhone, setSenderPhone] = useState(null);
    const [senderAddress1, setSenderAddress1] = useState(null);
    const [senderAddress2, setSenderAddress2] = useState(null);
    const [senderAddress3, setSenderAddress3] = useState(null);
    const [senderZipCode, setSenderZipCode] = useState(null);
    const [senderCity, setSenderCity] = useState(null);
    const [senderState, setSenderState] = useState(null);
    const [receiverZipCode, setReceiverZipCode] = useState(null);
    const [receiverCity, setReceiverCity] = useState(null);
    const [receiverState, setReceiverState] = useState(null);
    const [receiverAddress1, setReceiverAddress1] = useState(null);
    const [receiverAddress2, setReceiverAddress2] = useState(null);
    const [receiverAddress3, setReceiverAddress3] = useState(null);
    const [senderCountry, setSenderCountry] = useState({ value: 'US', label: 'UNITED STATES' })
    const [signatureOption,setSignatureOption] = useState([
        { value: "nodeliveryconfirmation", label: "No Delivery Confirmation" },
        { value: "deliveryconfiration", label: 'Delivery Confirmation(No Signature)' },
        { value: "signaturerequired", label: 'Signature Required' },
        { value: 'adultsignture', label: 'Adlut Signature Required' }

    ])
    const [showUpsLocation,setShowUpsLocation] = useState(false)
    const [accessPointeId,setAccessPointId] = useState("")
    const [upsLocationName,setUpsLocationName] = useState("")   
    
    
const
[upsLocationAddressLine1,setUpsLocationAddressLine1] = useState("")  
const
[upsLocationAddressLine2,setUpsLocationAddressLine2] = useState("") 
const
[upsLocationAddressLine3,setUpsLocationAddressLine3] = useState("") 
     
    const [upsLocationCity,setUpsLocationCity] = useState("")
    const [upsLocationStatePostalCode,setUpsLocationStatePostalCode] = useState("")
    const [upsLocationCountry,setUpsLocationCountry] = useState(null)
    const [upsLocationStateProvinceCode,setUpsLocationStateProvinceCode] = useState("")
    const [defaultSenderState, setDefaultSenderState] = useState(null)
    const [defaultReceiverState, setDefaultReceiverState] = useState(null)
    const [paperless,setPaperless] = useState(paperlessOptions[0])
    const [selectedUnit, setUnit] = useState(options[0])
    const [isVisibleInsurance, setIsVisibleInsurance] = useState(false)
    const [selectedInsurance, setInsurance] = useState(insuranceOptions[0])
    const [customeAmount,setCustomeAmount] = useState(null)
    const [signature, setSignature] = useState(signatureOption[0])
    const [response, setResponse] = useState(null);
    const [billingAccountZip, setBillingAccountZip] = useState(null);
    const [selectedAlcoholRecipientType,setSelectedAlcoholRecipientType] = useState({})
    const [receiverCountry, setReceiverCountry] = useState({ value: "AF", label: "AFGHANISTAN" })
    const [billingPartyCountry, setBillingPartyCountry] = useState({ value: "AF", label: "AFGHANISTAN" })

    const [isVisibleCity, setIsVisibleCity] = useState(true);
    const [isVisibleZipCode, setIsVisibleZipCode] = useState(false);
    const [isVisibleState, setIsVisibleState] = useState(false);
    const [shipDate, setShipDate] = useState(null)
    const [dimension, setDimension] = useState({})
    const [shipError, setShipError] = useState([])
    const [currentShipId, setCurrentShipId] = useState(null)
    const [description, setDescription] = useState(null)
    const [shipResult, setShipResult] = useState({})
    const [labelImage, setLabelImage] = useState(null)
    const [contentType, setContentType] = useState(null)
    const [showToast, setShowToast] = useState(false);
    const [dutiableParty, setDutiableParty] = useState({ value: 'receiver', label: 'Receiver' })
    const [recordImporter, setRecordImporter] = useState({ value: 'receiver', label: 'Receiver' })
    const [notificationToast, setNotificationToast] = useState(false)
    const [commercialInvoice, setCommercialInvoice] = useState(false)
    const [commercialInvoiceOunces,setCommercialInvoiceOunces] = useState(0)
    const [invoiceType, setInvoiceType] = useState({ value: "commercial", label: "Commercial" })
    const [commoditiesOption, setCommoditiesOption] = useState([])
    const [csvIntegrationData, setCsvIntegrationData] = useState([])
    const [selectedIntegration, setSelectedIntegration] = useState(null)
    const [bulkShipData, setBulkShipData] = useState([])
    const [showCsvModal, setShowCsvModal] = useState(false)
    const [referenceNumber, setReferenceNumber] = useState(null)
    const [firstKey, setFirstKey] = useState(null)
    const [formGroups, setFormGroups] = useState([]);
    const [suggestedAddress,setSuggestedAddress] = useState([])
    const [shipLength, setShipLength] = useState(null)
    const [shipWidth, setShipWidth] = useState(null)
    const [shipHeight, setShipHeight] = useState(null)
    const [shipWeight, setShipWeight] = useState(0);
    const [ttlWeight, setTtlWeight] = useState(0);
    const [totalCommercialInvoiceWeight, setTotalCommercialInvoiceWeight] = useState(0);
    const [totalOuncesGrams, setTotalOuncesGrams] = useState(0)
    const [totalCustomeAmount, setTotalCustomeAmount] = useState(0)
    const [recordImporterOption,setRecordImporterOption] = useState([{ value: "receiver", label: "Receiver" }])
    const [ttlOunces,setTtlOunces] = useState(0);
    const [isVisibleCommodityPlus, setIsVisibleCommodityPlus] = useState(true)
    const [commodityFormGroups, setCommodityFormGroup] = useState([])
    const [commodityQuantity, setCommodityQuantity] = useState(0)
    const [commodityWeight, setCommodityWeight] = useState(0)
    const [selectedCertificateFile, setSelectedCertificateFile] = useState(null);
    const [workflowName, setWorkflowName] = useState('');
    const [carrierCode, setCarrierCode] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileContentType, setFileContentType] = useState('');
    const [meta, setMeta] = useState({}); // Assuming this is an object with necessary metadata

    const handleCertificateButtonClick = () => {
        document.getElementById('csvFileInput').click();
    };

    const handleCertificateFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);

            // Define your metadata here
            const workflowName = 'ETDPreshipment'; // or 'ETDPostshipment'
            const carrierCode = 'FDXE'; // or 'FDXG', etc.
            const fileName = file.name;
            const contentType = file.type; // Automatically detect content type

            const meta = {
                // Add any other metadata required for the API here
                shipmentId: '12345',
                sender: 'Company XYZ',
                receiver: 'Company ABC'
            };

            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('document', JSON.stringify({
                workflowName,
                carrierCode,
                name: fileName,
                contentType,
                meta
            }));

            // Replace with your API endpoint
            const apiEndpoint = 'https://documentapitest.prod.fedex.com/sandbox/documents/v1/etds/upload';

            try {
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();
                console.log('Success:', data);
                // Handle success (e.g., show a success message, update the state, etc.)
            } catch (error) {
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        }
    };



    const handleFileUpload = () => {
        if (!selectedCertificateFile) {
            alert("Please select a file first!");
            return;
        }

        if (!workflowName || !carrierCode || !fileName || !contentType || !meta) {
            alert("Please provide all required details!");
            return;
        }

        const formData = new FormData();
        formData.append('attachment', selectedFile);
        formData.append('document', JSON.stringify({
            workflowName,
            carrierCode,
            name: fileName,
            contentType,
            meta
        }));

        // Replace with your API endpoint
        const apiEndpoint = 'YOUR_API_ENDPOINT';

        fetch(apiEndpoint, {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle success (e.g., show a success message, update the state, etc.)
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle error (e.g., show an error message)
        });
    };

 

   

    const handleCommodityChange = (id, field, value) => {
        const updatedFormGroups = commodityFormGroups.map((group) => {
            if (group.id === id) {
                return { ...group, [field]: value };
            }
            return group;
        });
        setCommodityFormGroup(updatedFormGroups);
        updateTotals(updatedFormGroups);
    };

    const handleRemoveCommodityFormGroup = (index) => {
        const updatedFormGroups = commodityFormGroups.filter((_, i) => i !== index);
        setCommodityFormGroup(updatedFormGroups);
        updateTotals(updatedFormGroups);
    };
    useEffect(() => {
        console.log(customerPackageOptions,"customerPackageOptions=>")
    },[customerPackageOptions])

    const updateTotals = (groups) => {
        const totalQuantity = groups.reduce((acc, group) => acc + (parseFloat(group.quantity) || 0), 0);
        let totalWeight = groups.reduce((acc, group) => {
            let weight = parseFloat(group.weight) || 0;
            let ouncesGrams = parseFloat(group.ounces) || 0;
    
            // Convert ounces to pounds if selectedUnit is 1 (assuming 1 is pounds and 2 is kilograms)
            if (selectedUnit?.value === 1) {
                weight += ouncesGrams / 16;
            } else if (selectedUnit?.value === 2) {
                // Convert grams to kilograms
                weight += ouncesGrams / 1000;
            }
    
            return acc + weight;
        }, 0);
        let totalOunces = groups.reduce((acc, group) => {
            
            let ouncesGrams = parseFloat(group.ounces) || 0;
    
           
    
            return acc + ouncesGrams;
        }, 0);
        console.log(totalOunces,"totalOunces==>>>")
        
        setTtlQty(totalQuantity);
        setTotalCommercialInvoiceWeight(totalWeight);
        setTtlOunces(totalOunces)
    };

    useEffect(() => {
        updateTotals(commodityFormGroups);
    }, [commodityFormGroups]);

    const handleAddFormGroup = () => {
        setFormGroups([...formGroups, { weight:0, length: shipLength, width: shipWidth, height: shipHeight,ouncesGrams: '',insurance:{ value: 'general', label: 'General' } ,customeAmount:0 }]);
    };
    const handleAddCommodityFormGroup = () => {
        setCommodityFormGroup([
            ...commodityFormGroups,
            { id: Date.now(), contentDescription: '', unitValue: '', quantity: 0, weight: 0, ounces: '' }
        ]);
    }

    const handleCheckboxChange = (e) => {
        setShowToast(e.target.checked);
    };
    const handleInputChange = (index, field, value) => {
        const newFormGroups = formGroups.map((group, i) =>
            i === index ? { ...group, [field]: value } : group
        );
        setFormGroups(newFormGroups);

    };

    const handleSendNotification = (e) => {
        setNotificationToast(e.target
            .checked)
    }
    const handleCommercialInvoice = (e) => {
        setCommercialInvoice(e.target.checked)
        if (error) {
            setError(null)
        }
    }

    useEffect(() => {
        // Calculate total weight and quantity
        const totalWeight = formGroups.reduce((sum, group) => sum + parseFloat(group.weight || 0), 0);
        const totalOunces = formGroups.reduce((sum, group) => sum + parseFloat(group.ouncesGrams || 0), 0);
        const totalCustomeAmountData =  formGroups.reduce((sum, group) => sum + parseFloat(group.customeAmount || 0), 0);
        console.log(totalCustomeAmountData,"totalCustomeAmount==>>>")
        setTotalOuncesGrams(totalOunces)
        setTtlWeight(totalWeight);
        setTotalCustomeAmount(totalCustomeAmountData)
        const quantity = formGroups.length + 1
        setTtlQty(quantity);
    }, [formGroups]);

    // const [selectedOption, setSelectedOption] = useState(null);
    // const [option, setOption] = useState([
    //   { value: 1, label: "Select City" },
    //   { value: 2, label: "California" },
    //   { value: 3, label: "Tasmania" },
    //   { value: 4, label: "Auckland" },
    //   { value: 5, label: "Marlborough" },
    // ]);

    const senderCountryOptions = [
        { value: "US", label: "UNITED STATES" }
    ];

    const receiverCountryOptions = [
        { value: "AF", label: "AFGHANISTAN" },
        { value: "AX", label: "ALAND ISLANDS" },
        { value: "AL", label: "ALBANIA" },
        { value: "DZ", label: "ALGERIA" },
        { value: "AS", label: "AMERICAN SAMOA" },
        { value: "AD", label: "ANDORRA" },
        { value: "AO", label: "ANGOLA" },
        { value: "AI", label: "ANGUILLA" },
        { value: "AQ", label: "ANTARCTICA" },
        { value: "AG", label: "ANTIGUA / BARBUDA" },
        { value: "AR", label: "ARGENTINA" },
        { value: "AM", label: "ARMENIA" },
        { value: "AW", label: "ARUBA" },
        { value: "AU", label: "AUSTRALIA" },
        { value: "CX", label: "AUSTRALIA (CHRISTMAS ISLANDS)" },
        { value: "CC", label: "AUSTRALIA (COCOS KEELING ISLANDS)" },
        { value: "NF", label: "AUSTRALIA (NORFOLK ISLANDS)" },
        { value: "AT", label: "AUSTRIA" },
        { value: "AZ", label: "AZERBAIJAN" },
        { value: "BS", label: "BAHAMAS" },
        { value: "BH", label: "BAHRAIN" },
        { value: "BD", label: "BANGLADESH" },
        { value: "BB", label: "BARBADOS" },
        { value: "BY", label: "BELARUS" },
        { value: "BE", label: "BELGIUM" },
        { value: "BZ", label: "BELIZE" },
        { value: "BJ", label: "BENIN" },
        { value: "BM", label: "BERMUDA" },
        { value: "BT", label: "BHUTAN" },
        { value: "BO", label: "BOLIVIA" },
        { value: "XB", label: "BONAIRE (NETHERLANDS ANTILLES)" },
        { value: "BA", label: "BOSNIA / HERZEGOVINA" },
        { value: "BW", label: "BOTSWANA" },
        { value: "BV", label: "BOUVET ISLAND" },
        { value: "BR", label: "BRAZIL" },
        { value: "IO", label: "BRITISH INDIAN OCEAN TERRITORY" },
        { value: "BN", label: "BRUNEI" },
        { value: "BG", label: "BULGARIA" },
        { value: "BF", label: "BURKINA FASO" },
        { value: "BI", label: "BURUNDI" },
        { value: "KH", label: "CAMBODIA" },
        { value: "CM", label: "CAMEROON" },
        { value: "CA", label: "CANADA" },
        { value: "IC", label: "CANARY ISLANDS" },
        { value: "CV", label: "CAPE VERDE" },
        { value: "KY", label: "CAYMAN ISLANDS" },
        { value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
        { value: "TD", label: "CHAD" },
        { value: "CL", label: "CHILE" },
        { value: "CN", label: "CHINA" },
        { value: "CO", label: "COLOMBIA" },
        { value: "KM", label: "COMOROS" },
        { value: "CG", label: "CONGO" },
        { value: "CD", label: "CONGO, DEMOCRATIC REPUBLIC OF THE" },
        { value: "CK", label: "COOK ISLANDS" },
        { value: "CR", label: "COSTA RICA" },
        { value: "HR", label: "CROATIA" },
        { value: "CU", label: "CUBA" },
        { value: "CW", label: "CURACAO" },
        { value: "CY", label: "CYPRUS" },
        { value: "CZ", label: "CZECH REPUBLIC" },
        { value: "DK", label: "DENMARK" },
        { value: "DJ", label: "DJIBOUTI" },
        { value: "DM", label: "DOMINICA" },
        { value: "DO", label: "DOMINICAN REPUBLIC" },
        { value: "TL", label: "EAST TIMOR" },
        { value: "EC", label: "ECUADOR" },
        { value: "EG", label: "EGYPT" },
        { value: "SV", label: "EL SALVADOR" },
        { value: "ER", label: "ERITREA" },
        { value: "EE", label: "ESTONIA" },
        { value: "ET", label: "ETHIOPIA" },
        { value: "FK", label: "FALKLAND ISLANDS (MALVINAS)" },
        { value: "FO", label: "FAROE ISLANDS" },
        { value: "FJ", label: "FIJI" },
        { value: "WF", label: "FIJI (WALLIS / FUTUNA ISLANDS)" },
        { value: "FI", label: "FINLAND" },
        { value: "FR", label: "FRANCE" },
        { value: "GF", label: "FRENCH GUYANA" },
        { value: "PF", label: "FRENCH POLYNESIA" },
        { value: "TF", label: "FRENCH SOUTHERN TERRITORIES" },
        { value: "GA", label: "GABON" },
        { value: "GM", label: "GAMBIA" },
        { value: "GE", label: "GEORGIA" },
        { value: "DE", label: "GERMANY" },
        { value: "GH", label: "GHANA" },
        { value: "GI", label: "GIBRALTAR" },
        { value: "GR", label: "GREECE" },
        { value: "GL", label: "GREENLAND" },
        { value: "GD", label: "GRENADA" },
        { value: "GP", label: "GUADELOUPE" },
        { value: "GU", label: "GUAM" },
        { value: "GT", label: "GUATEMALA" },
        { value: "GG", label: "GUERNSEY" },
        { value: "GQ", label: "GUINEA (EQUATORIAL GUINEA)" },
        { value: "GW", label: "GUINEA BISSAU" },
        { value: "GN", label: "GUINEA REPUBLIC (GUINEA)" },
        { value: "GY", label: "GUYANA (BRITISH)" },
        { value: "HT", label: "HAITI" },
        { value: "HM", label: "HEARD ISLAND AND MCDONALD ISLANDS" },
        { value: "HN", label: "HONDURAS" },
        { value: "HK", label: "HONG KONG" },
        { value: "HU", label: "HUNGARY" },
        { value: "IS", label: "ICELAND" },
        { value: "IN", label: "INDIA" },
        { value: "ID", label: "INDONESIA" },
        { value: "IR", label: "IRAN (ISLAMIC REPUBLIC OF)" },
        { value: "IQ", label: "IRAQ" },
        { value: "IE", label: "IRELAND, REPUBLIC OF (IRELAND)" },
        { value: "IL", label: "ISRAEL" },
        { value: "IT", label: "ITALY" },
        { value: "SM", label: "ITALY (SAN MARINO)" },
        { value: "VA", label: "ITALY (VATICAN CITY)" },
        { value: "CI", label: "IVORY COAST" },
        { value: "JM", label: "JAMAICA" },
        { value: "JP", label: "JAPAN" },
        { value: "JE", label: "JERSEY" },
        { value: "JO", label: "JORDAN" },
        { value: "KZ", label: "KAZAKHSTAN" },
        { value: "KE", label: "KENYA" },
        { value: "KI", label: "KIRIBATI" },
        { value: "KR", label: "KOREA, REPUBLIC OF (KOREA SOUTH)" },
        { value: "KP", label: "KOREA, THE D.P.R. OF (KOREA NORTH)" },
        { value: "KV", label: "KOSOVO" },
        { value: "KW", label: "KUWAIT" },
        { value: "KG", label: "KYRGYZSTAN" },
        { value: "LA", label: "LAOS" },
        { value: "LV", label: "LATVIA" },
        { value: "LB", label: "LEBANON" },
        { value: "LS", label: "LESOTHO" },
        { value: "LR", label: "LIBERIA" },
        { value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
        { value: "LI", label: "LIECHTENSTEIN" },
        { value: "LT", label: "LITHUANIA" },
        { value: "LU", label: "LUXEMBOURG" },
        { value: "MO", label: "MACAO" },
        { value: "MK", label: "MACEDONIA, REPUBLIC OF (FYROM)" },
        { value: "MG", label: "MADAGASCAR" },
        { value: "MW", label: "MALAWI" },
        { value: "MY", label: "MALAYSIA" },
        { value: "MV", label: "MALDIVES" },
        { value: "ML", label: "MALI" },
        { value: "MT", label: "MALTA" },
        { value: "MH", label: "MARSHALL ISLANDS" },
        { value: "MQ", label: "MARTINIQUE" },
        { value: "MR", label: "MAURITANIA" },
        { value: "MU", label: "MAURITIUS" },
        { value: "YT", label: "MAYOTTE" },
        { value: "MX", label: "MEXICO" },
        { value: "FM", label: "MICRONESIA, FEDERATED STATES OF" },
        { value: "MD", label: "MOLDOVA, REPUBLIC OF" },
        { value: "MC", label: "MONACO" },
        { value: "MN", label: "MONGOLIA" },
        { value: "ME", label: "MONTENEGRO" },
        { value: "MS", label: "MONTSERRAT" },
        { value: "MA", label: "MOROCCO" },
        { value: "MZ", label: "MOZAMBIQUE" },
        { value: "MM", label: "MYANMAR" },
        { value: "NA", label: "NAMIBIA" },
        { value: "NR", label: "NAURU, REPUBLIC OF" },
        { value: "NP", label: "NEPAL" },
        { value: "NL", label: "NETHERLANDS" },
        { value: "AN", label: "NETHERLANDS ANTILLES" },
        { value: "NC", label: "NEW CALEDONIA" },
        { value: "NZ", label: "NEW ZEALAND" },
        { value: "NI", label: "NICARAGUA" },
        { value: "NE", label: "NIGER" },
        { value: "NG", label: "NIGERIA" },
        { value: "NU", label: "NIUE" },
        { value: "MP", label: "NORTHERN MARIANA ISLANDS" },
        { value: "NO", label: "NORWAY" },
        { value: "OM", label: "OMAN" },
        { value: "PK", label: "PAKISTAN" },
        { value: "PW", label: "PALAU" },
        { value: "PS", label: "PALESTINIAN TERRITORY, OCCUPIED" },
        { value: "PA", label: "PANAMA" },
        { value: "PG", label: "PAPUA NEW GUINEA" },
        { value: "PY", label: "PARAGUAY" },
        { value: "PE", label: "PERU" },
        { value: "PH", label: "PHILIPPINES" },
        { value: "PN", label: "PITCAIRN" },
        { value: "PL", label: "POLAND" },
        { value: "PT", label: "PORTUGAL" },
        { value: "PR", label: "PUERTO RICO" },
        { value: "QA", label: "QATAR" },
        { value: "RE", label: "REUNION ISLANDS" },
        { value: "RO", label: "ROMANIA" },
        { value: "RU", label: "RUSSIAN FEDERATION (RUSSIA)" },
        { value: "RW", label: "RWANDA" },
        { value: "PM", label: "SAINT PIERRE AND MIQUELON" },
        { value: "WS", label: "SAMOA" },
        { value: "ST", label: "SAO TOME / PRINCIPE" },
        { value: "SA", label: "SAUDI ARABIA" },
        { value: "SN", label: "SENEGAL" },
        { value: "RS", label: "SERBIA" },
        { value: "SC", label: "SEYCHELLES" },
        { value: "SL", label: "SIERRA LEONE" },
        { value: "SG", label: "SINGAPORE" },
        { value: "SK", label: "SLOVAKIA" },
        { value: "SI", label: "SLOVENIA" },
        { value: "SB", label: "SOLOMON ISLANDS" },
        { value: "SO", label: "SOMALIA" },
        { value: "XS", label: "SOMALILAND, REP OF (NORTH SOMALIA)" },
        { value: "ZA", label: "SOUTH AFRICA" },
        { value: "SH", label: "SOUTH AFRICA (ST HELENA)" },
        { value: "GS", label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
        { value: "SS", label: "SOUTH SUDAN" },
        { value: "ES", label: "SPAIN" },
        { value: "LK", label: "SRI LANKA" },
        { value: "XY", label: "ST BARTHELEMY" },
        { value: "XE", label: "ST EUSTATIUS (NETHERLANDS ANTILLES)" },
        { value: "KN", label: "ST KITTS AND NEVIS" },
        { value: "LC", label: "ST LUCIA" },
        { value: "XM", label: "ST MAARTEN (NETHERLANDS ANTILLES)" },
        { value: "VC", label: "ST VINCENT / GRENADINE" },
        { value: "SD", label: "SUDAN" },
        { value: "SR", label: "SURINAME (SURINAM)" },
        { value: "SJ", label: "SVALBARD AND JAN MAYEN" },
        { value: "SZ", label: "SWAZILAND" },
        { value: "SE", label: "SWEDEN" },
        { value: "CH", label: "SWITZERLAND" },
        { value: "SY", label: "SYRIA" },
        { value: "TW", label: "TAIWAN" },
        { value: "TJ", label: "TAJIKISTAN" },
        { value: "TZ", label: "TANZANIA, UNITED REPUBLIC OF" },
        { value: "TH", label: "THAILAND" },
        { value: "TG", label: "TOGO" },
        { value: "TK", label: "TOKELAU" },
        { value: "TO", label: "TONGA" },
        { value: "TT", label: "TRINIDAD / TOBAGO" },
        { value: "TN", label: "TUNISIA" },
        { value: "TR", label: "TURKEY" },
        { value: "TM", label: "TURKMENISTAN" },
        { value: "TC", label: "TURKS / CAICOS ISLANDS" },
        { value: "TV", label: "TUVALU" },
        { value: "UG", label: "UGANDA" },
        { value: "UA", label: "UKRAINE" },
        { value: "AE", label: "UNITED ARAB EMIRATES" },
        { value: "GB", label: "UNITED KINGDOM" },
        { value: "US", label: "UNITED STATES" },
        { value: "UM", label: "UNITED STATES MINOR OUTLYING ISLANDS" },
        { value: "UY", label: "URUGUAY" },
        { value: "UZ", label: "UZBEKISTAN" },
        { value: "VU", label: "VANUATU" },
        { value: "VE", label: "VENEZUELA" },
        { value: "VN", label: "VIETNAM" },
        { value: "VG", label: "VIRGIN ISLANDS (BRITISH)" },
        { value: "VI", label: "VIRGIN ISLANDS (USA)" },
        { value: "EH", label: "WESTERN SAHARA" },
        { value: "YE", label: "YEMEN" },
        { value: "ZM", label: "ZAMBIA" },
        { value: "ZW", label: "ZIMBABWE" },
    ];
    const [addId, setAddId] = useState(null)
    const [userShip, setUserShip] = useState([])
    const updateSenderDetails = async () => {
        if (addId) {
            try {
                console.log(senderState.state, "--state")
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-address/${addId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify({
                        type: 'sender',
                        name: senderName, // Replace with the actual field names from your form
                        company: senderCompany,
                        zipCode: senderZipCode,
                        city: senderCity,
                        state: senderState?.value,
                        address1: senderAddress1,
                        address2: senderAddress2,
                        address3: senderAddress3,
                        email: senderEmail,
                        phone: senderPhone,
                        country: senderCountry?.value, // Assuming senderCountry is an object with value and label properties
                    }),
                });

                if (!response.ok) {
                    // Handle the error, e.g., show an error message
                    console.error(`HTTP error! Status: ${response.status}`);
                    return;
                }

                const data = await response.json();
                console.log(data); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-address`;
            const requestData = {
                type: 'sender',
                country: senderCountry?.value,
                zip: senderZipCode,
                state: senderState.value,
                city: senderCity,
                address1: senderAddress1,
                address2: senderAddress2,
                address3: senderAddress3,
                user: Cookies.get('id'),
                phone: senderPhone,
                name: senderName
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {

                    setResponse(data);
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }

    };
    const [activeButton, setActiveButton] = useState(1)
    const [service, setService] = useState(null)
    const [packageOfService, setPackageOfService] = useState(null)
    const prevPackageOfServiceRef = useRef();
    const [shipName, setShipName] = useState(null)
    const [error, setError] = useState(null);
    const [ouncesGrams, setOuncesGrams] = useState(null)
    const [ttlQty, setTtlQty] = useState(null);
    const [dutiableAccountZip, setDutiableAccountZip] = useState(null);

    const [dutiableAccount, setDutiableAccount] = useState(null);

    const [hts, setHts] = useState(null)
    const [chargeAmount, setChargeAmount] = useState('')
    const [packageReleaseCode, setPackageReleaseCode] = useState(null)
    const [upsLocation, setUpsLocation] = useState(null)
    const [shipperTIN, setShipperTIN] = useState(null)
    const [shipperTINType, setShipperTINType] = useState({})
    const [exportComplianceStatement,setExportCompilanceStatement] = useState(null)

    const [commercialSignature,setCommercialSignature] = useState(null)
    const [selectedPhysicalPackingType,setSelectedPhysicalPackingType] = useState({})
    const [containAlcohol, setContainAlcohol] = useState(false)

    const [deliverToAddress, setDeliverToAddress] = useState(false)


    const [commercialInvoiceWeight, setCommercialInvoiceWeight] = useState(0)
    // const [commercialInvoiceWeight, setCommercialInvoiceWeight] = useState(0)

    const [grams, setGrams] = useState(null)
    const [contentDescription, setContentDescription] = useState('')
    const [isVisibleLength, setIsVisibleLength] = useState(true)
    const [isHidelbh, setIsHidelbh] = useState(false)
    const [fileData, setFileData] = useState([]);
    // const [isVisibleWeight,setIsVisibleWeight] = useState(true)
    const [isVisibleHeight, setIsVisibleHeight] = useState(true)
    const [isVisibleWidth, setIsVisibleWidth] = useState(true)
    const [isVisiblePlus, setIsVisiblePlus] = useState(true)
    const [packageWeight, setPackageWeight] = useState({})
    const [senderStateList, setSenderStateList] = useState([])
    const [receiverStateList, setReceiverStateList] = useState([])
    const [transpotationalCharges, setTranspotationalCharges] = useState(null)
    const [serviceCharges, setServiceCharges] = useState(null)
    const [totalCharges, setTotalCharges] = useState(null)
    const [chunkedPackageArray, setChunkedPackageArray] = useState([])
    const [profitMargin, setProfitMargin] = useState(null)
    const [commodities, setCommodities] = useState([])
    const [pickupLocation, setPickupLocation] = useState('')
    const [serviceType, setServiceType] = useState(null);
    const [indexObject, setIndexObject] = useState(null)
    const [upspackages, setUpsPackages] = useState([]);


    const [showModal, setShowModal] = useState(false);
    const [showSaveShipModal, setSaveShipModal] = useState(false);
    const [selectedToggleOption, setSelectedToggleOption] = useState('FTR');
    const [itnToggleOption, setItnToggleOption] = useState('EIN')
    const handleChangeToggle = (value) => {
        setSelectedToggleOption(value);
    };
    const handleChangeITNToggle = (value) => {
        setItnToggleOption(value)
    }
    const handleUpsLocationModal  = () => {
        setShowUpsLocation(false);
    }
    const handleRemoveFormGroup = (index) => {
        const newFormGroups = formGroups.filter((_, i) => i !== index);
        setFormGroups(newFormGroups);
    };
    const [upsservice, setUpsService] = useState([
        { code: "01", Description: "UPS Next Day Air®" },
        { code: "02", Description: "UPS 2nd Day Air®" },
        { code: "03", Description: "UPS® Ground" },
        { code: "14", Description: "UPS Next Day Air® Early" },
        { code: "13", Description: "UPS Next Day Air Saver®" },
        { code: "59", Description: "UPS 2nd Day Air A.M.®" },
        { code: "12", Description: "UPS 3 Day Select" },
        { code: "FEDEX_INTERNATIONAL_PRIORITY", Description: "FedEx International Priority®" },
        { code: "INTERNATIONAL_ECONOMY", Description: "FedEx International Economy®" }
    ])
    const handleSaveShowModal = () => {
        if (!currentShipId) {
            setSaveShipModal(true);
        }
        else {
            console.log('call createShipApi,"createShipApi=>>>')
            createShipApi()
        }
    };

    const handleSaveCloseModal = () => {
        setSaveShipModal(false);
    };
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCloseCsvModal = () => {
        setShowCsvModal(false);
    };




    const getAdminData = async () => {
        const headersList = {
            "Content-Type": "application/json"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/get-admin`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                if (data.profitMargin) {
                    setProfitMargin(data.profitMargin)
                }
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }

   
    const getPackageData = async (packageData) => {
        console.log('call get all package')
        const headersList = {

            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get('token')}`
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-all-package`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                console.log(data, "data.data.packageType", packageData)
                setCustomPackageDetails(data.data)
                if (data.data.length) {
                    const arr = customerPackageOptions;
                    
                    const customeData = data.data.map(item => (
                        {
                        value: item.packageType,
                        label: item.package
                    }))
                    console.log(customeData,"customeData==>>>>")
                    const arrData = [
                        {
                            label:'Customer Provided Dimensions',
                            value:''
                        },
                        ...customeData
                    ]
                    console.log(arrData,"arrData=>")
                    setCustomerPackageOptions(arrData)
                    const updatedPackages = packageData.concat(data.data.map(item => ({

                        code: item.packageType,
                        Description: item.package
                    })));

                    // Assuming upspackages is your array of objects
                    const uniquePackages = updatedPackages.filter((packageEle, index, self) =>
                        index === self.findIndex(p => p.Description === packageEle.Description)
                    );
                    setUpsPackages(uniquePackages);
                    // const packageData = upspackages.find(ele => Number(ele.packageType) == data.packageType)
                    // setPackageOfService(packageData)
                }
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    const getCommodities = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-commodities`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "dataa=>")
                    const commoditiesWithLabels = data.data.map(item => ({
                        ...item,
                        value: item.productId,
                        label: item.integration// Replace 'your_label_here' with the label you want to assign
                    }))
                    setCommoditiesOption(commoditiesWithLabels);
                    // handleClose()
                })
                .catch(error => {

                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }
    const getImporterOfRecords = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-importer-records`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "impirterdataa=>")
                    const importerOfRecordsData = data.data.map(item => ({
                        ...item,
                        value: item._id,
                        label: item.name// Replace 'your_label_here' with the label you want to assign
                    }))
                    importerOfRecordsData.unshift({ value:'receiver',label:'Receiver'})
                    setRecordImporterOption(importerOfRecordsData);
                    // handleClose()
                })
                .catch(error => {

                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }
    function handleFileSelect(event, csvIntegration) {
        setShowCsvModal(false)
        console.log(selectedIntegration, "selectedIntegration")
        const file = event.target.files[0];
        console.log(file, "file=>>>", csvIntegration)
        if (file) {
            const reader = new FileReader();

            reader.onload = function (event) {
                const fileContent = event.target.result;
                console.log(fileContent, "fileContent")



                // Split the file content by newline character to get rows
                const rows = fileContent.split('\n');
                // Assuming the first row contains column headers
                const headers = rows[0].split(',').map(header => header.replace(/"/g, '').trim());

                // Assuming the first row contains column headers
                // const headers = rows[0].split(',');

                // Count the number of columns
                const numberOfColumns = headers.length;

                // Initialize an array to store the parsed data
                const dataArray = [];

                // Iterate through each row into values
                for (let i = 1; i < rows.length; i++) {
                    // Split the current row into values
                    const values = rows[i].split(',');

                    // Initialize an object to store the current row's data
                    const rowData = {};

                    // Iterate through each value and assign it to the corresponding header key
                    for (let j = 0; j < headers.length; j++) {
                        // Remove double quotes from the values
                        let trimmedValue = values[j]?.replace(/"/g, '');
                        rowData[headers[j]] = trimmedValue?.trim();
                    }

                    // Push the constructed object into the dataArray
                    dataArray.push(rowData);


                }
                setFileData(dataArray)

                console.log(dataArray, "dataArray=>")

                if (numberOfColumns < csvIntegration?.columns?.length) {
                    // console.log(headers[i], "headers[i]=>")
                    setError(`column is missing!`)
                }
                else {
                    let data = {};
                
                    // Helper function to find the index number of a given column value
                    const findIndexNumber = (array, colValue) => {
                        const item = array.find(item => item.col === colValue);
                        if (item) {
                            let index = 0;
                            for (let i = 0; i < item.index.length; i++) {
                                index = index * 26 + (item.index.charCodeAt(i) - 64); // 'A' is 1, 'B' is 2, etc.
                            }
                            return index - 1; // Return zero-based index
                        }
                        return -1; // Return -1 if the colValue is not found
                    };
                
                    if (selectedIntegration?.columns.some(item => item.col === "empty")) {
                        console.log(findIndexNumber(selectedIntegration.columns, "empty"));
                    }
                    if (selectedIntegration?.columns.some(item => item.col === "id")) {
                        data.Description = findIndexNumber(selectedIntegration.columns, "id");
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'created')) {
                        data.Date = findIndexNumber(selectedIntegration.columns, 'created');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'shippingService')) {
                        data.Service = findIndexNumber(selectedIntegration.columns, 'shippingService');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'length')) {
                        data.Length = findIndexNumber(selectedIntegration.columns, 'length');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'width')) {
                        data.Width = findIndexNumber(selectedIntegration.columns, 'width');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'height')) {
                        data.Height = findIndexNumber(selectedIntegration.columns, 'height');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'weight')) {
                        data.Weight = findIndexNumber(selectedIntegration.columns, 'weight');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'poNumber')) {
                        data.PoNumber = findIndexNumber(selectedIntegration.columns, 'poNumber');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originName')) {
                        data.originName = findIndexNumber(selectedIntegration.columns, 'originName');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originCompany')) {
                        data.originCompany = findIndexNumber(selectedIntegration.columns, 'originCompany');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originAddress1')) {
                        data.SenderAddress1 = findIndexNumber(selectedIntegration.columns, 'originAddress1');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originAddress2')) {
                        data.SenderAddress2 = findIndexNumber(selectedIntegration.columns, 'originAddress2');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originAddress3')) {
                        data.SenderAddress3 = findIndexNumber(selectedIntegration.columns, 'originAddress3');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originCity')) {
                        data.SenderCity = findIndexNumber(selectedIntegration.columns, 'originCity');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originState')) {
                        data.SenderState = findIndexNumber(selectedIntegration.columns, 'originState');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originZip')) {
                        data.SenderZip = findIndexNumber(selectedIntegration.columns, 'originZip');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originCountry')) {
                        data.SenderCountry = findIndexNumber(selectedIntegration.columns, 'originCountry');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originEmail')) {
                        data.SenderEmail = findIndexNumber(selectedIntegration.columns, 'originEmail');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'originPhone')) {
                        data.SenderPhone = findIndexNumber(selectedIntegration.columns, 'originPhone');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationName')) {
                        data.receiverName = findIndexNumber(selectedIntegration.columns, 'destinationName');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationCompany')) {
                        data.receiverCompany = findIndexNumber(selectedIntegration.columns, 'destinationCompany');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationAddress1')) {
                        data.receiverAddress1 = findIndexNumber(selectedIntegration.columns, 'destinationAddress1');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationAddress2')) {
                        data.receiverAddress2 = findIndexNumber(selectedIntegration.columns, 'destinationAddress2');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationAddress3')) {
                        data.receiverAddress3 = findIndexNumber(selectedIntegration.columns, 'destinationAddress3');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationCity')) {
                        data.receiverCity = findIndexNumber(selectedIntegration.columns, 'destinationCity');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationState')) {
                        data.receiverState = findIndexNumber(selectedIntegration.columns, 'destinationState');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationZip')) {
                        data.receiverZip = findIndexNumber(selectedIntegration.columns, 'destinationZip');
                    }
                    console.log(selectedIntegration?.columns.some(item => item.col === 'destinationCountry'),"selectedIntegration?.columns.some(item => item.col === 'destinationCountry')")
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationCountry')) {
                        console.log(findIndexNumber(selectedIntegration.columns, 'destinationCountry'),"findIndexNumber(selectedIntegration.columns, 'destinationCountry')-->>>")
                        data.receiverCountry = findIndexNumber(selectedIntegration.columns, 'destinationCountry');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationEmail')) {
                        data.receiverEmail = findIndexNumber(selectedIntegration.columns, 'destinationEmail');
                    }
                    if (selectedIntegration?.columns.some(item => item.col === 'destinationPhone')) {
                        data.receiverPhone = findIndexNumber(selectedIntegration.columns, 'destinationPhone');
                    }
                
                    console.log(data, 'data=>');
                
                    setIndexObject(data);
                }
                console.log(numberOfColumns, "numberOfColumns")
                console.log(selectedIntegration.columns.length, "csvIntegration.column.length")
            };

            reader.readAsText(file);
        }
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('status', 'uploaded');
            formData.append('uploaded', new Date().toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }));
            formData.append('acceptedOrders', '');
            formData.append('submittedOrders', '');
            formData.append('user', Cookies.get('id'));

            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/upload-csv`, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }
    }

    const handleCallApi = async (fileData, KeyObject) => {
        console.log("handleCallApi=>")
        console.log(fileData, "fileData=>", KeyObject, "KeyObject=>")
        let arr = []
        for (let i = 0; i < fileData.length; i++) {
            console.log(i, "--->>oiii")
            const bodyData = {}
            const keys = Object.keys(fileData[i]);
            for (const key in KeyObject) {

                // Check if the property belongs to the object itself (not inherited)
                if (KeyObject.hasOwnProperty(key) && fileData[i]) {
                    // Access the value of the property using obj[key]

                    const value = KeyObject[key];
                    console.log(`Key: ${key}, Value: ${value}`);

                    const getKey = keys[value]
                    console.log(getKey, "getKey=>")
                    const getVal = fileData[i][getKey]
                    if (fileData[i][getKey]) {
                        bodyData[key] = fileData[i][getKey]
                    }

                    if (getVal == "FedEx International Priority®" || getVal == "FedEx International Economy®" || getVal == "FedEx Ground®" || getVal == "FedEx First Overnight®" || getVal == "FedEx First Overnight®" || getVal == "FedEx 2Day®" || getVal == "FedEx Express Saver®" || getVal == "FedEx Home Delivery®") {
                        console.log("Call setService1")
                        setServiceType('fedEx')
                    }



                }
            }
            console.log(bodyData, "bodyData=>")
            if (Object.keys(bodyData).length !== 0) {
                arr.push(bodyData)
            }


        }
        console.log(arr, "arr=================")
        const firstKey = Object.keys(arr[0])[0];
        console.log(firstKey, "firstKey=>")
        setFirstKey(firstKey)
        setBulkShipData(arr)
    }

    useEffect(() => {
        if (fileData?.length && indexObject) {
            handleCallApi(fileData, indexObject)
        }
    }, [fileData, indexObject])
    function handleUploadCsvButtonClick() {

        document.getElementById('csvFileInput').click();
    }
    const getCsvIntegration = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ecomm-csv`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "dataa=>")

                    setCsvIntegrationData(data.data);
                    // handleClose()
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }
    const getAllSignatures = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-all-signature`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "dataa=>")
                    
                    const d = data.data.map(ele => {
                        return {
                            label:ele.name,
                            value:ele.name
                        }
                    })
                    setSignatureOptions(d);                    
                    
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if(err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }

    useEffect(() => {
        getAdminData()
        getCommodities()
        getCsvIntegration()
        getAllSignatures()
        getImporterOfRecords()
    }, [])
    const updateReceiverDetails = async () => {
        if (addId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-address/${addId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify({
                        type: 'receiver',
                        name: receiverName, // Replace with the actual field names from your form
                        company: receiverCompany,
                        zipCode: receiverZipCode,
                        city: receiverCity,
                        state: receiverState?.value,
                        address1: receiverAddress1,
                        address2: receiverAddress2,
                        address3: receiverAddress3,
                        email: receiverEmail,
                        phone: receiverPhone,
                        country: receiverCountry?.value, // Assuming senderCountry is an object with value and label properties       
                    }),
                });

                if (!response.ok) {
                    // Handle the error, e.g., show an error message
                    console.error(`HTTP error! Status: ${response.status}`);
                    return;
                }

                const data = await response.json();
                console.log(data); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-address`;
            const requestData = {
                name: receiverName, // Replace with the actual field names from your form
                company: receiverCompany,
                zipCode: receiverZipCode,
                city: receiverCity,
                state: receiverState?.value,
                address1: receiverAddress1,
                address2: receiverAddress2,
                address3: receiverAddress3,
                email: receiverEmail,
                phone: receiverPhone,
                country: receiverCountry?.value,
                user: Cookies.get('id'),
                type: 'receiver'
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {

                    setResponse(data);
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }

    };
    const voidShip = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/void-ship`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // You may need to include additional headers such as authorization headers
                },
                body: JSON.stringify({
                    trackingNumber: "trackingNumber",
                }),
            });

            if (!response.ok) {
                // Handle the error, e.g., show an error message
                console.error(`HTTP error! Status: ${response.status}`);
                return;
            }

            const data = await response.json();
            console.log(data); // Log the response data
            // Handle success, e.g., update state or show a success message
        } catch (error) {
            // Handle other errors, e.g., network error
            console.error(error);
            if (error) {
                setError('Network Error!')
            }
        }

    }
    const validateAddress = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/validate-address`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${Cookies.get('token')}`
                    // You may need to include additional headers such as authorization headers
                },
                body: JSON.stringify({
                    AddressLine1: receiverAddress1,
                    AddressLine2:receiverAddress2,
                    AddressLine3:receiverAddress3,
                    pinCode:receiverZipCode,
                    countryCode:receiverCountry?.value,
                    city:receiverCity,
                    state:receiverState?.value,
                    user:Cookies.get('id')
                }),
            });
            const data = await response.json();
            if (!data.status) {
                // Handle the error, e.g., show an error message
                console.error(`HTTP error! Status: ${response.status}`);
                setError(data.err.message)
                return false;
            }
            else {
                return true
            }

            
            console.log(data); // Log the response data
            // Handle success, e.g., update state or show a success message
        } catch (error) {
            // Handle other errors, e.g., network error
            console.error(error);
            if (error) {
                setError('Network Error!')
                return false
            }
        }

    }
    const validateFedExAddress = async (Address) => {
        try {
            console.log(receiverAddress1,receiverAddress2,receiverAddress3,receiverCity,receiverZipCode,receiverState)
            let bodyData = {
                streetLines: [receiverAddress1,receiverAddress2,receiverAddress3],
                    city:receiverCity,
                    postalCode:receiverZipCode,
                    countryCode:receiverCountry?.value,
                    city:receiverCity,
                    stateOrProvinceCode:receiverState?.value,
                    user:Cookies.get('id')
            }
            if(Address) {
                bodyData = {
                    streetLines: [Address?.AddressLine1,Address?.AddressLine2,Address?.AddressLine3],
                    postalCode:Address?.pincode,
                    countryCode:Address?.CountryCode?.value,
                    city:Address.city,
                    stateOrProvinceCode:Address?.state,
                    user:Cookies.get('id')
                }
            }
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/validate-fedex-address`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${Cookies.get('token')}`
                    // You may need to include additional headers such as authorization headers
                },
                body: JSON.stringify(bodyData),
            });
            const data = await response.json();
            if (!data.status) {
                // Handle the error, e.g., show an error message
                console.error(`HTTP error! Status: ${response.status}`);
                setError(data.err.message)
                return false;
            }
            else {
                if(data?.data?.resolvedAddresses.length) {
                    setSuggestedAddress(data?.data?.resolvedAddresses)
                    return true;
                }
               
            }

            
            console.log(data); // Log the response data
            // Handle success, e.g., update state or show a success message
        } catch (error) {
            // Handle other errors, e.g., network error
            console.error(error);
            if (error) {
                setError('Network Error!')
                return false
            }
        }

    }

    const saveReceiverDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-address`, {
                method: "PUSH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // You may need to include additional headers such as authorization headers
                },
                body: JSON.stringify({
                    name: receiverName, // Replace with the actual field names from your form
                    company: receiverCompany,
                    zipCode: receiverZipCode,
                    city: receiverZipCode,
                    state: receiverState?.value,
                    address1: receiverAddress1,
                    address2: receiverAddress2,
                    address3: receiverAddress3,
                    email: receiverEmail,
                    phone: receiverPhone,
                    country: receiverCountry?.value, // Assuming senderCountry is an object with value and label properties
                }),
            });

            if (!response.ok) {
                // Handle the error, e.g., show an error message
                console.error(`HTTP error! Status: ${response.status}`);
                return;
            }

            const data = await response.json();
            console.log(data); // Log the response data
            // Handle success, e.g., update state or show a success message
        } catch (error) {
            // Handle other errors, e.g., network error
            console.error(error);
            if (error) {
                setError('Network Error!')
            }
        }
    };

   

    useEffect(() => {
        if (senderCountry) {
            const Data = statesData.filter(ele => senderCountry?.value == ele.country_code)
            setSenderStateList(Data);
            setOriginCountry(senderCountry)
        }
    }, [senderCountry])
    useEffect(() => {
        if (receiverCountry) {
            const Data = statesData.filter(ele => receiverCountry?.value == ele.country_code)
            console.log(Data, "Data=>")
            setReceiverStateList(Data)
            setDutiableCountry(receiverCountry)
        }
    }, [receiverCountry])

    useEffect(() => {
        if (defaultSenderState) {
            const getState = senderStateList.find(ele => ele?.value == defaultSenderState)
            setSenderState(getState)
        }
    }, [defaultSenderState])
    useEffect(() => {
        console.log(defaultReceiverState, "defaultReceiverState", receiverStateList)
        if (defaultReceiverState) {
            const countryData = statesData.filter(ele => receiverCountry?.value == ele.country_code)
            const getState = countryData.find(ele => ele?.value == defaultReceiverState)
            console.log(getState, "defaultReceiverState getState")
            setReceiverState(getState)
        }
    }, [defaultReceiverState])

    // useEffect(() => {

    //     if (receiverStateList.length) {
    //         setReceiverState(receiverStateList[0].label)
    //     }
    // }, [receiverStateList])
    // useEffect(() => {
    //     if (senderStateList.length) {
    //         setSenderState(senderStateList[0].label)
    //     }

    // }, [senderStateList])

    useEffect(() => {
        if (packageOfService?.Description == 'UPS Express Box') {
            setShipWidth(12.5)
            setShipLength(18)
            setShipHeight(3.75)
            setTtlQty(1)
            setTtlWeight(0)
            setGrams(0)
        }
        else if (packageOfService?.Description == "UPS Tube") {
            setShipWidth(6)
            setShipLength(38)
            setShipHeight(6)
        }
        else if (packageOfService?.Description == "UPS Large Box") {
            setShipWidth(13)
            setShipLength(18)
            setShipHeight(3)
        }
        else if (packageOfService?.Description == "UPS Letter") {
            setShipWeight(0.5)
            setIsVisibleHeight(false)
            setIsVisibleLength(false)
            setIsVisibleWidth(false)
            setIsVisiblePlus(false)

        }
        else if (packageOfService?.Description == "UPS Small Box") {
            setShipWidth(11)
            setShipLength(13)
            setShipHeight(2)
        }
        else if (packageOfService?.Description == "UPS Pak") {
            setShipWidth(12.75)
            setShipLength(16)
            setShipHeight(1)
        }
        else if (packageOfService?.Description == "UPS Medium Box") {
            setShipWidth(11)
            setShipLength(16)
            setShipHeight(3)
        }
        else if (packageOfService?.Description == "FedEx® Small Box") {
            setShipWidth(10.875)
            setShipLength(12.375)
            setShipHeight(1.500)
        }
        else if (packageOfService?.Description == "FedEx® Envelope") {
            setIsHidelbh(true)

        }
        else if (packageOfService?.Description == "FedEx® Large Pak") {
            setShipWidth(15.5)
            setShipLength(12)
            setShipHeight(1)
        }
        else if (packageOfService?.Description == "FedEx® Medium Box") {
            setShipWidth(13.25)
            setShipLength(11.5)
            setShipHeight(2.375)
        }
        else if (packageOfService?.Description == "FedEx® Tube") {
            setShipWidth(38)
            setShipLength(6)
            setShipHeight(6)
        }
        else if (packageOfService?.Description == "FedEx® Padded Pak") {
            setShipWidth(14.75)
            setShipLength(11.75)
            setShipHeight(1)
        }
        else if (packageOfService?.Description == "FedEx® Large Box") {
            setShipWidth(12.375)
            setShipLength(17.5)
            setShipHeight(3)
        }
        else if (packageOfService?.Description == "FedEx® Pak") {
            setShipWidth(12)
            setShipLength(15.5)
            setShipHeight(1)
        }
        else if (packageOfService?.Description == "Your Packaging") {
            setShipWidth(15.5)
            setShipLength(12)
            setShipHeight(1)
        }
        else if (packageOfService?.Description == "FedEx® Box") {
            setShipWidth(13.25)
            setShipLength(11.5)
            setShipHeight(2.375)
        }
        else if (packageOfService?.Description == "FedEx® Small Pak") {
            setShipWidth(10.25)
            setShipLength(12.75)
            setShipHeight(1)
        }
        prevPackageOfServiceRef.current = packageOfService;
    }, [packageOfService])
    const prevPackageOfService = prevPackageOfServiceRef.current;
    useEffect(() => {
        if(selectedCustomPackage) {
            const getData = customerPackageOptions.find(ele => ele?.package == selectedCustomPackage.value);
            if(selectedCustomPackage.value == 21) {
                const getDetails = customePackageDetails.find(ele => ele.packageType == 21)    
                if(getDetails){
                    setShipLength(getDetails?.length)
                    setShipWidth(getDetails?.width)
                    setShipHeight(getDetails?.height)
                    setShipWeight(getDetails?.weight)
                }   
             }
            if(getData?.length) {
                setShipLength(getData?.length)
            }
            if(getData?.width) {
                setShipWidth(getData?.width)
            }
            if(getData?.height) {
                setShipHeight(getData?.height)
            }
            if(getData?.weight) {
                setShipWeight(getData?.weight)
            }
        }
    },[selectedCustomPackage,customerPackageOptions])

    useEffect(() => {
        if(prevPackageOfService?.code !== '02') {
            const getArr = customerPackageOptions
            getArr.unshift({
                value:prevPackageOfService?.code,
                label:prevPackageOfService?.Description
            })
            setCustomerPackageOptions(getArr)
        }
       
    },[prevPackageOfService ])
    useEffect(() => {
        if (user) {
            setSenderEmail(user.email)
            setSenderCompany(user.companyName)
        }
    }, [user])

    // const upspackages = [
    //     { code: "21", Description: "UPS Express Box" },
    //     { code: "03", Description: "UPS Tube" },
    //     { code: "2c", Description: "UPS Large Box" },
    //     { code: "01", Description: "UPS Letter" },
    //     { code: "2a", Description: "UPS Small Box" },
    //     { code: "02", Description: "Customer Supplied Package" },
    //     { code: "04", Description: "UPS Pak" },
    //     { code: "2b", Description: "UPS Medium Box" },
    // ]




    // Assuming each row has 3 columns
    const numberOfColumns = 3;

    // Chunk the array into subarrays of the specified length (number of columns)
    const chunkedArray = upsservice.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / numberOfColumns);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);


    useEffect(() => {
        console.log(service, "service===>>")
        if (service?.code == "FEDEX_INTERNATIONAL_PRIORITY" || service?.code == "INTERNATIONAL_ECONOMY" || service?.code == "FEDEX_GROUND" || service?.code == "FIRST_OVERNIGHT" || service?.code == "PRIORITY_OVERNIGHT" || service?.code == "FEDEX_2_DAY" || service?.code == "FEDEX_EXPRESS_SAVER" || service?.code == "GROUND_HOME_DELIVERY") {
            console.log("call fedex service")
            const packagesData = [
                {code:"YOUR_PACKAGING",Description:"Your Packaging"},
                { code: "FEDEX_SMALL_BOX", Description: "FedEx® Small Box" },
                { code: "", Description: "FedEx® Envelope" },
                { code: "", Description: "FedEx® Large Pak" },
                { code: "FEDEX_MEDIUM_BOX", Description: "FedEx® Medium Box" },
                { code: "FEDEX_TUBE", Description: "FedEx® Tube" },
                { code: "", Description: "FedEx® Padded Pak" },
                { code: "FEDEX_LARGE_BOX", Description: "FedEx® Large Box" },
                { code: "FEDEX_PAK", Description: "FedEx® Pak" },
                // { code: "YOUR_PACKAGING", Description: "Your Packaging" },
                { code: "FEDEX_BOX", Description: "FedEx® Box" },
                { code: "", Description: "FedEx® Small Pak" },

            ]
            setUpsPackages(packagesData)
            console.log("Call setService2")
            setServiceType('fedEx')
            getPackageData(packagesData)
            const contentTypeData = [
                {value:"Sold",label:"Merchandise"},
{value:"Not Sold",label:"Documents"},
{value:"Gift",label:"Gift"},
{value:"Sample",label:"Sample"},
{value:"Repair and Return",label:"Repair and Return"},
{value:"Personal Effects",label:"Personal Effects"},
            ]
            setContentTypeOptions(contentTypeData)
            const signtureOpt = [
                {value:"NO_SIGNATURE_REQUIRED",label:"No Signature Required"},
{value:"ADULT",label:"Adult Signature Required"},
{value:"DIRECT",label:"Direct Signature Required"},
{value:"SERVICE_DEFAULT",label:"Service Default"},
            ]
            console.log(signtureOpt,"signtureOpt==>>")
            setSignatureOption(signtureOpt)
            console.log("cll if 1")
        }

        else {
            console.log("Call setService3")
            setServiceType('')
            const upsData = [
                { code: "02", Description: "Customer Supplied Package" },
                { code: "21", Description: "UPS Express Box" },
                { code: "03", Description: "UPS Tube" },
                { code: "2c", Description: "UPS Large Box" },
                { code: "01", Description: "UPS Letter" },
                { code: "2a", Description: "UPS Small Box" },
                { code: "04", Description: "UPS Pak" },
                { code: "2b", Description: "UPS Medium Box" },
            ]
            setUpsPackages(upsData)

            getPackageData(upsData)
            const contentTypeData = [
                { value: "goods", label: "Goods" },
                { value: "document", label: 'Document' },
            ]
            setContentTypeOptions(contentTypeData)
            const signatureOpt = [
                { value: "nodeliveryconfirmation", label: "No Delivery Confirmation" },
                { value: "deliveryconfiration", label: 'Delivery Confirmation(No Signature)' },
                { value: "signaturerequired", label: 'Signature Required' },
                { value: 'adultsignture', label: 'Adlut Signature Required' }
        
            ]
            setSignatureOption(signatureOpt)
        }
        
    }, [service])

    useEffect(() => {
        if(termsOfTrade?.value == 'DDP' ) {
            setDutiablePartyOptions([
                { value: "sender", label: "Sender" },
                { value: "thirdparty", label: "Third Party" }
            ])
            setDutiableParty({ value: "sender", label: "Sender" })
        }
    },[termsOfTrade])

    useEffect(() => {
        if(termsOfTrade?.value == 'DDP' && serviceType == 'fedEx') {
            setDutiablePartyOptions([
                { value: "sender", label: "Sender" },
                { value: "thirdparty", label: "Third Party" }
            ])
        }
        else if(billingParty?.value == 'receiver') {
            setBillingPartyCountry(receiverCountry)
        }
        else if(billingParty?.value == 'thirdparty') {
            setBillingPartyCountry(senderCountry)
        }
     
        else if(billingParty?.value == 'sender' && termsOfTrade?.value == 'DDP') {
            setDutiableParty({ value: "sender", label: "Sender" })
        }
        
    },[billingParty])

    useEffect(() => {
        console.log(upspackages, "upspackages=>")

        if (upspackages.length) {
            const chunkedPackageArray = upspackages?.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / numberOfColumns);

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                }

                resultArray[chunkIndex].push(item);
                console.log(resultArray, "resultArray")

                return resultArray;
            }, []);
            setChunkedPackageArray(chunkedPackageArray)
        }
    }, [upspackages])
    // const chunkedPackageArray = upspackages?.reduce((resultArray, item, index) => {
    //     const chunkIndex = Math.floor(index / numberOfColumns);

    //     if (!resultArray[chunkIndex]) {
    //         resultArray[chunkIndex] = []; // start a new chunk
    //     }

    //     resultArray[chunkIndex].push(item);
    //     console.log(resultArray, "resultArray")

    //     return resultArray;
    // }, []);

    const [validationError, setValidationError] = useState([])


    useEffect(() => {
        if (receiverCountry?.value == 'AL') {
            setIsVisibleState(true)
            setIsVisibleZipCode(true)

        }
        if (receiverCountry?.value == 'DZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true)
        }
        if (receiverCountry?.value == 'AS') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true)
        }
        if (receiverCountry?.value == 'AD') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'AR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'AM') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'AU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'AT') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'AZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BD') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'US') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BY') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BM') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'BA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'BG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'BN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'KH') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'CA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IC') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'CL') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }

        if (receiverCountry?.value == 'CN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'CO') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'CR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'HR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'CU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'CY') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'EC') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'EE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'ET') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'FO') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'FI') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'FR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'GF') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'GE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'DE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'GH') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'GR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'GL') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'GP') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'GU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'GG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'GG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'HK') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'HU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IS') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'ID') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'IR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IT') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IL') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'IE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'SM') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'VA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'JP') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'JE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'JO') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'KZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'KR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'KV') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'KG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'LV') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'LI') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'LT') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'LU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'MK') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)
        }
        if (receiverCountry?.value == 'MD') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'MY') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'MV') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'MR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'MQ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'YT') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
        }
        if (receiverCountry?.value == 'MX') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'MX') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'FM') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'MD') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'MC') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'MN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'ME') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'MA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'NL') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'AN') {
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'NC') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'NZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'NG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'MP') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'NO') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'PK') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'PW') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'PG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'PE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'PH') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'PL') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'PT') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'PR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'QA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'RE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'RO') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'RU') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'SA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'SG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'SK') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'SI') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'ZA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'ES') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'LK') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'XY') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'SZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'SE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'CH') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'SY') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'TW') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'TJ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'TZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'TH') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'TN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'TR') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'UG') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'UA') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'AE') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'GB') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'US') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'UM') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'UZ') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);
            setIsVisibleState(true)

        }
        if (receiverCountry?.value == 'VN') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'VI') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (receiverCountry?.value == 'ZW') {
            setIsVisibleZipCode(true);
            setIsVisibleCity(true);

        }
        if (senderCountry?.value == 'US' && receiverCountry?.value == 'US') {
            setUpsService([
                { code: '01', Description: "UPS Next Day Air®" },
                { code: '02', Description: "UPS 2nd Day Air®" },
                { code: '03', Description: "UPS® Ground" },
                { code: '14', Description: "UPS Next Day Air® Early" },
                { code: '13', Description: "UPS Next Day Air Saver®" },
                { code: '59', Description: "UPS 2nd Day Air A.M.®" },
                { code: '12', Description: "UPS 3 Day Select®" },
                { code: 'PRIORITY_OVERNIGHT', Description: "FedEx Priority Overnight®" },

                { code: 'FIRST_OVERNIGHT', Description: "FedEx First Overnight®" },

                { code: 'STANDARD_OVERNIGHT', Description: "FedEx Standard Overnight®" },
                { code: 'FEDEX_2_DAY', Description: "FedEx 2Day®" },
                // { code: '', Description: "FedEx 2Day®" },
                { code: 'FEDEX_GROUND', Description: "FedEx Ground®" },
                { code: 'GROUND_HOME_DELIVERY', Description: 'FedEx Home Delivery®' }
            ])
        }
        else if (senderCountry?.value == 'US' && receiverCountry?.value == 'AS') {
            setUpsService([
                { code: '11', Description: "UPS® Standard" },
                { code: '07', Description: "UPS Worldwide Express®" },
                { code: '54', Description: "UPS Worldwide Express Plus®" },
                { code: '08', Description: "UPS Worldwide Expedited®" },

                { code: '65', Description: "UPS Worldwide Saver®" },
                { code: '01', Description: "UPS Next Day Air®" },
                { code: '02', Description: "UPS 2nd Day Air®" },
                { code: '03', Description: "UPS® Ground" },
                { code: '14', Description: "UPS Next Day Air® Early" },
                { code: '13', Description: "UPS Next Day Air Saver®" },
                { code: '59', Description: "UPS 2nd Day Air A.M.®" },
                { code: '12', Description: "UPS 3 Day Select" },
                { code: 'FEDEX_GROUND', Description: "FedEx International Ground®" },
                { code: 'PRIORITY_OVERNIGHT', Description: "FedEx Priority Overnight®" },

                { code: 'FIRST_OVERNIGHT', Description: "FedEx First Overnight®" },

                { code: 'STANDARD_OVERNIGHT', Description: "FedEx Standard Overnight®" },
                { code: 'FEDEX_2_DAY', Description: "FedEx 2Day®" },
                { code: 'FEDEX_2_DAY', Description: "FedEx 2Day®" },
                { code: 'FEDEX_GROUND', Description: "FedEx Ground®" },
                { code: 'GROUND_HOME_DELIVERY', Description: 'FedEx Home Delivery®' }
            ])
        }
        else if (senderCountry?.value == 'US' && receiverCountry?.value == 'CA') {
            setUpsService([
                { code: '11', Description: "UPS® Standard" },
                { code: '07', Description: "UPS Worldwide Express®" },
                { code: '54', Description: "UPS Worldwide Express Plus®" },
                { code: '08', Description: "UPS Worldwide Expedited®" },
                { code: '65', Description: "UPS Worldwide Saver®" },
                { code: 'FEDEX_INTERNATIONAL_PRIORITY', Description: "FedEx International Priority®" },
                { code: 'INTERNATIONAL_ECONOMY', Description: "FedEx International Economy®" },
                { code: 'FEDEX_GROUND', Description: "FedEx International Ground®" },
            ])
        }
        else if (senderCountry?.value == 'US' && receiverCountry?.value == 'PR') {
            setUpsService([
                { code: '11', Description: "UPS® Standard" },
                { code: '07', Description: "UPS Worldwide Express®" },
                { code: '54', Description: "UPS Worldwide Express Plus®" },
                { code: '08', Description: "UPS Worldwide Expedited®" },
                { code: '65', Description: "UPS Worldwide Saver®" },
                { code: '01', Description: "UPS Next Day Air®" },
                { code: '02', Description: "UPS 2nd Day Air®" },
                { code: '03', Description: "UPS® Ground" },
                { code: '14', Description: "UPS Next Day Air® Early" },
                { code: '13', Description: "UPS Next Day Air Saver®" },
                { code: '59', Description: "UPS 2nd Day Air A.M.®" },
                { code: '12', Description: "UPS 3 Day Select" },
                { code: 'FEDEX_INTERNATIONAL_PRIORITY', Description: "FedEx International Priority®" },
                { code: 'INTERNATIONAL_ECONOMY', Description: "FedEx International Economy®" },
                { code: 'FEDEX_GROUND', Description: "FedEx International Ground®" },
                { code: 'PRIORITY_OVERNIGHT', Description: "FedEx Priority Overnight®" },
                { code: 'FIRST_OVERNIGHT', Description: "FedEx First Overnight®" },
                { code: 'STANDARD_OVERNIGHT', Description: "FedEx Standard Overnight®" },
                { code: 'FEDEX_2_DAY', Description: "FedEx 2Day®" },
                { code: 'FEDEX_EXPRESS_SAVER', Description: "FedEx Express Saver®" },
                { code: 'FEDEX_GROUND', Description: "FedEx Ground®" },
                { code: 'GROUND_HOME_DELIVERY', Description: "FedEx Home Delivery®" }
            ])
        }
        else {
            setUpsService([
                { Description: "UPS Worldwide Express®" },
                { Description: "UPS Worldwide Express Plus®" },
                { Description: "UPS Worldwide Expedited®" },
                { Description: "UPS Worldwide Saver®" },
                { code: 'FEDEX_INTERNATIONAL_PRIORITY', Description: "FedEx International Priority®" },
                { code: "INTERNATIONAL_ECONOMY", Description: "FedEx International Economy®" }
            ])
        }
    }, [senderCountry, receiverCountry])

    // Use the updateAddressAPI function when you want to trigger the API call
    // For example, you can call this function on a button click or form submission
    // e.g., <button onClick={updateAddressAPI}>Update Address</button>

    useEffect(() => {
        console.log(commodities, "commodities===>>>")
        if (commodities) {
            setHts(commodities.hts)
            const getOriginCountry = receiverCountryOptions.find(ele => ele?.value == commodities.country)
            setOriginCountry(getOriginCountry)
            setPickupLocation(commodities?.pickLocation)
            setCommercialInvoiceWeight(commodities?.weight ? commodities?.weight : 0)
            setChargeAmount(commodities.price)
        }
    }, [commodities])
    const getSenderDetails = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-address`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            if (data.message == "jwt expired") {
                navigate('/login')
            }
            if (data) {
                const getCountry = senderCountryOptions.find(ele => ele?.value == data.country)
                setAddId(data._id)
                setSenderName(data.name)
                if (data.user.email) {
                    setSenderEmail(data.user.email)
                }
                else {
                    setSenderEmail(user.data.email)
                }
                if (data.user.companyName) {
                    setSenderCompany(data.user.companyName)
                }
                else {
                    setSenderCompany(user.data.companyName)
                }
                setSenderAddress1(data.address1)
                setSenderAddress2(data.address2)
                setSenderAddress3(data.address3)
                setSenderCountry(getCountry);
                setDefaultSenderState(data.state)

                setSenderCity(data.city)
                setSenderState(data.state)
                setSenderZipCode(data.zip)
                setSenderPhone(data.phone)
                setSenderDetails(true)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }

    useEffect(() => {
        console.log(dimension, "dimension")
    }, [dimension])

    useEffect(() => {
        if (selectedUnit?.value == 1) {
            const data = {
                "dimensionCode": "IN",
                "dimensionDescription": "Inches",
                ...shipLength && { "length": shipLength },
                ...shipWidth && { "width": shipWidth },
                ...shipHeight && { "height": shipHeight }

            }
            setDimension(data)
            const shipmentWeight = {
                "code": "LBS",
                "description": "Pounds",
                ...shipWeight && { "weight": shipWeight }
            }
            setPackageWeight(shipmentWeight)
        }
        else if (selectedUnit?.value == 2) {
            const data = {
                "dimensionCode": "CM",
                "dimensionDescription": "Centimeters",
                ...shipLength && { "length": shipLength },
                ...shipWidth && { "width": shipWidth },
                ...shipHeight && { "height": shipHeight }

            }
            setDimension(data)
            const shipmentWeight = {
                "code": "KGS",
                "description": "Kilograms",
                ...shipWeight && { "weight": shipWeight }
            }
            setPackageWeight(shipmentWeight)
        }
    }, [selectedUnit, shipWidth, shipHeight, shipLength, shipWeight])

    // useEffect(() => {
    //     createShipApi()
    // },[receiverCompany])

    useEffect(() => {
        console.log(process.env.REACT_APP_BACKEND_URL, "REACT_APP_BACKEND_URL")
        getSenderDetails()
        const email = Cookies.get('email');
        const companyName = Cookies.get('companyName')
        if (email) {
            setSenderEmail(email)

        }
        if (companyName) {
            setSenderCompany(companyName)
        }
    }, [])

    const handleStateChange = (event) => {
        console.log(event.target?.value, "event.target?.value")
        setSenderState(event.target?.value)
    }
    async function createBulkShipApi(bodyContent, serviceTypeName) {
        console.log('call  create ship', serviceTypeName)
        // let currentShip = currentShipId ? currentShipId : shipId
        // let type = serviceType ? serviceType : serviceTypeName

        let headersList = {
            "Content-Type": "application/json"
        }
        console.log(serviceType, "serviceType")
        const id = Cookies.get('id')

        console.log(serviceType, "serviceType==>")
        // let bodyContent = JSON.stringify({
        //     "Description": "Ship WS test",
        //     "ShipTo": {
        //         "name": "Happy Dog Pet Supply",
        //         "number": "9225377171",
        //         "Address": {
        //             "AddressLine1": "123 Main St",
        //             "city": "timonium",
        //             "pincode": "21030",
        //             "CountryCode": "US"
        //         }
        //     },
        //     "ShipFrom": {
        //         "name": "T and T Designs",
        //         "number": "1234567890",
        //         "Address": {
        //             "AddressLine1": "2311 York Rd",
        //             "city": "Alpharetta",
        //             "pincode": "30005",
        //             "CountryCode": "US"
        //         }

        //     },
        //     "Service": {
        //         "code": "03",
        //         "Description": "Express"
        //     },
        //     "Package": {

        //         "packaging": {
        //             "code": "02",
        //             "Description": "Nails"
        //         },
        //         "Dimensions": {
        //             "dimensionCode": "IN",
        //             "dimensionDescription": "Inches",
        //             "length": "10",
        //             "width": "30",
        //             "height": "45"
        //         },
        //         "PackageWeight": {
        // "code": "LBS",
        // "description": "Pounds",
        // "Weight": "5"
        //         }
        //     },
        //     "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2VBN0p3QnBycEZMRWs1SFN0dzE2ZTNkeDVKSiIsIm5iZiI6MTcwNjU0NTE5NCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNjU1OTU5NCwiaWF0IjoxNzA2NTQ1MTk0LCJqdGkiOiI1MzZmOTI2YS1hYTg1LTQyODMtOThhOS01ZGQyYzYzZTczYzcifQ.KrVNj8zHAVAMfeDJc3CobmN2EKq5D61wxAiWJ31Ljf70sqPN1cpks0JLpUc2OIY8E_LLOMbckUxmZyNWWKL7iQCVHCXa_TNECxSTL8TOGQoxkwW5N4AN13xsywjhc0MqZH1g6JxYikQ65h7z0uXMiHrNadtW61n6RC2Dfx4XDLfkAmiGk4sV5G8TVvz7QJV1dQC6BTjWblRg52a0PzdUrYqeXtcXo-txqDEIOLaBk8lFJUQ2yirLtdam57KetnnyWHEzKl_p5KjG92DuRvJQ_xlXuBEPLZ8YHvnteMjKld-zRqY7weGt7HhjGxhK0VzHbK9kXPh5L_tbBIKpcajRqy9dRYWdyo1LzUQptQR_MTUe-8SVTHRQ3xezcyGENizWYhKygWQyZTno3T8zh0KawR9s2mAhT8j_ZT3mL0IBatOUqpRzytBDe93j1sR4hSU9IpRBJPKpUUK98I_b8tSS5HieDa_fgAcLYf020qzX2wJT6Iua-s2HL7hqW635E-XqBZh3Kmwuau98PwlwlCpim_QkGOYBiYN5z5amRaflDScJRi4UeON4-eHG6e5EGTL4VPDwnFiJSpAqNmOV5Xd0L5IAtZ5Tlne1_TQodOBjros81D6lGf0HKER4RzkZj4zDlhf7TSWJfrmL2zmnoDgyobSCY4V0HaYhLzwHuvz6S1k"



        // });
        let endpoint = 'create-ship'
        if (serviceType == 'fedEx') {
            endpoint = 'create-fedex-ship'
        }

        try {
            let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${endpoint}`, bodyContent, {
                headers: headersList
            });

            setShipResult(response?.data?.data?.ShipmentResponse)
            setServiceCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.ServiceOptionsCharges)
            setTranspotationalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TransportationCharges)

            setTotalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TotalCharges)
            console.log(serviceType, "serviceType=>")
            if (serviceType == 'fedEx') {
                console.log('call fedex save ', response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount)
                setShipResult(response?.data?.data?.output)
                const charges = {
                    CurrencyCode: "USD",
                    MonetaryValue: response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount
                }
                setTotalCharges(charges)
                
                await saveFedExResponseOfShipment(currentShip, response?.data?.data?.transactionId, response?.data?.data?.output)
            } else {
                console.log('call upi save ')
                await saveResponseOfShipment(currentShip, response?.data?.data?.ShipmentResponse?.Response, response?.data?.data?.ShipmentResponse?.ShipmentResults)
            }

        } catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log( err?.response?.data?.err?.errors, "err===-->>>")
            if (err instanceof Error) {
                if(err?.response?.data?.err?.errors?.length) {
                    const errArr = await err?.response?.data?.err?.errors?.map(error => error.message)
                    console.log(errArr, "errArr--")
                    // Assuming err.message contains the error messages from the server
                    setShipError(errArr)
                }
                else {
                    const errArr = await err?.response?.data?.err.map(error => error.message)
                    console.log(errArr, "errArr--")
                    // Assuming err.message contains the error messages from the server
                    setShipError(errArr)
                }
                
            } else {
                // If it's a network error or other error not related to server response
                setValidationError(prevErrors => [...prevErrors, "An error occurred while processing your request."]);
            }

        }
    }

    async function createShipApi(shipId, serviceTypeName) {

        if (!senderName) {
            setError('Sender name is missing!')
            return
        }
        if (!senderPhone) {
            setError('Sender phone number is missing!')
            return
        }
        if (!senderCompany) {
            setError('Sender company is missing!')
            return
        }
        if (!senderAddress1) {
            setError('Sender address is missing!')
            return
        }
        if (!senderState) {
            setError('Sender state is missing!')
            return
        }
        if (!senderCity) {
            setError('Sender city is missing!')
            return
        }
        if (!senderCountry) {
            setError('Sender country is missing!')
            return
        }
        if (!senderZipCode) {
            setError('Sender zip code is missing!')
            return
        }
        if (!receiverName) {
            setError('Receiver name is missing!')
            return
        }
        if (!receiverPhone) {
            setError('Receiver phone number is missing!')
            return
        }
        if (!receiverCompany && !isResidential) {
            setError('Receiver company is missing!')
            return
        }
        if (!receiverAddress1) {
            setError('Receiver address is missing!')
            return
        }
        if (!receiverCity) {
            setError('Receiver city is missing!')
            return
        }
        if (!receiverState) {
            setError('Receiver state is missing!')
            return
        }
        if (!receiverZipCode) {
            setError('Receiver zip code is missing!')
            return
        }
        console.log(receiverCountry,"receiverCountry=>")
        if (!receiverCountry) {
            setError('Receiver country code is missing!')
            return
        }
        if (!shipWeight) {
            setError('Weight is missing!')
            return
        }
        if(receiverCountry?.value == 'PR') {
            const getValidation = await validateAddress()
            if(!getValidation) {
                return false
            }
        }
        else {
            const getValidation = await validateFedExAddress()
            console.log(getValidation,"getValidation=>")
            if(!getValidation) {
                return false
            }
            
        }
        
        // if(senderName || senderPhone || senderCompany ||senderAddress1 ||  senderCity || senderZipCode)
        let currentShip = currentShipId ? currentShipId : shipId
        let type = serviceType ? serviceType : serviceTypeName

        let headersList = {
            "Content-Type": "application/json"
        }
        console.log(serviceType, "serviceType")
        const id = Cookies.get('id')
        let bodyContent = {
            "user": id,
            "shipId": currentShipId,
            "Description": shipName,
            "UserNumber": senderPhone,
            "isFedEx": type == 'fedEx' ? true : false,
            "ShipFrom": {
                "name": senderName,
                "number": senderPhone,
                "company": senderCompany,
                "Address": {
                    "AddressLine1": senderAddress1,
                    "city": senderCity,
                    "pincode": senderZipCode,
                    "CountryCode": senderCountry.value,
                    "state": senderState?.value
                }
            },
            "ShipTo": {
                "name": receiverName,
                "number": receiverPhone,
                "company": receiverCompany,
                "Address": {
                    "AddressLine1": receiverAddress1,
                    "city": receiverCity,
                    "pincode": receiverZipCode,
                    "CountryCode": receiverCountry?.value,
                    "state": receiverState?.value
                }

            },
            "Service": service,
            "Package": {
                "packaging": packageOfService,
                "Dimensions": dimension,
                "PackageWeight": packageWeight
            },
            "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2dpMUoycjc2VjFSd1Yyd3FmS1VxaVBXenhYeCIsIm5iZiI6MTcwNzkzNTkyOCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNzk1MDMyOCwiaWF0IjoxNzA3OTM1OTI4LCJqdGkiOiI5ZDkyMGZhMi1jNDQxLTQ3YzQtODQyZi0wNDM4YTkyN2UxODMifQ.eERxNU5bKkXhX6wDLfWkLL7MrzF40asZhxBd6AwApYj6HKXvouVXi84_Bwzog07WbTTYl3qihAjYrK5tKvkh4mGcgJW0bpHVo3z2d-XZzp8rUdF0q_hETwdt6_gC3bXZz3kIR-CA8r6nTni_L21kaRZf_PiJ7al-qVslSm4zVhaHqmjWEfAs9fBNE_gAnPkgz2UBs_oawIaE_y2_usEDKkvQtuxxrBj8cTmbR5zAcBy0duCQ_YI4_Gj6g1j6HYwkXP-wb6QPe-qNn74Ihd3UGFSRpHTPzJsRjvZULM5RQq_j9j2fe4MzsBswqyTlVCMa5Hm7w3z0CX6XJCklcbrT4_Y-RYctpkTHhlKtC1IPlsuj_XwKOx6fHmbyTfPKOn3qFy1-K2BaF0PEFOShgLwcYu8QOi6AFTuUQdAT_9cwI-4vKhLP5Lq-SlMzK8IfhL36eOqazh6xoepF3sprrkqdu4YGyy_3JlUVID_85STkWjX2dZmbS7VqAmkjoeQGRWwtt-722J5EmE6lQDgu8rqda1rGPGDBsBdY8NLx5mALo2-7YHVGVJdF1zS3oa5aXhRzgQTUCGo1RDXXuCAsjj3GSFByq74-HXemGGCdwEAwLG_jXyfktQl7YH2HE_AfII9BWZ8xXN19-bS2lLiJmQ7b5m5nU68ulfFmDCAruu9KP0w"
        };
        console.log(serviceType, "serviceType==>")
        // let bodyContent = JSON.stringify({
        //     "Description": "Ship WS test",
        //     "ShipTo": {
        //         "name": "Happy Dog Pet Supply",
        //         "number": "9225377171",
        //         "Address": {
        //             "AddressLine1": "123 Main St",
        //             "city": "timonium",
        //             "pincode": "21030",
        //             "CountryCode": "US"
        //         }
        //     },
        //     "ShipFrom": {
        //         "name": "T and T Designs",
        //         "number": "1234567890",
        //         "Address": {
        //             "AddressLine1": "2311 York Rd",
        //             "city": "Alpharetta",
        //             "pincode": "30005",
        //             "CountryCode": "US"
        //         }

        //     },
        //     "Service": {
        //         "code": "03",
        //         "Description": "Express"
        //     },
        //     "Package": {

        //         "packaging": {
        //             "code": "02",
        //             "Description": "Nails"
        //         },
        //         "Dimensions": {
        //             "dimensionCode": "IN",
        //             "dimensionDescription": "Inches",
        //             "length": "10",
        //             "width": "30",
        //             "height": "45"
        //         },
        //         "PackageWeight": {
        // "code": "LBS",
        // "description": "Pounds",
        // "Weight": "5"
        //         }
        //     },
        //     "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2VBN0p3QnBycEZMRWs1SFN0dzE2ZTNkeDVKSiIsIm5iZiI6MTcwNjU0NTE5NCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNjU1OTU5NCwiaWF0IjoxNzA2NTQ1MTk0LCJqdGkiOiI1MzZmOTI2YS1hYTg1LTQyODMtOThhOS01ZGQyYzYzZTczYzcifQ.KrVNj8zHAVAMfeDJc3CobmN2EKq5D61wxAiWJ31Ljf70sqPN1cpks0JLpUc2OIY8E_LLOMbckUxmZyNWWKL7iQCVHCXa_TNECxSTL8TOGQoxkwW5N4AN13xsywjhc0MqZH1g6JxYikQ65h7z0uXMiHrNadtW61n6RC2Dfx4XDLfkAmiGk4sV5G8TVvz7QJV1dQC6BTjWblRg52a0PzdUrYqeXtcXo-txqDEIOLaBk8lFJUQ2yirLtdam57KetnnyWHEzKl_p5KjG92DuRvJQ_xlXuBEPLZ8YHvnteMjKld-zRqY7weGt7HhjGxhK0VzHbK9kXPh5L_tbBIKpcajRqy9dRYWdyo1LzUQptQR_MTUe-8SVTHRQ3xezcyGENizWYhKygWQyZTno3T8zh0KawR9s2mAhT8j_ZT3mL0IBatOUqpRzytBDe93j1sR4hSU9IpRBJPKpUUK98I_b8tSS5HieDa_fgAcLYf020qzX2wJT6Iua-s2HL7hqW635E-XqBZh3Kmwuau98PwlwlCpim_QkGOYBiYN5z5amRaflDScJRi4UeON4-eHG6e5EGTL4VPDwnFiJSpAqNmOV5Xd0L5IAtZ5Tlne1_TQodOBjros81D6lGf0HKER4RzkZj4zDlhf7TSWJfrmL2zmnoDgyobSCY4V0HaYhLzwHuvz6S1k"



        // });
        let endpoint = 'create-ship'
        if (serviceType == 'fedEx') {
            endpoint = 'create-fedex-ship'
        }

        try {
            let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${endpoint}`, bodyContent, {
                headers: headersList
            });

            setShipResult(response?.data?.data?.ShipmentResponse)
            setServiceCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.ServiceOptionsCharges)
            setTranspotationalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TransportationCharges)

            setTotalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TotalCharges)
            console.log(serviceType, "serviceType=>")
            if (serviceType == 'fedEx') {
                console.log('call fedex save ', response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount)
                setShipResult(response?.data?.data?.output)
                const charges = {
                    CurrencyCode: "USD",
                    MonetaryValue: response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount
                }
                setTotalCharges(charges)
                console.log(currentShip, "currentShipId")
                await saveFedExResponseOfShipment(currentShip, response?.data?.data?.transactionId, response?.data?.data?.output)
            } else {
                console.log('call upi save ')
                await saveResponseOfShipment(currentShip, response?.data?.data?.ShipmentResponse?.Response, response?.data?.data?.ShipmentResponse?.ShipmentResults)
            }

        } catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log(err, "err===>>>")
            if (err instanceof Error) {
                if(err?.response?.data?.err?.errors.length) {
                    const errArr = await err?.response?.data?.err?.errors.map(error => error.message)
                console.log(errArr, "errArr--")
                // Assuming err.message contains the error messages from the server
                setShipError(errArr)
                }
                else {
                    const errArr = await err?.response?.data?.err.map(error => error.message)
                console.log(errArr, "errArr--")
                // Assuming err.message contains the error messages from the server
                setShipError(errArr)
                }
                
            } else {
                // If it's a network error or other error not related to server response
                setValidationError(prevErrors => [...prevErrors, "An error occurred while processing your request."]);
            }

        }
    }

    async function createLoadShipApi(shipId, serviceType, data) {     

        if(receiverCountry?.value == 'PR') {
            const getValidation = await validateAddress()
            if(!getValidation) {
                return false
            }
            
        }else {
            const getValidation = await validateFedExAddress(data?.shipReceiver?.Address)
            if(!getValidation) {
                return false
            }
            
            
        }
      
        let currentShip = currentShipId ? currentShipId : shipId

        let headersList = {
            "Content-Type": "application/json"
        }
        const id = Cookies.get('id')


        const weight = {
            code: packageWeight?.code,
            description: packageWeight?.description,
            weight: data.package.PackageWeight.weight
        }

        let bodyContent = {
            "user": id,
            "shipId": currentShip,
            "Description": data?.description,
            "UserNumber": senderPhone,
            "isFedEx": serviceType == 'fedEx' ? true : false,
            "ShipFrom": {
                "name": data?.shipSender?.name,
                "number": data?.shipSender.number,
                "company": data?.shipSender.company,
                "Address": {
                    "AddressLine1": data?.shipSender.Address.AddressLine1,
                    "city": data?.shipSender.Address.city,
                    "pincode": data?.shipSender.Address.pincode,
                    "CountryCode": data?.shipSender.Address.CountryCode.value,
                    "state": data?.shipSender.Address.state
                }
            },
            "ShipTo": {
                "name": data?.shipReceiver.name,
                "number": data?.shipReceiver.number,
                "company": data?.shipReceiver.company,
                "Address": {
                    "AddressLine1": data?.shipReceiver.Address.AddressLine1,
                    "city": data?.shipReceiver.Address.city,
                    "pincode": data?.shipReceiver.Address.pincode,
                    "CountryCode": data?.shipReceiver.Address.CountryCode.value,
                    "state": data?.shipReceiver.Address.state
                }

            },
            "Service": data?.service,
            "Package": {
                "packaging": data?.package.packaging,
                "Dimensions": dimension,
                "PackageWeight": weight
            },
            "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2dpMUoycjc2VjFSd1Yyd3FmS1VxaVBXenhYeCIsIm5iZiI6MTcwNzkzNTkyOCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNzk1MDMyOCwiaWF0IjoxNzA3OTM1OTI4LCJqdGkiOiI5ZDkyMGZhMi1jNDQxLTQ3YzQtODQyZi0wNDM4YTkyN2UxODMifQ.eERxNU5bKkXhX6wDLfWkLL7MrzF40asZhxBd6AwApYj6HKXvouVXi84_Bwzog07WbTTYl3qihAjYrK5tKvkh4mGcgJW0bpHVo3z2d-XZzp8rUdF0q_hETwdt6_gC3bXZz3kIR-CA8r6nTni_L21kaRZf_PiJ7al-qVslSm4zVhaHqmjWEfAs9fBNE_gAnPkgz2UBs_oawIaE_y2_usEDKkvQtuxxrBj8cTmbR5zAcBy0duCQ_YI4_Gj6g1j6HYwkXP-wb6QPe-qNn74Ihd3UGFSRpHTPzJsRjvZULM5RQq_j9j2fe4MzsBswqyTlVCMa5Hm7w3z0CX6XJCklcbrT4_Y-RYctpkTHhlKtC1IPlsuj_XwKOx6fHmbyTfPKOn3qFy1-K2BaF0PEFOShgLwcYu8QOi6AFTuUQdAT_9cwI-4vKhLP5Lq-SlMzK8IfhL36eOqazh6xoepF3sprrkqdu4YGyy_3JlUVID_85STkWjX2dZmbS7VqAmkjoeQGRWwtt-722J5EmE6lQDgu8rqda1rGPGDBsBdY8NLx5mALo2-7YHVGVJdF1zS3oa5aXhRzgQTUCGo1RDXXuCAsjj3GSFByq74-HXemGGCdwEAwLG_jXyfktQl7YH2HE_AfII9BWZ8xXN19-bS2lLiJmQ7b5m5nU68ulfFmDCAruu9KP0w"
        };
        console.log(serviceType, "serviceType==>")
        // let bodyContent = JSON.stringify({
        //     "Description": "Ship WS test",
        //     "ShipTo": {
        //         "name": "Happy Dog Pet Supply",
        //         "number": "9225377171",
        //         "Address": {
        //             "AddressLine1": "123 Main St",
        //             "city": "timonium",
        //             "pincode": "21030",
        //             "CountryCode": "US"
        //         }
        //     },
        //     "ShipFrom": {
        //         "name": "T and T Designs",
        //         "number": "1234567890",
        //         "Address": {
        //             "AddressLine1": "2311 York Rd",
        //             "city": "Alpharetta",
        //             "pincode": "30005",
        //             "CountryCode": "US"
        //         }

        //     },
        //     "Service": {
        //         "code": "03",
        //         "Description": "Express"
        //     },
        //     "Package": {

        //         "packaging": {
        //             "code": "02",
        //             "Description": "Nails"
        //         },
        //         "Dimensions": {
        //             "dimensionCode": "IN",
        //             "dimensionDescription": "Inches",
        //             "length": "10",
        //             "width": "30",
        //             "height": "45"
        //         },
        //         "PackageWeight": {
        // "code": "LBS",
        // "description": "Pounds",
        // "Weight": "5"
        //         }
        //     },
        //     "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2VBN0p3QnBycEZMRWs1SFN0dzE2ZTNkeDVKSiIsIm5iZiI6MTcwNjU0NTE5NCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNjU1OTU5NCwiaWF0IjoxNzA2NTQ1MTk0LCJqdGkiOiI1MzZmOTI2YS1hYTg1LTQyODMtOThhOS01ZGQyYzYzZTczYzcifQ.KrVNj8zHAVAMfeDJc3CobmN2EKq5D61wxAiWJ31Ljf70sqPN1cpks0JLpUc2OIY8E_LLOMbckUxmZyNWWKL7iQCVHCXa_TNECxSTL8TOGQoxkwW5N4AN13xsywjhc0MqZH1g6JxYikQ65h7z0uXMiHrNadtW61n6RC2Dfx4XDLfkAmiGk4sV5G8TVvz7QJV1dQC6BTjWblRg52a0PzdUrYqeXtcXo-txqDEIOLaBk8lFJUQ2yirLtdam57KetnnyWHEzKl_p5KjG92DuRvJQ_xlXuBEPLZ8YHvnteMjKld-zRqY7weGt7HhjGxhK0VzHbK9kXPh5L_tbBIKpcajRqy9dRYWdyo1LzUQptQR_MTUe-8SVTHRQ3xezcyGENizWYhKygWQyZTno3T8zh0KawR9s2mAhT8j_ZT3mL0IBatOUqpRzytBDe93j1sR4hSU9IpRBJPKpUUK98I_b8tSS5HieDa_fgAcLYf020qzX2wJT6Iua-s2HL7hqW635E-XqBZh3Kmwuau98PwlwlCpim_QkGOYBiYN5z5amRaflDScJRi4UeON4-eHG6e5EGTL4VPDwnFiJSpAqNmOV5Xd0L5IAtZ5Tlne1_TQodOBjros81D6lGf0HKER4RzkZj4zDlhf7TSWJfrmL2zmnoDgyobSCY4V0HaYhLzwHuvz6S1k"



        // });
        console.log(serviceType,"serviceType=>")
        let endpoint = 'create-ship'
        if (serviceType == 'fedEx') {
            endpoint = 'create-fedex-ship'
        }

        try {
            let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${endpoint}`, bodyContent, {
                headers: headersList
            });

            setShipResult(response?.data?.data?.ShipmentResponse)
            setServiceCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.ServiceOptionsCharges)
            setTranspotationalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TransportationCharges)

            setTotalCharges(response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges.TotalCharges)
            console.log(serviceType, "serviceType=>")
            if (serviceType == 'fedEx') {
                console.log('call fedex save ', response?.data?.data?.output.transactionShipments[0].completedShipmentDetail.shipmentRating.shipmentRateDetails[0].totalNetCharge)
                setShipResult(response?.data?.data?.output)
                const charges = {
                    CurrencyCode: "USD",
                    MonetaryValue: response?.data?.data?.output.transactionShipments[0].completedShipmentDetail.shipmentRating.shipmentRateDetails[0].totalNetCharge
                }
                setTotalCharges(charges)
                console.log(currentShip, "currentShipId")
                await saveFedExResponseOfShipment(currentShip, response?.data?.data?.transactionId, response?.data?.data?.output)
            } else {
                console.log('call upi save ')
                await saveResponseOfShipment(currentShip, response?.data?.data?.ShipmentResponse?.Response, response?.data?.data?.ShipmentResponse?.ShipmentResults)
            }

        } catch (err) {
            if (err?.status == 401) {
                navigate('/login')
            }
            console.log( err?.response?.data?.err?.errors, "err===-->>>")
            console.log(err?.response?.data?.err,"err?.response?.data?.err?")
            if (err instanceof Error) {
                if(err?.response?.data?.err?.errors?.length) {
                    const errArr = await err?.response?.data?.err?.errors?.map(error => error.message)
                    console.log(errArr, "errArr--")
                    // Assuming err.message contains the error messages from the server
                    setShipError(errArr)
                }
                else {
                    const errArr = await err?.response?.data?.err.map(error => error.message)
                    console.log(errArr, "errArr--")
                    // Assuming err.message contains the error messages from the server
                    setShipError(errArr)
                }
            } else {
                // If it's a network error or other error not related to server response
                setValidationError(prevErrors => [...prevErrors, "An error occurred while processing your request."]);
            }

        }
    }



    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        // You can implement your phone number validation logic here
        // For simplicity, let's assume the phone number must contain only numbers and be 10 digits long
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    // useEffect(() => {
    //     if (!receiverName) {
    //         setValidationError(prevErrors => [...prevErrors, 'Receiver Name is Required']);
    //     }
    //     // if(!receiverCompany) {
    //     //     setValidationError(prevErrors => [...prevErrors, 'Service Type is required']);
    //     // }
    //     if (!receiverZipCode) {
    //         setValidationError(prevErrors => [...prevErrors, 'Zip Code is required']);
    //     }
    //     if (!receiverAddress1) {
    //         setValidationError(prevErrors => [...prevErrors, 'Receiver address is required!']);
    //     }
    //     if (!receiverEmail) {
    //         setValidationError(prevErrors => [...prevErrors, 'Receiver email is required!']);
    //     }
    //     if (!receiverPhone) {
    //         setValidationError(prevErrors => [...prevErrors, 'Receiver phone is required!']);
    //     }
    //     if (!receiverCountry) {
    //         setValidationError(prevErrors => [...prevErrors, 'Receiver country is required!']);

    //     }
    //     if (!service) {
    //         setValidationError(prevErrors => [...prevErrors, 'Service is required!']);

    //     }
    //     if (!packageOfService) {
    //         setValidationError(prevErrors => [...prevErrors, 'Package is required!']);
    //     }
    // }, [])

    const handleSaveSenderDetails = () => {
        const errors = {};
        if (!senderName.trim()) {
            errors.senderName = 'Sender name is required';
        }
        // Add similar validation for other sender fields

        if (!validateEmail(senderEmail)) {
            errors.senderEmail = 'Invalid email format';
        }

        if (!validatePhone(senderPhone)) {
            errors.senderPhone = 'Invalid phone number';
        }

        setSenderErrors(errors);

        // Proceed with saving sender details if there are no errors
        if (Object.keys(errors).length === 0) {
            // Save sender details
        }
    };

    const handleSaveReceiverDetails = () => {
        const errors = {};
        if (!receiverName.trim()) {
            errors.receiverName = 'Receiver name is required';
        }
        // Add similar validation for other receiver fields

        if (!validateEmail(receiverEmail)) {
            errors.receiverEmail = 'Invalid email format';
        }

        if (!validatePhone(receiverPhone)) {
            errors.receiverPhone = 'Invalid phone number';
        }

        setReceiverErrors(errors);

        // Proceed with saving receiver details if there are no errors
        if (Object.keys(errors).length === 0) {
            // Save receiver details
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ship`, {
                    method: 'GET',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${Cookies.get('token')}`, // Replace with your actual access token
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data); // Handle the response data as needed
                if (data.data.length) {
                    setUserShip(data.data)
                }
            } catch (error) {
                console.error('There was an error fetching the data:', error);
            }
        };

        fetchData();
    }, []);

    const saveShipment = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/save-ship`, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
                    'Authorization': `Bearer ${Cookies.get('token')}`, // Replace with your actual access token
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    description: shipName,
                    shipSender: {
                        "name": senderName,
                        "number": senderPhone,
                        "company": senderCompany,
                        "email": senderEmail,
                        "Address": {
                            "AddressLine1": senderAddress1,
                            "city": senderCity,
                            "state": senderState?.value,
                            "pincode": senderZipCode,
                            "CountryCode": senderCountry,
                        }
                    },
                    shipReceiver: {
                        "name": receiverName,
                        "number": receiverPhone,
                        "company": receiverCompany,
                        "email": receiverEmail,
                        "Address": {
                            "AddressLine1": receiverAddress1,
                            "city": receiverCity,
                            "state": receiverState?.value,
                            "pincode": receiverZipCode,
                            "CountryCode": receiverCountry
                        }
                    },
                    service: service,
                    package: {
                        "packaging": packageOfService,
                        "Dimensions": dimension,
                        "PackageWeight": packageWeight
                    },
                }),
            });



            const data = await response.json();
            if (data.status) {
                console.log('call true', data)
                console.log(data.data._id, "data.data._id==========>>")
                setCurrentShipId(data.data._id)
                console.log(data.description, "data.description")
                console.log("data.data._id==========>>")
                console.log("setShipName1")
                setShipName(data.data.description)
                await createShipApi(data.data._id)
                handleSaveCloseModal()
            }
            else {
                console.log('call else')
                throw new Error(data.error)
            }
        } catch (error) {
            console.log(error, "error->s", error.message)
            setError(error.message)
            console.error('There was an error fetching the data:', error);
            handleSaveCloseModal()
        }
    };

    const saveResponseOfShipment = async (shipId, responseData, shipMentResult) => {
        console.log('saveResponseOfShipment call', shipId, responseData, shipMentResult)
        try {

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/ship-response`, {
                method: 'POST',
                headers: {

                    'Authorization': `Bearer ${Cookies.get('token')}`, // Replace with your actual access token
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shipId: shipId,
                    response: responseData,
                    shipResult: shipMentResult,


                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data); // Handle the response data as needed
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        }
    }
    const saveFedExResponseOfShipment = async (shipId, transactionId, output) => {
        console.log('call saveFedExResponseOfShipment', shipId)
        try {

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/ship-response`, {
                method: 'POST',
                headers: {

                    'Authorization': `Bearer ${Cookies.get('token')}`, // Replace with your actual access token
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shipId: shipId,
                    transactionId: transactionId,
                    output: output,
                    isFedEx: true

                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data); // Handle the response data as needed
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        }
    }
    const makePayment = async () => {
        console.log(process.env.REACT_APP_STRIP_KEY_PUBLISH, "process.env.REACT_APP_STRIP_KEY_PUBLISH")
        const stripe = await loadStripe(process.env.REACT_APP_STRIP_KEY_PUBLISH)
        const body = {
            products: {
                procduct_name: shipName,
                price: profitMargin ? totalCharges?.MonetaryValue * (1 + profitMargin / 100) : totalCharges?.MonetaryValue
            },
            shipId: currentShipId

        }
        console.log(body, "products=>>>", totalCharges, profitMargin)

        let headersList = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get('token')}`,
        }
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-checkout-payment`, {
            method: 'POST',
            headers: headersList,
            body: JSON.stringify(body)
        })
        const session = await res.json()

        const result = stripe.redirectToCheckout({
            sessionId: session.id
        })
    }

    const createShipHandler = async (ele) => {
        console.log(ele, "ele=================>")
        console.log(selectedIntegration?.columns.indexOf('id'), "csvIntegration?.columns.indexOf('id')")
        if (ele?.Description) {
            setReferenceNumber(ele.Description)
        }
        if (ele?.Date) {
            console.log(ele?.Date, "ele?.Date=>")
            setShipDate(ele?.Date)
        }
        if (ele?.Service) { 
            setService(ele.Service)
        }
        if (ele?.Length) {
            setShipLength(ele?.Length)
        }
        if (ele?.Width) {
            setShipWidth(ele?.Width)
        }
        if (ele?.Height) {
            setShipHeight(ele?.Height)
        }
        if (ele?.Weight) {
            setShipWeight(ele?.Weight)
        }
        if (ele?.SenderName) {
            setSenderName(ele?.SenderName)
        }
        if (ele?.Company) {
            setSenderCompany(ele?.Company)
        }
        if (ele?.SenderAddress1) {
            setSenderAddress1(ele?.SenderAddress1)
        }
        if (ele?.SenderAddress2) {
            setSenderAddress2(ele?.SenderAddress2)
        }
        if (ele?.SenderAddress3) {
            setSenderAddress3(ele?.SenderAddress3)
        }
        if (ele?.SenderCity) {
            setSenderCity(ele?.SenderCity)
        }
        console.log(ele?.SenderState,"ele?.SenderState=>")
        if (ele?.SenderState) {
            setSenderState(ele?.SenderState)
        }
        if (ele?.SenderZip) {
            setSenderZipCode(ele?.SenderZip)
        }
        if (ele?.SenderCountry) {
            setSenderCountry(ele?.SenderCountry)
        }
        if (ele?.SenderPhone) {
            setSenderPhone(ele?.SenderPhone)
        }
        if (ele?.SenderEmail) {
            setSenderEmail(ele?.SenderEmail)
        }
        if (ele?.receiverName) {
            setReceiverName(ele?.receiverName)
        }
       
        if (ele?.receiverCompany) {
            setReceiverCompany(ele?.receiverCompany)
        }
        if (ele?.receiverAddress1) {
            setReceiverAddress1(ele?.receiverAddress1)
        }
        if (ele?.receiverAddress2) {
            setReceiverAddress2(ele?.receiverAddress2)
        }
        if (ele?.receiverAddress3) {
            setReceiverAddress3(ele?.receiverAddress3)
        }
        if (ele?.receiverCity) {
            setReceiverCity(ele?.receiverCity)
        }
      
        if (ele?.receiverZip) {
            setReceiverZipCode(ele?.receiverZip)
        }
        if (ele?.receiverCountry) {
            const getCountry = receiverCountryOptions.find(item => item.value == ele?.receiverCountry)
            console.log(getCountry,"getCountry=>")
            setReceiverCountry(getCountry)
        }
        
        if (ele?.receiverEmail) {
            setReceiverEmail(ele?.receiverEmail)
        }
        if (ele?.receiverPhone) {
            setReceiverPhone(ele?.receiverPhone)
        }
        if (ele?.receiverState) {
            let getStateData = statesData.find(item => item.value == ele.receiverState)
            if(!getStateData) {
                getStateData = statesData.find(item => item.label == ele.receiverState)
            }
           
            setReceiverState(getStateData)
        }

    }
    const saveUpsLocation = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-location`;
                const requestData = {
                    accessPointeId: accessPointeId,
                    upsLocationName: upsLocationName,
                    upsLocationAddressLine1: upsLocationAddressLine1,
                    upsLocationAddressLine2: upsLocationAddressLine2,
                    upsLocationAddressLine3: upsLocationAddressLine3,
                    upsLocationCity: upsLocationCity,
                    upsLocationStateProvinceCode: upsLocationStateProvinceCode,
                    upsLocationStatePostalCode:upsLocationStatePostalCode,
                    upsLocationCountry:upsLocationCountry?.value,
                    user:Cookies.get('id')
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data, "dta==")
                        // Handle successful response here
                        handleUpsLocationModal();
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error while calling API:', error);
                        handleUpsLocationModal();
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                // Handle any synchronous errors
                console.error('Caught synchronous error:', err);
                handleUpsLocationModal();
            }
    }
    const loadShipMent = async (shipId) => {


        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-shipment/${shipId}`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            if (data) {
                console.log(data.data.shipSender.Address.state, "data.data.shipSender.Address.state")
                const getStateLabel = statesData.find(ele => ele?.value == data.data.shipSender.Address.state)
               

                console.log(getStateLabel, "getStateLabel")
                console.log(data.data.shipReceiver.Address.state, "data.data.shipReceiver.Address.state")
                console.log(data.data.description, "data.data.description")
                setCurrentShipId(shipId)
                console.log("setShipName2")
                setShipName(data.data.description)
                setSenderName(data.data.shipSender.name)
                setSenderPhone(data.data.shipSender.number)
                setSenderEmail(data.data.shipSender.email)
                setSenderCompany(data.data.shipSender.company)
                setSenderAddress1(data.data.shipSender.Address.AddressLine1)
                setSenderCity(data.data.shipSender.Address.city)
                setSenderCountry(data.data.shipSender.Address.CountryCode)
                setDefaultSenderState(data.data.shipSender.Address.state)
                setSenderZipCode(data.data.shipSender.Address.pincode)

                setReceiverName(data.data.shipReceiver.name)
                setReceiverPhone(data.data.shipReceiver.number)
                setReceiverEmail(data.data.shipReceiver.email)
                setReceiverCompany(data.data.shipReceiver.company)
                setReceiverAddress1(data.data.shipReceiver.Address.AddressLine1)
                setReceiverCity(data.data.shipReceiver.Address.city)
                setReceiverZipCode(data.data.shipReceiver.Address.pincode)
                setReceiverCountry(data.data.shipReceiver.Address.CountryCode)
                setDefaultReceiverState(data.data.shipReceiver.Address.state)
                if (data.data.shipSender.Address.AddressLine2) {
                    setSenderAddress2(data.data.shipSender.Address.AddressLine2)
                }
                if (data.data.shipSender.Address.AddressLine3) {
                    setSenderAddress3(data.data.shipSender.Address.AddressLine3)
                }
                if (data.data.shipReceiver.Address.AddressLine2) {
                    setReceiverAddress1(data.data.shipSender.Address.AddressLine2)
                }
                if (data.data.shipReceiver.Address.AddressLine3) {
                    setReceiverAddress1(data.data.shipSender.Address.AddressLine3)
                }
                let fedExApi = ''
                setService(data.data.service)
                console.log(data.data.service?.code, "data.data.service?.code==>>")
                if (data.data.service?.code == "FEDEX_INTERNATIONAL_PRIORITY" || data.data.service?.code == "INTERNATIONAL_ECONOMY" || data.data.service?.code == "FEDEX_GROUND" || data.data.service?.code == "FIRST_OVERNIGHT" || data.data.service?.code == "PRIORITY_OVERNIGHT" || data.data.service?.code == "FEDEX_2_DAY" || data.data.service?.code == "FEDEX_EXPRESS_SAVER" || data.data.service?.code == "GROUND_HOME_DELIVERY") {
                    console.log("Call setService4")
                    setServiceType('fedEx')
                    fedExApi = 'fedEx'
                }
                setPackageOfService(data.data.package.packaging)
                setActiveButton(3)
                console.log(data.data.package.PackageWeight.code, "data.data.package.PackageWeight.code=>")
                if (data.data.package.PackageWeight.code == 'LBS') {
                    setUnit(options[0])
                }
                setShipWidth(data.data.package.Dimensions.width)
                setShipHeight(data.data.package.Dimensions.height)
                setShipLength(data.data.package.Dimensions.length)
                console.log(data.data.package.PackageWeight.weight, "data.data.package.PackageWeight.weight=>")
                setShipWeight(data.data.package.PackageWeight.weight)
                setTotalCharges(data?.data?.shipResult?.ShipmentCharges.TotalCharges)
                setTranspotationalCharges(data?.data?.shipResult?.ShipmentCharges?.TransportationCharges)
                setServiceCharges(data?.data?.shipResult?.ShipmentCharges?.ServiceOptionsCharges)


                
                console.log('call create ShipApi2449')
                await createLoadShipApi(shipId, fedExApi, data.data)
                handleCloseModal()


                // const getCountry = senderCountryOptions.find(ele => ele?.value == data.country)
                // setAddId(data._id)
                // setSenderName(data.name)
                // if (data.user.email) {
                //     setSenderEmail(data.user.email)
                // }
                // else {
                //     setSenderEmail(user.data.email)
                // }
                // if (data.user.companyName) {
                //     setSenderCompany(data.user.companyName)
                // }
                // else {
                //     setSenderCompany(user.data.companyName)
                // }
                // setSenderAddress1(data.address1)
                // setSenderAddress2(data.address2)
                // setSenderAddress3(data.address3)
                // setSenderCountry(getCountry);
                // setSenderCity(data.city)
                // setSenderState(data.state)
                // setSenderZipCode(data.zip)
                // setSenderPhone(data.phone)
                // setSenderDetails(true)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }




    return (
        <>
            <Header />
            <Sidebar
                id="menu-item"
                id1="menu-items"
                activeClassName="ship"
            />
            <>
                <div className="page-wrapper">
                    <div className="content">

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">

                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Ship </Link>
                                        </li>

                                        {/* <li className="breadcrumb-item">
                              <i className="feather-chevron-right">
                                <FeatherIcon icon="chevron-right" />
                              </i>
                            </li> */}
                                        {/* <li className="breadcrumb-item active">Doctor Dashboard</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Modal show={showUpsLocation} onHide={handleUpsLocationModal}>
                            <Modal.Header>
                                <Modal.Title>UPS Location</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <input type="text" placeholder="Access Point Id"  onChange={(e) => {
                                    console.log("setShipName3")
                                    setAccessPointId(e.target?.value)
                                }} className="form-control" value={accessPointeId} />
                                       <input type="text" placeholder="Name" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationName(e.target?.value)
                                }} className="form-control" value={upsLocationName} />
                                       <input type="text" placeholder="Address Line 1" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationAddressLine1(e.target?.value)
                                }} className="form-control" value={upsLocationAddressLine1} />
                                       <input type="text" placeholder="Address Line 2" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationAddressLine2(e.target?.value)
                                }} className="form-control" value={upsLocationAddressLine2} />
                                       <input type="text" placeholder="Address Line 3" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationAddressLine3(e.target?.value)
                                }} className="form-control" value={upsLocationAddressLine3} />
                                       <input type="text" placeholder="City" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationCity(e.target?.value)
                                }} className="form-control" value={upsLocationCity} />
                                       <input type="text" placeholder="State Province Code (required)
" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationStateProvinceCode(e.target?.value)
                                }} className="form-control" value={upsLocationStateProvinceCode} />
                                       <input type="text" placeholder="Postal Code (required)" onChange={(e) => {
                                    console.log("setShipName3")
                                    setUpsLocationStatePostalCode(e.target?.value)
                                }} className="form-control" value={upsLocationStatePostalCode} />
                                <div className="form-group">
                                                                                    <label>Country:</label>
                                                                                    <Select
                                                                                        value={upsLocationCountry}
                                                                                        onChange={(selectedOption) => setUpsLocationCountry(selectedOption)}
                                                                                        options={receiverCountryOptions}
                                                                                    />
                                                                                </div>
                                {/* Add the content of your modal here */}
                                {/* This can include the form for loading shipment */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={saveUpsLocation}>
                                    Save Shipment
                                </button>   
                                <button className="btn btn-secondary" onClick={handleSaveCloseModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showSaveShipModal} onHide={handleSaveCloseModal}>
                            <Modal.Header>
                                <Modal.Title>Save Shipment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <input type="text" placeholder="Name" onChange={(e) => {
                                    console.log("setShipName3")
                                    setShipName(e.target?.value)
                                }} className="form-control" value={shipName} />

                                {/* Add the content of your modal here */}
                                {/* This can include the form for loading shipment */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={saveShipment}>
                                    Save Shipment
                                </button>
                                <button className="btn btn-secondary" onClick={handleSaveCloseModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showCsvModal} onHide={handleCloseCsvModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Csv Integrations</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {console.log(csvIntegrationData, "csvIntegrationData=>")}
                                        {csvIntegrationData && csvIntegrationData.length && csvIntegrationData.map((shipment) => (
                                            <tr key={shipment._id}>
                                                <td>{shipment.integrationName}</td>
                                                <td><button onClick={() => {
                                                    handleUploadCsvButtonClick()
                                                    setSelectedIntegration(shipment)
                                                }}>Upload Csv</button></td>
                                                <input type="file" id="csvFileInput" style={{ display: 'none' }} onChange={(e) => {
                                                    console.log(shipment, "shipment=>")
                                                    

                                                    handleFileSelect(e, shipment)
                                                }} />

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* Add the content of your modal here */}
                                {/* This can include the form for loading shipment */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={handleCloseCsvModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Load Saved Shipment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userShip.map((shipment) => (
                                            <tr key={shipment._id}>
                                                <td><button onClick={() => loadShipMent(shipment._id)}>{shipment.description}</button></td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* Add the content of your modal here */}
                                {/* This can include the form for loading shipment */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={handleCloseModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <div className="row">
                            <div className="col-xl-4 d-flex">
                                <div style={{ overflowY: 'auto', height: 'calc(100vh - 200px)' }} className="card chat-box">
                                    <div className="card text-center p-3 ">
                                        <p><strong> <i className="fa-solid fa-circle-exclamation"></i> Get Started!</strong></p>
                                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => { setShowCsvModal(true) }}>Upload CSV</button>
                                    </div>
                                    <ul>
                                        {validationError.length ? validationError.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        )) : ''}
                                    </ul>
                                    <ul>
                                        {shipError?.length ? shipError.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        )) : ''}
                                    </ul>
                                    {bulkShipData.length ?
                                        <p><b>Orders:</b></p> : ''
                                    }

{bulkShipData.length ? bulkShipData.map((ele, index) => (
  <div className="shadow p-3 mb-5 bg-white rounded" style={{ cursor: 'pointer' }} onClick={() => createShipHandler(ele)} key={index}>
    {console.log(firstKey, "firstKey=>s")}
    <p>{ele[firstKey]}</p>
    {console.log(ele, "ele==>>")}
    {ele.Description === referenceNumber && suggestedAddress.length ? (
      <>
        <p>Addresses</p>
        {suggestedAddress.map((addr, addrIndex) => (
          <Card style={{ width: '18rem', backgroundColor: '#dee2e6' }} key={addrIndex}>
            <Card.Body>
              <Card.Text>
                {addr?.streetLinesToken[0] && <p>{addr?.streetLinesToken[0]},</p>}
                {addr?.streetLinesToken[1] && <p>{addr?.streetLinesToken[1]},</p>}
                {addr?.streetLinesToken[2] && <p>{addr?.streetLinesToken[2]},</p>}
                {addr?.postalCode && <p>{addr?.postalCode},</p>}
                {addr?.city && <p>{addr?.city},</p>}
                {addr?.stateOrProvinceCode && <p>{addr?.stateOrProvinceCode},</p>}
                {addr?.countryCode && <p>{receiverCountryOptions.find(item => item.value === addr?.countryCode)?.label}.</p>}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </>
    ) : null}
    {ele.Description === referenceNumber && totalCharges && (
      <Card style={{ width: '18rem', backgroundColor: '#dee2e6' }}>
        <Card.Body>
          {serviceCharges && (
            <>
              <Card.Title>Shipment Charges</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Service Charges</Card.Subtitle>
              <Card.Text>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>{`Currency: ${serviceCharges?.CurrencyCode}`}</p>
                  <p>Value: {serviceCharges?.MonetaryValue}</p>
                </div>
              </Card.Text>
            </>
          )}
          {transpotationalCharges && (
            <>
              <Card.Subtitle className="mb-2 text-muted">Transportation Charges</Card.Subtitle>
              <Card.Text>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>{`Currency: ${transpotationalCharges?.CurrencyCode}`}</p>
                  <p>Value: {transpotationalCharges?.MonetaryValue}</p>
                </div>
              </Card.Text>
            </>
          )}
          <Card.Subtitle className="mb-2 text-muted">Total Charges</Card.Subtitle>
          <Card.Text>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>{`Currency: ${totalCharges.CurrencyCode}`}</p>
              <p>Value: {profitMargin ? totalCharges?.MonetaryValue * (1 + profitMargin / 100) : totalCharges?.MonetaryValue}</p>
            </div>
          </Card.Text>
          <button style={{ marginRight: '10px' }} className="btn btn-primary" onClick={makePayment}>Pay</button>
          <button className="btn btn-primary" onClick={voidShip}>Cancel</button>
        </Card.Body>
      </Card>
    )}
  </div>
)) : null}

                                    {console.log(suggestedAddress,"suggestedAddress-==>>>")}
                                    
                                    
                                    {/* <p>This is where your pending orders will show up once you setup an ecommerce integration.</p> */}
                                    {/* <button className="btn btn-secondary btn-lg me-1">Add Your First Integration</button> */}
                                </div>

                            </div>
                            <div className="col-xl-8" style={{ overflowY: 'auto', height: 'calc(100vh - 200px)' }}>
                                <div className="card chat-box d-flex flex-column" style={{ gap: '10px' }}>
                                    <button onClick={handleShowModal} className="btn btn-secondary  me-1 " style={{ width: 'fit-content' }}><i className="fa-solid fa-folder"></i> Load Shipment Form</button>
                                    <div className=" chat-search-group ">

                                        <div className="chat-user-group clinic-user mb-0 d-flex align-items-center">




                                            <div className="chat-users">
                                                <div className="user-titles">
                                                    <label>Ship Date:</label>
                                                </div>
                                                <div className="user-text">
                                                    <input type="date" className="form-control" value={shipDate} onChange={(e) => setShipDate(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="chat-users">
                                                <div className="user-titles">
                                                    <label>Reference:</label>
                                                </div>
                                                <div className="user-text">
                                                    <input value={referenceNumber} onChange={(e) => setReferenceNumber(e.target.value)} type="text" className="form-control" placeholder="e.g order number" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Chat */}
                                <div className="card chat-box ">
                                    {/* Join Call */}
                                    <div className="page-content">
                                        <div className="meeting">
                                            <div className="meeting-wrapper">
                                                <div className="meeting-list">
                                                    <div className="w-100">
                                                        {error && <p style={{ color: 'red' }}>
                                                            {error}
                                                        </p>}
                                                        <div className="d-flex" style={{ gap: 20 }}>
                                                            <div className="w-50">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <p className="m-0"><strong>Sender</strong></p>
                                                                        <button onClick={updateSenderDetails} className="bg-transparent border-0"><i className="fa-solid fa-save"></i></button>
                                                                        <button className="bg-transparent border-0" onClick={() => setSenderDetails(false)}><i className="fa-solid fa-edit"></i></button>

                                                                    </div>
                                                                    <button><i className="fa-solid fa-arrows-left-right"></i></button>
                                                                </div>
                                                                <div>
                                                                    {senderDetails && <div>
                                                                        {senderName && <p>{senderName}</p>}
                                                                        {senderCompany && <p>{senderCompany}</p>}
                                                                        {senderAddress1 && <p>{senderAddress1}</p>}
                                                                        {senderAddress1 && <p>{senderAddress1}</p>}
                                                                        {senderAddress2 && <p>{senderAddress2}</p>}
                                                                        {senderAddress3 && <p>{senderAddress3}</p>}
                                                                        {(senderCity || senderState || senderZipCode) && <p>{senderCity}, {senderState?.label} {senderZipCode}</p>}
                                                                        <p>{senderCountry?.label}</p>
                                                                        <p>{senderPhone}</p>
                                                                    </div>}
                                                                    {!senderDetails &&
                                                                        <div>

                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Country:</label>
                                                                                        <Select
                                                                                            value={senderCountry}
                                                                                            onChange={(selectedOption) => setSenderCountry(selectedOption)}
                                                                                            options={senderCountryOptions}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Name:</label>
                                                                                        <input onChange={(e) => {
                                                                                            setSenderName(e.target?.value)
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                        }} type="text" value={senderName} className="form-control" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Company:</label>
                                                                                        <input type="text" onChange={(e) => {
                                                                                            setSenderCompany(e.target?.value)
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                        }} className="form-control" value={senderCompany} required />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>ZIP code:</label>
                                                                                        <input type="text" className="form-control" value={senderZipCode} onChange={(e) => {
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                            setSenderZipCode(e.target?.value)
                                                                                        }} required />

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>City:</label>
                                                                                        <input type="text" className="form-control" value={senderCity} onChange={(e) => {
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                            setSenderCity(e.target?.value)
                                                                                        }} required />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>State:</label>
                                                                                        <Select
                                                                                            value={senderState}
                                                                                            onChange={(selectedOption) => {
                                                                                                if (error) {
                                                                                                    setError(null)
                                                                                                }
                                                                                                setSenderState(selectedOption)
                                                                                            }}
                                                                                            options={senderStateList}
                                                                                            required
                                                                                        />
                                                                                        {/* <Autocomplete
                                                                                    id="combo-box-demo"
                                                                                     renderInput={(params) => <TextField {...params} variant="outlined"/>}
                                                                                     
                                                                                        value={senderState}
                                                                                        disablePortal
                                                                                        disableClearable
                                                                                        options={senderStateList}
                                                                                        sx={{
                                                                                            '.MuiOutlinedInput-root' : {
                                                                                                padding:'2px'
                                                                                            },
                                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                              border: '1px solid #e9eaf6;',
                                                                                            },
                                                                                          }}
                                                                                    /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Address1:</label>
                                                                                        <input type="text" className="form-control" value={senderAddress1} onChange={(e) => {
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                            setSenderAddress1(e.target?.value)
                                                                                        }} required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Address2:</label>
                                                                                        <input type="text" className="form-control" value={senderAddress2} onChange={(e) => setSenderAddress2(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Address3:</label>
                                                                                        <input type="text" className="form-control" value={senderAddress3} onChange={(e) => setSenderAddress3(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Email:</label>
                                                                                        <input type="email" className="form-control" value={senderEmail} onChange={(e) => {
                                                                                            setSenderEmail(e.target?.value)
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                        }} required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Phone:</label>
                                                                                        <input type="phone" className="form-control" value={senderPhone} onChange={(e) => {
                                                                                            setSenderPhone(e.target?.value)
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                        }} required={true} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>}
                                                                </div>
                                                            </div>
                                                            <div className="w-50">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <p className="m-0 "><strong>Receiver</strong></p>
                                                                        <button onClick={updateReceiverDetails} className="bg-transparent border-0"><i className="fa-solid fa-save"></i></button>
                                                                        <button className="bg-transparent border-0" onClick={() => setReceiverDetails(true)}><i className="fa-solid fa-edit"></i></button>

                                                                    </div>
                                                                    <label>
                                                                        <input type="checkbox" checked={isResidential} onChange={() => setIsResidential(!isResidential)} className="form-check-input" />
                                                                        Residential
                                                                    </label>
                                                                </div>
                                                                <div>

                                                                    {!receiverDetails && <div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    <label>Country:</label>
                                                                                    <Select
                                                                                        value={receiverCountry}
                                                                                        onChange={(selectedOption) => setReceiverCountry(selectedOption)}
                                                                                        options={receiverCountryOptions}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>State/Province:</label>
                                                                                        <input type="text" className="form-control" />
                                                                                    </div>
                                                                                </div> */}
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Name:</label>
                                                                                    <input type="text" className="form-control" value={receiverName} required={true} onChange={(e) => {
                                                                                        if (error) {
                                                                                            setError(null)
                                                                                        }
                                                                                        setValidationError(prevErrors => prevErrors.filter(error => error !== 'Receiver Name is Required')); setReceiverName(e.target?.value)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Company:</label>
                                                                                    <input type="text" className="form-control" disabled={isResidential ? true : false} value={receiverCompany} onChange={(e) => {
                                                                                        if (error) {
                                                                                            setError(null)
                                                                                        }
                                                                                        setReceiverCompany(e.target?.value)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            {isVisibleZipCode && <div className={isVisibleZipCode ? "col-md-4" : "col-md-12"}>
                                                                                <div className="form-group">
                                                                                    <label>ZIP code:</label>
                                                                                    <input type="text" className="form-control" value={receiverZipCode} onChange={(e) => {
                                                                                        if (error) {
                                                                                            setError(null)
                                                                                        }
                                                                                        setValidationError(prevErrors => prevErrors.filter(error => error !== 'Zip Code is required')); setReceiverZipCode(e.target?.value)
                                                                                    }} />
                                                                                </div>
                                                                            </div>}
                                                                            {isVisibleState &&

                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ whiteSpace: 'pre' }}>State/Province:</label>
                                                                                        <Select
                                                                                            value={receiverState}
                                                                                            onChange={(selectedOption) => {
                                                                                                if (error) {
                                                                                                    setError(null)
                                                                                                }
                                                                                                setReceiverState(selectedOption)
                                                                                            }}
                                                                                            options={receiverStateList}
                                                                                        />
                                                                                    </div>
                                                                                </div>}
                                                                            {isVisibleCity && <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label>City:</label>
                                                                                    <input type="text" className="form-control" value={receiverCity} onChange={(e) => setReceiverCity(e.target?.value)} />
                                                                                </div>
                                                                            </div>}

                                                                        </div>

                                                                        {/* <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Email:</label>
                                                                                        <input type="text" className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Phone:</label>
                                                                                        <input type="text" className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    <label>Address1:</label>
                                                                                    <input type="text" className="form-control" value={receiverAddress1} onChange={(e) => setReceiverAddress1(e.target?.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Address2:</label>
                                                                                    <input type="text" className="form-control" value={receiverAddress2} onChange={(e) => setReceiverAddress2(e.target?.value)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Address3:</label>
                                                                                    <input type="text" className="form-control" value={receiverAddress3} onChange={(e) => setReceiverAddress3(e.target?.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Email:</label>
                                                                                    <input type="text" className="form-control" value={receiverEmail} onChange={(e) => setReceiverEmail(e.target?.value)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>Phone:</label>
                                                                                    <input type="text" className="form-control" value={receiverPhone} onChange={(e) => {
                                                                                        if (error) {
                                                                                            setError(null)
                                                                                        }
                                                                                        setReceiverPhone(e.target?.value)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="checkbox">
                                                                <label>
                                                                    <input type="checkbox" name="checkbox" /> Return address is different from sender address
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* <MultiStepForm /> */}
                                                        <div className="d-flex">
                                                            <div className="col-md-4 ">
                                                                <input type="text" className="form-control custom-input" placeholder=" Select Service" value={service?.Description} style={{ pointerEvents: !service ? 'none' : '', cursor: service ? 'pointer' : '' }} onClick={() => setActiveButton(1)} />
                                                                {/* <button style={{ width: "250px" }} className="btn btn-secondary btn-lg me-1"> */}

                                                                {/* </button> */}


                                                            </div>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control custom-input" style={{ pointerEvents: !packageOfService ? 'none' : '', cursor: packageOfService ? 'pointer' : '' }} value={packageOfService?.Description} placeholder=" Select Package" disabled={activeButton !== 2 && !packageOfService} onClick={() => setActiveButton(2)} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control custom-input" placeholder="Details" disabled={activeButton !== 3} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card-box">
                                                                    <div className="card-block">
                                                                        {activeButton == 3 && <div>

                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Units:</label>
                                                                                        <Select
                                                                                            value={selectedUnit}
                                                                                            onChange={(selectedOption) => setUnit(selectedOption)}
                                                                                            options={options}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                

                                                                            </div>
                                                                            {(packageOfService?.code == '02' || packageOfService?.code == 'YOUR_PACKAGING') ? <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Custom Package Type:</label>
                                                                                        <Select
                                                                                            value={selectedCustomPackage}
                                                                                            onChange={(selectedOption) => setSelectedCustomPackage(selectedOption)}
                                                                                            options={customerPackageOptions}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                

                                                                            </div>:''}
                                                                            <div className="row">
                                                                                {isVisibleLength && <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        {console.log(packageOfService,"packageOfService=>")}
                                                                                        <label>Length:</label>
                                                                                        <input type="text" className="form-control" value={shipLength} onChange={(e) => setShipLength(e.target?.value)} disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'} />
                                                                                    </div>
                                                                                </div>}
                                                                                {isVisibleWidth && <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>Width:</label>
                                                                                        <input type="text" className="form-control" value={shipWidth} onChange={(e) => {

                                                                                            setShipWidth(e.target?.value)
                                                                                        }} disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'} />
                                                                                    </div>
                                                                                </div>}
                                                                                {isVisibleHeight && <div className="col-md-4" >
                                                                                    <div className="form-group">
                                                                                        <label style={{ whiteSpace: 'pre' }}>Height:</label>
                                                                                        <input type="text" className="form-control" value={shipHeight} onChange={(e) => setShipHeight(e.target?.value)} disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'} />
                                                                                    </div>
                                                                                </div>}
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">

                                                                                        <label>Weight:</label>
                                                                                        <input type="text" className="form-control" placeholder={selectedUnit?.value == 1 ? 'lb' : selectedUnit?.value == 2 ? 'kg' : ''} value={shipWeight} onChange={(e) => {
                                                                                            if (error) {
                                                                                                setError(null)
                                                                                            }
                                                                                            setShipWeight(e.target?.value)
                                                                                        }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>{selectedUnit?.value == 1 ? 'Ounces' : 'Grams'}</label>
                                                                                        <input type="text" className="form-control" placeholder={selectedUnit?.value == 1 ? 'oz' : selectedUnit?.value == 2 ? 'g' : ''} value={ouncesGrams} onChange={(e) => setOuncesGrams(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4" >
                                                                                    <div className="form-group">
                                                                                        <label style={{ whiteSpace: 'pre' }}>Insure Amt:</label>
                                                                                        <input type="text" className="form-control" placeholder="USD $ 0.00" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <div className="col-md-4" >
                                                                                    <label>Insurance Category:</label>
                                                                                    <Select
                                                                                        value={selectedInsurance}
                                                                                        onChange={(selectedOption) => setInsurance(selectedOption)}
                                                                                        options={insuranceOptions}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-4" style={{ marginTop:20}}>
                                                                                    <div className="form-group" >
                                                                                        <label style={{ whiteSpace: 'pre' }}>Customs Amt:</label>
                                                                                        <input type="text" className="form-control" placeholder="USD $" value={customeAmount} onChange={(e) => setCustomeAmount(e.target.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                {isVisiblePlus && (
                                                                                    <div className="col-md-4" style={{ marginTop: '20px' }}>
                                                                                        <button
                                                                                            className="bg-transparent border-0"
                                                                                            onClick={handleAddFormGroup}
                                                                                        >
                                                                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            {formGroups.map((group, index) => (
                                                                                <div key={index}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label>Length:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    value={group.length}
                                                                                                    onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                                                                                                    disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label>Width:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    value={group.width}
                                                                                                    onChange={(e) => handleInputChange(index, 'width', e.target.value)}
                                                                                                    disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label style={{ whiteSpace: 'pre' }}>Height:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    value={group.height}
                                                                                                    onChange={(e) => handleInputChange(index, 'height', e.target.value)}
                                                                                                    disabled={packageOfService?.code !== '02' && packageOfService?.code !== 'YOUR_PACKAGING'}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label>Weight:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder={
                                                                                                        selectedUnit?.value === 1 ? 'lb' : selectedUnit?.value === 2 ? 'kg' : ''
                                                                                                    }
                                                                                                    value={group.weight}
                                                                                                    onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label>{selectedUnit?.value === 1 ? 'Ounces' : 'Grams'}</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder={
                                                                                                        selectedUnit?.value === 1 ? 'oz' : selectedUnit?.value === 2 ? 'g' : ''
                                                                                                    }
                                                                                                    value={group.ouncesGrams}
                                                                                                    onChange={(e) => handleInputChange(index, 'ouncesGrams', e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label style={{ whiteSpace: 'pre' }}>Insure Amt:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="USD $ 0.00"
                                                                                                    value={group.insureAmt}
                                                                                                    onChange={(e) => handleInputChange(index, 'insureAmt', e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">

                                                                                        <div className="col-md-4" >
                                                                                            <label>Insurance Category:</label>
                                                                                            <Select
                                                                                                value={group.insurance}
                                                                                                onChange={(e) => handleInputChange(index, 'insurance', e.target.value)}
                                                                                                options={insuranceOptions}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-4" >
                                                                                            <div className="form-group">
                                                                                                <label style={{ whiteSpace: 'pre' }}>Customs Amt:</label>
                                                                                                <input type="text" className="form-control" placeholder="USD $" value={group.customeAmount} onChange={(e) => handleInputChange(index, 'customeAmount', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                     

                                                                                        <div className="col-md-4">
                                                                                            <button
                                                                                                className="bg-transparent border-0"
                                                                                                onClick={() => handleRemoveFormGroup(index)}
                                                                                            >
                                                                                                <i className="fa fa-minus-circle" aria-hidden="true"></i>
                                                                                            </button>
                                                                                            <button
                                                                                            className="bg-transparent border-0"
                                                                                            onClick={handleAddFormGroup}
                                                                                        >
                                                                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                                        </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            <div className="row">

                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>Total Quantity:</label>
                                                                                        <input type="text" className="form-control" value={ttlQty} onChange={(e) => setTtlQty(e.target?.value)} disabled />
                                                                                    </div>
                                                                                </div>
                                                                                {console.log(totalOuncesGrams,"totalOuncesGrams=>>>")}
                                                                                <div className="col-md-4" >
                                                                                    <div className="form-group">
                                                                                        <label style={{ whiteSpace: 'pre' }}>Total Weight:</label>
                                                                                        <input type="text" className="form-control" value={`${Math.round(ttlWeight + Number(shipWeight) + ((Number(ouncesGrams) + Number(totalOuncesGrams))/16))} `} onChange={(e) => setTtlWeight(e.target?.value)} disabled />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>{selectedUnit?.value == 1 ? 'Total Ounces' : 'Grams'}</label>

                                                                                        <input type="text" className="form-control" placeholder={selectedUnit?.value == 1 ? 'oz' : selectedUnit?.value == 2 ? 'g' : ''} value={`${Number(ouncesGrams) + Number(totalOuncesGrams)}`} onChange={(e) => setTotalOuncesGrams(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label>{'Total Customs Amt'}</label>
                                                                                        {console.log(totalCustomeAmount,"totalCustomeAmount=>")}

                                                                                        <input type="text" className="form-control" placeholder={selectedUnit?.value == 1 ? 'oz' : selectedUnit?.value == 2 ? 'g' : ''} value={`${Number(customeAmount) + Number(totalCustomeAmount)}`} onChange={(e) => setTotalCustomeAmount(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Content Description(0/80):</label>
                                                                                        <input type="text" className="form-control" value={`${contentDescription}`} onChange={(e) => setContentDescription(e.target?.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Signature:</label>
                                                                                        <Select
                                                                                            value={signature}
                                                                                            onChange={(selectedOption) => setSignature(selectedOption)}
                                                                                            options={signatureOption}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <ButtonGroup toggle>
                                                                                        <ToggleButton
                                                                                            type="radio"
                                                                                            variant="primary"
                                                                                            name="options"
                                                                                            value="FTR"
                                                                                            checked={selectedToggleOption === 'FTR'}
                                                                                            onClick={() => {
                                                                                                console.log('FTR option selected');
                                                                                                handleChangeToggle('FTR');
                                                                                            }}
                                                                                        >
                                                                                            FTR
                                                                                        </ToggleButton>
                                                                                        <ToggleButton
                                                                                            type="radio"
                                                                                            variant="primary"
                                                                                            name="options"
                                                                                            value="ITN"
                                                                                            checked={selectedToggleOption === 'ITN'}
                                                                                            onClick={() => {
                                                                                                console.log('ITN option selected');
                                                                                                handleChangeToggle('ITN');
                                                                                            }}
                                                                                        >
                                                                                            ITN
                                                                                        </ToggleButton>
                                                                                    </ButtonGroup>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {console.log(selectedToggleOption, "selectedToggleOption")}
                                                                                    {selectedToggleOption === 'FTR' ? (
                                                                                        <div className="form-group">
                                                                                            <label>FTR Code:</label>
                                                                                            <Select
                                                                                                value={ftrCode}
                                                                                                onChange={(selectedOption) => setFtrCode(selectedOption)}
                                                                                                options={ftrCodeOptions}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="form-group">
                                                                                            <label>ITN Number:</label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                value={taxIdNumber}
                                                                                                onChange={(e) => setTaxIdNumber(e.target?.value)}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {console.log(selectedToggleOption,"selectedToggleOption=>",serviceType)}
                                                                            {selectedToggleOption == 'ITN' && serviceType !== 'fedEx' &&
                                                                             <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <ButtonGroup toggle>
                                                                                        <ToggleButton
                                                                                            type="radio"
                                                                                            variant="primary"
                                                                                            name="options"
                                                                                            value="EIN"
                                                                                            checked={itnToggleOption === 'EIN'}
                                                                                            onClick={() => {
                                                                                                console.log('EIN option selected');
                                                                                                handleChangeITNToggle('EIN');
                                                                                            }}
                                                                                        >
                                                                                            EIN
                                                                                        </ToggleButton>
                                                                                        <ToggleButton
                                                                                            type="radio"
                                                                                            variant="primary"
                                                                                            name="options"
                                                                                            value="DNS"
                                                                                            checked={itnToggleOption === 'DNS'}
                                                                                            onClick={() => {
                                                                                                console.log('DNS option selected');
                                                                                                handleChangeITNToggle('DNS');
                                                                                            }}
                                                                                        >
                                                                                            DNS
                                                                                        </ToggleButton>
                                                                                        <ToggleButton
                                                                                            type="radio"
                                                                                            variant="primary"
                                                                                            name="options"
                                                                                            value="FGN"
                                                                                            checked={itnToggleOption === 'FGN'}
                                                                                            onClick={() => {
                                                                                                console.log('FGN option selected');
                                                                                                handleChangeITNToggle('FGN');
                                                                                            }}
                                                                                        >
                                                                                            FGN
                                                                                        </ToggleButton>
                                                                                    </ButtonGroup>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {console.log(selectedToggleOption, "selectedToggleOption")}
                                                                                    {selectedToggleOption === 'FTR' ? (
                                                                                        <div className="form-group">
                                                                                            <label>FTR Code:</label>
                                                                                            <Select
                                                                                                value={ftrCode}
                                                                                                onChange={(selectedOption) => setFtrCode(selectedOption)}
                                                                                                options={ftrCodeOptions}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="form-group">
                                                                                            <label>Tax ID Number:</label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                value={itnNumber}
                                                                                                onChange={(e) => setItnNumber(e.target?.value)}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>}
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Content Type:</label>
                                                                                        <Select
                                                                                            value={contentType}
                                                                                            onChange={(selectedOption) => setContentType(selectedOption)}
                                                                                            options={contentTypeOptions}
                                                                                            style={customStyles}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Terms of Trade:</label>
                                                                                        <Select
                                                                                            value={termsOfTrade}
                                                                                            onChange={(selectedOption) => setTermsOfTrade(selectedOption)}
                                                                                            options={termsOfTradeOptions}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>PO Number:</label>
                                                                                        <input type="email" className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Saturday Delivery
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                     { serviceType !== 'fedEx'? 
                                                                             <div className="row">
                                                                             <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" checked={additionlDelivery} onChange={() => setAdditionalDelivery(!additionlDelivery)} />
                                                                                            Additional Handling 
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" checked={carbonNeutral} onChange={() => setCarbonNeutral(!carbonNeutral)} />
                                                                                            Carbon Neutral

                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" checked={returnService} onChange={() => setReturnService(!returnService)}/>
                                                                                            Return Service
                                                                                        </label>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                
                                                                                <div className="form-group row">

                                                                                    {/* <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleSendNotification} />
                                                                                                Send notification email
                                                                                            </label>
                                                                                        </div>

                                                                                    </div> */}
                                                                                    
                                                                                   
                                                                                   



                                                                                </div>
                                                                            </div> : 
                                                                            <div className="row" >
                                                                                 <div className="col-md-4">
                                                                             <div className="form-group">
                                                                                        <label>Batteries:</label>
                                                                                        <Select
                                                                                            value={selectedBatteries}
                                                                                            onChange={(selectedOption) => (selectedOption)}
                                                                                            options={batteriesOptions}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                        <label style={{ marginTop:'30px'}}>
                                                                                        <input type="checkbox" className="form-check-input" checked={saturdayPickup} onChange={() => setSaturdayPickup(!saturdayPickup)}/>
                                                                                        Saturday Pickup
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" checked={nonStandardContainer} onChange={() => setNonStandardContainer(!nonStandardContainer)}/>
                                                                                            Non-Standard Container
                                                                                        </label>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                </div>}
                                                                            {serviceType == 'fedEx' ? <div class="row">
                                                                            <div className="col-md-4">
                                                                             <div className="form-group">
                                                                                        <label>Contains Alcohol:</label>
                                                                                        <input type="checkbox" className="form-check-input" checked={containAlcohol} onChange={() => setContainAlcohol(!containAlcohol)}/>
                                                                                    </div>
                                                                                </div>

                                                                            </div> : ''}
                                                                            {containAlcohol ?  
                                                                             <div className="col-md-4">
                                                                             <div className="form-group">
                                                                                        <label>Alcohol Recipient Type:</label>
                                                                                        <Select
                                                                                            value={selectedAlcoholRecipientType}
                                                                                            onChange={(selectedOption) => (selectedOption)}
                                                                                            options={alcoholRecipientTypeOptions}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div> : ''
                                                                            }
                                                                             {serviceType == 'fedEx'?  
                                                                             <div className="col-md-4">
                                                                             <div className="form-group">
                                                                                        <label>Physical Packging Type:</label>
                                                                                        <Select
                                                                                            value={selectedPhysicalPackingType}
                                                                                            onChange={(selectedOption) => setSelectedPhysicalPackingType(selectedOption)}
                                                                                            options={physicalPackagingTypeOptions}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div> : ''
                                                                            }
                                                                           {serviceType !== 'fedEx'? <div className="row">
                                                                             <div className="col-md-4">
                                                                             <div className="form-group">
                                                                                        <label>UPS Location:</label>
                                                                                        <input type="text" className="form-control" value={`${upsLocationName} ${upsLocationCity}`} onClick={() => setShowUpsLocation(true)}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                        <label>Package Release Code:</label>
                                                                                        <input type="text" className="form-control" value={packageReleaseCode} onChange={(e ) => setPackageReleaseCode(e.target.value)}/>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label style={{ marginTop: '30px' }}>
                                                                                            <input type="checkbox" className="form-check-input" checked={deliverToAddress} onChange={() => setDeliverToAddress(!deliverToAddress)}/>
                                                                                            Deliver to Addressee Only
                                                                                        </label>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                               
                                                                                
                                                                            </div>: '' }
                                                                                <div className="form-group row">
                                                                                    <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleCheckboxChange} />
                                                                                                Change Billing/Dutiable Party
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <Toast style={{ width: '100%' }} show={showToast} onClose={() => setShowToast(false)} >
{console.log(termsOfTrade,"termsOfTrade=>")}
                                                                                    <Toast.Body>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label>Billing Party:</label>
                                                                                                <Select
                                                                                                    value={billingParty}
                                                                                                    onChange={(selectedOption) => setBillingParty(selectedOption)}
                                                                                                    options={billingPartyOption}
                                                                                                    
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        { (billingParty?.value == 'receiver' || billingParty?.value == 'thirdparty') && <div className="row">

                                                                                            <div className="col-md-4">
                                                                                                <div className="form-group">
                                                                                                    <label>Billing Account:</label>
                                                                                                    <input type="text" className="form-control"   />
                                                                                                </div>
                                                                                            </div>
                                                                                            {console.log(termsOfTrade?.value == 'DAP',billingParty?.value,"termsOfTrade?.value == 'DAP' && billingParty?.value !== 'receiver' && serviceType !== 'fedEx'")}
                                                                                            {((termsOfTrade?.value == 'DAP' && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'CIP'&& billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'CPT'&& billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'DAT'&& billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'EXW'&& billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'FCA'&& billingParty?.value !== 'sender' && serviceType !== 'fedEx') || ((termsOfTrade?.value == 'DDP' && serviceType !== 'fedEx') && (billingParty?.value == 'receiver' || billingParty?.value == 'thirdparty'))) ? <div className="col-md-4" >
                                                                                                <div className="form-group">
                                                                                                    <label style={{ whiteSpace: 'pre' }}>Billing Account Zip:</label>
                                                                                                    <input type="text" className="form-control" value={`${billingAccountZip ? billingAccountZip : ''}`} onChange={(e) => setBillingAccountZip(e.target?.value)}  />
                                                                                                </div>
                                                                                            </div>:''}
                                                                                           {((termsOfTrade?.value == 'DAP' && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'CIP' && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'CPT'  && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'DAT'  && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'EXW'  && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'FCA'  && billingParty?.value !== 'sender' && serviceType !== 'fedEx') || ((termsOfTrade?.value == 'DDP' && serviceType !== 'fedEx') && (billingParty?.value == 'receiver' || billingParty?.value == 'thirdparty'))) ? <div className="col-md-4">
                                                                                                <div className="form-group">
                                                                                                    <label>Billing Account Country:</label>
                                                                                                    <Select
                                                                                                        value={billingPartyCountry}
                                                                                                        onChange={(selectedOption) => {
                                                                                                            if (error) {
                                                                                                                setError(null)
                                                                                                            } setBillingPartyCountry(selectedOption)
                                                                                                        }}
                                                                                                        options={receiverCountryOptions}
                                                                                                    />
                                                                                                </div>
                                                                                            </div> : ''}
                                                                                        </div>}
                                                                                        {billingParty?.value && <Alert key={'warning'} variant={'warning'}>{'Sender is reponsibel for pickup fees'}</Alert>
                                                                                        }
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Dutiable Party</label>
                                                                                                    <Select
                                                                                                        value={dutiableParty}
                                                                                                        onChange={(selectedOption) => setDutiableParty(selectedOption)}
                                                                                                        options={duatiablePartyOption}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {(termsOfTrade?.value == 'DAP' || termsOfTrade?.value == 'CIP' || termsOfTrade?.value == 'CPT' || termsOfTrade?.value == 'DAT' || termsOfTrade?.value == 'EXW' || termsOfTrade?.value == 'FCA') ?<div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Dutiable Account:</label>
                                                                                                    <input type="text" placeholder="Optional" className="form-control" value={dutiableAccount} onChange={(e) => setDutiableAccount(e.target?.value)}  />
                                                                                                </div>
                                                                                            </div>:''}
                                                                                        </div>
                                                                                       {((termsOfTrade?.value == 'DAP' && serviceType !== 'fedEx') ||( termsOfTrade?.value == 'CIP'&& serviceType !== 'fedEx') || (termsOfTrade?.value == 'CPT' && serviceType !== 'fedEx') || (termsOfTrade?.value == 'DAT'&& serviceType !== 'fedEx') || (termsOfTrade?.value == 'EXW'&& serviceType !== 'fedEx') ||( termsOfTrade?.value == 'FCA'&& serviceType !== 'fedEx')) ? <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Dutiable Account Zip:</label>
                                                                                                    <input type="text" className="form-control" value={dutiableAccountZip} onChange={(e) => setDutiableAccountZip(e.target?.value)}  />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Dutiable Account Country</label>
                                                                                                    <Select
                                                                                                        value={dutiableCountry}
                                                                                                        onChange={(selectedOption) => setDutiableCountry(selectedOption)}
                                                                                                        options={receiverCountryOptions}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                    </Toast.Body>
                                                                                </Toast>
                                                                                <div className="form-group row">

                                                                                    {/* <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleSendNotification} />
                                                                                                Send notification email
                                                                                            </label>
                                                                                        </div>

                                                                                    </div> */}
                                                                                    <Toast style={{ width: '100%' }} show={notificationToast} onClose={() => setNotificationToast(false)} >

                                                                                        <Toast.Body>
                                                                                            <div className="col-md-12">
                                                                                                <div className="checkbox">
                                                                                                    <label>
                                                                                                        <input type="checkbox" name="checkbox" /> Send XPS Ship notification
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-12">
                                                                                                <div className="checkbox">
                                                                                                    <label>
                                                                                                        <input type="checkbox" name="checkbox" /> Send UPS notification
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-12">
                                                                                                <div className="checkbox">
                                                                                                    <label>
                                                                                                        <input type="checkbox" name="checkbox" /> {`Send notification email to ${Cookies.get('email')}`}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-12">
                                                                                                <div className="form-group">
                                                                                                    <label>Emails</label>
                                                                                                    <input type="email" className="form-control" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                                                <Form.Label>Message</Form.Label>
                                                                                                <Form.Control as="textarea" rows={3} />
                                                                                            </Form.Group>


                                                                                            {billingParty?.value && <Alert key={'warning'} variant={'warning'}>{'Sender is reponsibel for pickup fees'}</Alert>
                                                                                            }
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Dutiable Party</label>
                                                                                                        <Select
                                                                                                            value={dutiableParty}
                                                                                                            onChange={(selectedOption) => setDutiableParty(selectedOption)}
                                                                                                            options={duatiablePartyOption}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Dutiable Account:</label>
                                                                                                        <input type="text" className="form-control" value={dutiableAccount} onChange={(e) => setDutiableAccount(e.target?.value)} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Dutiable Account Zip:</label>
                                                                                                        <input type="text" className="form-control" value={ttlQty} onChange={(e) => setTtlQty(e.target?.value)} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Dutiable Account Country</label>
                                                                                                        <Select
                                                                                                            value={termsOfTrade}
                                                                                                            onChange={(selectedOption) => setTermsOfTrade(selectedOption)}
                                                                                                            options={receiverCountryOptions}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Toast.Body>
                                                                                    </Toast>
                                                                                    {(senderCountry?.value == 'US' && receiverCountry?.value !== 'US') && <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleCommercialInvoice} />
                                                                                                Include Commercial Invoice
                                                                                            </label>
                                                                                        </div>

                                                                                    </div>}
                                                                                    <Toast style={{ width: '100%' }} show={commercialInvoice} onClose={() => setCommercialInvoice(false)} >

                                                                                        <Toast.Body>
                                                                                    <div class="row">
                                                                                            <div className="col-md-4">
                                                                                                <div className="form-group">
                                                                                                    <label>Invoice Type:</label>
                                                                                                    <Select
                                                                                                        value={invoiceType}
                                                                                                        onChange={(selectedOption) => setInvoiceType(selectedOption)}
                                                                                                        options={invoiceTypeOptions}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-4">
                                                                                                <div className="form-group">
                                                                                                    <label>Paperless:</label>
                                                                                                    <Select
                                                                                                        value={paperless}
                                                                                                        onChange={(selectedOption) => setPaperless(selectedOption)}
                                                                                                        options={paperlessOptions}
                                                                                                    />
                                                                                                </div>
                                                                                                
                                                                                           
                                                                                            </div>
                                                                                        
                                                                                            {paperless?.value == true ? <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                              <label>Signature</label>                                      <Select
                                                                                            value={commercialSignature}
                                                                                            onChange={(selectedOption) => setCommercialSignature(selectedOption)}
                                                                                            options={signatureOptions}
                                                                                        />
                                                                                                    </div>
                                                                                                </div> : ''}
                                                                                        
                                                                                           </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Receiver Tax ID/VAT</label>
                                                                                                        <input type="text" className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Package Marks</label>
                                                                                                        <input type="text" className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {serviceType !== 'fedEx' ?  <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Sender Tax ID/VAT</label>
                                                                                                        <input type="text" className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Sender Tax ID/VAT Issuer Country :</label>
                                                                                                        <Select
                                                                                                            value={invoiceType}
                                                                                                            onChange={(selectedOption) => setInvoiceType(selectedOption)}
                                                                                                            options={receiverCountryOptions}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>:''}
                                                                                            {serviceType == 'fedEx' ? 
                                                                                            <div className="row">
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>Shipper TIN</label>
                                                                                                        <input type="text" className="form-control"  value={shipperTIN} onChange={e => setShipperTIN(e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>Shipper TIN Type :</label>
                                                                                                        <Select
                                                                                                            value={shipperTINType}
                                                                                                            onChange={(selectedOption) => setShipperTINType(selectedOption)}
                                                                                                            options={shipperTINTypeOptions}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>Export Compliance Statement</label>
                                                                                                        <input type="text" className="form-control" value={exportComplianceStatement}  onChange={e => setExportCompilanceStatement(e.target.value)}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : ''}
                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Importer Of Record :</label>
                                                                                                    <Select
                                                                                                        value={recordImporter}
                                                                                                        onChange={(selectedOption) => setRecordImporter(selectedOption)}
                                                                                                        options={recordImporterOption}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Select Commodities :</label>
                                                                                                    <Select
                                                                                                        value={commodities}
                                                                                                        onChange={(selectedOption) => setCommodities(selectedOption)}
                                                                                                        options={commoditiesOption}

                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Description(0/35)</label>
                                                                                                        <input type="text" className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Unit Value</label>
                                                                                                        <input type="text" placeholder={'$'} className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Quantity</label>
                                                                                                        <input type="text" className="form-control"  />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Weight</label>
                                                                                                        <input type="text" placeholder={'$'} className="form-control" value={`${commercialInvoiceWeight}`} onChange={(e) => setCommercialInvoiceWeight(e.target?.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Ounces</label>
                                                                                                    <input type="text" placeholder={"oz"} value={`${commercialInvoiceOunces}`} onChange={(e) => setCommercialInvoiceOunces(e.target?.value)} className="form-control" />
                                                                                                </div>
                                                                                            </div>
                                                                                            {commodities && <div className="col-md-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Pickup Location</label>
                                                                                                    <input type="text" placeholder={"oz"} className="form-control" value={`${pickupLocation ? pickupLocation : ''}`} onChange={(e) => setPickupLocation(e.target?.value)} />
                                                                                                </div>
                                                                                            </div>

                                                                                            }

                                                                                            <div className="row">
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>HTS#/B#:</label>
                                                                                                        <input type="text" className="form-control" value={hts} onChange={(e) => setHts(e.target?.value)}  />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>Country of Origin:</label>
                                                                                                        <Select
                                                                                                            value={originCountry}
                                                                                                            onChange={(selectedOption) => setOriginCountry(selectedOption)}
                                                                                                            options={senderCountryOptions}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {isVisibleCommodityPlus && (
                                                                                                    <div className="col-md-4" style={{ marginTop: '20px' }}>
                                                                                                        <button
                                                                                                            className="bg-transparent border-0"
                                                                                                            onClick={handleAddCommodityFormGroup}
                                                                                                        >
                                                                                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            {commodityFormGroups.map((group, index) => (
                                                                                                <div key={group.id} className="form-group-container">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6">
                                                                                                            <div className="form-group">
                                                                                                                <label>Description ({group.contentDescription.length}/35)</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={group.contentDescription}
                                                                                                                    onChange={(e) => handleCommodityChange(group.id, 'contentDescription', e.target.value)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-md-6">
                                                                                                            <div className="form-group">
                                                                                                                <label>Unit Value</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    placeholder="$"
                                                                                                                    className="form-control"
                                                                                                                    value={group.unitValue}
                                                                                                                    onChange={(e) => handleCommodityChange(group.id, 'unitValue', e.target.value)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6">
                                                                                                            <div className="form-group">
                                                                                                                <label>Quantity</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={group.quantity}
                                                                                                                    onChange={(e) => handleCommodityChange(group.id, 'quantity', e.target.value)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-md-6">
                                                                                                            <div className="form-group">
                                                                                                                <label>Weight</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    placeholder="$"
                                                                                                                    className="form-control"
                                                                                                                    value={group.weight}
                                                                                                                    onChange={(e) => handleCommodityChange(group.id, 'weight', e.target.value)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6">
                                                                                                        <div className="form-group">
                                                                                                            <label>Ounces</label>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="oz"
                                                                                                                className="form-control"
                                                                                                                value={group.ounces}
                                                                                                                onChange={(e) => handleCommodityChange(group.id, 'ounces', e.target.value)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-12">
                                                                                                            <button
                                                                                                                className="bg-transparent border-0"
                                                                                                                onClick={() => handleRemoveCommodityFormGroup(index)}
                                                                                                            >
                                                                                                                <i className="fa fa-minus-circle" aria-hidden="true"></i>
                                                                                                            </button>
                                                                                                            <button
                                                                                                            className="bg-transparent border-0"
                                                                                                            onClick={handleAddCommodityFormGroup}
                                                                                                        >
                                                                                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                                                        </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                            <div className="row">

                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label>Total Quantity:</label>
                                                                                                        <input type="text" className="form-control" value={ttlQty} onChange={(e) => setTtlQty(e.target?.value)} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {console.log(commercialInvoiceOunces,"commercialInvoiceOunces===>>")}
                                                                                                <div className="col-md-4" >
                                                                                                    <div className="form-group">
                                                                                                        <label style={{ whiteSpace: 'pre' }}>Total Weight:</label>
                                                                                                        <input type="text" className="form-control" value={`${Math.round(totalCommercialInvoiceWeight + Number(commercialInvoiceWeight) + Number(commercialInvoiceOunces/16))}  `} onChange={(e) => setTotalCommercialInvoiceWeight(e.target?.value)} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                    {console.log(commercialInvoiceOunces,ttlOunces,"ttlOunces=>")}                                                                    <label>{selectedUnit?.value == 1 ? 'Ounces' : 'Grams'}</label>
                                                                                                        <input type="text" className="form-control" placeholder={selectedUnit?.value == 1 ? 'oz' : selectedUnit?.value == 2 ? 'g' : ''} value={`${Number(commercialInvoiceOunces) + Number(ttlOunces)}`} onChange={(e) => setTtlOunces(e.target?.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Charge Description</label>
                                                                                                        <input type="text" className="form-control" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <label>Charge Amount</label>
                                                                                                        <input type="text" placeholder={'$'} className="form-control" value={`${chargeAmount ? chargeAmount : 0}`} onChange={(e) => setChargeAmount(e.target?.value)} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            {serviceType == 'fedEx' ? <div class="row">
                                                                                            <div class="col-md-6">
                                                                                            <button onClick={handleCertificateButtonClick}>
                            Upload Certificate of Origin to FedEx
                        </button>
                        <input
                            type="file"
                            id="csvFileInput"
                            style={{ display: 'none' }}
                            onChange={handleCertificateFileChange}
                        />

                                                                                            </div>
                                                                                            </div> : ''}
                                                                                        </Toast.Body>
                                                                                    </Toast>



                                                                                </div>
                                                                            </div>
                                                                        
                                                                            <p className="fw-bold"> Need a pickup? Pickup options will be provided when you complete this shipment.</p>
                                                                        </div>}
                                                                        <div className="table-responsive">
                                                                            {activeButton == 2 && <table className="table mb-0">
                                                                                <tbody>
                                                                                    {chunkedPackageArray.map((rowData, rowIndex) => (
                                                                                        <tr key={rowIndex}>
                                                                                            {rowData.map((cellData, cellIndex) => (
                                                                                                <td style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                    if (typeof (cellData.code) == Number) {
                                                                                                        const getPackge = upspackages.find(ele => Number(ele.code) == cellData.code)
                                                                                                        setPackageOfService(getPackge)

                                                                                                    }
                                                                                                    else {
                                                                                                        setPackageOfService(cellData)
                                                                                                    }
                                                                                                    setActiveButton(3)
                                                                                                }} key={cellIndex}>{cellData.Description}</td>
                                                                                            ))}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>}
                                                                            {activeButton == 1 && <table className="table mb-0">
                                                                                <tbody>
                                                                                    {chunkedArray.map((rowData, rowIndex) => (
                                                                                        <tr key={rowIndex}>
                                                                                            {console.log(rowData, "rowdtata===")}                                                                        {rowData.map((cellData, cellIndex) => (


                                                                                                <td style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                    setService(cellData)
                                                                                                    setActiveButton(2)
                                                                                                }} key={cellIndex}>{cellData.Description}</td>

                                                                                            ))}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* Horizontal View */}

                                                    {/* /Horizontal View */}
                                                </div>
                                                {/* Meet Footer */}
                                                <div className="meet-footer">
                                                    <div className="meet-icons">
                                                        {/* <div className="met-icons">
                                                            <ul className="meet-items">
                                                                <li className="meet-item">
                                                                    <Link to="#" className="mute-bt">
                                                                        <img src={callicon3} alt="img" />
                                                                    </Link>
                                                                </li>
                                                                <li className="meet-item">
                                                                    <Link to="#" className="mute-video">
                                                                        <i className="feather-video">
                                                                            <FeatherIcon icon="video" />
                                                                        </i>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                        <div className="end-call-chat">
                                                            <button className="btn btn-secondary btn-lg me-1" onClick={handleSaveShowModal}> Submit</button>
                                                        </div>
                                                        {/* <div className="end-calls">
                                                            <ul className="meet-items">
                                                                <li className="meet-item">
                                                                    <Link to="#" className="hand-raise">
                                                                        <img src={callicon1} alt="img" />
                                                                    </Link>
                                                                </li>
                                                                <li className="meet-item">
                                                                    <Link to="#" className="showInviteList">
                                                                        <img src={callicon2} alt="img" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {/* /Meet Footer */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Join Call */}
                                </div>
                                {/* /Chat */}
                            </div>
                        </div>

                        {/* /Page Header */}

                    </div>
                </div>
            </>
        </>

    )
}
export default Ship;
// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer, { fetchUser } from './slices/userSlice';
import userProfileReducer from './slices/userProfileSlice'
import { thunk } from 'redux-thunk';

const store = configureStore({
  reducer: {
    user: userReducer,
    profile:userProfileReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
});

// Optional: Dispatch an initial action to fetch user data
store.dispatch(fetchUser(1));

export default store;

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Form } from 'react-bootstrap'

const NotificationSetting = () => {
    const [notificationCompanyName, setNotificationCompanyName] = useState(null)
    const [notificationFromAddress, setNotificationFromAddress] = useState(null)
    const [notificationEmailRecipients, setNotificationEmailRecipients] = useState(null)
    const [notificationEmailMessage, setNotificationEmailMessage] = useState(null);
    const [error, setError] = useState(null)
    const [outOfDelivery, setOutOfDelivery] = useState(false)
    const [delivered, setDelivered] = useState(false)
    const [exception, setException] = useState(false)
    const [sendEmailNotificationByDefault,setSendEmailNotificationByDefault] = useState(null)
    const [addPONumberAndReferenceNumber,setAddPONumberAndReferenceNumber] = useState(null)
    const [sendOneOffShipmentNotification,setSendOneOffShipmentNotification] = useState(null)
    const [isVisibleProductDescription,setIsVisibleProductDescription] = useState(false)
    const [productDescription,setProductDescription] = useState(null)
    const [message,setMessage] = useState(null)
    const [id,setId] = useState(null)
    const [trackingPageId,setTrackingPageId] = useState(null)
    const [isTransit,setIsTransit] = useState(false)
    const [inTransitCustomEmailText,setInTransitCustomEmailText] = useState(null)
    const [deliveredCustomEmailText,setDeliveredCustomEmailText] = useState(null)
    const [exceptionCustomEmailText,setExceptionCustomEmailText] = useState(null)
    const [outOfDeliveryCustomEmailText,setoutOfDeliveryCustomEmailText] = useState(null)
    const [trackingLineOnNotificationEmail,setTrackingLineOnNotificationEmail] = useState(false)
    const [textMessageAlertOption,setTextMessageAlertOption] = useState(false)
    const [receiptAlertId,setReceiptAlertId] = useState(null)
    const [senderAlertId,setSenderAlertId] = useState(null)
    const [senderIsTransit,setSenderIsTransit] = useState(false)
    const [senderIsTransitCustomEmailText,setSenderIsTransitCustomEmailText] = useState(null)
    const [senderDeliveredCustomEmailText,setSenderDeliveredCustomEmailText] = useState(null)
    const [senderOutofDeliveryCustomEmailText,setSenderOutofDeliveryCustomEmailText] = useState(null)
    const [senderExceptionCustomEmailText,setSenderExceptionCustomEmailText] = useState(null)
    const [senderOutOfDelivery, setSenderOutOfDelivery] = useState(false)
    const [senderDelivered, setSenderDelivered] = useState(false)
    const [senderException, setSenderException] = useState(false)
    // const [delivered,setDelivered] = useState(false)



    // const [sendEmailNotificationByDefault,setSendEmailNotificationByDefault] = useState(null)

       // Functions to handle checkbox changes
    const handleRecipientCheckboxChange = () => {
        setSendOneOffShipmentNotification(!sendOneOffShipmentNotification);
    };

    const handleSenderCheckboxChange = () => {
        setSendEmailNotificationByDefault(!sendEmailNotificationByDefault);
    };

    const handleReferenceFieldCheckboxChange = () => {
        setAddPONumberAndReferenceNumber(!addPONumberAndReferenceNumber);
    };

    const handleSenderAlert = async (e) => {
        const bodyData = {
            isTransit:senderIsTransit,
            inTransitCustomEmailText:senderIsTransitCustomEmailText,
            outOfDelivery:senderOutOfDelivery,
            outOfDeliveryCustomEmailText:senderOutofDeliveryCustomEmailText,
            delivered:senderDelivered,
            deliveredCustomEmailText:senderDeliveredCustomEmailText,
            exception:senderException,
            exceptionCustomEmailText:senderExceptionCustomEmailText,
            user:Cookies.get('id')
            
        }
        e.preventDefault();
        if(senderAlertId) {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-sender-alert/${senderAlertId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-sender-alert`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };

    const handleReceiptAlert = async (e) => {
        const bodyData = {
            receiptAlertId:receiptAlertId,
            isTransit,
            inTransitCustomEmailText,
            outOfDelivery,
            outOfDeliveryCustomEmailText,
            delivered,
            deliveredCustomEmailText,
            exception,
            exceptionCustomEmailText,
            trackingLineOnNotificationEmail,
            textMessageAlertOption,
            user:Cookies.get('id')
            
        }
        e.preventDefault();
        if(receiptAlertId) {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-receipt-alert/${receiptAlertId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-receipt-alert`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };
    
    const handleTrackingPageSubmittion = async (e) => {
        const bodyData = {
            isVisibleProductDescription: isVisibleProductDescription,
            productDescription:productDescription,
            user:Cookies.get('id')
            
        }
        e.preventDefault();
        if(trackingPageId) {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-tracking-page/${trackingPageId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-tracking-page`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };

    const handleSubmit = async (e) => {
        const bodyData = {
            notificationCompanyName: notificationCompanyName,
            notificationFromAddress: notificationFromAddress,
            notificationEmailRecipients: notificationEmailRecipients,
            notificationEmailMessage:notificationEmailMessage,
            sendOneOffShipmentNotification:sendOneOffShipmentNotification,
            sendEmailNotificationByDefault:sendEmailNotificationByDefault,
            addPONumberAndReferenceNumber:addPONumberAndReferenceNumber,
            user:Cookies.get('id')
            
        }
        e.preventDefault();
        if(id) {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-general-setting/${id}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-general-setting`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
    
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData),
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error])
    const getSenderDetails = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-sender-alert`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data[0];
                setSenderAlertId(res._id)
                setSenderIsTransit(res.isTransit)
                setSenderIsTransitCustomEmailText(res.inTransitCustomEmailText)
                setSenderOutOfDelivery(res.outOfDelivery)
                setSenderOutofDeliveryCustomEmailText(res.outOfDeliveryCustomEmailText)
                setSenderDelivered(res.delivered)
                setSenderDeliveredCustomEmailText(res.deliveredCustomEmailText)
                setSenderException(res.exception)
                setSenderExceptionCustomEmailText(res.exceptionCustomEmailText)

           
                
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    const getReceiptAlerts = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-receipt-alert`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data[0];
                setReceiptAlertId(res._id)
                setIsTransit(res.isTransit)
                setInTransitCustomEmailText(res.inTransitCustomEmailText)
                setOutOfDelivery(res.outOfDelivery)
                setoutOfDeliveryCustomEmailText(res.outOfDeliveryCustomEmailText)
                setDelivered(res.delivered)
                setDeliveredCustomEmailText(res.deliveredCustomEmailText)
                setException(res.exception)
                setExceptionCustomEmailText(res.exceptionCustomEmailText)
                setTrackingLineOnNotificationEmail(res.trackingLineOnNotificationEmail)
                setTextMessageAlertOption(res.textMessageAlertOption)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    const getGeneralNotification = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-general-setting`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data[0];
                setId(res._id)
                setAddPONumberAndReferenceNumber(res.addPONumberAndReferenceNumber)
                setSendEmailNotificationByDefault(res.sendEmailNotificationByDefault)
                setSendOneOffShipmentNotification(res.sendOneOffShipmentNotification)
                setNotificationCompanyName(res.notificationCompanyName)
                setNotificationFromAddress(res.notificationFromAddress)
                setNotificationEmailRecipients(res.notificationEmailRecipients)
                setNotificationEmailMessage(res.notificationEmailMessage)
                // const getCountry = SenderCountryOptions.find(ele => ele.value == data.country)
                // const getState = senderStateList.find(ele => ele.value == data.state)
                // console.log(getState, "getState")
                // setAddId(data._id)
                // setSenderName(data.name)
                // if (data.user.email) {
                //     setSenderEmail(data.user.email)
                // }
                // else {
                //     setSenderEmail(user.data.email)
                // }
                // if (data.user.companyName) {
                //     setSenderCompany(data.user.companyName)
                // }
                // else {
                //     setSenderCompany(user.data.companyName)
                // }
                // setSenderAddress1(data.address1)
                // setSenderAddress2(data.address2)
                // setSenderCountry(getCountry);
                // setSenderCity(data.city)
                // setSenderState(getState)
                // setSenderZipCode(data.zip)
                // setSenderPhone(data.phone)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    const getTrackingPage = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-tracking-page`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data[0];
                setTrackingPageId(res._id)
                setIsVisibleProductDescription(res.isVisibleProductDescription)
                setProductDescription(res.productDescription)
                
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }

    useEffect(() => {
        getGeneralNotification()
        getTrackingPage()
        getReceiptAlerts()
        getSenderDetails()
    }, [])
    return (

        <div>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="index.html">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="settings-menu-links">
                        <ul className="nav nav-tabs menu-tabs">
                            <li className="nav-item">
                                <Link className="nav-link " to="/settingschangepassword">
                                    Security
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/billing-address">
                                    Billing Address
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                <Link className="nav-link" to="/localization">
                  Localization
                </Link>
              </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/paymentsetting">
                                    Payment Settings
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link className="nav-link" to="/commodities">
                                    Commodities
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/custome-package">
                                    Custom Package Types
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/paperless-signature">
                                    Paperless Signature
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/importer-setting">
                                    Importer Of Record
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link" to="/notification-setting">
                                    Notifications
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/other-setting">
                                    Other Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/csv-integration-list">
                                    Add CSV Integration
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {message && <p style={{ color: 'green' }}>{message}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <h4 className="page-title">General Notification Settings</h4>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <div className="form-group local-forms">
                                                    <label>
                                                        Shipment Notification Company Name
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setNotificationCompanyName(e.target.value)}
                                                        value={notificationCompanyName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <div className="form-group local-forms">
                                                    <label>
                                                        Shipment Notification From Address
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setNotificationFromAddress(e.target.value)}
                                                        value={notificationFromAddress}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <div className="form-group local-forms">
                                                    <label>
                                                        Default Shipment Notification Email Recipients
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(e) => setNotificationEmailRecipients(e.target.value)}
                                                        value={notificationEmailRecipients}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>Default Shipment Notification Email Message</Form.Label>
                                                    <Form.Control as="textarea"  rows={3} value={notificationEmailMessage}  // Set the value of the textarea
                    onChange={(e) => setNotificationEmailMessage(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <Form.Check
                type={'checkbox'}
                id={`recipient-checkbox`}
                label={`Send email notification for one-off shipments to recipient when shipments are booked through Ship screen or through the Core API`}
                checked={sendOneOffShipmentNotification}
                onChange={handleRecipientCheckboxChange}
            />
            <Form.Check
                type={'checkbox'}
                id={`sender-checkbox`}
                label={`Send email notification to sender by default`}
                checked={sendEmailNotificationByDefault}
                onChange={handleSenderCheckboxChange}
            />
            <Form.Check
                type={'checkbox'}
                id={`reference-field-checkbox`}
                label={`Add Reference field and PO number (if filled out) to Notification Email`}
                checked={addPONumberAndReferenceNumber}
                onChange={handleReferenceFieldCheckboxChange}
            />
                                            <div className="col-12">
                                                <div className="doctor-submit text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary submit-form me-2"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <form >
                                        <div className="row">
                                            <h4 className="page-title">Tracking Page Settings</h4>
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`default-${'checkbox'}`}
                                                label={`Show product descriptions and images on the tracking page when available`}
                                                checked={isVisibleProductDescription}
                                                onChange={() => setIsVisibleProductDescription(!isVisibleProductDescription)}
                                            />
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>Contact Information</Form.Label>
                                                    <Form.Control as="textarea" rows={3} value={productDescription}  onChange={(e) => setProductDescription(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <div className="doctor-submit text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary submit-form me-2"
                                                        onClick={handleTrackingPageSubmittion}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <h4 className="page-title" style={{ fontWeight: 'bold' }}>Receipt Alerts</h4>
                                            <p>Choose to send emails, or SMS messages, to the recipient when the tracking status of a shipment is updated to be one of the following statuses
                                            </p>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`In Transit`}
                                                    checked={isTransit}
                                                    onChange={() => setIsTransit(!isTransit)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>Custom Email Text</Form.Label>
                                                    <Form.Control as="textarea" rows={3} value={inTransitCustomEmailText} onChange={(e) => setInTransitCustomEmailText(e.target.value)}/>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Out for Delivery`}
                                                    checked={outOfDelivery} onChange={(e) => setOutOfDelivery(!outOfDelivery)}


                                                />
                                            </div>
                                            {outOfDelivery &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={outOfDeliveryCustomEmailText} onChange={(e) => setoutOfDeliveryCustomEmailText(e.target.value)}/>
                                                    </Form.Group>
                                                </div>

                                            }
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Delivered`}
                                                    checked={delivered}
                                                    onChange={() => setDelivered(!delivered)}
                                                />
                                            </div>
                                            {delivered &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3}  value={deliveredCustomEmailText} onChange={(e) => setDeliveredCustomEmailText(e.target.value)} />
                                                    </Form.Group>
                                                </div>

                                            }
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Exception`}
                                                    checked={exception}
                                                    onChange={() => setException(!exception)}
                                                />
                                            </div>
                                            {exception &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={exceptionCustomEmailText} onChange={(e) => setExceptionCustomEmailText(e.target.value)}/>
                                                    </Form.Group>
                                                </div>

                                            }
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Include tracking link on notification emails`}
                                                    checked={trackingLineOnNotificationEmail}
                                                    onChange={() => setTrackingLineOnNotificationEmail(!trackingLineOnNotificationEmail)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Enable text message alert option for recipient`}
                                                    checked={textMessageAlertOption}
                                                    onChange={() => setTextMessageAlertOption(!textMessageAlertOption)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="doctor-submit text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary submit-form me-2"
                                                        onClick={handleReceiptAlert}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>




                                        </div>
                                        <div className="row">
                                            <h4 className="page-title"

                                                style={{ fontWeight: 'bold' }}>Sender Alerts</h4>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`In Transit`}
                                                    checked={senderIsTransit}
                                                    onChange={() => setSenderIsTransit(!senderIsTransit)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>Custom Email Text</Form.Label>
                                                    <Form.Control as="textarea" rows={3} value={senderIsTransitCustomEmailText} onChange={(e) => setSenderIsTransitCustomEmailText(e.target.value)}/>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Out for Delivery`}
                                                    checked={senderOutOfDelivery}
                                                    onChange={() => setSenderOutOfDelivery(!senderOutOfDelivery)}


                                                />
                                            </div>
                                            {outOfDelivery &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={senderOutofDeliveryCustomEmailText} onChange={(e) => setSenderOutofDeliveryCustomEmailText(e.target.value)}/>
                                                    </Form.Group>
                                                </div>

                                            }
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Delivered`}
                                                    checked={senderDelivered}
                                                    onChange={() => setSenderDelivered(!senderDelivered)}

                                                />
                                            </div>
                                            {delivered &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={senderDeliveredCustomEmailText} onChange={(e) => setSenderDeliveredCustomEmailText(e.target.value)}/>
                                                    </Form.Group>
                                                </div>

                                            }
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Exception`}
                                                    checked={senderException}
                                                    onChange={() => setSenderException(!senderException)}

                                                />
                                            </div>
                                            {exception &&
                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>Custom Email Text</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={senderExceptionCustomEmailText} onChange={(e) => setSenderExceptionCustomEmailText(e.target.value)}/>
                                                    </Form.Group>
                                                </div>

                                            }

<div className="col-12">
                                            <div className="doctor-submit text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary submit-form me-2"
                                                        onClick={handleSenderAlert}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>





                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Content */}
                <div className="notification-box">
                    <div className="msg-sidebar notifications msg-noti">
                        <div className="topnav-dropdown-header">
                            <span>Messages</span>
                        </div>
                        <div className="drop-scroll msg-list-scroll" id="msg_list">
                            <ul className="list-box">
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Richard Miles </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item new-message">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">John Doe</span>
                                                <span className="message-time">1 Aug</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Tarah Shropshire </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Mike Litorus</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Catherine Manseau </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">D</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Domenic Houston </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">B</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Buster Wigton </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Rolland Webber </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Claire Mapes </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Melita Faucher</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Jeffery Lalor</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">L</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Loren Gatlin</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Tarah Shropshire</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="chat.html">See all messages</Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default NotificationSetting

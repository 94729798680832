/* eslint-disable */
import React, { useEffect, useState } from "react";

import Sidebar from "../Sidebar";
import Header from "../Header";
import Select from "react-select";
// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import {
    imagesend,
    morning_img_01,
} from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Modal, Table } from "react-bootstrap";
import Cookies from "js-cookie";
import { label } from "yet-another-react-lightbox";

// import CountUp from "react-countup";

const PaperlessSignature = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [integration, setIntegration] = useState(null)
    const [productId, setProductId] = useState(null)
    const handleClose = () => setShow(false);
    const [contacts, setContact] = useState([])
    const [signatureId, setSignatureId] = useState(null)
    const [phone, setPhone] = useState(null)
    const [image, setImage] = useState(null)
    const [title, setTitle] = useState(null)
    const [place, setPlace] = useState(null)
    const [name, setName] = useState(null)
    const [signatureOptions,setSignatureOption] = useState([])
    const [response, setResponse] = useState([])
    const [defaultSignature,setDefaultSignature] = useState(false)


    // const [selectedOption, setSelectedOption] = useState(null);
    // eslint-disable-next-line no-unused-vars
    // const [year, setyear] = useState([
    //   { value: 1, label: "2022" },
    //   { value: 2, label: "2021" },
    //   { value: 3, label: "2020" },
    //   { value: 4, label: "2019" },
    // ]);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImage(file)
    };

    const handleShow = (id) => {
        if (id) {
            setSignatureId(id)
        }
        setShow(true)
    };
    const handleUpload = () => {
        // Handle file upload logic here, for example, send the file to the server
        console.log('Selected file:', selectedFile);
        // Reset selected file after upload
        setSelectedFile(null);
    };

    const handleSubmit = async (id,defaultSignature) => {
     
        console.log(id, "id===>>>");
        if (id) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('title', title);
            formData.append('place', place);
            formData.append('phone', phone);
            formData.append('image', image);
            if(defaultSignature) {
                formData.append('default', true);
            }
           

            formData.append('user', Cookies.get('id'));
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-signature/${id}`;
                fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                       
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    },
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data, "dataa=>")
                        setResponse(data.data);
                        console.log(data.data,"dtt")
                        const transformedData = data.data.map(ele => {
                            // Log each element to verify its structure
                            console.log(ele, "Element in data array");
                
                            // Ensure ele has the expected properties
                            return {
                                label: ele.name,
                                value: ele.default
                            };
                        });
                        console.log(transformedData,"transformedData==>>")
                       
                        handleClose()
                    })
                    .catch(error => {
                        console.error('Error while calling API:', error);
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                console.log(err)
            }
        }
        else {
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-signature`;
                const formData = new FormData();
                formData.append('name', name);
                formData.append('title', title);
                formData.append('place', place);
                formData.append('phone', phone);
                formData.append('image', image);
                formData.append('user', Cookies.get('id'));


                fetch(apiUrl, {
                    method: 'POST',
                    body: formData,
                })
                    .then(response => response.json())
                    .then(data => {
                        
                       
                        // Handle successful response here
                        handleClose();
                        getCommodities()
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error while calling API:', error);
                    });
            } catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                // Handle any synchronous errors
                console.error('Caught synchronous error:', err);
            }



        }

    }

    const getCommodities = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-all-signature`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "dataa=>")
                    setResponse(data.data);
                    const d = data.data.map(ele => {
                        return {
                            label:ele.name,
                            value:ele?.default
                        }
                    })
                    setSignatureOption(d)
                    const getDefault = data.data.find(ele => ele.default == true)
                    setDefaultSignature({
                        label:getDefault.name,
                        value:true
                    })
                    handleClose()
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if(err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }
    const updateActiveSignature = async () => {
        if(defaultSignature) {

            const getId = response.find(ele => ele.name == defaultSignature?.label)._id
            handleSubmit(getId,defaultSignature)
        }
    }
 
    useEffect(() => {
        getCommodities()
    }, [])


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Contact Name:</label>
                            <input onChange={(e) => setName(e.target.value)} type="text" value={name} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Contact Title:</label>
                            <input onChange={(e) => setTitle(e.target.value)} type="text" value={title} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Contact Place:</label>
                            <input onChange={(e) => setPlace(e.target.value)} type="text" value={place} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Phone Number:</label>
                            <input onChange={(e) => setPhone(e.target.value)} type="text" value={phone} className="form-control" required />

                        </Form.Group>

                        <Form.Group>
                            <input
                                type="file"
                                id="custom-file"
                                label="Choose file"
                                onChange={handleFileChange}
                                custom
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit(signatureId)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header />
            <Sidebar
                id="menu-item"
                id1="menu-items"
                activeClassName="admin-dashboard"
            />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Paperless Signature </Link>
                                        </li>
                                        {/* <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Admin Dashboard</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        <div >
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>Contact Name</th>
                                                        <th>Phone Number</th>

                                                        <th>Default</th>
    <th></th>
    <th></th>

                                                  </tr>
                                                </thead>
                                                <tbody>
                                                    {console.log(response, "res===========")}                                         {response?.length && response.map(contact => (
                                                        <tr key={contact._id}>
                                                            <td>{contact.name}</td>
                                                            <td>{contact.phone}</td>
                                                            <td>
                                                                {contact.image && <img src={`${process.env.REACT_APP_BACKEND_URL}/${contact.image}`} alt="Contact Image" style={{ maxWidth: "100px", maxHeight: "100px" }} />}
                                                            </td>                                       <td>
                                                                <input
                                                                    type="checkbox"
                                                             onChange={() => console.log('Selected', contact.name)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button variant="primary" onClick={() => handleShow(contact?._id)}>Edit</Button>{' '}
                                                                <Button variant="danger" onClick={() => handleDelete(contact.id)}>Delete</Button>
                                                            </td>
            <td>
               {contact?.default ? <p><b>Active</b></p> : ''}     </td>                                            
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <Button onClick={() => handleShow()}>Add Signature</Button>
                                    </div>
                                    <div>
                                    <div className="form-group">
                                                                                        <label>Default Signature:</label>
                                                                                        {console.log(signatureOptions,"signatureOptions==>>")}
                                                                                        <Select
                                                                                            value={defaultSignature}
                                                                                            onChange={(selectedOption) => {setDefaultSignature(selectedOption)
                                                        console.log(response,"response==>>>")
          const getItem = response.length && response.find(ele => ele.label == selectedOption.label)?._id
          handleSubmit(getItem,selectedOption)

                                                                                            }
                                                                                        }
                                                                                            options={signatureOptions}
                                                                                            required
                                                                                        />       
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={imagesend} alt="#" width={50} height={46} />
                                    <h3>Are you sure want to delete this ?</h3>
                                    <div className="m-t-20">
                                        {" "}
                                        <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                            Close
                                        </Link>
                                        <button type="submit" className="btn btn-danger">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <img src={imagesend} alt="#" width={50} height={46} />
                                        <h3>Are you sure want to delete this ?</h3>
                                        <div className="m-t-20">
                                            {" "}
                                            <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                                Close
                                            </Link>
                                            <button type="submit" className="btn btn-danger">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default PaperlessSignature;

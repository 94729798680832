/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { itemRender, onShowSizeChange } from "../../Pagination";
import { invoice_list_data } from "../../GlobalData/TableData";
import Invoice_HeaderContent from "./Invoice_HeaderContent";
import { imagesend } from "../../imagepath";
import Cookies from "js-cookie";
import InvoicePDF from "./InvoicePDF";
import { pdf } from '@react-pdf/renderer';


const InvoiceList = () => {
    const [invoiceData,setInvoiceData] = useState([])
    const [error,setError] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getInvoiceData = async () => {
      try {
        const userId = Cookies.get('id')
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-invoice`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`
            // You may need to include additional headers such as authorization headers
          },
          body: JSON.stringify({
            user: userId
          }),
        });
  
        if (!response.ok) {
          // Handle the error, e.g., show an error message
          console.error(`HTTP error! Status: ${response.status}`);
          return;
        }
  
        const data = await response.json();
        console.log(data[0].id)
        setInvoiceData(data)
      } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
        if (error) {
          setError('Network Error!')
        }
      }
    }
    useEffect(() => {
      getInvoiceData()
    },[])

    const handleDownloadCSV = async () => {

    }
    const handleDownloadPDF = async (id) => {
      // Find the invoice data for the given id
      const selectedInvoice = invoiceData.find((invoice) => invoice.id === id);
  
      // Create a PDF blob
      const pdfBlob = await pdf(<InvoicePDF invoiceData={selectedInvoice} />).toBlob();
  
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `invoice_${id}.pdf`;
  
      // Simulate click on the anchor to trigger download
      link.click();
    };
    const handleDownloadLabel = async () => {[

    ]}
    const columns = [
        {
            title: "Invoice Id",
            dataIndex: "id",
            sorter: (a, b) => a.id.length - b.id.length,
            render: (text, record) => (
              <>
                  <Link to="/invoice-details">{record.id}</Link>
              </>
            ),
        },
        {
            title: "Created on",
            dataIndex: "date",
            sorter: (a, b) => a.Created_on.length - b.Created_on.length
        },
        {
            title: "Amount",
            dataIndex: "amount",
            sorter: (a, b) => a.amount.length - b.amount.length,
            render: (text, record) => (
                <div className="text-primary">
                    ${record.amount}
                </div>
            )
        },
        {
          title: "Download",
          dataIndex: "download",
          render: (text, record) => (
              <div className="d-flex" style={{ gap:'10px'}}>
                  <button className="btn btn-primary" onClick={() => handleDownloadCSV(record.id)}>Download CSV</button>
                  <button className="btn btn-primary" onClick={() => handleDownloadPDF(record.id)}>Download PDF</button>
                  <button className="btn btn-primary" onClick={() => handleDownloadLabel(record.id)}>Download Label</button>
              </div>
          )
      }
    ]

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item13"
        id1="menu-items13"
        activeClassName="invoice-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                     <Link to="/invoice-list">Invoice </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Invoice List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <Invoice_HeaderContent />
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body p-4">
                    <div className="table-responsive">
                      <Table
                        pagination={{
                          total: invoiceData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={invoiceData}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    </>
  );
};

export default InvoiceList;

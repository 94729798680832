import React from "react";
import {
  login02,
  // loginicon01,
  // loginicon02,
  // loginicon03,
  loginlogo,
} from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import axios from "axios";

const Signup = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('')
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    try {

      // Validation
      if (!email || !password || !companyName) {
        setError("Please fill in all fields.");
        return;
      }

      // Assuming you have a registration API endpoint
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/register`, {
        email,
        password,
        companyName,
      });
      if(response.data.statusCode == 400) {
        setError(response.data.message)
        return false
      }
      if (response.status == 200) {
        navigate('/login')
      }
      // Handle successful registration (e.g., redirect to login page)
      console.log('Registration successful:', response.data);
    } catch (error) {
      // Handle registration error (e.g., display an error message)
      console.error('Registration failed:', error.message);
      setError(error.message)

    }
  };
  
  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/">
                          <img width={'200px'} src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Getting Started</h2>
                      {/* Form */}
                      <div className="form-group">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="email" value={email}
                          onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <label>
                          Password <span className="login-danger">*</span>
                        </label>
                        <input
                          type={passwordVisible ? 'password' : ''}
                          className="form-control pass-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                      </div>
                      <form action="./login">
                        <div className="form-group">
                          <label>
                            Company Name <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={handleClick}
                          >
                            Sign up
                          </button>
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Already have account? <Link to="/login">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types'; // Import PropTypes

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const InvoicePDF = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Invoice Id: {invoiceData.id}</Text>
        <Text>Created on: {invoiceData.date}</Text>
        <Text>Amount: ${invoiceData.amount}</Text>
        {/* Add more details as needed */}
      </View>
    </Page>
  </Document>
);

// Add prop types validation
InvoicePDF.propTypes = {
  invoiceData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    // Add more prop types as needed
  }).isRequired,
};

export default InvoicePDF;

/* eslint-disable */
import React, { useEffect, useState } from "react";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../Sidebar";;
import Header from "../Header";
import { useReactToPrint } from 'react-to-print';

// Component to render the UPS shipping label

// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import {
  // Avatar2,
  // Avatar3,
  // Avatar4,
  // Avatar5,
  // calendar,
  // dep_icon1,
  // dep_icon2,
  // dep_icon3,
  // dep_icon4,
  // dep_icon5,
  // empty_wallet,
  imagesend,
  morning_img_01,
  // profile_add,
  // scissor,
  // user001,
} from "../imagepath";
import { Link, useNavigate,useLocation,useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
// import CountUp from "react-countup";
// const UPSLabelComponent = React.forwardRef((props, ref) => {
//   return <iframe ref={ref} src={props.labelUrl} style={{ width: '100%', height: '100%' }} title="UPS Label" />;
// });


const Success = () => {
  // const labelUrl = 'https://www.ups.com/uel/llp/1Z12345E8791315509/link/labelAll/XLBR/0uHrBtnAFwm4NRuUC7XNCqQ7Tb2RRs0D0oimIIqZ3Mse/en_US?loc=en_US&cie=true&pdr=false'
  const { id } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Get the value of the "bulk" query parameter
  const bulk = searchParams.get('bulk');
  const [labelUrl, setLabelUrl] = useState(null)
  const [receiptUrl, setReceiptUrl] = useState(null)
  const [base64Label, setBase64Label] = useState(null)
  const [bulkShipData,setBulkShipData] = useState([])
  const [amount,setAmount] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const componentRef = React.useRef();

  const handlePrintPDF = (base64Data) => {
    // Decode base64 to binary data
    const binaryString = atob(base64Data);

    // Convert binary string to Uint8Array
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the bytes
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create URL representing the PDF content
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new window and print it
    const pdfWindow = window.open(url);
    if (pdfWindow) {
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  const base64ToPDF = (base64Data) => {
    // Decode base64 to binary data
    const binaryData = atob(base64Data);

    // Create ArrayBuffer from binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create Blob object from ArrayBuffer
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create URL representing the PDF content
    const pdfUrl = URL.createObjectURL(blob);

    // Open the PDF in a new window
    const pdfWindow = window.open(pdfUrl);

    // If the new window is opened successfully
    if (pdfWindow) {
      // Wait for the PDF to load
      pdfWindow.onload = () => {
        // Print the PDF document
        pdfWindow.print();
      };
    } else {
      // Handle case where new window couldn't be opened
      console.error('Could not open new window for printing');
    }
  };

  const labelRecovery = async () => {
    const userId = Cookies.get('id')
    if(bulk) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/bulk-ship-label-recovery`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`
            // You may need to include additional headers such as authorization headers
          },
          body: JSON.stringify({
            shipId: id,
            user: userId
          }),
        });

        if (!response.ok) {
          // Handle the error, e.g., show an error message
          console.error(`HTTP error! Status: ${response.status}`);
          return;
        }

        const data = await response.json();
        console.log(data?.data,"labelRecovery")
        if (data?.data[0]?.data?.LabelResults) {
          setBulkShipData(data?.data)
          setAmount(data?.data[0]?.amount)
          setLabelUrl(data?.data[0]?.data?.LabelResults.LabelImage.URL)
          setReceiptUrl(data?.data[0]?.data?.LabelResults.Receipt.Image.GraphicImage)
          setBase64Label(data?.data[0]?.data?.LabelResults.LabelImage.GraphicImage)
          console.log(data?.data[0]?.amount,"data?.data[0]?.data?.amount")
          
        }
        if(data?.data[0]?.data?.encodedLabel) {
          // setBase64Label(data?.data?.encodedLabel)
          // setAmount(data?.data?.amount)
        }
    
        // Handle success, e.g., update state or show a success message
      } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
        if (error) {
          setError('Network Error!')
        }
      }
    } 
    else {
      console.log(id, "id=>")

      
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/label-recovery`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`
            // You may need to include additional headers such as authorization headers
          },
          body: JSON.stringify({
            shipId: id,
            user: userId
          }),
        });

        if (!response.ok) {
          // Handle the error, e.g., show an error message
          console.error(`HTTP error! Status: ${response.status}`);
          return;
        }

        const data = await response.json();
        console.log(data?.data)
        if (data?.data?.LabelResults) {
          setAmount(data?.data?.amount)
          setLabelUrl(data?.data?.LabelResults.LabelImage.URL)
          setReceiptUrl(data?.data?.LabelResults.Receipt.Image.GraphicImage)
          setBase64Label(data?.data?.LabelResults.LabelImage.GraphicImage)
          console.log(data?.amount,"data?.data?.amount")
          
        }
        if(data?.data?.encodedLabel) {
          setBase64Label(data?.data?.encodedLabel)
          setAmount(data?.data?.amount)
        }
    
        // Handle success, e.g., update state or show a success message
      } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
        if (error) {
          setError('Network Error!')
        }
      }
    }
      

  }

  
  useEffect(() => {
    labelRecovery();
  }, [])
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    {/* <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li> */}
                    {/* <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Admin Dashboard</li> */}
                  </ul>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      <span><i className="fa fa-cube" /> Label Your Package</span>
                    </h2>
                    {bulkShipData.length ?
                      <table>
                        <thead>
                          <tr>
                          <th>Ship</th>
                          <th>Lable</th>
                          <th>Receipt</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          {bulkShipData?.map(ele => (
                            <tr>
                              <td>{ele.ship.description}</td>
                              <td><button type="button" className="btn btn-primary btn-lg me-1" onClick={() => base64ToPDF(ele.data.LabelResults.LabelImage.GraphicImage)}>Print Label</button></td>
                              <td><button type="button" className="btn btn-primary btn-lg me-1" onClick={() => base64ToPDF(ele?.data.LabelResults.Receipt.Image.GraphicImage)}>Print Receipt</button></td>

</tr>   
                          ))
                         
                          }
                        
                        </tbody>
                      </table>
                  :
                  <>
                   {base64Label && <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => base64ToPDF(base64Label)}>Print Label</button>}
                   {receiptUrl && <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => base64ToPDF(receiptUrl)}>Print Receipt</button>}</>
                        }
                   
                  </div>

                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                      alt="#" />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div id="delete_patient" className="modal fade delete-modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="delete_patient" className="modal fade delete-modal" role="dialog">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src={imagesend} alt="#" width={50} height={46} />
                    <h3>Are you sure want to delete this ?</h3>
                    <div className="m-t-20">
                      {" "}
                      <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                        Close
                      </Link>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Success;

// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

// Async action to fetch user data
export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/profile`);
  return response.data;
});

// Async action to perform user login
export const loginUser = createAsyncThunk('user/loginUser', async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/login`, { email, password });

    console.log(response.data,"response.data.email")
    // Store user data in cookies



    return response.data;
  } catch (error) {
    // If the request fails, return the error message as the payload of the rejected action
    return rejectWithValue(error.message || 'Login failed');
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    loading: false,
    error: null,
    isAuthenticated: Cookies.get('token') ? true : false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
     
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.isAuthenticated = true;
        // Handle successful login (if needed)
        console.log('Login successful:', action.payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectUser = (state) => {
  return state.user.data
};

export const userIsAuthenticate  = (state) => {
  return state.user.isAuthenticated
  
};
export default userSlice.reducer;

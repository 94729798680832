import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
// import { Link } from "react-router-dom";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
    // Avatar1,
    // Avatar2,
    // Avatar3,
    // Avatar4,
    // Avatar5,
    // Avatar6,
    // Avatar7,
    // Avatar8,
    // Avatar9,
    // blogimg2,
    // chaticon1,
    // chaticon2,
    // chaticon3,
    // clock,
    // incomingcall,
    // outgoing,
    // receivedcall,
    // removecall,
    // voiceicon1,
    // voiceicon2,
    // voiceicon3,

} from "../imagepath";
import { Button, Modal } from "react-bootstrap";
import { Select } from "@mui/material";

const AddOrderModal = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [show, setShow] = useState(false);
    // const [showModal, setShowModal] = useState(true);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  
    // const [option, setOption] = useState([
    //     { value: 1, label: "option 1" },
    //     { value: 2, label: "option 2" },
    //     { value: 3, label: "option 3" },
    //     { value: 4, label: "option 4" },
    //     { value: 5, label: "option 5" },
    //   ]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowModal(true);
    //     }, 2000); // 2 minutes = 120,000 milliseconds

    //     return () => clearTimeout(timer);
    // }, []);
    // console.log(showModal, "showmodal")
    return (
        <>
            <div className="modal-open">
                <div className="main-wrapper">
                    <Header />
                    <Sidebar
                        id="menu-item9"
                        id1="menu-items9"
                        activeClassName="incoming-call"
                    />
                    <>
                        <div className="page-wrapper">
                            <div className="content">

                            </div>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Modal heading</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Woohoo, you are reading this text in a modal!
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div id="incoming_call" className="modal custom-modal" role="dialog">
                            {
                                <div className="modal">
                                    <div className="modal-content">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card-box">
                                                                <h4 className="card-title">Basic Inputs</h4>
                                                                <form >
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Text Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input type="text" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Password
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input type="password" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Disabled Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                disabled="disabled"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Readonly Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                defaultValue="readonly"
                                                                                readOnly="readonly"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Placeholder
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Placeholder"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            File input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input className="form-control" type="file" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Default select
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <Select
                                                                                defaultValue={selectedOption}
                                                                                onChange={setSelectedOption}
                                                                            // options={option}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">Radio</label>
                                                                        <div className="col-md-10">
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 1
                                                                                </label>
                                                                            </div>
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 2
                                                                                </label>
                                                                            </div>
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 3
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Checkbox
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 1
                                                                                </label>
                                                                            </div>
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 2
                                                                                </label>
                                                                            </div>
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 3
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Textarea
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <textarea
                                                                                rows={5}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter text here"
                                                                                defaultValue={""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Input Addons
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <div className="input-group">
                                                                                <div className="input-group-prepend">
                                                                                    <span className="input-group-text">$</span>
                                                                                </div>
                                                                                <input className="form-control" type="text" />
                                                                                <button className="btn btn-primary" type="button">
                                                                                    Button
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="card-box">
                                                                <h4 className="card-title">Input Sizes</h4>
                                                                <form action="#">
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Large Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-lg"
                                                                                placeholder=".form-control-lg"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Default Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder=".form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Small Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-sm"
                                                                                placeholder=".form-control-sm"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                        { (
                            <div
                                id="incoming_call"
                                className="modal custom-modal fade show"
                                role="dialog"
                                style={{ display: "block" }}
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="crypto-income-blk text-center">
                                            <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card-box">
                                                                <h4 className="card-title">Basic Inputs</h4>
                                                                <form action="#">
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Text Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input type="text" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Password
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input type="password" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Disabled Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                disabled="disabled"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Readonly Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                defaultValue="readonly"
                                                                                readOnly="readonly"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Placeholder
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Placeholder"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            File input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input className="form-control" type="file" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Default select
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <Select
                                                                                defaultValue={selectedOption}
                                                                                onChange={setSelectedOption}
                                                                                // options={option}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">Radio</label>
                                                                        <div className="col-md-10">
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 1
                                                                                </label>
                                                                            </div>
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 2
                                                                                </label>
                                                                            </div>
                                                                            <div className="radio">
                                                                                <label>
                                                                                    <input type="radio" name="radio" /> Option 3
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Checkbox
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 1
                                                                                </label>
                                                                            </div>
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 2
                                                                                </label>
                                                                            </div>
                                                                            <div className="checkbox">
                                                                                <label>
                                                                                    <input type="checkbox" name="checkbox" /> Option 3
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Textarea
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <textarea
                                                                                rows={5}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter text here"
                                                                                defaultValue={""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Input Addons
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <div className="input-group">
                                                                                <div className="input-group-prepend">
                                                                                    <span className="input-group-text">$</span>
                                                                                </div>
                                                                                <input className="form-control" type="text" />
                                                                                <button className="btn btn-primary" type="button">
                                                                                    Button
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="card-box">
                                                                <h4 className="card-title">Input Sizes</h4>
                                                                <form action="#">
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Large Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-lg"
                                                                                placeholder=".form-control-lg"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Default Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder=".form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-form-label col-md-2">
                                                                            Small Input
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-sm"
                                                                                placeholder=".form-control-sm"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                </div>

            </div>
        </>
    );
};

export default AddOrderModal;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchUserProfile = createAsyncThunk('user/fetchUser', async (token) => {
  console.log(token,"token=>>>123")
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/profile`, {
      headers: {
        Authorization: `Bearer ${token}`, // Assuming you have stored the token in your Redux state under auth.token
        'Content-Type': 'application/json'}
      });
  return response.data;
});
const userProfileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectUserProfile = (state) => {
  console.log(state,"stat")
    return state?.profile?.user?.data
  };

export default userProfileSlice.reducer;

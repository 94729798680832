/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Button, Form } from 'react-bootstrap'

const OtherSetting = () => {
    const [enableBarcodeMode, setEnableBarcodeMode] = useState(false)
    const [disbleRefenrenceValue, setDisbleRefenrenceValue] = useState(false)
    const [carrierInsurance, setCarrierInsurance] = useState(false)
    const [importerRecorderRequired, setImporterRecorderRequired] = useState(false);
    const [error, setError] = useState(null)
    const [nameAndSkuAsReference, setNmeAndSkuAsReference] = useState(false)
    const [createContentDescription, setCreateContentDescription] = useState(false)
    const [contentDescription, setContentDescription] = useState(null)
    const [dontPopulateContentDescription, setDontPopulateContentDescription] = useState(false)
    const [defaultAESNumber, setDefaultAESNumber] = useState(null)
    const [requireShipperReference, setRequireShipperReference] = useState(false)
    const [requireDepartmentNumber, setRequireDepartmentNumber] = useState(false)
    const [requireRecordImporter, setRequireRecordImporter] = useState(false)
    const [requirePONumber, setRequirePONumber] = useState(false)
    const [dontAutometicallyShowLabel, setDontAutometicallyShowLabel] = useState(false)
    const [useLegacyLabelPopup, setUseLegacyLabelPopup] = useState(false)
    const [autoSaveReceiptAddresses, setAutoSaveReceiptAddresses] = useState(false)
    const [persistQuoteCondensed, setPersistQuoteCondensed] = useState(false)
    const [showAlternateQuote, setShowAlternateQuote] = useState(false)
    const [viewAddressBook, setViewAddressBook] = useState(false)
    const [viewOwnHistory, setViewOwnHistory] = useState(false)
    const [hideOrderItemPrice, setHideOrderItemPrice] = useState(false)
    const [hidePrepayBalance, setHidePrepayBalance] = useState(false)
    const [allowAdminToApprovePrepayCharges, setAllowAdminToApprovePrepayCharges] = useState(false)
    const [simpleInventorySystem, setSimpleInventorySystem] = useState(false)
    const [isShortenAddressFields, setIsShortenAddressFields] = useState(false)
    const [enableSecondShipperReference, setEnableSecondShipperReference] = useState(false)
    const [previouslySelectedServiceAndPackage,setPreviouslySelectedServiceAndPackage] = useState(false)
    const [id,setId] = useState(null)
    const [message,setMessage] = useState(null);
    const handleSubmit = async (e) => {
        
        const bodyData = {
            enableBarcodeMode,
            disbleRefenrenceValue,
            carrierInsurance,
            importerRecorderRequired,
            nameAndSkuAsReference,
            createContentDescription,
            enableSecondShipperReference,
            isShortenAddressFields,
            contentDescription,
            dontPopulateContentDescription,
            defaultAESNumber,
            requireShipperReference,
            requireDepartmentNumber,
            requireRecordImporter,
            requirePONumber,
            dontAutometicallyShowLabel,
            useLegacyLabelPopup,
            autoSaveReceiptAddresses,
            persistQuoteCondensed,
            showAlternateQuote,
            viewAddressBook,
            viewOwnHistory,
            hideOrderItemPrice,
            hidePrepayBalance,
            allowAdminToApprovePrepayCharges,
            simpleInventorySystem,
            previouslySelectedServiceAndPackage,
            user:Cookies.get('id')
        }
        e.preventDefault();
        if(id) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-other-setting/${id}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData)
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-other-setting`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('token')}`
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify(bodyData)
                });
    
    
    
                const data = await response.json();
                if (data.status) {
                    setMessage(data.message)
                }
                else {
                    setError(data.message)
                }
                console.log(data, "dta"); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                setError(error)
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
      
    };
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error])

    useEffect(() => {
        console.log(importerRecorderRequired,"enble")
    },[importerRecorderRequired])

    
    const getOtherSetting = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-other-setting`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {
                let res = data.data[0];
                setId(res._id)
                setEnableBarcodeMode(res.enableBarcodeMode)
                setDisbleRefenrenceValue(res.disbleRefenrenceValue)
                setCarrierInsurance(res.carrierInsurance)
                setEnableSecondShipperReference(res.enableSecondShipperReference)
                setImporterRecorderRequired(res.importerRecorderRequired)
                setNmeAndSkuAsReference(res.nameAndSkuAsReference)
                setCreateContentDescription(res.createContentDescription)
                setContentDescription(res.contentDescription)
                setDontPopulateContentDescription(res.dontPopulateContentDescription)
                setDefaultAESNumber(res.defaultAESNumber)
                setRequireShipperReference(res.requireShipperReference)
                setRequireDepartmentNumber(res.requireDepartmentNumber)
                setRequireRecordImporter(res.quireRecordImporter)
                setRequirePONumber(res.requirePONumber)
                setDontAutometicallyShowLabel(res.dontAutometicallyShowLabel)
                setUseLegacyLabelPopup(res.useLegacyLabelPopup)
                setAutoSaveReceiptAddresses(res.AutoSaveReceiptAddresses)
                setPersistQuoteCondensed(res.persistQuoteCondensed)
                setShowAlternateQuote(res.showAlternateQuote)
                setViewAddressBook(res.viewAddressBook)
                setViewOwnHistory(res.viewOwnHistory)
                setHideOrderItemPrice(res.hideOrderItemPrice)
                setHidePrepayBalance(res.hidePrepayBalance)
                setAllowAdminToApprovePrepayCharges(res.allowAdminToApprovePrepayCharges)
                setSimpleInventorySystem(res.simpleInventorySystem)
                setIsShortenAddressFields(res.isShortenAddressFields)
                setPreviouslySelectedServiceAndPackage(res.previouslySelectedServiceAndPackage)
                // const getCountry = SenderCountryOptions.find(ele => ele.value == data.country)
                // const getState = senderStateList.find(ele => ele.value == data.state)
                // console.log(getState, "getState")
                // setAddId(data._id)
                // setSenderName(data.name)
                // if (data.user.email) {
                //     setSenderEmail(data.user.email)
                // }
                // else {
                //     setSenderEmail(user.data.email)
                // }
                // if (data.user.companyName) {
                //     setSenderCompany(data.user.companyName)
                // }
                // else {
                //     setSenderCompany(user.data.companyName)
                // }
                // setSenderAddress1(data.address1)
                // setSenderAddress2(data.address2)
                // setSenderCountry(getCountry);
                // setSenderCity(data.city)
                // setSenderState(getState)
                // setSenderZipCode(data.zip)
                // setSenderPhone(data.phone)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }
    useEffect(() => {
        getOtherSetting()
    },[])
    return (

        <div>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="index.html">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="settings-menu-links">
                        <ul className="nav nav-tabs menu-tabs">
                            <li className="nav-item">
                                <Link className="nav-link " to="/settingschangepassword">
                                    Security
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/billing-address">
                                    Billing Address
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                <Link className="nav-link" to="/localization">
                  Localization
                </Link>
              </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/paymentsetting">
                                    Payment Settings
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link className="nav-link" to="/commodities">
                                    Commodities
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/custome-package">
                                    Custom Package Types
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/paperless-signature">
                                    Paperless Signature
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/importer-setting">
                                    Importer Of Record
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/notification-setting">
                                    Notifications
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link" to="/other-setting">
                                    Other Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/csv-integration-list">
                                    Add CSV Integration
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {message && <p style={{ color: 'green' }}>{message}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <h4 className="page-title" style={{ fontWeight: 'bold' }}>Batch Tab Options</h4>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Enable barcode mode on batch - keyword field will be auto selected after each save`}
                                                    checked={enableBarcodeMode}
                                        onChange={() => setEnableBarcodeMode(!enableBarcodeMode)}
                                                   
                                                />
                                            </div>
                                           
                                           
                                           




                                        </div>
                                        <div className="row">
                                            <h4 className="page-title"

                                                style={{ fontWeight: 'bold' }}>Batch Tab and Ship Tab Options</h4>
                                                

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Disable write-in for shipper reference value (user must choose from presets)`}
                                                    checked={disbleRefenrenceValue}
                                        onChange={() => setDisbleRefenrenceValue(!disbleRefenrenceValue)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Keep previously selected service type and package type when selecting new orders`}
                                                    checked={previouslySelectedServiceAndPackage}
                                        onChange={() => setPreviouslySelectedServiceAndPackage(!previouslySelectedServiceAndPackage)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Use carrier insurance only`}
                                                    checked={carrierInsurance}
                                        onChange={() => setCarrierInsurance(!carrierInsurance)}
                                                   
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Require Importer of Record to be specified when booking shipments`}
                                                    checked={importerRecorderRequired}
                                                    onChange={() => setImporterRecorderRequired(!importerRecorderRequired)}


                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Use name and sku from first item on order as Reference & PO Number for shipping label`}
                                                    checked={nameAndSkuAsReference}
                                                    onChange={() => setNmeAndSkuAsReference(!nameAndSkuAsReference)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Shorten address fields to 35 characters to ignore XPS Ship validation errors while booking FedEx shipments`}
                                                    checked={isShortenAddressFields}
                                                    onChange={() => setIsShortenAddressFields(!isShortenAddressFields)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Enable second shipper reference when available`}
                                                    checked={enableSecondShipperReference}
                                                    onChange={() => setEnableSecondShipperReference(!enableSecondShipperReference)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Create Content Description From Item Skus Instead of Item Titles`}
                                                    checked={createContentDescription}
                                                    onChange={() => setCreateContentDescription(!createContentDescription)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <div className="form-group local-forms">
                                                    <label>
                                                        Content Description Override
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        value={contentDescription}
                                                        onChange={(e) => setContentDescription(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Don't Populate Content Description With Item Info`}
                                                    value={dontPopulateContentDescription}
                                                    checked={dontPopulateContentDescription}
                                                    onChange={() => setDontPopulateContentDescription(!dontPopulateContentDescription)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <div className="form-group local-forms">
                                                    <label>
                                                        Default AES/EIN Number
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        value={defaultAESNumber}
                                                        onChange={(e) => setDefaultAESNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h4 className="page-title"

                                                style={{ fontWeight: 'bold' }}> Ship Tab Options</h4>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Require shipper reference to be provided when booking shipments`}
                                                    checked={requireShipperReference}
                                                    onChange={() => setRequireShipperReference(!requireShipperReference)}
                                                    
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Require department number to be specified when booking shipments`}
                                                    checked={requireDepartmentNumber}
                                                    onChange={() => setRequireDepartmentNumber(!requireDepartmentNumber)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Require Importer of Record to be specified when booking shipments`}
                                                    checked={requireRecordImporter}
                                                    onChange={() => setRequireRecordImporter(!requireRecordImporter)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Require PO Number to be specified when booking shipments (FedEx and UPS only)`}
                                                    checked={requirePONumber}
                                                    onChange={() => setRequirePONumber(!requirePONumber)}


                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Don't automatically show label after shipment is created`}
                                                    checked={dontAutometicallyShowLabel}
                                                    onChange={() => setDontAutometicallyShowLabel(!dontAutometicallyShowLabel)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Use legacy label pop-up even in Chrome and Safari`}
                                                    checked={useLegacyLabelPopup}
                                                    onChange={() => setUseLegacyLabelPopup(!useLegacyLabelPopup)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Auto-save recipient addresses`}
                                                    checked={autoSaveReceiptAddresses}
                                                    onChange={() => setAutoSaveReceiptAddresses(!autoSaveReceiptAddresses)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Persist quote condensed/expanded state`}
                                                    checked={persistQuoteCondensed}
                                                    onChange={() => setPersistQuoteCondensed(!persistQuoteCondensed)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Show alternate quote errors`}
                                                    checked={showAlternateQuote}
                                                    onChange={() => setShowAlternateQuote(!showAlternateQuote)}
                                                    
                                                />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <h4 className="page-title"

                                                style={{ fontWeight: 'bold' }}> Users</h4>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Only allow users to view their own address book`}
                                                    checked={viewAddressBook}
                                                    onChange={() => setViewAddressBook(!viewAddressBook)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Only allow users to view their own history`}
                                                    checked={viewOwnHistory}
                                                    onChange={() => setViewOwnHistory(!viewOwnHistory)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Hide order item prices for non-Admin users`}
                                                    checked={hideOrderItemPrice
                                                    }
                                                    onChange={() => setHideOrderItemPrice(!hideOrderItemPrice)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Hide prepay balance for non-Admin users`}
                                                    checked={hidePrepayBalance
                                                    }
                                                    onChange={() => setHidePrepayBalance(!hidePrepayBalance)}

                                                />

                                            </div>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Only allow XPS Ship Admins to approve Prepay recharges`}
                                                    checked={allowAdminToApprovePrepayCharges
                                                    }
                                                    onChange={() => setAllowAdminToApprovePrepayCharges(!allowAdminToApprovePrepayCharges)}
                                                />
                                            </div>



                                        </div>
                                        <div className="row">
                                            <h4 className="page-title"

                                                style={{ fontWeight: 'bold' }}> Warehouse Inventory</h4>

                                            <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Enable simple inventory system`}
                                                    checked={simpleInventorySystem
                                                    }
                                                    onChange={() => setSimpleInventorySystem(!simpleInventorySystem)}
                                                />
                                            </div>
                                          
                                            {/* <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Hide order item prices for non-Admin users`}
                                                />
                                            </div> */}

                                            {/* <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Hide prepay balance for non-Admin users`}
                                                    value={outOfDelivery}


                                                />

                                            </div> */}

                                            {/* <div className="col-12 col-md-6 col-xl-12">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-${'checkbox'}`}
                                                    label={`Only allow XPS Ship Admins to approve Prepay recharges`}
                                                    value={delivered}
                                                />
                                            </div> */}
                                        </div>
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Content */}
                <div className="notification-box">
                    <div className="msg-sidebar notifications msg-noti">
                        <div className="topnav-dropdown-header">
                            <span>Messages</span>
                        </div>
                        <div className="drop-scroll msg-list-scroll" id="msg_list">
                            <ul className="list-box">
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Richard Miles </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item new-message">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">John Doe</span>
                                                <span className="message-time">1 Aug</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Tarah Shropshire </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Mike Litorus</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Catherine Manseau </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">D</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Domenic Houston </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">B</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Buster Wigton </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Rolland Webber </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Claire Mapes </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Melita Faucher</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Jeffery Lalor</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">L</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Loren Gatlin</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Tarah Shropshire</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="chat.html">See all messages</Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default OtherSetting

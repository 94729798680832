/* eslint-disable */
import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import {
  Alert,
  Form,
  ButtonGroup,
  Modal,
  Toast,
  ToggleButton,
  Card,
} from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import statesData from "../../../states.json";
import { loadStripe } from "@stripe/stripe-js";
const BulkShip = () => {
  const prevPackageOfServiceRef = useRef();
  const options = [
    { value: 1, label: "in / lb / oz" },
    { value: 2, label: "cm / kg / g" },
  ];

  const billingPartyOption = [
    { value: "receiver", label: "Receiver" },
    { value: "sender", label: "Sender" },
    { value: "thirdparty", label: "Third Party" },
  ];
  const insuranceOptions = [
    { value: "general", label: "General" },
    { value: "laptoplcdtv", label: "Laptop,LCD,TV" },
    { value: "glassware", label: "Ceramics" },
    { value: "jewelry", label: "Jewelry" },
  ];
  const receiverCountryOptions = [
    { value: "AF", label: "AFGHANISTAN" },
    { value: "AX", label: "ALAND ISLANDS" },
    { value: "AL", label: "ALBANIA" },
    { value: "DZ", label: "ALGERIA" },
    { value: "AS", label: "AMERICAN SAMOA" },
    { value: "AD", label: "ANDORRA" },
    { value: "AO", label: "ANGOLA" },
    { value: "AI", label: "ANGUILLA" },
    { value: "AQ", label: "ANTARCTICA" },
    { value: "AG", label: "ANTIGUA / BARBUDA" },
    { value: "AR", label: "ARGENTINA" },
    { value: "AM", label: "ARMENIA" },
    { value: "AW", label: "ARUBA" },
    { value: "AU", label: "AUSTRALIA" },
    { value: "CX", label: "AUSTRALIA (CHRISTMAS ISLANDS)" },
    { value: "CC", label: "AUSTRALIA (COCOS KEELING ISLANDS)" },
    { value: "NF", label: "AUSTRALIA (NORFOLK ISLANDS)" },
    { value: "AT", label: "AUSTRIA" },
    { value: "AZ", label: "AZERBAIJAN" },
    { value: "BS", label: "BAHAMAS" },
    { value: "BH", label: "BAHRAIN" },
    { value: "BD", label: "BANGLADESH" },
    { value: "BB", label: "BARBADOS" },
    { value: "BY", label: "BELARUS" },
    { value: "BE", label: "BELGIUM" },
    { value: "BZ", label: "BELIZE" },
    { value: "BJ", label: "BENIN" },
    { value: "BM", label: "BERMUDA" },
    { value: "BT", label: "BHUTAN" },
    { value: "BO", label: "BOLIVIA" },
    { value: "XB", label: "BONAIRE (NETHERLANDS ANTILLES)" },
    { value: "BA", label: "BOSNIA / HERZEGOVINA" },
    { value: "BW", label: "BOTSWANA" },
    { value: "BV", label: "BOUVET ISLAND" },
    { value: "BR", label: "BRAZIL" },
    { value: "IO", label: "BRITISH INDIAN OCEAN TERRITORY" },
    { value: "BN", label: "BRUNEI" },
    { value: "BG", label: "BULGARIA" },
    { value: "BF", label: "BURKINA FASO" },
    { value: "BI", label: "BURUNDI" },
    { value: "KH", label: "CAMBODIA" },
    { value: "CM", label: "CAMEROON" },
    { value: "CA", label: "CANADA" },
    { value: "IC", label: "CANARY ISLANDS" },
    { value: "CV", label: "CAPE VERDE" },
    { value: "KY", label: "CAYMAN ISLANDS" },
    { value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
    { value: "TD", label: "CHAD" },
    { value: "CL", label: "CHILE" },
    { value: "CN", label: "CHINA" },
    { value: "CO", label: "COLOMBIA" },
    { value: "KM", label: "COMOROS" },
    { value: "CG", label: "CONGO" },
    { value: "CD", label: "CONGO, DEMOCRATIC REPUBLIC OF THE" },
    { value: "CK", label: "COOK ISLANDS" },
    { value: "CR", label: "COSTA RICA" },
    { value: "HR", label: "CROATIA" },
    { value: "CU", label: "CUBA" },
    { value: "CW", label: "CURACAO" },
    { value: "CY", label: "CYPRUS" },
    { value: "CZ", label: "CZECH REPUBLIC" },
    { value: "DK", label: "DENMARK" },
    { value: "DJ", label: "DJIBOUTI" },
    { value: "DM", label: "DOMINICA" },
    { value: "DO", label: "DOMINICAN REPUBLIC" },
    { value: "TL", label: "EAST TIMOR" },
    { value: "EC", label: "ECUADOR" },
    { value: "EG", label: "EGYPT" },
    { value: "SV", label: "EL SALVADOR" },
    { value: "ER", label: "ERITREA" },
    { value: "EE", label: "ESTONIA" },
    { value: "ET", label: "ETHIOPIA" },
    { value: "FK", label: "FALKLAND ISLANDS (MALVINAS)" },
    { value: "FO", label: "FAROE ISLANDS" },
    { value: "FJ", label: "FIJI" },
    { value: "WF", label: "FIJI (WALLIS / FUTUNA ISLANDS)" },
    { value: "FI", label: "FINLAND" },
    { value: "FR", label: "FRANCE" },
    { value: "GF", label: "FRENCH GUYANA" },
    { value: "PF", label: "FRENCH POLYNESIA" },
    { value: "TF", label: "FRENCH SOUTHERN TERRITORIES" },
    { value: "GA", label: "GABON" },
    { value: "GM", label: "GAMBIA" },
    { value: "GE", label: "GEORGIA" },
    { value: "DE", label: "GERMANY" },
    { value: "GH", label: "GHANA" },
    { value: "GI", label: "GIBRALTAR" },
    { value: "GR", label: "GREECE" },
    { value: "GL", label: "GREENLAND" },
    { value: "GD", label: "GRENADA" },
    { value: "GP", label: "GUADELOUPE" },
    { value: "GU", label: "GUAM" },
    { value: "GT", label: "GUATEMALA" },
    { value: "GG", label: "GUERNSEY" },
    { value: "GQ", label: "GUINEA (EQUATORIAL GUINEA)" },
    { value: "GW", label: "GUINEA BISSAU" },
    { value: "GN", label: "GUINEA REPUBLIC (GUINEA)" },
    { value: "GY", label: "GUYANA (BRITISH)" },
    { value: "HT", label: "HAITI" },
    { value: "HM", label: "HEARD ISLAND AND MCDONALD ISLANDS" },
    { value: "HN", label: "HONDURAS" },
    { value: "HK", label: "HONG KONG" },
    { value: "HU", label: "HUNGARY" },
    { value: "IS", label: "ICELAND" },
    { value: "IN", label: "INDIA" },
    { value: "ID", label: "INDONESIA" },
    { value: "IR", label: "IRAN (ISLAMIC REPUBLIC OF)" },
    { value: "IQ", label: "IRAQ" },
    { value: "IE", label: "IRELAND, REPUBLIC OF (IRELAND)" },
    { value: "IL", label: "ISRAEL" },
    { value: "IT", label: "ITALY" },
    { value: "SM", label: "ITALY (SAN MARINO)" },
    { value: "VA", label: "ITALY (VATICAN CITY)" },
    { value: "CI", label: "IVORY COAST" },
    { value: "JM", label: "JAMAICA" },
    { value: "JP", label: "JAPAN" },
    { value: "JE", label: "JERSEY" },
    { value: "JO", label: "JORDAN" },
    { value: "KZ", label: "KAZAKHSTAN" },
    { value: "KE", label: "KENYA" },
    { value: "KI", label: "KIRIBATI" },
    { value: "KR", label: "KOREA, REPUBLIC OF (KOREA SOUTH)" },
    { value: "KP", label: "KOREA, THE D.P.R. OF (KOREA NORTH)" },
    { value: "KV", label: "KOSOVO" },
    { value: "KW", label: "KUWAIT" },
    { value: "KG", label: "KYRGYZSTAN" },
    { value: "LA", label: "LAOS" },
    { value: "LV", label: "LATVIA" },
    { value: "LB", label: "LEBANON" },
    { value: "LS", label: "LESOTHO" },
    { value: "LR", label: "LIBERIA" },
    { value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
    { value: "LI", label: "LIECHTENSTEIN" },
    { value: "LT", label: "LITHUANIA" },
    { value: "LU", label: "LUXEMBOURG" },
    { value: "MO", label: "MACAO" },
    { value: "MK", label: "MACEDONIA, REPUBLIC OF (FYROM)" },
    { value: "MG", label: "MADAGASCAR" },
    { value: "MW", label: "MALAWI" },
    { value: "MY", label: "MALAYSIA" },
    { value: "MV", label: "MALDIVES" },
    { value: "ML", label: "MALI" },
    { value: "MT", label: "MALTA" },
    { value: "MH", label: "MARSHALL ISLANDS" },
    { value: "MQ", label: "MARTINIQUE" },
    { value: "MR", label: "MAURITANIA" },
    { value: "MU", label: "MAURITIUS" },
    { value: "YT", label: "MAYOTTE" },
    { value: "MX", label: "MEXICO" },
    { value: "FM", label: "MICRONESIA, FEDERATED STATES OF" },
    { value: "MD", label: "MOLDOVA, REPUBLIC OF" },
    { value: "MC", label: "MONACO" },
    { value: "MN", label: "MONGOLIA" },
    { value: "ME", label: "MONTENEGRO" },
    { value: "MS", label: "MONTSERRAT" },
    { value: "MA", label: "MOROCCO" },
    { value: "MZ", label: "MOZAMBIQUE" },
    { value: "MM", label: "MYANMAR" },
    { value: "NA", label: "NAMIBIA" },
    { value: "NR", label: "NAURU, REPUBLIC OF" },
    { value: "NP", label: "NEPAL" },
    { value: "NL", label: "NETHERLANDS" },
    { value: "AN", label: "NETHERLANDS ANTILLES" },
    { value: "NC", label: "NEW CALEDONIA" },
    { value: "NZ", label: "NEW ZEALAND" },
    { value: "NI", label: "NICARAGUA" },
    { value: "NE", label: "NIGER" },
    { value: "NG", label: "NIGERIA" },
    { value: "NU", label: "NIUE" },
    { value: "MP", label: "NORTHERN MARIANA ISLANDS" },
    { value: "NO", label: "NORWAY" },
    { value: "OM", label: "OMAN" },
    { value: "PK", label: "PAKISTAN" },
    { value: "PW", label: "PALAU" },
    { value: "PS", label: "PALESTINIAN TERRITORY, OCCUPIED" },
    { value: "PA", label: "PANAMA" },
    { value: "PG", label: "PAPUA NEW GUINEA" },
    { value: "PY", label: "PARAGUAY" },
    { value: "PE", label: "PERU" },
    { value: "PH", label: "PHILIPPINES" },
    { value: "PN", label: "PITCAIRN" },
    { value: "PL", label: "POLAND" },
    { value: "PT", label: "PORTUGAL" },
    { value: "PR", label: "PUERTO RICO" },
    { value: "QA", label: "QATAR" },
    { value: "RE", label: "REUNION ISLANDS" },
    { value: "RO", label: "ROMANIA" },
    { value: "RU", label: "RUSSIAN FEDERATION (RUSSIA)" },
    { value: "RW", label: "RWANDA" },
    { value: "PM", label: "SAINT PIERRE AND MIQUELON" },
    { value: "WS", label: "SAMOA" },
    { value: "ST", label: "SAO TOME / PRINCIPE" },
    { value: "SA", label: "SAUDI ARABIA" },
    { value: "SN", label: "SENEGAL" },
    { value: "RS", label: "SERBIA" },
    { value: "SC", label: "SEYCHELLES" },
    { value: "SL", label: "SIERRA LEONE" },
    { value: "SG", label: "SINGAPORE" },
    { value: "SK", label: "SLOVAKIA" },
    { value: "SI", label: "SLOVENIA" },
    { value: "SB", label: "SOLOMON ISLANDS" },
    { value: "SO", label: "SOMALIA" },
    { value: "XS", label: "SOMALILAND, REP OF (NORTH SOMALIA)" },
    { value: "ZA", label: "SOUTH AFRICA" },
    { value: "SH", label: "SOUTH AFRICA (ST HELENA)" },
    { value: "GS", label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
    { value: "SS", label: "SOUTH SUDAN" },
    { value: "ES", label: "SPAIN" },
    { value: "LK", label: "SRI LANKA" },
    { value: "XY", label: "ST BARTHELEMY" },
    { value: "XE", label: "ST EUSTATIUS (NETHERLANDS ANTILLES)" },
    { value: "KN", label: "ST KITTS AND NEVIS" },
    { value: "LC", label: "ST LUCIA" },
    { value: "XM", label: "ST MAARTEN (NETHERLANDS ANTILLES)" },
    { value: "VC", label: "ST VINCENT / GRENADINE" },
    { value: "SD", label: "SUDAN" },
    { value: "SR", label: "SURINAME (SURINAM)" },
    { value: "SJ", label: "SVALBARD AND JAN MAYEN" },
    { value: "SZ", label: "SWAZILAND" },
    { value: "SE", label: "SWEDEN" },
    { value: "CH", label: "SWITZERLAND" },
    { value: "SY", label: "SYRIA" },
    { value: "TW", label: "TAIWAN" },
    { value: "TJ", label: "TAJIKISTAN" },
    { value: "TZ", label: "TANZANIA, UNITED REPUBLIC OF" },
    { value: "TH", label: "THAILAND" },
    { value: "TG", label: "TOGO" },
    { value: "TK", label: "TOKELAU" },
    { value: "TO", label: "TONGA" },
    { value: "TT", label: "TRINIDAD / TOBAGO" },
    { value: "TN", label: "TUNISIA" },
    { value: "TR", label: "TURKEY" },
    { value: "TM", label: "TURKMENISTAN" },
    { value: "TC", label: "TURKS / CAICOS ISLANDS" },
    { value: "TV", label: "TUVALU" },
    { value: "UG", label: "UGANDA" },
    { value: "UA", label: "UKRAINE" },
    { value: "AE", label: "UNITED ARAB EMIRATES" },
    { value: "GB", label: "UNITED KINGDOM" },
    { value: "US", label: "UNITED STATES" },
    { value: "UM", label: "UNITED STATES MINOR OUTLYING ISLANDS" },
    { value: "UY", label: "URUGUAY" },
    { value: "UZ", label: "UZBEKISTAN" },
    { value: "VU", label: "VANUATU" },
    { value: "VE", label: "VENEZUELA" },
    { value: "VN", label: "VIETNAM" },
    { value: "VG", label: "VIRGIN ISLANDS (BRITISH)" },
    { value: "VI", label: "VIRGIN ISLANDS (USA)" },
    { value: "EH", label: "WESTERN SAHARA" },
    { value: "YE", label: "YEMEN" },
    { value: "ZM", label: "ZAMBIA" },
    { value: "ZW", label: "ZIMBABWE" },
  ];
  const [selectedCustomPackage,setSelectedCustomPackage] = useState(null)
  const [defaultSenderState, setDefaultSenderState] = useState(null);
  const [receiverDetails, setReceiverDetails] = useState(false);
  const [exportComplianceStatement, setExportCompilanceStatement] =
    useState(null);
  const [dimension, setDimension] = useState({});
  const [senderName, setSenderName] = useState(null);
  const [receiverName, setReceiverName] = useState(null);
  const [totalCharges, setTotalCharges] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [serviceCharges, setServiceCharges] = useState(0);
  const [receiverCompany, setReceiverCompany] = useState(null);
  const [senderCompany, setSenderCompany] = useState(null);
  const [receiverEmail, setReceiverEmail] = useState(null);
  const [receiverPhone, setReceiverPhone] = useState(null);
  const [senderEmail, setSenderEmail] = useState(null);
  const [senderPhone, setSenderPhone] = useState(null);
  const [senderAddress1, setSenderAddress1] = useState(null);
  const [senderAddress2, setSenderAddress2] = useState(null);
  const [senderAddress3, setSenderAddress3] = useState(null);
  const [senderZipCode, setSenderZipCode] = useState(null);
  const [senderCity, setSenderCity] = useState(null);
  const [senderState, setSenderState] = useState(null);
  const [saturdayPickup, setSaturdayPickup] = useState(false);
  const [receiverZipCode, setReceiverZipCode] = useState(null);
  const [profitMargin, setProfitMargin] = useState(null)
  const [receiverCity, setReceiverCity] = useState(null);
  const [receiverState, setReceiverState] = useState(null);
  const [senderStateList, setSenderStateList] = useState([]);
  const [receiverAddress1, setReceiverAddress1] = useState(null);
  const [receiverAddress2, setReceiverAddress2] = useState(null);
  const [receiverAddress3, setReceiverAddress3] = useState(null);
  const [senderDetails, setSenderDetails] = useState(false);
  const [packageWeight, setPackageWeight] = useState({});
  const [recordImporterOption, setRecordImporterOption] = useState([
    { value: "receiver", label: "Receiver" },
  ]);
  const [pickupLocation, setPickupLocation] = useState("");
  const [originCountry, setOriginCountry] = useState(null);
  const [commercialInvoiceOunces, setCommercialInvoiceOunces] = useState(0);
  const [commercialInvoiceWeight, setCommercialInvoiceWeight] = useState(0);
  const [commodities, setCommodities] = useState([]);
  const [commoditiesOption, setCommoditiesOption] = useState([]);
  const [shipErrArr, setShipErrArr] = useState([]);
  const invoiceTypeOptions = [
    { value: "commercial", label: "Commercial" },
    { value: "proforma", label: "Proforma" },
  ];
  const paperlessOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
  ];
  const [recordImporter, setRecordImporter] = useState({
    value: "receiver",
    label: "Receiver",
  });
  const [paperless, setPaperless] = useState(paperlessOptions[0]);
  const [duatiablePartyOption, setDutiablePartyOptions] = useState([
    { value: "receiver", label: "Receiver" },
  ]);
  const [notificationToast, setNotificationToast] = useState(false);
  const [dutiableCountry, setDutiableCountry] = useState(null);
  const [billingParty, setBillingParty] = useState({
    value: "sender",
    label: "Sender",
  });
  const [csvModel, setShowCsvModal] = useState(false);
  const [dutiableParty, setDutiableParty] = useState({
    value: "receiver",
    label: "Receiver",
  });
  const [invoiceType, setInvoiceType] = useState({
    value: "commercial",
    label: "Commercial",
  });
  const [addId, setAddId] = useState(null);
  const [commercialInvoice, setCommercialInvoice] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [deliverToAddress, setDeliverToAddress] = useState(false);
  const [containAlcohol, setContainAlcohol] = useState(false);
  const [customeAmount, setCustomeAmount] = useState(null);
  const [transpotationalCharges, setTranspotationalCharges] = useState(0);
  const [isResidential, setIsResidential] = useState(false);
  const [totalCommercialInvoiceWeight, setTotalCommercialInvoiceWeight] =
    useState(0);
  const [dutiableAccountZip, setDutiableAccountZip] = useState(null);
  const [returnService, setReturnService] = useState(false);
  const [dutiableAccount, setDutiableAccount] = useState(null);
  const [ttlOunces, setTtlOunces] = useState(0);
  const [commodityFormGroups, setCommodityFormGroup] = useState([]);

  const [hts, setHts] = useState(null);
  const [chargeAmount, setChargeAmount] = useState("");

  const [packageReleaseCode, setPackageReleaseCode] = useState(null);
  const [upsLocation, setUpsLocation] = useState(null);
  const [shipperTIN, setShipperTIN] = useState(null);
  const [contentDescription, setContentDescription] = useState("");
  const [shipperTINType, setShipperTINType] = useState({});
  const [totalCustomeAmount, setTotalCustomeAmount] = useState(0);
  const [totalOuncesGrams, setTotalOuncesGrams] = useState(0);
  const [ttlWeight, setTtlWeight] = useState(0);
  const [ouncesGrams, setOuncesGrams] = useState(null);
  const [selectedInsurance, setInsurance] = useState(insuranceOptions[0]);
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [isVisibleLength, setIsVisibleLength] = useState(true);
  const [grams, setGrams] = useState(null);
  const [packageOfService, setPackageOfService] = useState(null);
  const [savedShip,setSavedShip] = useState([])
  const [isVisibleHeight, setIsVisibleHeight] = useState(true);
  const [isVisibleWidth, setIsVisibleWidth] = useState(true);
  const [selectedUnit, setUnit] = useState(options[0]);
  const [ttlQty, setTtlQty] = useState(null);
  const [shipLength, setShipLength] = useState(null);
  const [formGroups, setFormGroups] = useState([]);
  const [shipWidth, setShipWidth] = useState(null);
  const [shipHeight, setShipHeight] = useState(null);
  const [carbonNeutral, setCarbonNeutral] = useState(false);
  const [shipArr, setShipArr] = useState([]);
  const [shipWeight, setShipWeight] = useState(0);
  const [additionlDelivery, setAdditionalDelivery] = useState(false);
  const [customerPackageOptions, setCustomerPackageOptions] = useState([]);
  const [selectedPhysicalPackingType, setSelectedPhysicalPackingType] =
    useState({});
  const [nonStandardContainer, setNonStandardContainer] = useState(false);
  const [customePackageDetails, setCustomPackageDetails] = useState([]);
  const [bulkShipData, setBulkShipData] = useState([]);
  const [validationError, setValidationError] = useState([]);
  const [shipData, setShipData] = useState([]);
  const [indexObject, setIndexObject] = useState(null);
  const [firstKey, setFirstKey] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [csvIntegrationData, setCsvIntegrationData] = useState([]);
  const [error, setError] = useState(null);
  const [upsService, setUpsService] = useState([
    {
      value: "01",
      code: "01",
      label: "UPS Next Day Air®",
      Description: "UPS Next Day Air®",
    },
    {
      value: "02",
      label: "UPS 2nd Day Air®",
      code: "02",
      Description: "UPS 2nd Day Air®",
    },
    {
      value: "03",
      label: "UPS® Ground",
      code: "03",
      Description: "UPS® Ground",
    },
    {
      value: "14",
      label: "UPS Next Day Air® Early",
      code: "14",
      Description: "UPS Next Day Air® Early",
    },
    {
      value: "13",
      label: "UPS Next Day Air Saver®",
      code: "13",
      Description: "UPS Next Day Air Saver®",
    },
    {
      value: "59",
      label: "UPS 2nd Day Air A.M.®",
      code: "59",
      Description: "UPS 2nd Day Air A.M.®",
    },
    {
      value: "12",
      label: "UPS 3 Day Select",
      code: "12",
      Description: "UPS 3 Day Select",
    },
    {
      value: "FEDEX_INTERNATIONAL_PRIORITY",
      label: "FedEx International Priority®",
      code: "FEDEX_INTERNATIONAL_PRIORITY",
      Description: "FedEx International Priority®",
    },
    {
      value: "INTERNATIONAL_ECONOMY",
      label: "FedEx International Economy®",
      code: "INTERNATIONAL_ECONOMY",
      Description: "FedEx International Economy®",
    },
  ]);
  const [contentType, setContentType] = useState(null);
  const ftrCodeOptions = [
    { value: "30.37(a)", label: "30.37(a)" },
    { value: "30.36", label: "30,36" },
    { value: "30.37(b)", label: "30.37(b)" },
    { value: "30.37(f)", label: "30.37(f)" },
    { value: "30.37(g)", label: "30.37(g)" },
    { value: "30.37(h)", label: "30.37(h)" },
    { value: "30.37(i)", label: "30.37(i)" },
    { value: "30.37(j)", label: "30.37(j)" },
    { value: "30.37(k)", label: "30.37(k)" },
    { value: "30.37(o)", label: "30.37(o)" },
    { value: "30.37(q)", label: "30.37(q)" },
    { value: "30.37(r)", label: "30.37(r)" },
    { value: "30.37(s)", label: "30.37(s)" },
    { value: "30.37(t)", label: "30.37(t)" },
    { value: "30.37(u)", label: "30.37(u)" },
    { value: "30.37(w)", label: "30.37(w)" },
    { value: "30.37(x)", label: "30.37(x)" },
    { value: "30.37(y)", label: "30.37(y)" },
    { value: "30.39", label: "30,39" },
    { value: "30.40(a)", label: "30.40(a)" },
    { value: "30.40(b)", label: "30.40(b)" },
    { value: "30.40(c)", label: "30.40(c)" },
    { value: "30.40(d)", label: "30.40(d)" },
    { value: "30.2(d)(2)", label: "30.2(d)(2)" },
  ];
  const shipperTINTypeOptions = [
    { value: "BUSINESS_NATIONAL", label: "Business: National" },
    { value: "BUSINESS_STATE", label: "Business: State" },
    { value: "BUSINESS_UNION", label: "Business: Union" },
    { value: "PERSONAL_NATIONAL", label: "Personal: National" },
    { value: "PERSONAL_STATE", label: "Personal: State" },
  ];
  const physicalPackagingTypeOptions = [
    { value: "", label: "N/A" },
    { value: "BAG", label: "Bag" },
    { value: "BARREL", label: "Barrel" },
    { value: "BASKET", label: "Basket" },
    { value: "BOX", label: "Box" },
    { value: "BUCKET", label: "Bucket" },
    { value: "BUNDLE", label: "Bundle" },
    { value: "CARTON", label: "Carton" },
    { value: "CASE", label: "Case" },
    { value: "CONTAINER", label: "Container" },
    { value: "CRATE", label: "Crate" },
    { value: "CYLINDER", label: "Cylinder" },
    { value: "DRUM", label: "Drum" },
    { value: "ENVELOPE", label: "Envelope" },
    { value: "HAMPER", label: "Hamper" },
    { value: "OTHER", label: "Other" },
    { value: "PAIL", label: "Pail" },
    { value: "PALLET", label: "Pallet" },
    { value: "PIECE", label: "Piece" },
    { value: "REEL", label: "Reel" },
    { value: "ROLL", label: "Roll" },
    { value: "SKID", label: "Skid" },
    { value: "TANK", label: "Tank" },
    { value: "TUBE", label: "Tube" },
  ];
  const [contentTypeOptions, setContentTypeOptions] = useState([
    { value: "goods", label: "Goods" },
    { value: "document", label: "Document" },
  ]);
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  useEffect(() => {
    if (packageOfService?.Description == "UPS Express Box") {
      setShipWidth(12.5);
      setShipLength(18);
      setShipHeight(3.75);
      setTtlQty(1);
      setTtlWeight(0);
      setGrams(0);
    } else if (packageOfService?.Description == "UPS Tube") {
      setShipWidth(6);
      setShipLength(38);
      setShipHeight(6);
    } else if (packageOfService?.Description == "UPS Large Box") {
      setShipWidth(13);
      setShipLength(18);
      setShipHeight(3);
    } else if (packageOfService?.Description == "UPS Letter") {
      setShipWeight(0.5);
      setIsVisibleHeight(false);
      setIsVisibleLength(false);
      setIsVisibleWidth(false);
      setIsVisiblePlus(false);
    } else if (packageOfService?.Description == "UPS Small Box") {
      setShipWidth(11);
      setShipLength(13);
      setShipHeight(2);
    } else if (packageOfService?.Description == "UPS Pak") {
      setShipWidth(12.75);
      setShipLength(16);
      setShipHeight(1);
    } else if (packageOfService?.Description == "UPS Medium Box") {
      setShipWidth(11);
      setShipLength(16);
      setShipHeight(3);
    } else if (packageOfService?.Description == "FedEx® Small Box") {
      setShipWidth(10.875);
      setShipLength(12.375);
      setShipHeight(1.5);
    } else if (packageOfService?.Description == "FedEx® Envelope") {
      setIsHidelbh(true);
    } else if (packageOfService?.Description == "FedEx® Large Pak") {
      setShipWidth(15.5);
      setShipLength(12);
      setShipHeight(1);
    } else if (packageOfService?.Description == "FedEx® Medium Box") {
      setShipWidth(13.25);
      setShipLength(11.5);
      setShipHeight(2.375);
    } else if (packageOfService?.Description == "FedEx® Tube") {
      setShipWidth(38);
      setShipLength(6);
      setShipHeight(6);
    } else if (packageOfService?.Description == "FedEx® Padded Pak") {
      setShipWidth(14.75);
      setShipLength(11.75);
      setShipHeight(1);
    } else if (packageOfService?.Description == "FedEx® Large Box") {
      setShipWidth(12.375);
      setShipLength(17.5);
      setShipHeight(3);
    } else if (packageOfService?.Description == "FedEx® Pak") {
      setShipWidth(12);
      setShipLength(15.5);
      setShipHeight(1);
    } else if (packageOfService?.Description == "Your Packaging") {
      setShipWidth(15.5);
      setShipLength(12);
      setShipHeight(1);
    } else if (packageOfService?.Description == "FedEx® Box") {
      setShipWidth(13.25);
      setShipLength(11.5);
      setShipHeight(2.375);
    } else if (packageOfService?.Description == "FedEx® Small Pak") {
      setShipWidth(10.25);
      setShipLength(12.75);
      setShipHeight(1);
    }
    prevPackageOfServiceRef.current = packageOfService;
  }, [packageOfService]);
  useEffect(() => {
    if (selectedUnit?.value == 1) {
      const data = {
        dimensionCode: "IN",
        dimensionDescription: "Inches",
        ...(shipLength && { length: shipLength }),
        ...(shipWidth && { width: shipWidth }),
        ...(shipHeight && { height: shipHeight }),
      };
      setDimension(data);
      const shipmentWeight = {
        code: "LBS",
        description: "Pounds",
        ...(shipWeight && { weight: shipWeight }),
      };
      setPackageWeight(shipmentWeight);
    } else if (selectedUnit?.value == 2) {
      const data = {
        dimensionCode: "CM",
        dimensionDescription: "Centimeters",
        ...(shipLength && { length: shipLength }),
        ...(shipWidth && { width: shipWidth }),
        ...(shipHeight && { height: shipHeight }),
      };
      setDimension(data);
      const shipmentWeight = {
        code: "KGS",
        description: "Kilograms",
        ...(shipWeight && { weight: shipWeight }),
      };
      setPackageWeight(shipmentWeight);
    }
  }, [selectedUnit, shipWidth, shipHeight, shipLength, shipWeight]);

  const [signatureOption, setSignatureOption] = useState([
    { value: "nodeliveryconfirmation", label: "No Delivery Confirmation" },
    {
      value: "deliveryconfiration",
      label: "Delivery Confirmation(No Signature)",
    },
    { value: "signaturerequired", label: "Signature Required" },
    { value: "adultsignture", label: "Adlut Signature Required" },
  ]);
  const [ftrCode, setFtrCode] = useState(null);
  const [selectedToggleOption, setSelectedToggleOption] = useState("FTR");
  const [signature, setSignature] = useState(signatureOption[0]);
  const [shipError, setShipError] = useState([]);
  const [service, setService] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [taxIdNumber, setTaxIdNumber] = useState(null);
  const [billingAccountZip, setBillingAccountZip] = useState(null);
  const [senderCountry, setSenderCountry] = useState({
    value: "US",
    label: "UNITED STATES",
  });
  const [billingPartyCountry, setBillingPartyCountry] = useState({
    value: "AF",
    label: "AFGHANISTAN",
  });
  const [itnNumber, setItnNumber] = useState(null);
  const [itnToggleOption, setItnToggleOption] = useState("EIN");
  const [receiverCountry, setReceiverCountry] = useState({
    value: "CA",
    label: "CANADA",
  });

  const [serviceType, setServiceType] = useState("");
  const [isVisiblePlus, setIsVisiblePlus] = useState(true);
  useEffect(() => {
    if (senderCountry?.value == "US" && receiverCountry?.value == "US") {
      setUpsService([
        {
          value: "01",
          label: "UPS Next Day Air®",
          code: "01",
          Description: "UPS Next Day Air®",
        },
        {
          value: "02",
          label: "UPS 2nd Day Air®",
          code: "02",
          Description: "UPS 2nd Day Air®",
        },
        {
          value: "03",
          label: "UPS® Ground",
          code: "03",
          Description: "UPS® Ground",
        },
        {
          value: "14",
          label: "UPS Next Day Air® Early",
          code: "14",
          Description: "UPS Next Day Air® Early",
        },
        {
          value: "13",
          label: "UPS Next Day Air Saver®",
          code: "13",
          Description: "UPS Next Day Air Saver®",
        },
        {
          value: "59",
          label: "UPS 2nd Day Air A.M.®",
          code: "59",
          Description: "UPS 2nd Day Air A.M.®",
        },
        {
          value: "12",
          label: "UPS 3 Day Select®",
          code: "12",
          Description: "UPS 3 Day Select®",
        },
        {
          value: "PRIORITY_OVERNIGHT",
          label: "FedEx Priority Overnight®",
          code: "PRIORITY_OVERNIGHT",
          Description: "FedEx Priority Overnight®",
        },

        {
          value: "FIRST_OVERNIGHT",
          label: "FedEx First Overnight®",
          code: "FIRST_OVERNIGHT",
          Description: "FedEx First Overnight®",
        },

        {
          value: "STANDARD_OVERNIGHT",
          label: "FedEx Standard Overnight®",
          code: "STANDARD_OVERNIGHT",
          Description: "FedEx Standard Overnight®",
        },
        {
          value: "FEDEX_2_DAY",
          label: "FedEx 2Day®",
          code: "FEDEX_2_DAY",
          Description: "FedEx 2Day®",
        },
        // { value: '', label: "FedEx 2Day®" },
        {
          value: "FEDEX_GROUND",
          label: "FedEx Ground®",
          code: "FEDEX_2_DAY",
          Description: "FedEx 2Day®",
        },
        {
          value: "GROUND_HOME_DELIVERY",
          label: "FedEx Home Delivery®",
          code: "GROUND_HOME_DELIVERY",
          Description: "FedEx Home Delivery®",
        },
      ]);
    } else if (senderCountry?.value == "US" && receiverCountry?.value == "AS") {
      setUpsService([
        { value: "11", label: "UPS® Standard" },
        { value: "07", label: "UPS Worldwide Express®" },
        { value: "54", label: "UPS Worldwide Express Plus®" },
        { value: "08", label: "UPS Worldwide Expedited®" },

        { value: "65", label: "UPS Worldwide Saver®" },
        { value: "01", label: "UPS Next Day Air®" },
        { value: "02", label: "UPS 2nd Day Air®" },
        { value: "03", label: "UPS® Ground" },
        { value: "14", label: "UPS Next Day Air® Early" },
        { value: "13", label: "UPS Next Day Air Saver®" },
        { value: "59", label: "UPS 2nd Day Air A.M.®" },
        { value: "12", label: "UPS 3 Day Select" },
        { value: "FEDEX_GROUND", label: "FedEx International Ground®" },
        { value: "PRIORITY_OVERNIGHT", label: "FedEx Priority Overnight®" },

        { value: "FIRST_OVERNIGHT", label: "FedEx First Overnight®" },

        { value: "STANDARD_OVERNIGHT", label: "FedEx Standard Overnight®" },
        { value: "FEDEX_2_DAY", label: "FedEx 2Day®" },
        { value: "FEDEX_2_DAY", label: "FedEx 2Day®" },
        { value: "FEDEX_GROUND", label: "FedEx Ground®" },
        { value: "GROUND_HOME_DELIVERY", label: "FedEx Home Delivery®" },
      ]);
    } else if (senderCountry?.value == "US" && receiverCountry?.value == "CA") {
      setUpsService([
        {
          value: "11",
          label: "UPS® Standard",
          code: "11",
          Description: "UPS® Standard",
        },
        {
          value: "07",
          label: "UPS Worldwide Express®",
          code: "07",
          Description: "UPS Worldwide Express®",
        },
        {
          value: "54",
          label: "UPS Worldwide Express Plus®",
          code: "54",
          Description: "UPS Worldwide Express Plus®",
        },
        {
          value: "08",
          label: "UPS Worldwide Expedited®",
          code: "08",
          Description: "UPS Worldwide Expedited®",
        },
        {
          value: "65",
          label: "UPS Worldwide Saver®",
          code: "65",
          Description: "UPS Worldwide Saver®",
        },
        {
          value: "FEDEX_INTERNATIONAL_PRIORITY",
          label: "FedEx International Priority®",
          code: "FEDEX_INTERNATIONAL_PRIORITY",
          Description: "FedEx International Priority®",
        },
        {
          value: "INTERNATIONAL_ECONOMY",
          label: "FedEx International Economy®",
          code: "INTERNATIONAL_ECONOMY",
          Description: "FedEx International Economy®",
        },
        {
          value: "FEDEX_GROUND",
          label: "FedEx International Ground®",
          code: "FEDEX_GROUND",
          Description: "FedEx International Ground®",
        },
      ]);
    } else if (senderCountry?.value == "US" && receiverCountry?.value == "PR") {
      setUpsService([
        { value: "11", label: "UPS® Standard" },
        { value: "07", label: "UPS Worldwide Express®" },
        { value: "54", label: "UPS Worldwide Express Plus®" },
        { value: "08", label: "UPS Worldwide Expedited®" },
        { value: "65", label: "UPS Worldwide Saver®" },
        { value: "01", label: "UPS Next Day Air®" },
        { value: "02", label: "UPS 2nd Day Air®" },
        { value: "03", label: "UPS® Ground" },
        { value: "14", label: "UPS Next Day Air® Early" },
        { value: "13", label: "UPS Next Day Air Saver®" },
        { value: "59", label: "UPS 2nd Day Air A.M.®" },
        { value: "12", label: "UPS 3 Day Select" },
        {
          value: "FEDEX_INTERNATIONAL_PRIORITY",
          label: "FedEx International Priority®",
        },
        {
          value: "INTERNATIONAL_ECONOMY",
          label: "FedEx International Economy®",
        },
        { value: "FEDEX_GROUND", label: "FedEx International Ground®" },
        { value: "PRIORITY_OVERNIGHT", label: "FedEx Priority Overnight®" },
        { value: "FIRST_OVERNIGHT", label: "FedEx First Overnight®" },
        { value: "STANDARD_OVERNIGHT", label: "FedEx Standard Overnight®" },
        { value: "FEDEX_2_DAY", label: "FedEx 2Day®" },
        { value: "FEDEX_EXPRESS_SAVER", label: "FedEx Express Saver®" },
        { value: "FEDEX_GROUND", label: "FedEx Ground®" },
        { value: "GROUND_HOME_DELIVERY", label: "FedEx Home Delivery®" },
      ]);
    } else {
      setUpsService([
        {
          value: "01",
          code: "01",
          label: "UPS Next Day Air®",
          Description: "UPS Next Day Air®",
        },
        {
          value: "02",
          label: "UPS 2nd Day Air®",
          code: "02",
          Description: "UPS 2nd Day Air®",
        },
        {
          value: "03",
          label: "UPS® Ground",
          code: "03",
          Description: "UPS® Ground",
        },
        {
          value: "14",
          label: "UPS Next Day Air® Early",
          code: "14",
          Description: "UPS Next Day Air® Early",
        },
        {
          value: "13",
          label: "UPS Next Day Air Saver®",
          code: "13",
          Description: "UPS Next Day Air Saver®",
        },
        {
          value: "59",
          label: "UPS 2nd Day Air A.M.®",
          code: "59",
          Description: "UPS 2nd Day Air A.M.®",
        },
        {
          value: "12",
          label: "UPS 3 Day Select",
          code: "12",
          Description: "UPS 3 Day Select",
        },
        {
          value: "FEDEX_INTERNATIONAL_PRIORITY",
          label: "FedEx International Priority®",
          code: "FEDEX_INTERNATIONAL_PRIORITY",
          Description: "FedEx International Priority®",
        },
        {
          value: "INTERNATIONAL_ECONOMY",
          label: "FedEx International Economy®",
          code: "INTERNATIONAL_ECONOMY",
          Description: "FedEx International Economy®",
        },
      ]);
    }
  }, [senderCountry, receiverCountry]);
  useEffect(() => {
    if(selectedCustomPackage) {
        const getData = customerPackageOptions.find(ele => ele?.package == selectedCustomPackage.value);
        if(selectedCustomPackage.value == 21) {
            const getDetails = customePackageDetails.find(ele => ele.packageType == 21)    
            if(getDetails){
                setShipLength(getDetails?.length)
                setShipWidth(getDetails?.width)
                setShipHeight(getDetails?.height)
                setShipWeight(getDetails?.weight)
            }   
         }
        if(getData?.length) {
            setShipLength(getData?.length)
        }
        if(getData?.width) {
            setShipWidth(getData?.width)
        }
        if(getData?.height) {
            setShipHeight(getData?.height)
        }
        if(getData?.weight) {
            setShipWeight(getData?.weight)
        }
    }
},[selectedCustomPackage,customerPackageOptions])
  const handleCloseCsvModal = () => {
    setShowCsvModal(false);
  };
  const termsOfTradeOptions = [
    { value: "DAP", label: "DAP - Delivered at Place" },
    { value: "CIP", label: "CIP - Costs, Insurance Paid" },
    { value: "CPT", label: "CPT - Carriage Paid To" },
    { value: "DAT", label: "DAT - Delivered at Terminal" },
    { value: "DDP", label: "DDP - Delivered Duty Paid" },
    { value: "EXW", label: "EXW - Ex Works" },
    { value: "FCA", label: "FCA - Free Carrier" },
  ];
  const batteriesOptions = [
    { value: "none", label: "None" },
    { value: "lithiumion", label: "Lithium ion" },
    { value: "lithium metal", label: "Lithium metal" },
  ];
  const [isVisibleCommodityPlus, setIsVisibleCommodityPlus] = useState(true);
  const [selectedBatteries, setSelectedBatteries] = useState(
    batteriesOptions[0]
  );

  const [termsOfTrade, setTermsOfTrade] = useState({
    value: "DAP",
    label: "DAP - Delivered at Place",
  });
  const senderCountryOptions = [{ value: "US", label: "UNITED STATES" }];
  const [upsPackages, setUpsPackages] = useState([
    { value: "21", label: "UPS Express Box" },
    { value: "03", label: "UPS Tube" },
    { value: "2c", label: "UPS Large Box" },
    { value: "01", label: "UPS Letter" },
    { value: "2a", label: "UPS Small Box" },
    { value: "02", label: "Customer Supplied Package" },
    { value: "04", label: "UPS Pak" },
    { value: "2b", label: "UPS Medium Box" },
  ]);

  useEffect(() => {
    if (
      service?.code == "FEDEX_INTERNATIONAL_PRIORITY" ||
      service?.code == "INTERNATIONAL_ECONOMY" ||
      service?.code == "FEDEX_GROUND" ||
      service?.code == "FIRST_OVERNIGHT" ||
      service?.code == "PRIORITY_OVERNIGHT" ||
      service?.code == "FEDEX_2_DAY" ||
      service?.code == "FEDEX_EXPRESS_SAVER" ||
      service?.code == "GROUND_HOME_DELIVERY"
    ) {
      const packagesData = [
        {
          code: "YOUR_PACKAGING",
          Description: "Your Packaging",
          value: "YOUR_PACKAGING",
          label: "Your Packaging",
        },
        {
          code: "FEDEX_SMALL_BOX",
          Description: "FedEx® Small Box",
          value: "FEDEX_SMALL_BOX",
          label: "FedEx® Small Box",
        },
        {
          code: "",
          Description: "FedEx® Envelope",
          label: "",
          Description: "FedEx® Envelope",
        },
        {
          code: "",
          Description: "FedEx® Large Pak",
          value: "",
          label: "FedEx® Large Pak",
        },
        {
          code: "FEDEX_MEDIUM_BOX",
          Description: "FedEx® Medium Box",
          value: "FEDEX_MEDIUM_BOX",
          label: "FedEx® Medium Box",
        },
        {
          code: "FEDEX_TUBE",
          Description: "FedEx® Tube",
          value: "FEDEX_TUBE",
          label: "FedEx® Tube",
        },
        {
          code: "",
          Description: "FedEx® Padded Pak",
          value: "",
          label: "FedEx® Padded Pak",
        },
        {
          code: "FEDEX_LARGE_BOX",
          Description: "FedEx® Large Box",
          value: "FEDEX_LARGE_BOX",
          label: "FedEx® Large Box",
        },
        {
          code: "FEDEX_PAK",
          Description: "FedEx® Pak",
          label: "FEDEX_PAK",
          Description: "FedEx® Pak",
        },
        // { code: "YOUR_PACKAGING", Description: "Your Packaging" ,label: "YOUR_PACKAGING", Description: "Your Packaging" },
        {
          code: "FEDEX_BOX",
          Description: "FedEx® Box",
          value: "FEDEX_BOX",
          label: "FedEx® Box",
        },
        {
          code: "",
          Description: "FedEx® Small Pak",
          value: "",
          label: "FedEx® Small Pak",
        },
      ];
      setUpsPackages(packagesData);
      setServiceType("fedEx");
      getPackageData(packagesData);
      const contentTypeData = [
        { value: "Sold", label: "Merchandise" },
        { value: "Not Sold", label: "Documents" },
        { value: "Gift", label: "Gift" },
        { value: "Sample", label: "Sample" },
        { value: "Repair and Return", label: "Repair and Return" },
        { value: "Personal Effects", label: "Personal Effects" },
      ];
      setContentTypeOptions(contentTypeData);
      const signtureOpt = [
        { value: "NO_SIGNATURE_REQUIRED", label: "No Signature Required" },
        { value: "ADULT", label: "Adult Signature Required" },
        { value: "DIRECT", label: "Direct Signature Required" },
        { value: "SERVICE_DEFAULT", label: "Service Default" },
      ];
      setSignatureOption(signtureOpt);
    } else {
      setServiceType("");
      const upsData = [
        {
          code: "02",
          Description: "Customer Supplied Package",
          value: "02",
          label: "Customer Supplied Package",
        },
        {
          code: "21",
          value: "21",
          Description: "UPS Express Box",
          label: "UPS Express Box",
        },
        { code: "03", value: "03", Description: "UPS Tube", label: "UPS Tube" },
        {
          code: "2c",
          value: "2c",
          Description: "UPS Large Box",
          label: "UPS Large Box",
        },
        {
          code: "01",
          value: "01",
          Description: "UPS Letter",
          label: "UPS Letter",
        },
        {
          code: "2a",
          value: "2a",
          Description: "UPS Small Box",
          label: "UPS Small Box",
        },
        { code: "04", value: "04", Description: "UPS Pak", label: "UPS Pak" },
        {
          code: "2b",
          value: "2b",
          Description: "UPS Medium Box",
          label: "UPS Medium Box",
        },
      ];
      setUpsPackages(upsData);

      getPackageData(upsData);
      const contentTypeData = [
        { value: "goods", label: "Goods" },
        { value: "document", label: "Document" },
      ];
      setContentTypeOptions(contentTypeData);
      const signatureOpt = [
        { value: "nodeliveryconfirmation", label: "No Delivery Confirmation" },
        {
          value: "deliveryconfiration",
          label: "Delivery Confirmation(No Signature)",
        },
        { value: "signaturerequired", label: "Signature Required" },
        { value: "adultsignture", label: "Adlut Signature Required" },
      ];
      setSignatureOption(signatureOpt);
    }
  }, [service]);
  const saveResponseOfShipment = async (shipId, responseData, shipMentResult) => {
    console.log('saveResponseOfShipment call', shipId, responseData, shipMentResult)
    try {

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/ship-response`, {
            method: 'POST',
            headers: {

                'Authorization': `Bearer ${Cookies.get('token')}`, // Replace with your actual access token
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                shipId: shipId,
                response: responseData,
                shipResult: shipMentResult,


            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data); // Handle the response data as needed
    } catch (error) {
        console.error('There was an error fetching the data:', error);
    }
}
  const handleCertificateButtonClick = () => {
    document.getElementById("csvFileInput").click();
  };
  const createInvoice = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-bulk-invoice`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get("token")}`,
                // You may need to include additional headers such as authorization headers
            },
            body: JSON.stringify({
                ship: savedShip,
            }),
        });

        if (!response.ok) {
            // Handle the error, e.g., show an error message
            console.error(`HTTP error! Status: ${response.status}`);
            return;
        }

        const data = await response.json();
        console.log(data); // Log the response data
        if(data) {
            return data
        }
        // Handle success, e.g., update state or show a success message
    } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
        if (error) {
            setError('Network Error!')
        }
    }
  }
  const handleCertificateFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Define your metadata here
      const workflowName = "ETDPreshipment"; // or 'ETDPostshipment'
      const carrierCode = "FDXE"; // or 'FDXG', etc.
      const fileName = file.name;
      const contentType = file.type; // Automatically detect content type

      const meta = {
        // Add any other metadata required for the API here
        shipmentId: "12345",
        sender: "Company XYZ",
        receiver: "Company ABC",
      };

      const formData = new FormData();
      formData.append("attachment", file);
      formData.append(
        "document",
        JSON.stringify({
          workflowName,
          carrierCode,
          name: fileName,
          contentType,
          meta,
        })
      );

      // Replace with your API endpoint
      const apiEndpoint =
        "https://documentapitest.prod.fedex.com/sandbox/documents/v1/etds/upload";

      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log("Success:", data);
        // Handle success (e.g., show a success message, update the state, etc.)
      } catch (error) {
        console.error("Error:", error);
        // Handle error (e.g., show an error message)
      }
    }
  };

  const makePayment = async () => {
    const getresponse = await createInvoice()
    
    console.log(process.env.REACT_APP_STRIP_KEY_PUBLISH, "process.env.REACT_APP_STRIP_KEY_PUBLISH")

    const stripe = await loadStripe(process.env.REACT_APP_STRIP_KEY_PUBLISH)
    const body = {
        products: {
            procduct_name: 'Bullkship',
            price: profitMargin ? totalCharges?.MonetaryValue * (1 + profitMargin / 100) : totalCharges?.MonetaryValue
        },
        shipId: getresponse?._id,
        bulk:true

    }
    console.log(body, "products=>>>", totalCharges, profitMargin)

    let headersList = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Cookies.get('token')}`,
    }
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-checkout-payment`, {
        method: 'POST',
        headers: headersList,
        body: JSON.stringify(body)
    })
    const session = await res.json()

    const result = stripe.redirectToCheckout({
        sessionId: session.id
    })
}
const voidShip = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/void-ship`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // You may need to include additional headers such as authorization headers
            },
            body: JSON.stringify({
                trackingNumber: "trackingNumber",
            }),
        });

        if (!response.ok) {
            // Handle the error, e.g., show an error message
            console.error(`HTTP error! Status: ${response.status}`);
            return;
        }

        const data = await response.json();
        console.log(data); // Log the response data
        // Handle success, e.g., update state or show a success message
    } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
        if (error) {
            setError('Network Error!')
        }
    }

}
  const getSenderDetails = async () => {
    const headersList = {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-address`,
        {
          method: "GET", // Use "GET" method for sending parameters in URL
          headers: headersList,
        }
      );

      const data = await response.json();
      if (data.message == "jwt expired") {
        navigate("/login");
      }
      if (data) {
        console.log(data, "data===>>");
        const getCountry = senderCountryOptions.find(
          (ele) => ele?.value == data.country
        );
        setSenderDetails(true);

        setAddId(data._id);
        setSenderName(data.name);
        if (data.user.email) {
          setSenderEmail(data.user.email);
        } else {
          setSenderEmail(user.data.email);
        }
        if (data.user.companyName) {
          setSenderCompany(data.user.companyName);
        } else {
          setSenderCompany(user.data.companyName);
        }
        setSenderAddress1(data?.address1);
        if (data?.address2) {
          setSenderAddress2(data?.address2);
        }
        if (data?.address3) {
          setSenderAddress3(data?.address3);
        }
        setSenderCountry(getCountry);
        setDefaultSenderState(data.state);

        setSenderCity(data.city);
        const getState = statesData.find(ele => ele.value == data.state && data.country == ele.country_code)
        const stateObject = {
          value:getState.value,
          label:getState.label
        }
        setSenderState(stateObject);
        let senderZip = String(data.zip)
        setSenderZipCode(senderZip);
        setSenderPhone(data.phone);
      }

      // Handle the data or update the component state as needed
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors as needed
    }
  };
  const getPackageData = async (packageData) => {
    const headersList = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-all-package`,
        {
          method: "GET", // Use "GET" method for sending parameters in URL
          headers: headersList,
        }
      );

      const data = await response.json();
      if (data) {
        setCustomPackageDetails(data.data);
        if (data.data.length) {
          const arr = customerPackageOptions;

          const customeData = data.data.map((item) => ({
            value: item.packageType,
            label: item.package,
          }));
          const arrData = [
            {
              label: "Customer Provided Dimensions",
              value: "",
            },
            ...customeData,
          ];
          setCustomerPackageOptions(arrData);
          const updatedPackages = packageData?.concat(
            data.data.map((item) => ({
              code: item.packageType,
              Description: item.package,
            }))
          );

          // Assuming upspackages is your array of objects
          const uniquePackages = updatedPackages?.filter(
            (packageEle, index, self) =>
              index ===
              self.findIndex((p) => p.Description === packageEle.Description)
          );
          setUpsPackages(uniquePackages);
          // const packageData = upspackages.find(ele => Number(ele.packageType) == data.packageType)
          // setPackageOfService(packageData)
        }
      }

      // Handle the data or update the component state as needed
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors as needed
    }
  };
  // upsService.reduce((resultArray, item, index) => {
  //   const chunkIndex = Math.floor(index / numberOfColumns);

  //   if (!resultArray[chunkIndex]) {
  //       resultArray[chunkIndex] = []; // start a new chunk
  //   }

  //   resultArray[chunkIndex].push(item);

  //   return resultArray;
  // }, []);
  const updateSenderDetails = async () => {
    if (addId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-address/${addId}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // You may need to include additional headers such as authorization headers
            },
            body: JSON.stringify({
              type: "sender",
              name: senderName, // Replace with the actual field names from your form
              company: senderCompany,
              zipCode: senderZipCode,
              city: senderCity,
              state: senderState?.value,
              address1: senderAddress1,
              address2: senderAddress2,
              address3: senderAddress3,
              email: senderEmail,
              phone: senderPhone,
              country: senderCountry?.value, // Assuming senderCountry is an object with value and label properties
            }),
          }
        );

        if (!response.ok) {
          // Handle the error, e.g., show an error message
          console.error(`HTTP error! Status: ${response.status}`);
          return;
        }

        const data = await response.json();
        // Log the response data
        // Handle success, e.g., update state or show a success message
      } catch (error) {
        // Handle other errors, e.g., network error
        console.error(error);
      }
    } else {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-address`;
      const requestData = {
        type: "sender",
        country: senderCountry?.value,
        zip: senderZipCode,
        state: senderState.value,
        city: senderCity,
        address1: senderAddress1,
        address2: senderAddress2,
        address3: senderAddress3,
        user: Cookies.get("id"),
        phone: senderPhone,
        name: senderName,
      };

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":`Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error while calling API:", error);
        });
    }
  };
  useEffect(() => {
    if (senderCountry) {
      const Data = statesData.filter(
        (ele) => senderCountry?.value == ele.country_code
      );
      setSenderStateList(Data);
      setOriginCountry(senderCountry);
    }
  }, [senderCountry]);
  useEffect(() => {
    // Calculate total weight and quantity
    const totalWeight = formGroups.reduce(
      (sum, group) => sum + parseFloat(group.weight || 0),
      0
    );
    const totalOunces = formGroups.reduce(
      (sum, group) => sum + parseFloat(group.ouncesGrams || 0),
      0
    );
    const totalCustomeAmountData = formGroups.reduce(
      (sum, group) => sum + parseFloat(group.customeAmount || 0),
      0
    );

    setTotalOuncesGrams(totalOunces);
    setTtlWeight(totalWeight);
    setTotalCustomeAmount(totalCustomeAmountData);
    const quantity = formGroups.length + 1;
    setTtlQty(quantity);
  }, [formGroups]);
  const handleInputChange = (index, field, value) => {
    const newFormGroups = formGroups.map((group, i) =>
      i === index ? { ...group, [field]: value } : group
    );
    setFormGroups(newFormGroups);
  };
  const handleChangeToggle = (value) => {
    setSelectedToggleOption(value);
  };
  const handleCommercialInvoice = (e) => {
    setCommercialInvoice(e.target.checked);
    if (error) {
      setError(null);
    }
  };
  const handleRemoveFormGroup = (index) => {
    const newFormGroups = formGroups.filter((_, i) => i !== index);
    setFormGroups(newFormGroups);
  };
  const getCsvIntegration = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ecomm-csv`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCsvIntegrationData(data.data);
          // handleClose()
        })
        .catch((error) => {
          console.error("Error while calling API:", error);
        });
    } catch (err) {
      if (err?.status == 401) {
        navigate("/login");
      }
      console.log(err);
    }
  };
  const handleAddFormGroup = () => {
    setFormGroups([
      ...formGroups,
      {
        weight: 0,
        length: shipLength,
        width: shipWidth,
        height: shipHeight,
        ouncesGrams: "",
        insurance: { value: "general", label: "General" },
        customeAmount: 0,
      },
    ]);
  };

  async function createBulkShipApi(service, selectedPackage) {
    for (let i = 0; i < bulkShipData.length; i++) {
      bulkShipData[i].Service = {
        code: service.value,
        Description: service.label,
      };
    }
    // let currentShip = currentShipId ? currentShipId : shipId
    // let type = serviceTyp  e ? serviceType : serviceTypeName

    let headersList = {
      "Content-Type": "application/json",
    };
    // console.log(serviceType, "serviceType")
    const id = Cookies.get("id");

    // console.log(serviceType, "serviceType==>")
    // let bodyContent = JSON.stringify({
    //     "Description": "Ship WS test",
    //     "ShipTo": {
    //         "name": "Happy Dog Pet Supply",
    //         "number": "9225377171",
    //         "Address": {
    //             "AddressLine1": "123 Main St",
    //             "city": "timonium",
    //             "pincode": "21030",
    //             "CountryCode": "US"
    //         }
    //     },
    //     "ShipFrom": {
    //         "name": "T and T Designs",
    //         "number": "1234567890",
    //         "Address": {
    //             "AddressLine1": "2311 York Rd",
    //             "city": "Alpharetta",
    //             "pincode": "30005",
    //             "CountryCode": "US"
    //         }

    //     },
    //     "Service": {
    //         "code": "03",
    //         "Description": "Express"
    //     },
    //     "Package": {

    //         "packaging": {
    //             "code": "02",
    //             "Description": "Nails"
    //         },
    //         "Dimensions": {
    //             "dimensionCode": "IN",
    //             "dimensionDescription": "Inches",
    //             "length": "10",
    //             "width": "30",
    //             "height": "45"
    //         },
    //         "PackageWeight": {
    // "code": "LBS",
    // "description": "Pounds",
    // "Weight": "5"
    //         }
    //     },
    //     "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2VBN0p3QnBycEZMRWs1SFN0dzE2ZTNkeDVKSiIsIm5iZiI6MTcwNjU0NTE5NCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNjU1OTU5NCwiaWF0IjoxNzA2NTQ1MTk0LCJqdGkiOiI1MzZmOTI2YS1hYTg1LTQyODMtOThhOS01ZGQyYzYzZTczYzcifQ.KrVNj8zHAVAMfeDJc3CobmN2EKq5D61wxAiWJ31Ljf70sqPN1cpks0JLpUc2OIY8E_LLOMbckUxmZyNWWKL7iQCVHCXa_TNECxSTL8TOGQoxkwW5N4AN13xsywjhc0MqZH1g6JxYikQ65h7z0uXMiHrNadtW61n6RC2Dfx4XDLfkAmiGk4sV5G8TVvz7QJV1dQC6BTjWblRg52a0PzdUrYqeXtcXo-txqDEIOLaBk8lFJUQ2yirLtdam57KetnnyWHEzKl_p5KjG92DuRvJQ_xlXuBEPLZ8YHvnteMjKld-zRqY7weGt7HhjGxhK0VzHbK9kXPh5L_tbBIKpcajRqy9dRYWdyo1LzUQptQR_MTUe-8SVTHRQ3xezcyGENizWYhKygWQyZTno3T8zh0KawR9s2mAhT8j_ZT3mL0IBatOUqpRzytBDe93j1sR4hSU9IpRBJPKpUUK98I_b8tSS5HieDa_fgAcLYf020qzX2wJT6Iua-s2HL7hqW635E-XqBZh3Kmwuau98PwlwlCpim_QkGOYBiYN5z5amRaflDScJRi4UeON4-eHG6e5EGTL4VPDwnFiJSpAqNmOV5Xd0L5IAtZ5Tlne1_TQodOBjros81D6lGf0HKER4RzkZj4zDlhf7TSWJfrmL2zmnoDgyobSCY4V0HaYhLzwHuvz6S1k"

    // });
    let endpoint = "create-ship";
    // if (serviceType == 'fedEx') {
    //     endpoint = 'create-fedex-ship'
    // }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${endpoint}`,
        {
          headers: headersList,
        }
      );

      setShipResult(response?.data?.data?.ShipmentResponse);
      setServiceCharges(
        response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges
          .ServiceOptionsCharges
      );
      setTranspotationalCharges(
        response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges
          .TransportationCharges
      );

      setTotalCharges(
        response?.data?.data?.ShipmentResponse?.ShipmentResults.ShipmentCharges
          .TotalCharges
      );
      // console.log(serviceType, "serviceType=>")
      if (serviceType == 'fedEx') {
          // console.log('call fedex save ', response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount)
          // setShipResult(response?.data?.data?.output)
          // const charges = {
          //     CurrencyCode: "USD",
          //     MonetaryValue: response?.data?.data?.output.transactionShipments[0].pieceResponses[0].netRateAmount
          // }
          // setTotalCharges(charges)
          // console.log(currentShip, "currentShipId")
          // await saveFedExResponseOfShipment(currentShip, response?.data?.data?.transactionId, response?.data?.data?.output)
      } else {
          console.log('call upi save ')
          await saveResponseOfShipment(currentShip, response?.data?.data?.ShipmentResponse?.Response, response?.data?.data?.ShipmentResponse?.ShipmentResults)
      }
    } catch (err) {
      if (err?.status == 401) {
        navigate("/login");
      }
      if (err instanceof Error) {
        const errArr = await err?.response?.data?.err?.map(
          (error) => error.message
        );
        // Assuming err.message contains the error messages from the server
        setShipError(errArr);
      } else {
        // If it's a network error or other error not related to server response
        setValidationError((prevErrors) => [
          ...prevErrors,
          "An error occurred while processing your request.",
        ]);
      }
    }
  }
  function handleUploadCsvButtonClick() {
    document.getElementById("csvFileInput").click();
  }
  const getAdminData = async () => {
    const headersList = {
        "Content-Type": "application/json"
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/get-admin`, {
            method: "GET", // Use "GET" method for sending parameters in URL
            headers: headersList
        });

        const data = await response.json();
        console.log(data, "data of sender users");
        if (data) {
            if (data.profitMargin) {
                setProfitMargin(data.profitMargin)
            }
        }

        // Handle the data or update the component state as needed
    } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors as needed
    }
}
  useEffect(() => {
    getCsvIntegration();
    getPackageData();
    getSenderDetails();
    getAdminData()
  }, []);
  function handleFileSelect(event, csvIntegration) {
    setShowCsvModal(false);

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const fileContent = event.target.result;

        // Function to parse CSV correctly handling quoted values with commas
        function parseCSV(text) {
          const lines = text.split("\n");
          const result = [];

          // Function to correctly parse a CSV line handling quoted values with commas
          function parseLine(line) {
            const values = [];
            let inQuotes = false;
            let value = "";

            for (let char of line) {
              if (char === '"') {
                inQuotes = !inQuotes;
              } else if (char === "," && !inQuotes) {
                values.push(value);
                value = "";
              } else {
                value += char;
              }
            }
            values.push(value); // add the last value
            return values;
          }

          const headers = parseLine(lines[0]).map((header) =>
            header.replace(/"/g, "").trim().toLowerCase()
          );

          for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentline = parseLine(lines[i]);

            for (let j = 0; j < headers.length; j++) {
              obj[headers[j]] = currentline[j]
                ? currentline[j].replace(/"/g, "").trim()
                : "";
            }
            result.push(obj);
          }
          return result;
        }

        const dataArray = parseCSV(fileContent);
        console.log(dataArray, "dataArray==>>");

        setFileData(dataArray);

        const numberOfColumns = dataArray[0]
          ? Object.keys(dataArray[0]).length
          : 0;

        if (numberOfColumns < csvIntegration?.columns?.length) {
          setError(`column is missing!`);
        } else {
          let data = {};

          const findIndexNumber = (array, colValue) => {
            const item = array.find((item) => item.col === colValue);
            if (item) {
              let index = 0;
              for (let i = 0; i < item.index.length; i++) {
                index = index * 26 + (item.index.charCodeAt(i) - 64); // 'A' is 1, 'B' is 2, etc.
              }
              return index - 1; // Return zero-based index
            }
            return -1; // Return -1 if the colValue is not found
          };

          if (
            selectedIntegration?.columns.some((item) => item.col === "empty")
          ) {
          }

          if (selectedIntegration?.columns.some((item) => item.col === "id")) {
            data.Description = findIndexNumber(
              selectedIntegration.columns,
              "id"
            );
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "created")
          ) {
            data.Date = findIndexNumber(selectedIntegration.columns, "created");
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "shippingService"
            )
          ) {
            data.Service = findIndexNumber(
              selectedIntegration.columns,
              "shippingService"
            );
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "length")
          ) {
            data.Length = findIndexNumber(
              selectedIntegration.columns,
              "length"
            );
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "width")
          ) {
            data.Width = findIndexNumber(selectedIntegration.columns, "width");
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "height")
          ) {
            data.Height = findIndexNumber(
              selectedIntegration.columns,
              "height"
            );
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "weight")
          ) {
            data.Weight = findIndexNumber(
              selectedIntegration.columns,
              "weight"
            );
          }
          if (
            selectedIntegration?.columns.some((item) => item.col === "poNumber")
          ) {
            data.PoNumber = findIndexNumber(
              selectedIntegration.columns,
              "poNumber"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originName"
            )
          ) {
            data.originName = findIndexNumber(
              selectedIntegration.columns,
              "originName"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originCompany"
            )
          ) {
            data.originCompany = findIndexNumber(
              selectedIntegration.columns,
              "originCompany"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originAddress1"
            )
          ) {
            data.SenderAddress1 = findIndexNumber(
              selectedIntegration.columns,
              "originAddress1"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originAddress2"
            )
          ) {
            data.SenderAddress2 = findIndexNumber(
              selectedIntegration.columns,
              "originAddress2"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originAddress3"
            )
          ) {
            data.SenderAddress3 = findIndexNumber(
              selectedIntegration.columns,
              "originAddress3"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originCity"
            )
          ) {
            data.SenderCity = findIndexNumber(
              selectedIntegration.columns,
              "originCity"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originState"
            )
          ) {
            data.SenderState = findIndexNumber(
              selectedIntegration.columns,
              "originState"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originZip"
            )
          ) {
            data.SenderZip = findIndexNumber(
              selectedIntegration.columns,
              "originZip"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originCountry"
            )
          ) {
            data.SenderCountry = findIndexNumber(
              selectedIntegration.columns,
              "originCountry"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originEmail"
            )
          ) {
            data.SenderEmail = findIndexNumber(
              selectedIntegration.columns,
              "originEmail"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "originPhone"
            )
          ) {
            data.SenderPhone = findIndexNumber(
              selectedIntegration.columns,
              "originPhone"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationName"
            )
          ) {
            data.receiverName = findIndexNumber(
              selectedIntegration.columns,
              "destinationName"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationCompany"
            )
          ) {
            data.receiverCompany = findIndexNumber(
              selectedIntegration.columns,
              "destinationCompany"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationCountry"
            )
          ) {
            data.receiverCountry = findIndexNumber(
              selectedIntegration.columns,
              "destinationCountry"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationAddress1"
            )
          ) {
            data.receiverAddress1 = findIndexNumber(
              selectedIntegration.columns,
              "destinationAddress1"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationAddress2"
            )
          ) {
            data.receiverAddress2 = findIndexNumber(
              selectedIntegration.columns,
              "destinationAddress2"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationAddress3"
            )
          ) {
            data.receiverAddress3 = findIndexNumber(
              selectedIntegration.columns,
              "destinationAddress3"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationCity"
            )
          ) {
            data.receiverCity = findIndexNumber(
              selectedIntegration.columns,
              "destinationCity"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationState"
            )
          ) {
            data.receiverState = findIndexNumber(
              selectedIntegration.columns,
              "destinationState"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationZip"
            )
          ) {
            data.receiverZip = findIndexNumber(
              selectedIntegration.columns,
              "destinationZip"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationEmail"
            )
          ) {
            data.receiverEmail = findIndexNumber(
              selectedIntegration.columns,
              "destinationEmail"
            );
          }
          if (
            selectedIntegration?.columns.some(
              (item) => item.col === "destinationPhone"
            )
          ) {
            data.receiverPhone = findIndexNumber(
              selectedIntegration.columns,
              "destinationPhone"
            );
          }
          console.log(data, "data================>>>");
          setIndexObject(data);
        }
      };

      reader.readAsText(file);
    }

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("status", "uploaded");
      formData.append(
        "uploaded",
        new Date().toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
      );
      formData.append("acceptedOrders", "");
      formData.append("submittedOrders", "");
      formData.append("user", Cookies.get("id"));

      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/upload-csv`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }
  const handleAddCommodityFormGroup = () => {
    setCommodityFormGroup([
      ...commodityFormGroups,
      {
        id: Date.now(),
        contentDescription: "",
        unitValue: "",
        quantity: 0,
        weight: 0,
        ounces: "",
      },
    ]);
  };
  const handleCallApi = async (fileData, KeyObject) => {
    let arr = [];
    for (let i = 0; i < fileData.length; i++) {
      const bodyData = {};
      const keys = Object.keys(fileData[i]);
      for (const key in KeyObject) {
        // Check if the property belongs to the object itself (not inherited)
        if (KeyObject.hasOwnProperty(key) && fileData[i]) {
          // Access the value of the property using obj[key]

          const value = KeyObject[key];

          const getKey = keys[value];

          const getVal = fileData[i][getKey];
          if (fileData[i][getKey]) {
            bodyData[key] = fileData[i][getKey];
          }

          if (
            getVal == "FedEx International Priority®" ||
            getVal == "FedEx International Economy®" ||
            getVal == "FedEx Ground®" ||
            getVal == "FedEx First Overnight®" ||
            getVal == "FedEx First Overnight®" ||
            getVal == "FedEx 2Day®" ||
            getVal == "FedEx Express Saver®" ||
            getVal == "FedEx Home Delivery®"
          ) {
            setServiceType("fedEx");
          }
        }
      }
      if (Object.keys(bodyData).length !== 0) {
        arr.push(bodyData);
      }
    }
    const firstKey = Object.keys(arr[0])[0];
    setFirstKey(firstKey);
    setBulkShipData(arr);
  };
  const handleCheckboxChange = (e) => {
    setShowToast(e.target.checked);
  };
  useEffect(() => {
    if (fileData?.length && indexObject) {
      handleCallApi(fileData, indexObject);
    }
  }, [fileData, indexObject]);
  const bulkShipHandler = async () => {
    const packageOfbject = {
      code: packageOfService.code,
      Description: packageOfService.Description,
    };
    const newArr = bulkShipData.map((ele) => {
      console.log(ele, "ele?.receiverCountry==>>");
      const getReceiverCountry = receiverCountryOptions.find(
        (item) => item.value == ele?.receiverCountry
      );
      console.log(getReceiverCountry, "getReceiverCountry==>>>");
      let getReceiverStateData = statesData.find(
        (item) => item.value == ele.receiverState
      );
      if (!getReceiverStateData) {
        getReceiverStateData = statesData.find(
          (item) => item.label == ele.receiverState
        );
      }
      const data = {
        description: ele.Description,
        shipSender: {
          name: senderName,
          number: senderPhone,
          company: senderCompany,
          email: senderEmail,
          Address: {
            AddressLine1: senderAddress1,
            city: senderCity,
            state: senderState?.value,
            pincode: senderZipCode,
            CountryCode: senderCountry?.value,
          },
        },
        shipReceiver: {
          name: ele?.receiverName,
          number: ele?.receiverPhone,
          company: ele?.receiverCompany,
          email: ele?.receiverEmail,
          Address: {
            AddressLine1: ele?.receiverAddress1,
            city: ele?.receiverCity,
            state: getReceiverStateData?.value,
            pincode: ele?.receiverZip,
            CountryCode: ele?.receiverCountry,
          },
        },
        service: ele?.Service ? ele?.Service : service,
        package: {
          packaging: packageOfbject,
          Dimensions: dimension,
          PackageWeight: packageWeight,
        },
      };
      return data;
    });
    setShipArr(newArr);

    console.log(newArr, "newArr-----");
    let shipId = ''
    for (let i = 0; i < newArr.length; i++) {
      const getShip = shipData.find(
        (ele) => ele.description == newArr[i].description
      );
      console.log(getShip, "getShip---");
      if (!getShip) {
        const getRes = await saveShipment(newArr[i]);
        if (getRes == false) {
          continue;
        }
      } else {
        shipId = getShip._id
        continue;
      }
    }
    await createShipApi(shipId);
  };
  console.log(shipData,"shipData=>")
  useEffect(() => {
    const headersList = {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    // Fetch ship data from API
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-shipment-data`,
      {
        method: "GET", // Use "GET" method for sending parameters in URL
        headers: headersList,
      }
    )
      .then((response) => response.json())
      .then((data) => setShipData(data.data))
      .catch((error) => console.error("Error fetching ship data:", error));
  }, []);
  async function createShipApi(data) {
    if (!senderName) {
      setError("Sender name is missing!");
      return;
    }
    if (!senderPhone) {
      setError("Sender phone number is missing!");
      return;
    }
    if (!senderCompany) {
      setError("Sender company is missing!");
      return;
    }
    if (!senderAddress1) {
      setError("Sender address is missing!");
      return;
    }
    if (!senderState) {
      setError("Sender state is missing!");
      return;
    }
    if (!senderCity) {
      setError("Sender city is missing!");
      return;
    }
    if (!senderCountry) {
      setError("Sender country is missing!");
      return;
    }
    if (!senderZipCode) {
      setError("Sender zip code is missing!");
      return;
    }

    if (!shipWeight) {
      setError("Weight is missing!");
      return;
    }

    // if(senderName || senderPhone || senderCompany ||senderAddress1 ||  senderCity || senderZipCode)
    for (let i = 0; i < shipArr.length; i++) {
      shipArr[i].isFedEx = serviceType == "fedEx" ? true : false;
      let headersList = {
        "Content-Type": "application/json",
      };
      console.log(shipArr[i], "shipArr[i]---");
      const id = Cookies.get("id");
      const bodyData = {
        user: Cookies.get("id"),
        // "shipId": shipArr[i]._id,
        Description: shipArr[i].description,
        UserNumber: senderPhone,
        ShipFrom: {
          name: senderName,
          number: senderPhone,
          company: senderCompany,
          email: senderEmail,
          Address: {
            AddressLine1: senderAddress1,
            city: senderCity,
            state: senderState?.value,
            pincode: senderZipCode,
            CountryCode: senderCountry?.value,
          },
        },
        ShipTo: {
          name: shipArr[i].shipReceiver.name,
          number: shipArr[i].shipReceiver?.number,
          company: shipArr[i].shipReceiver?.company,
          email: shipArr[i].shipReceiver?.email,
          Address: {
            AddressLine1: shipArr[i].shipReceiver?.Address?.AddressLine1,
            city: shipArr[i].shipReceiver?.Address?.city,
            state: shipArr[i].shipReceiver?.Address?.state,
            pincode: shipArr[i].shipReceiver?.Address?.pincode,
            CountryCode: shipArr[i].shipReceiver?.Address?.CountryCode,
          },
        },
        Service: shipArr[i]?.service ? shipArr[i]?.service : service,
        Package: {
          packaging: shipArr[i]?.package?.packaging,
          Dimensions: shipArr[i]?.package?.Dimensions,
          PackageWeight: shipArr[i]?.package?.PackageWeight,
        },
      };
      console.log(bodyData, "bodyData==>>bodyData");
      let bodyContent = bodyData;
      let endpoint = "create-ship";
      if (serviceType == "fedEx") {
        endpoint = "create-fedex-ship";
      }

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/${endpoint}`,
          bodyContent,
          {
            headers: headersList,
          }
        );
        console.log(response?.data?.data?.ShipmentResponse?.ShipmentResults
            .ShipmentCharges.ServiceOptionsCharges.MonetaryValue,"bhjbjh") 
                  
        if(response?.data?.data?.ShipmentResponse) {
            let shipIds = savedShip
            shipIds.push(shipArr[i].description)
            setSavedShip(shipIds)
        }
        const serviceAmount = {
            CurrencyCode: "USD",
            MonetaryValue:
            serviceCharges + response?.data?.data?.ShipmentResponse?.ShipmentResults
            .ShipmentCharges.ServiceOptionsCharges.MonetaryValue,
          };
        setServiceCharges(serviceAmount);
        const transpotationalAmount = {
            CurrencyCode: "USD",
            MonetaryValue:
            transpotationalCharges + response?.data?.data?.ShipmentResponse?.ShipmentResults
        .ShipmentCharges.TransportationCharges.MonetaryValue,
          };
        console.log( response?.data?.data?.ShipmentResponse?.ShipmentResults
            .ShipmentCharges.TransportationCharges.MonetaryValue,"nmnbb")
        
        console.log( response?.data?.data?.ShipmentResponse?.ShipmentResults
            .ShipmentCharges.TotalCharges.MonetaryValue,"djdfgdshfghj")
        setTranspotationalCharges(transpotationalAmount);
        const totalAmount = {
            CurrencyCode: "USD",
            MonetaryValue:
            totalCharges + response?.data?.data?.ShipmentResponse?.ShipmentResults
        .ShipmentCharges.TotalCharges.MonetaryValue,
          };
       
        setTotalCharges(totalAmount);
        if (serviceType == "fedEx") {
          setShipResult(response?.data?.data?.output);
          const charges = {
            CurrencyCode: "USD",
            MonetaryValue:
              response?.data?.data?.output.transactionShipments[0]
                .pieceResponses[0].netRateAmount + totalCharges,
          };
          setTotalCharges(charges);

          await saveFedExResponseOfShipment(
            response?.data?.data,
            response?.data?.data?.transactionId,
            response?.data?.data?.output
          );
        } else {
          await saveResponseOfShipment(
            data,
            response?.data?.data?.ShipmentResponse?.Response,
            response?.data?.data?.ShipmentResponse?.ShipmentResults
          );
        }
      } catch (err) {
        if (err?.status == 401) {
          navigate("/login");
        }

        if (err instanceof Error) {
          if (err?.response?.data?.err?.errors.length) {
            const errArr = await err?.response?.data?.err?.errors.map(
              (error) => error.message
            );
            // Assuming err.message contains the error messages from the server
            setShipError(errArr);
            const errData = shipErrArr;
            errData.push({
              id: shipArr[i].description,
              err: errArr[0],
            });
            console.log(errData, "errData==>>>");
            setShipErrArr(errData);
          } else {
            const errArr = await err?.response?.data?.err.map(
              (error) => error.message
            );
            // Assuming err.message contains the error messages from the server
          }
        } else {
          // If it's a network error or other error not related to server response
          setValidationError((prevErrors) => [
            ...prevErrors,
            "An error occurred while processing your request.",
          ]);
        }
      }

      // let bodyContent = JSON.stringify({
      //     "Description": "Ship WS test",
      //     "ShipTo": {
      //         "name": "Happy Dog Pet Supply",
      //         "number": "9225377171",
      //         "Address": {
      //             "AddressLine1": "123 Main St",
      //             "city": "timonium",
      //             "pincode": "21030",
      //             "CountryCode": "US"
      //         }
      //     },
      //     "ShipFrom": {
      //         "name": "T and T Designs",
      //         "number": "1234567890",
      //         "Address": {
      //             "AddressLine1": "2311 York Rd",
      //             "city": "Alpharetta",
      //             "pincode": "30005",
      //             "CountryCode": "US"
      //         }

      //     },
      //     "Service": {
      //         "code": "03",
      //         "Description": "Express"
      //     },
      //     "Package": {

      //         "packaging": {
      //             "code": "02",
      //             "Description": "Nails"
      //         },
      //         "Dimensions": {
      //             "dimensionCode": "IN",
      //             "dimensionDescription": "Inches",
      //             "length": "10",
      //             "width": "30",
      //             "height": "45"
      //         },
      //         "PackageWeight": {
      // "code": "LBS",
      // "description": "Pounds",
      // "Weight": "5"
      //         }
      //     },
      //     "accessToken": "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrZXJpbXVya3VuQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0THB4SVV0MThuMUhpUzhlVFRpYjh1WGtQT0RHTW1KMzczZ1VKNHM1TVp1eU5LVTMiLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI2OEFFOUI4MC01MTM3LTFFMzktOTNERS0yOUVBMzM2MDUzRDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJ1cHNrZXJpbSIsImF0Ijoib2VBN0p3QnBycEZMRWs1SFN0dzE2ZTNkeDVKSiIsIm5iZiI6MTcwNjU0NTE5NCwiRGlzcGxheU5hbWUiOiJ1cHNrZXJpbSIsImV4cCI6MTcwNjU1OTU5NCwiaWF0IjoxNzA2NTQ1MTk0LCJqdGkiOiI1MzZmOTI2YS1hYTg1LTQyODMtOThhOS01ZGQyYzYzZTczYzcifQ.KrVNj8zHAVAMfeDJc3CobmN2EKq5D61wxAiWJ31Ljf70sqPN1cpks0JLpUc2OIY8E_LLOMbckUxmZyNWWKL7iQCVHCXa_TNECxSTL8TOGQoxkwW5N4AN13xsywjhc0MqZH1g6JxYikQ65h7z0uXMiHrNadtW61n6RC2Dfx4XDLfkAmiGk4sV5G8TVvz7QJV1dQC6BTjWblRg52a0PzdUrYqeXtcXo-txqDEIOLaBk8lFJUQ2yirLtdam57KetnnyWHEzKl_p5KjG92DuRvJQ_xlXuBEPLZ8YHvnteMjKld-zRqY7weGt7HhjGxhK0VzHbK9kXPh5L_tbBIKpcajRqy9dRYWdyo1LzUQptQR_MTUe-8SVTHRQ3xezcyGENizWYhKygWQyZTno3T8zh0KawR9s2mAhT8j_ZT3mL0IBatOUqpRzytBDe93j1sR4hSU9IpRBJPKpUUK98I_b8tSS5HieDa_fgAcLYf020qzX2wJT6Iua-s2HL7hqW635E-XqBZh3Kmwuau98PwlwlCpim_QkGOYBiYN5z5amRaflDScJRi4UeON4-eHG6e5EGTL4VPDwnFiJSpAqNmOV5Xd0L5IAtZ5Tlne1_TQodOBjros81D6lGf0HKER4RzkZj4zDlhf7TSWJfrmL2zmnoDgyobSCY4V0HaYhLzwHuvz6S1k"

      // });

      //   setShipResult(response?.data?.data?.ShipmentResponse)
    }
  }

  const saveShipment = async (shipmentBody) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/save-ship`,
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: `Bearer ${Cookies.get("token")}`, // Replace with your actual access token
            "Content-Type": "application/json",
          },
          body: JSON.stringify(shipmentBody),
        }
      );

      const data = await response.json();
      if (data.status) {
        // setCurrentShipId(data.data._id)

        // setShipName(data.data.description)
        await createShipApi(data.data._id);
        // handleSaveCloseModal()
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setError(error.message);
      console.error("There was an error fetching the data:", error);
      return false;
      // handleSaveCloseModal()
    }
  };
  const getCommodities = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-commodities`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const commoditiesWithLabels = data.data.map((item) => ({
            ...item,
            value: item.productId,
            label: item.integration, // Replace 'your_label_here' with the label you want to assign
          }));
          setCommoditiesOption(commoditiesWithLabels);
          // handleClose()
        })
        .catch((error) => {
          console.error("Error while calling API:", error);
        });
    } catch (err) {
      if (err?.status == 401) {
        navigate("/login");
      }
      console.log(err);
    }
  };
  return (
    <>
      <Modal show={csvModel} onHide={handleCloseCsvModal}>
        <Modal.Header closeButton>
          <Modal.Title>Csv Integrations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {csvIntegrationData &&
                csvIntegrationData.length &&
                csvIntegrationData.map((shipment) => (
                  <tr key={shipment._id}>
                    <td>{shipment.integrationName}</td>
                    <td>
                      <button
                        onClick={() => {
                          handleUploadCsvButtonClick();
                          setSelectedIntegration(shipment);
                        }}
                      >
                        Upload Csv
                      </button>
                    </td>
                    <input
                      type="file"
                      id="csvFileInput"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedIntegration(shipment);

                        handleFileSelect(e, shipment);
                      }}
                    />
                  </tr>
                ))}
            </tbody>
          </table>
          {/* Add the content of your modal here */}
          {/* This can include the form for loading shipment */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseCsvModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Header />

      <Sidebar id="menu-item" id1="menu-items" activeClassName="bulk-ship" />

      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Bulk Ship </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 d-flex">
                <div className="card chat-box">
                  <div className="card text-center p-3 ">
                    <p>
                      <strong>
                        {" "}
                        <i className="fa-solid fa-circle-exclamation"></i> Get
                        Started!
                      </strong>
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary btn-lg me-1"
                      onClick={() => {
                        setShowCsvModal(true);
                      }}
                    >
                      Upload CSV
                    </button>
                  </div>
                  <ul>
                    {validationError.length
                      ? validationError.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                      : ""}
                  </ul>
                  <ul>
                    {shipError?.length
                      ? shipError.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                      : ""}
                  </ul>
                  {bulkShipData.length ? (
                    <p>
                      <b>Orders:</b>
                    </p>
                  ) : (
                    ""
                  )}
                     {totalCharges ? <Card style={{ width: '18rem', backgroundColor: '#dee2e6' }}>
                                        <Card.Body>
                                            {serviceCharges && <>
                                                <Card.Title>Shipment Charges</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Service Charges</Card.Subtitle>
                                                {<Card.Text>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <p>{`Currency: ${serviceCharges?.CurrencyCode}`}</p>
                                                        <p>Value: {serviceCharges?.MonetaryValue}</p>
                                                    </div>
                                                </Card.Text>}
                                            </>}
                                            {transpotationalCharges && <>
                                                <Card.Subtitle className="mb-2 text-muted">Transportation Charges</Card.Subtitle>
                                                <Card.Text>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <p>{`Currency: ${transpotationalCharges?.CurrencyCode}`}</p>
                                                        <p>Value: {transpotationalCharges?.MonetaryValue}</p>
                                                    </div>
                                                </Card.Text>
                                            </>}
                                            <Card.Subtitle className="mb-2 text-muted">Total Charges</Card.Subtitle>
                                            <Card.Text>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <p>{`Currency: ${totalCharges.CurrencyCode}`}</p>
                                                    <p>Value: {profitMargin ? totalCharges?.MonetaryValue * (1 + profitMargin / 100) : totalCharges?.MonetaryValue}</p>
                                                </div>
                                            </Card.Text>


                                            <button style={{ marginRight: '10px' }} className="btn btn-primary" variant="seconadry" onClick={makePayment}>Pay</button>
                                            <button className="btn btn-primary" variant="seconadry" onClick={voidShip}>Cancel</button>
                                        </Card.Body>
                                    </Card> : ''}
                  {bulkShipData.length
                    ? bulkShipData.map((ele) => (
                        <>
                          <div class="shadow p-3 mb-5 bg-white rounded">
                            {ele[firstKey]}

                            <>
                              {console.log(shipErrArr, "shipErrArr=>>>")}
                              <p style={{color:'red',marginTop:'10px'}}>
                                {shipErrArr && shipErrArr.length
                                  ? shipErrArr.find(
                                      (item) => item.id === ele.Description
                                    )?.err
                                  : ""}
                              </p>
                            </>

                           
                          </div>
                        </>
                      ))
                    : ""}
                  {/* {totalCharges && (
                    <Card
                      style={{ width: "18rem", backgroundColor: "#dee2e6" }}
                    >
                      <Card.Body>
                        {serviceCharges && (
                          <>
                            <Card.Title>Shipment Charges</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Service Charges
                            </Card.Subtitle>
                            {
                              <Card.Text>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p>{`Currency: ${serviceCharges?.CurrencyCode}`}</p>
                                  <p>Value: {serviceCharges?.MonetaryValue}</p>
                                </div>
                              </Card.Text>
                            }
                          </>
                        )}
                        {transpotationalCharges && (
                          <>
                            <Card.Subtitle className="mb-2 text-muted">
                              Transportation Charges
                            </Card.Subtitle>
                            <Card.Text>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>{`Currency: ${transpotationalCharges?.CurrencyCode}`}</p>
                                <p>
                                  Value: {transpotationalCharges?.MonetaryValue}
                                </p>
                              </div>
                            </Card.Text>
                          </>
                        )}
                        <Card.Subtitle className="mb-2 text-muted">
                          Total Charges
                        </Card.Subtitle>
                        <Card.Text>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p>{`Currency: ${totalCharges.CurrencyCode}`}</p>
                            <p>
                              Value:{" "}
                              {profitMargin
                                ? totalCharges?.MonetaryValue *
                                  (1 + profitMargin / 100)
                                : totalCharges?.MonetaryValue}
                            </p>
                          </div>
                        </Card.Text>

                        <button
                          style={{ marginRight: "10px" }}
                          className="btn btn-primary"
                          variant="seconadry"
                          onClick={makePayment}
                        >
                          Pay
                        </button>
                        <button
                          className="btn btn-primary"
                          variant="seconadry"
                          onClick={makePayment}
                        >
                          Cancel
                        </button>
                      </Card.Body>
                    </Card>
                  )} */}
                  {/* <p>This is where your pending orders will show up once you setup an ecommerce integration.</p> */}
                  {/* <button className="btn btn-secondary btn-lg me-1">Add Your First Integration</button> */}
                </div>
              </div>

              <div
                className="col-xl-8"
                style={{ overflowY: "auto", height: "calc(100vh - 200px)" }}
              >
                <div className="card chat-box">
                  <div className="card text-left p-3 ">
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select Service:</label>
                        <Select
                          value={service}
                          onChange={(selectedOption) => {
                            if (error) {
                              setError(null);
                            }
                            setService(selectedOption);
                          }}
                          options={upsService}
                          required
                        />

                        {/* <Autocomplete
                                                                                    id="combo-box-demo"
                                                                                     renderInput={(params) => <TextField {...params} variant="outlined"/>}
                                                                                     
                                                                                        value={senderState}
                                                                                        disablePortal
                                                                                        disableClearable
                                                                                        options={senderStateList}
                                                                                        sx={{
                                                                                            '.MuiOutlinedInput-root' : {
                                                                                                padding:'2px'
                                                                                            },
                                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                              border: '1px solid #e9eaf6;',
                                                                                            },
                                                                                          }}
                                                                                    /> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select Package:</label>
                        <Select
                          value={packageOfService}
                          onChange={(selectedOption) => {
                            if (error) {
                              setError(null);
                            }
                            setPackageOfService(selectedOption);
                          }}
                          options={upsPackages}
                          required
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="m-0">
                          <strong>Sender</strong>
                        </p>
                        <button
                          onClick={updateSenderDetails}
                          className="bg-transparent border-0"
                        >
                          <i className="fa-solid fa-save"></i>
                        </button>
                        <button
                          className="bg-transparent border-0"
                          onClick={() => setSenderDetails(false)}
                        >
                          <i className="fa-solid fa-edit"></i>
                        </button>
                      </div>
                      <button>
                        <i className="fa-solid fa-arrows-left-right"></i>
                      </button>
                    </div>
                    <div>
                      {senderDetails && (
                        <div>
                          {senderName && <p>{senderName}</p>}
                          {senderCompany && <p>{senderCompany}</p>}
                          {senderAddress1 && <p>{senderAddress1}</p>}
                          {senderAddress1 && <p>{senderAddress1}</p>}
                          {senderAddress2 && <p>{senderAddress2}</p>}
                          {senderAddress3 && <p>{senderAddress3}</p>}
                          {(senderCity || senderState || senderZipCode) && (
                            <p>
                              {senderCity}, {senderState?.label} {senderZipCode}
                            </p>
                          )}
                          <p>{senderCountry?.label}</p>
                          <p>{senderPhone}</p>
                        </div>
                      )}
                      {!senderDetails && (
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Country:</label>
                                <Select
                                  value={senderCountry}
                                  onChange={(selectedOption) =>
                                    setSenderCountry(selectedOption)
                                  }
                                  options={senderCountryOptions}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name:</label>
                                <input
                                  onChange={(e) => {
                                    setSenderName(e.target?.value);
                                    if (error) {
                                      setError(null);
                                    }
                                  }}
                                  type="text"
                                  value={senderName}
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Company:</label>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setSenderCompany(e.target?.value);
                                    if (error) {
                                      setError(null);
                                    }
                                  }}
                                  className="form-control"
                                  value={senderCompany}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>ZIP code:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={senderZipCode}
                                  onChange={(e) => {
                                    if (error) {
                                      setError(null);
                                    }
                                    setSenderZipCode(e.target?.value);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>City:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={senderCity}
                                  onChange={(e) => {
                                    if (error) {
                                      setError(null);
                                    }
                                    setSenderCity(e.target?.value);
                                  }}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>State:</label>
                                <Select
                                  value={senderState}
                                  onChange={(selectedOption) => {
                                    if (error) {
                                      setError(null);
                                    }
                                    setSenderState(selectedOption);
                                  }}
                                  options={senderStateList}
                                  required
                                />
                                {/* <Autocomplete
                                                                                    id="combo-box-demo"
                                                                                     renderInput={(params) => <TextField {...params} variant="outlined"/>}
                                                                                     
                                                                                        value={senderState}
                                                                                        disablePortal
                                                                                        disableClearable
                                                                                        options={senderStateList}
                                                                                        sx={{
                                                                                            '.MuiOutlinedInput-root' : {
                                                                                                padding:'2px'
                                                                                            },
                                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                              border: '1px solid #e9eaf6;',
                                                                                            },
                                                                                          }}
                                                                                    /> */}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Address1:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={senderAddress1}
                                  onChange={(e) => {
                                    if (error) {
                                      setError(null);
                                    }
                                    setSenderAddress1(e.target?.value);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Address2:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={senderAddress2}
                                  onChange={(e) =>
                                    setSenderAddress2(e.target?.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Address3:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={senderAddress3}
                                  onChange={(e) =>
                                    setSenderAddress3(e.target?.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={senderEmail}
                                  onChange={(e) => {
                                    setSenderEmail(e.target?.value);
                                    if (error) {
                                      setError(null);
                                    }
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Phone:</label>
                                <input
                                  type="phone"
                                  className="form-control"
                                  value={senderPhone}
                                  onChange={(e) => {
                                    setSenderPhone(e.target?.value);
                                    if (error) {
                                      setError(null);
                                    }
                                  }}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {
                      <div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Units:</label>
                              <Select
                                value={selectedUnit}
                                onChange={(selectedOption) =>
                                  setUnit(selectedOption)
                                }
                                options={options}
                              />
                            </div>
                          </div>
                        </div>
                        {packageOfService?.code == "02" ||
                        packageOfService?.code == "YOUR_PACKAGING" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Custom Package Type:</label>
                                <Select
                                  value={selectedCustomPackage}
                                  onChange={(selectedOption) =>
                                    setSelectedCustomPackage(selectedOption)
                                  }
                                  options={customerPackageOptions}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          {isVisibleLength && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Length:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={shipLength}
                                  onChange={(e) =>
                                    setShipLength(e.target?.value)
                                  }
                                  disabled={
                                    packageOfService?.code !== "02" &&
                                    packageOfService?.code !== "YOUR_PACKAGING"
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {isVisibleWidth && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Width:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={shipWidth}
                                  onChange={(e) => {
                                    setShipWidth(e.target?.value);
                                  }}
                                  disabled={
                                    packageOfService?.code !== "02" &&
                                    packageOfService?.code !== "YOUR_PACKAGING"
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {isVisibleHeight && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label style={{ whiteSpace: "pre" }}>
                                  Height:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={shipHeight}
                                  onChange={(e) =>
                                    setShipHeight(e.target?.value)
                                  }
                                  disabled={
                                    packageOfService?.code !== "02" &&
                                    packageOfService?.code !== "YOUR_PACKAGING"
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Weight:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  selectedUnit?.value == 1
                                    ? "lb"
                                    : selectedUnit?.value == 2
                                    ? "kg"
                                    : ""
                                }
                                value={shipWeight}
                                onChange={(e) => {
                                  if (error) {
                                    setError(null);
                                  }
                                  setShipWeight(e.target?.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {selectedUnit?.value == 1 ? "Ounces" : "Grams"}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  selectedUnit?.value == 1
                                    ? "oz"
                                    : selectedUnit?.value == 2
                                    ? "g"
                                    : ""
                                }
                                value={ouncesGrams}
                                onChange={(e) =>
                                  setOuncesGrams(e.target?.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label style={{ whiteSpace: "pre" }}>
                                Insure Amt:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="USD $ 0.00"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div className="col-md-4">
                            <label>Insurance Category:</label>
                            <Select
                              value={selectedInsurance}
                              onChange={(selectedOption) =>
                                setInsurance(selectedOption)
                              }
                              options={insuranceOptions}
                            />
                          </div>
                          <div className="col-md-4" style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <label style={{ whiteSpace: "pre" }}>
                                Customs Amt:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="USD $"
                                value={customeAmount}
                                onChange={(e) =>
                                  setCustomeAmount(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          {isVisiblePlus && (
                            <div
                              className="col-md-4"
                              style={{ marginTop: "20px" }}
                            >
                              <button
                                className="bg-transparent border-0"
                                onClick={handleAddFormGroup}
                              >
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                        {formGroups.map((group, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Length:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={group.length}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "length",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      packageOfService?.code !== "02" &&
                                      packageOfService?.code !==
                                        "YOUR_PACKAGING"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Width:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={group.width}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "width",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      packageOfService?.code !== "02" &&
                                      packageOfService?.code !==
                                        "YOUR_PACKAGING"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ whiteSpace: "pre" }}>
                                    Height:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={group.height}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "height",
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      packageOfService?.code !== "02" &&
                                      packageOfService?.code !==
                                        "YOUR_PACKAGING"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Weight:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      selectedUnit?.value === 1
                                        ? "lb"
                                        : selectedUnit?.value === 2
                                        ? "kg"
                                        : ""
                                    }
                                    value={group.weight}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "weight",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    {selectedUnit?.value === 1
                                      ? "Ounces"
                                      : "Grams"}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      selectedUnit?.value === 1
                                        ? "oz"
                                        : selectedUnit?.value === 2
                                        ? "g"
                                        : ""
                                    }
                                    value={group.ouncesGrams}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "ouncesGrams",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ whiteSpace: "pre" }}>
                                    Insure Amt:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="USD $ 0.00"
                                    value={group.insureAmt}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "insureAmt",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>Insurance Category:</label>
                                <Select
                                  value={group.insurance}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "insurance",
                                      e.target.value
                                    )
                                  }
                                  options={insuranceOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ whiteSpace: "pre" }}>
                                    Customs Amt:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="USD $"
                                    value={group.customeAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "customeAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <button
                                  className="bg-transparent border-0"
                                  onClick={() => handleRemoveFormGroup(index)}
                                >
                                  <i
                                    className="fa fa-minus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  className="bg-transparent border-0"
                                  onClick={handleAddFormGroup}
                                >
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Total Quantity:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={ttlQty}
                                onChange={(e) => setTtlQty(e.target?.value)}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label style={{ whiteSpace: "pre" }}>
                                Total Weight:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={`${Math.round(
                                  ttlWeight +
                                    Number(shipWeight) +
                                    (Number(ouncesGrams) +
                                      Number(totalOuncesGrams)) /
                                      16
                                )} `}
                                onChange={(e) => setTtlWeight(e.target?.value)}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {selectedUnit?.value == 1
                                  ? "Total Ounces"
                                  : "Grams"}
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  selectedUnit?.value == 1
                                    ? "oz"
                                    : selectedUnit?.value == 2
                                    ? "g"
                                    : ""
                                }
                                value={`${
                                  Number(ouncesGrams) + Number(totalOuncesGrams)
                                }`}
                                onChange={(e) =>
                                  setTotalOuncesGrams(e.target?.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{"Total Customs Amt"}</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  selectedUnit?.value == 1
                                    ? "oz"
                                    : selectedUnit?.value == 2
                                    ? "g"
                                    : ""
                                }
                                value={`${
                                  Number(customeAmount) +
                                  Number(totalCustomeAmount)
                                }`}
                                onChange={(e) =>
                                  setTotalCustomeAmount(e.target?.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Content Description(0/80):</label>
                              <input
                                type="text"
                                className="form-control"
                                value={`${contentDescription}`}
                                onChange={(e) =>
                                  setContentDescription(e.target?.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Signature:</label>
                              <Select
                                value={signature}
                                onChange={(selectedOption) =>
                                  setSignature(selectedOption)
                                }
                                options={signatureOption}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <ButtonGroup toggle>
                              <ToggleButton
                                type="radio"
                                variant="primary"
                                name="options"
                                value="FTR"
                                checked={selectedToggleOption === "FTR"}
                                onClick={() => {
                                  handleChangeToggle("FTR");
                                }}
                              >
                                FTR
                              </ToggleButton>
                              <ToggleButton
                                type="radio"
                                variant="primary"
                                name="options"
                                value="ITN"
                                checked={selectedToggleOption === "ITN"}
                                onClick={() => {
                                  handleChangeToggle("ITN");
                                }}
                              >
                                ITN
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="col-md-6">
                            {selectedToggleOption === "FTR" ? (
                              <div className="form-group">
                                <label>FTR Code:</label>
                                <Select
                                  value={ftrCode}
                                  onChange={(selectedOption) =>
                                    setFtrCode(selectedOption)
                                  }
                                  options={ftrCodeOptions}
                                />
                              </div>
                            ) : (
                              <div className="form-group">
                                <label>ITN Number:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={taxIdNumber}
                                  onChange={(e) =>
                                    setTaxIdNumber(e.target?.value)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        {selectedToggleOption == "ITN" &&
                          serviceType !== "fedEx" && (
                            <div className="row">
                              <div className="col-md-6">
                                <ButtonGroup toggle>
                                  <ToggleButton
                                    type="radio"
                                    variant="primary"
                                    name="options"
                                    value="EIN"
                                    checked={itnToggleOption === "EIN"}
                                    onClick={() => {
                                      handleChangeITNToggle("EIN");
                                    }}
                                  >
                                    EIN
                                  </ToggleButton>
                                  <ToggleButton
                                    type="radio"
                                    variant="primary"
                                    name="options"
                                    value="DNS"
                                    checked={itnToggleOption === "DNS"}
                                    onClick={() => {
                                      handleChangeITNToggle("DNS");
                                    }}
                                  >
                                    DNS
                                  </ToggleButton>
                                  <ToggleButton
                                    type="radio"
                                    variant="primary"
                                    name="options"
                                    value="FGN"
                                    checked={itnToggleOption === "FGN"}
                                    onClick={() => {
                                      handleChangeITNToggle("FGN");
                                    }}
                                  >
                                    FGN
                                  </ToggleButton>
                                </ButtonGroup>
                              </div>
                              <div className="col-md-6">
                                {selectedToggleOption === "FTR" ? (
                                  <div className="form-group">
                                    <label>FTR Code:</label>
                                    <Select
                                      value={ftrCode}
                                      onChange={(selectedOption) =>
                                        setFtrCode(selectedOption)
                                      }
                                      options={ftrCodeOptions}
                                    />
                                  </div>
                                ) : (
                                  <div className="form-group">
                                    <label>Tax ID Number:</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={itnNumber}
                                      onChange={(e) =>
                                        setItnNumber(e.target?.value)
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Content Type:</label>
                              <Select
                                value={contentType}
                                onChange={(selectedOption) =>
                                  setContentType(selectedOption)
                                }
                                options={contentTypeOptions}
                                style={customStyles}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Terms of Trade:</label>
                              <Select
                                value={termsOfTrade}
                                onChange={(selectedOption) =>
                                  setTermsOfTrade(selectedOption)
                                }
                                options={termsOfTradeOptions}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>PO Number:</label>
                              <input type="email" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label style={{ marginTop: "30px" }}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                />
                                Saturday Delivery
                              </label>
                            </div>
                          </div>
                          {serviceType !== "fedEx" ? (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={additionlDelivery}
                                      onChange={() =>
                                        setAdditionalDelivery(
                                          !additionlDelivery
                                        )
                                      }
                                    />
                                    Additional Handling
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={carbonNeutral}
                                      onChange={() =>
                                        setCarbonNeutral(!carbonNeutral)
                                      }
                                    />
                                    Carbon Neutral
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={returnService}
                                      onChange={() =>
                                        setReturnService(!returnService)
                                      }
                                    />
                                    Return Service
                                  </label>
                                </div>
                              </div>

                              <div className="form-group row">
                                {/* <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleSendNotification} />
                                                                                                Send notification email
                                                                                            </label>
                                                                                        </div>

                                                                                    </div> */}
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Batteries:</label>
                                  <Select
                                    value={selectedBatteries}
                                    onChange={(selectedOption) =>
                                      selectedOption
                                    }
                                    options={batteriesOptions}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={saturdayPickup}
                                      onChange={() =>
                                        setSaturdayPickup(!saturdayPickup)
                                      }
                                    />
                                    Saturday Pickup
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={nonStandardContainer}
                                      onChange={() =>
                                        setNonStandardContainer(
                                          !nonStandardContainer
                                        )
                                      }
                                    />
                                    Non-Standard Container
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          {serviceType == "fedEx" ? (
                            <div class="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Contains Alcohol:</label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={containAlcohol}
                                    onChange={() =>
                                      setContainAlcohol(!containAlcohol)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {containAlcohol ? (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Alcohol Recipient Type:</label>
                                <Select
                                  value={selectedAlcoholRecipientType}
                                  onChange={(selectedOption) => selectedOption}
                                  options={alcoholRecipientTypeOptions}
                                  required
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {serviceType == "fedEx" ? (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Physical Packging Type:</label>
                                <Select
                                  value={selectedPhysicalPackingType}
                                  onChange={(selectedOption) =>
                                    setSelectedPhysicalPackingType(
                                      selectedOption
                                    )
                                  }
                                  options={physicalPackagingTypeOptions}
                                  required
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {serviceType !== "fedEx" ? (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>UPS Location:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={upsLocation}
                                    onChange={(e) =>
                                      setUpsLocation(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Package Release Code:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={packageReleaseCode}
                                    onChange={(e) =>
                                      setPackageReleaseCode(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label style={{ marginTop: "30px" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={deliverToAddress}
                                      onChange={() =>
                                        setDeliverToAddress(!deliverToAddress)
                                      }
                                    />
                                    Deliver to Addressee Only
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group row">
                            <div className="col-md-10">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={handleCheckboxChange}
                                  />
                                  Change Billing/Dutiable Party
                                </label>
                              </div>
                            </div>
                          </div>
                          <Toast
                            style={{ width: "100%" }}
                            show={showToast}
                            onClose={() => setShowToast(false)}
                          >
                            <Toast.Body>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Billing Party:</label>
                                  <Select
                                    value={billingParty}
                                    onChange={(selectedOption) =>
                                      setBillingParty(selectedOption)
                                    }
                                    options={billingPartyOption}
                                  />
                                </div>
                              </div>
                              {(billingParty?.value == "receiver" ||
                                billingParty?.value == "thirdparty") && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Billing Account:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  {(termsOfTrade?.value == "DAP" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "CIP" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "CPT" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "DAT" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "EXW" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "FCA" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "DDP" &&
                                    serviceType !== "fedEx" &&
                                    (billingParty?.value == "receiver" ||
                                      billingParty?.value == "thirdparty")) ? (
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label style={{ whiteSpace: "pre" }}>
                                          Billing Account Zip:
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={`${
                                            billingAccountZip
                                              ? billingAccountZip
                                              : ""
                                          }`}
                                          onChange={(e) =>
                                            setBillingAccountZip(
                                              e.target?.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {(termsOfTrade?.value == "DAP" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "CIP" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "CPT" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "DAT" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "EXW" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "FCA" &&
                                    billingParty?.value !== "sender" &&
                                    serviceType !== "fedEx") ||
                                  (termsOfTrade?.value == "DDP" &&
                                    serviceType !== "fedEx" &&
                                    (billingParty?.value == "receiver" ||
                                      billingParty?.value == "thirdparty")) ? (
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Billing Account Country:</label>
                                        <Select
                                          value={billingPartyCountry}
                                          onChange={(selectedOption) => {
                                            if (error) {
                                              setError(null);
                                            }
                                            setBillingPartyCountry(
                                              selectedOption
                                            );
                                          }}
                                          options={receiverCountryOptions}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                              {billingParty?.value && (
                                <Alert key={"warning"} variant={"warning"}>
                                  {"Sender is reponsibel for pickup fees"}
                                </Alert>
                              )}
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Dutiable Party</label>
                                    <Select
                                      value={dutiableParty}
                                      onChange={(selectedOption) =>
                                        setDutiableParty(selectedOption)
                                      }
                                      options={duatiablePartyOption}
                                    />
                                  </div>
                                </div>
                                {termsOfTrade?.value == "DAP" ||
                                termsOfTrade?.value == "CIP" ||
                                termsOfTrade?.value == "CPT" ||
                                termsOfTrade?.value == "DAT" ||
                                termsOfTrade?.value == "EXW" ||
                                termsOfTrade?.value == "FCA" ? (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account:</label>
                                      <input
                                        type="text"
                                        placeholder="Optional"
                                        className="form-control"
                                        value={dutiableAccount}
                                        onChange={(e) =>
                                          setDutiableAccount(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {(termsOfTrade?.value == "DAP" &&
                                serviceType !== "fedEx") ||
                              (termsOfTrade?.value == "CIP" &&
                                serviceType !== "fedEx") ||
                              (termsOfTrade?.value == "CPT" &&
                                serviceType !== "fedEx") ||
                              (termsOfTrade?.value == "DAT" &&
                                serviceType !== "fedEx") ||
                              (termsOfTrade?.value == "EXW" &&
                                serviceType !== "fedEx") ||
                              (termsOfTrade?.value == "FCA" &&
                                serviceType !== "fedEx") ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account Zip:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={dutiableAccountZip}
                                        onChange={(e) =>
                                          setDutiableAccountZip(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account Country</label>
                                      <Select
                                        value={dutiableCountry}
                                        onChange={(selectedOption) =>
                                          setDutiableCountry(selectedOption)
                                        }
                                        options={receiverCountryOptions}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </Toast.Body>
                          </Toast>
                          <div className="form-group row">
                            {/* <div className="col-md-10">
                                                                                        <div className="form-group">
                                                                                            <label>
                                                                                                <input type="checkbox" className="form-check-input" onChange={handleSendNotification} />
                                                                                                Send notification email
                                                                                            </label>
                                                                                        </div>

                                                                                    </div> */}
                            <Toast
                              style={{ width: "100%" }}
                              show={notificationToast}
                              onClose={() => setNotificationToast(false)}
                            >
                              <Toast.Body>
                                <div className="col-md-12">
                                  <div className="checkbox">
                                    <label>
                                      <input type="checkbox" name="checkbox" />{" "}
                                      Send XPS Ship notification
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="checkbox">
                                    <label>
                                      <input type="checkbox" name="checkbox" />{" "}
                                      Send UPS notification
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="checkbox">
                                    <label>
                                      <input type="checkbox" name="checkbox" />{" "}
                                      {`Send notification email to ${Cookies.get(
                                        "email"
                                      )}`}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Emails</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Message</Form.Label>
                                  <Form.Control as="textarea" rows={3} />
                                </Form.Group>

                                {billingParty?.value && (
                                  <Alert key={"warning"} variant={"warning"}>
                                    {"Sender is reponsibel for pickup fees"}
                                  </Alert>
                                )}
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Party</label>
                                      <Select
                                        value={dutiableParty}
                                        onChange={(selectedOption) =>
                                          setDutiableParty(selectedOption)
                                        }
                                        options={duatiablePartyOption}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={dutiableAccount}
                                        onChange={(e) =>
                                          setDutiableAccount(e.target?.value)
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account Zip:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={ttlQty}
                                        onChange={(e) =>
                                          setTtlQty(e.target?.value)
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Dutiable Account Country</label>
                                      <Select
                                        value={termsOfTrade}
                                        onChange={(selectedOption) =>
                                          setTermsOfTrade(selectedOption)
                                        }
                                        options={receiverCountryOptions}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Toast.Body>
                            </Toast>
                            {
                              <div className="col-md-10">
                                <div className="form-group">
                                  <label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={handleCommercialInvoice}
                                    />
                                    Include Commercial Invoice
                                  </label>
                                </div>
                              </div>
                            }
                            <Toast
                              style={{ width: "100%" }}
                              show={commercialInvoice}
                              onClose={() => setCommercialInvoice(false)}
                            >
                              <Toast.Body>
                                <div class="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Invoice Type:</label>
                                      <Select
                                        value={invoiceType}
                                        onChange={(selectedOption) =>
                                          setInvoiceType(selectedOption)
                                        }
                                        options={invoiceTypeOptions}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Paperless:</label>
                                      <Select
                                        value={paperless}
                                        onChange={(selectedOption) =>
                                          setPaperless(selectedOption)
                                        }
                                        options={paperlessOptions}
                                      />
                                    </div>
                                  </div>

                                  {paperless?.value == true ? (
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Signature</label>{" "}
                                        <Select
                                          value={commercialSignature}
                                          onChange={(selectedOption) =>
                                            setCommercialSignature(
                                              selectedOption
                                            )
                                          }
                                          options={signatureOptions}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Receiver Tax ID/VAT</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Package Marks</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {serviceType !== "fedEx" ? (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Sender Tax ID/VAT</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Sender Tax ID/VAT Issuer Country :
                                        </label>
                                        <Select
                                          value={invoiceType}
                                          onChange={(selectedOption) =>
                                            setInvoiceType(selectedOption)
                                          }
                                          options={receiverCountryOptions}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {serviceType == "fedEx" ? (
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Shipper TIN</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={shipperTIN}
                                          onChange={(e) =>
                                            setShipperTIN(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Shipper TIN Type :</label>
                                        <Select
                                          value={shipperTINType}
                                          onChange={(selectedOption) =>
                                            setShipperTINType(selectedOption)
                                          }
                                          options={shipperTINTypeOptions}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>
                                          Export Compliance Statement
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={exportComplianceStatement}
                                          onChange={(e) =>
                                            setExportCompilanceStatement(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Importer Of Record :</label>
                                    <Select
                                      value={recordImporter}
                                      onChange={(selectedOption) =>
                                        setRecordImporter(selectedOption)
                                      }
                                      options={recordImporterOption}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Select Commodities :</label>
                                    <Select
                                      value={commodities}
                                      onChange={(selectedOption) =>
                                        setCommodities(selectedOption)
                                      }
                                      options={commoditiesOption}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Description(0/35)</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Unit Value</label>
                                      <input
                                        type="text"
                                        placeholder={"$"}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Quantity</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Weight</label>
                                      <input
                                        type="text"
                                        placeholder={"$"}
                                        className="form-control"
                                        value={`${commercialInvoiceWeight}`}
                                        onChange={(e) =>
                                          setCommercialInvoiceWeight(
                                            e.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Ounces</label>
                                    <input
                                      type="text"
                                      placeholder={"oz"}
                                      value={`${commercialInvoiceOunces}`}
                                      onChange={(e) =>
                                        setCommercialInvoiceOunces(
                                          e.target?.value
                                        )
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                {commodities && (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Pickup Location</label>
                                      <input
                                        type="text"
                                        placeholder={"oz"}
                                        className="form-control"
                                        value={`${
                                          pickupLocation ? pickupLocation : ""
                                        }`}
                                        onChange={(e) =>
                                          setPickupLocation(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>HTS#/B#:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={hts}
                                        onChange={(e) =>
                                          setHts(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Country of Origin:</label>
                                      <Select
                                        value={originCountry}
                                        onChange={(selectedOption) =>
                                          setOriginCountry(selectedOption)
                                        }
                                        options={senderCountryOptions}
                                      />
                                    </div>
                                  </div>
                                  {isVisibleCommodityPlus && (
                                    <div
                                      className="col-md-4"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <button
                                        className="bg-transparent border-0"
                                        onClick={handleAddCommodityFormGroup}
                                      >
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {commodityFormGroups.map((group, index) => (
                                  <div
                                    key={group.id}
                                    className="form-group-container"
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Description (
                                            {group.contentDescription.length}
                                            /35)
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={group.contentDescription}
                                            onChange={(e) =>
                                              handleCommodityChange(
                                                group.id,
                                                "contentDescription",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Unit Value</label>
                                          <input
                                            type="text"
                                            placeholder="$"
                                            className="form-control"
                                            value={group.unitValue}
                                            onChange={(e) =>
                                              handleCommodityChange(
                                                group.id,
                                                "unitValue",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Quantity</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={group.quantity}
                                            onChange={(e) =>
                                              handleCommodityChange(
                                                group.id,
                                                "quantity",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Weight</label>
                                          <input
                                            type="text"
                                            placeholder="$"
                                            className="form-control"
                                            value={group.weight}
                                            onChange={(e) =>
                                              handleCommodityChange(
                                                group.id,
                                                "weight",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Ounces</label>
                                        <input
                                          type="text"
                                          placeholder="oz"
                                          className="form-control"
                                          value={group.ounces}
                                          onChange={(e) =>
                                            handleCommodityChange(
                                              group.id,
                                              "ounces",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <button
                                          className="bg-transparent border-0"
                                          onClick={() =>
                                            handleRemoveCommodityFormGroup(
                                              index
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-minus-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <button
                                          className="bg-transparent border-0"
                                          onClick={handleAddCommodityFormGroup}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Total Quantity:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={ttlQty}
                                        onChange={(e) =>
                                          setTtlQty(e.target?.value)
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label style={{ whiteSpace: "pre" }}>
                                        Total Weight:
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={`${Math.round(
                                          totalCommercialInvoiceWeight +
                                            Number(commercialInvoiceWeight) +
                                            Number(commercialInvoiceOunces / 16)
                                        )}  `}
                                        onChange={(e) =>
                                          setTotalCommercialInvoiceWeight(
                                            e.target?.value
                                          )
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>
                                        {selectedUnit?.value == 1
                                          ? "Ounces"
                                          : "Grams"}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                          selectedUnit?.value == 1
                                            ? "oz"
                                            : selectedUnit?.value == 2
                                            ? "g"
                                            : ""
                                        }
                                        value={`${
                                          Number(commercialInvoiceOunces) +
                                          Number(ttlOunces)
                                        }`}
                                        onChange={(e) =>
                                          setTtlOunces(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Charge Description</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Charge Amount</label>
                                      <input
                                        type="text"
                                        placeholder={"$"}
                                        className="form-control"
                                        value={`${
                                          chargeAmount ? chargeAmount : 0
                                        }`}
                                        onChange={(e) =>
                                          setChargeAmount(e.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {serviceType == "fedEx" ? (
                                  <div class="row">
                                    <div class="col-md-6">
                                      <button
                                        onClick={handleCertificateButtonClick}
                                      >
                                        Upload Certificate of Origin to FedEx
                                      </button>
                                      <input
                                        type="file"
                                        id="csvFileInput"
                                        style={{ display: "none" }}
                                        onChange={handleCertificateFileChange}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Toast.Body>
                            </Toast>
                          </div>
                        </div>

                        <p className="fw-bold">
                          {" "}
                          Need a pickup? Pickup options will be provided when
                          you complete this shipment.
                        </p>
                      </div>
                    }
                    <div className="col-md-12">
                      <button
                        className="btn btn-secondary"
                        onClick={() => bulkShipHandler()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  {/* {totalCharges && (
                    <Card
                      style={{ width: "18rem", backgroundColor: "#dee2e6" }}
                    >
                      <Card.Body>
                        {serviceCharges && (
                          <>
                            <Card.Title>Shipment Charges</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Service Charges
                            </Card.Subtitle>
                            {
                              <Card.Text>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p>{`Currency: ${serviceCharges?.CurrencyCode}`}</p>
                                  <p>Value: {serviceCharges?.MonetaryValue}</p>
                                </div>
                              </Card.Text>
                            }
                          </>
                        )}
                        {transpotationalCharges && (
                          <>
                            <Card.Subtitle className="mb-2 text-muted">
                              Transportation Charges
                            </Card.Subtitle>
                            <Card.Text>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>{`Currency: ${transpotationalCharges?.CurrencyCode}`}</p>
                                <p>
                                  Value: {transpotationalCharges?.MonetaryValue}
                                </p>
                              </div>
                            </Card.Text>
                          </>
                        )}
                        <Card.Subtitle className="mb-2 text-muted">
                          Total Charges
                        </Card.Subtitle>
                        <Card.Text>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p>{`Currency: ${totalCharges.CurrencyCode}`}</p>
                            <p>
                              Value:{" "}
                              {profitMargin
                                ? totalCharges?.MonetaryValue *
                                  (1 + profitMargin / 100)
                                : totalCharges?.MonetaryValue}
                            </p>
                          </div>
                        </Card.Text>

                        <button
                          style={{ marginRight: "10px" }}
                          className="btn btn-primary"
                          variant="seconadry"
                          onClick={makePayment}
                        >
                          Pay
                        </button>
                        <button
                          className="btn btn-primary"
                          variant="seconadry"
                          onClick={makePayment}
                        >
                          Cancel
                        </button>
                      </Card.Body>
                    </Card>
                  )} */}
                  {/* <p>This is where your pending orders will show up once you setup an ecommerce integration.</p> */}
                  {/* <button className="btn btn-secondary btn-lg me-1">Add Your First Integration</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default BulkShip;

/* eslint-disable */
import React, { useEffect, useState } from "react";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Select from "react-select";
// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import Cookies from 'js-cookie'
import {
  // Avatar2,
  // Avatar3,
  // Avatar4,
  // Avatar5,
  // calendar,
  // dep_icon1,
  // dep_icon2,
  // dep_icon3,
  // dep_icon4,
  // dep_icon5,
  // empty_wallet,
  imagesend,
  morning_img_01,
  // profile_add,
  // scissor,
  // user001,
} from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Modal, Table } from "react-bootstrap";
// import CountUp from "react-countup";

const CsvInterationList = () => {
  const receiverCountryOptions = [
    { value: "AF", label: "AFGHANISTAN" },
    { value: "AX", label: "ALAND ISLANDS" },
    { value: "AL", label: "ALBANIA" },
    { value: "DZ", label: "ALGERIA" },
    { value: "AS", label: "AMERICAN SAMOA" },
    { value: "AD", label: "ANDORRA" },
    { value: "AO", label: "ANGOLA" },
    { value: "AI", label: "ANGUILLA" },
    { value: "AQ", label: "ANTARCTICA" },
    { value: "AG", label: "ANTIGUA / BARBUDA" },
    { value: "AR", label: "ARGENTINA" },
    { value: "AM", label: "ARMENIA" },
    { value: "AW", label: "ARUBA" },
    { value: "AU", label: "AUSTRALIA" },
    { value: "CX", label: "AUSTRALIA (CHRISTMAS ISLANDS)" },
    { value: "CC", label: "AUSTRALIA (COCOS KEELING ISLANDS)" },
    { value: "NF", label: "AUSTRALIA (NORFOLK ISLANDS)" },
    { value: "AT", label: "AUSTRIA" },
    { value: "AZ", label: "AZERBAIJAN" },
    { value: "BS", label: "BAHAMAS" },
    { value: "BH", label: "BAHRAIN" },
    { value: "BD", label: "BANGLADESH" },
    { value: "BB", label: "BARBADOS" },
    { value: "BY", label: "BELARUS" },
    { value: "BE", label: "BELGIUM" },
    { value: "BZ", label: "BELIZE" },
    { value: "BJ", label: "BENIN" },
    { value: "BM", label: "BERMUDA" },
    { value: "BT", label: "BHUTAN" },
    { value: "BO", label: "BOLIVIA" },
    { value: "XB", label: "BONAIRE (NETHERLANDS ANTILLES)" },
    { value: "BA", label: "BOSNIA / HERZEGOVINA" },
    { value: "BW", label: "BOTSWANA" },
    { value: "BV", label: "BOUVET ISLAND" },
    { value: "BR", label: "BRAZIL" },
    { value: "IO", label: "BRITISH INDIAN OCEAN TERRITORY" },
    { value: "BN", label: "BRUNEI" },
    { value: "BG", label: "BULGARIA" },
    { value: "BF", label: "BURKINA FASO" },
    { value: "BI", label: "BURUNDI" },
    { value: "KH", label: "CAMBODIA" },
    { value: "CM", label: "CAMEROON" },
    { value: "CA", label: "CANADA" },
    { value: "IC", label: "CANARY ISLANDS" },
    { value: "CV", label: "CAPE VERDE" },
    { value: "KY", label: "CAYMAN ISLANDS" },
    { value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
    { value: "TD", label: "CHAD" },
    { value: "CL", label: "CHILE" },
    { value: "CN", label: "CHINA" },
    { value: "CO", label: "COLOMBIA" },
    { value: "KM", label: "COMOROS" },
    { value: "CG", label: "CONGO" },
    { value: "CD", label: "CONGO, DEMOCRATIC REPUBLIC OF THE" },
    { value: "CK", label: "COOK ISLANDS" },
    { value: "CR", label: "COSTA RICA" },
    { value: "HR", label: "CROATIA" },
    { value: "CU", label: "CUBA" },
    { value: "CW", label: "CURACAO" },
    { value: "CY", label: "CYPRUS" },
    { value: "CZ", label: "CZECH REPUBLIC" },
    { value: "DK", label: "DENMARK" },
    { value: "DJ", label: "DJIBOUTI" },
    { value: "DM", label: "DOMINICA" },
    { value: "DO", label: "DOMINICAN REPUBLIC" },
    { value: "TL", label: "EAST TIMOR" },
    { value: "EC", label: "ECUADOR" },
    { value: "EG", label: "EGYPT" },
    { value: "SV", label: "EL SALVADOR" },
    { value: "ER", label: "ERITREA" },
    { value: "EE", label: "ESTONIA" },
    { value: "ET", label: "ETHIOPIA" },
    { value: "FK", label: "FALKLAND ISLANDS (MALVINAS)" },
    { value: "FO", label: "FAROE ISLANDS" },
    { value: "FJ", label: "FIJI" },
    { value: "WF", label: "FIJI (WALLIS / FUTUNA ISLANDS)" },
    { value: "FI", label: "FINLAND" },
    { value: "FR", label: "FRANCE" },
    { value: "GF", label: "FRENCH GUYANA" },
    { value: "PF", label: "FRENCH POLYNESIA" },
    { value: "TF", label: "FRENCH SOUTHERN TERRITORIES" },
    { value: "GA", label: "GABON" },
    { value: "GM", label: "GAMBIA" },
    { value: "GE", label: "GEORGIA" },
    { value: "DE", label: "GERMANY" },
    { value: "GH", label: "GHANA" },
    { value: "GI", label: "GIBRALTAR" },
    { value: "GR", label: "GREECE" },
    { value: "GL", label: "GREENLAND" },
    { value: "GD", label: "GRENADA" },
    { value: "GP", label: "GUADELOUPE" },
    { value: "GU", label: "GUAM" },
    { value: "GT", label: "GUATEMALA" },
    { value: "GG", label: "GUERNSEY" },
    { value: "GQ", label: "GUINEA (EQUATORIAL GUINEA)" },
    { value: "GW", label: "GUINEA BISSAU" },
    { value: "GN", label: "GUINEA REPUBLIC (GUINEA)" },
    { value: "GY", label: "GUYANA (BRITISH)" },
    { value: "HT", label: "HAITI" },
    { value: "HM", label: "HEARD ISLAND AND MCDONALD ISLANDS" },
    { value: "HN", label: "HONDURAS" },
    { value: "HK", label: "HONG KONG" },
    { value: "HU", label: "HUNGARY" },
    { value: "IS", label: "ICELAND" },
    { value: "IN", label: "INDIA" },
    { value: "ID", label: "INDONESIA" },
    { value: "IR", label: "IRAN (ISLAMIC REPUBLIC OF)" },
    { value: "IQ", label: "IRAQ" },
    { value: "IE", label: "IRELAND, REPUBLIC OF (IRELAND)" },
    { value: "IL", label: "ISRAEL" },
    { value: "IT", label: "ITALY" },
    { value: "SM", label: "ITALY (SAN MARINO)" },
    { value: "VA", label: "ITALY (VATICAN CITY)" },
    { value: "CI", label: "IVORY COAST" },
    { value: "JM", label: "JAMAICA" },
    { value: "JP", label: "JAPAN" },
    { value: "JE", label: "JERSEY" },
    { value: "JO", label: "JORDAN" },
    { value: "KZ", label: "KAZAKHSTAN" },
    { value: "KE", label: "KENYA" },
    { value: "KI", label: "KIRIBATI" },
    { value: "KR", label: "KOREA, REPUBLIC OF (KOREA SOUTH)" },
    { value: "KP", label: "KOREA, THE D.P.R. OF (KOREA NORTH)" },
    { value: "KV", label: "KOSOVO" },
    { value: "KW", label: "KUWAIT" },
    { value: "KG", label: "KYRGYZSTAN" },
    { value: "LA", label: "LAOS" },
    { value: "LV", label: "LATVIA" },
    { value: "LB", label: "LEBANON" },
    { value: "LS", label: "LESOTHO" },
    { value: "LR", label: "LIBERIA" },
    { value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
    { value: "LI", label: "LIECHTENSTEIN" },
    { value: "LT", label: "LITHUANIA" },
    { value: "LU", label: "LUXEMBOURG" },
    { value: "MO", label: "MACAO" },
    { value: "MK", label: "MACEDONIA, REPUBLIC OF (FYROM)" },
    { value: "MG", label: "MADAGASCAR" },
    { value: "MW", label: "MALAWI" },
    { value: "MY", label: "MALAYSIA" },
    { value: "MV", label: "MALDIVES" },
    { value: "ML", label: "MALI" },
    { value: "MT", label: "MALTA" },
    { value: "MH", label: "MARSHALL ISLANDS" },
    { value: "MQ", label: "MARTINIQUE" },
    { value: "MR", label: "MAURITANIA" },
    { value: "MU", label: "MAURITIUS" },
    { value: "YT", label: "MAYOTTE" },
    { value: "MX", label: "MEXICO" },
    { value: "FM", label: "MICRONESIA, FEDERATED STATES OF" },
    { value: "MD", label: "MOLDOVA, REPUBLIC OF" },
    { value: "MC", label: "MONACO" },
    { value: "MN", label: "MONGOLIA" },
    { value: "ME", label: "MONTENEGRO" },
    { value: "MS", label: "MONTSERRAT" },
    { value: "MA", label: "MOROCCO" },
    { value: "MZ", label: "MOZAMBIQUE" },
    { value: "MM", label: "MYANMAR" },
    { value: "NA", label: "NAMIBIA" },
    { value: "NR", label: "NAURU, REPUBLIC OF" },
    { value: "NP", label: "NEPAL" },
    { value: "NL", label: "NETHERLANDS" },
    { value: "AN", label: "NETHERLANDS ANTILLES" },
    { value: "NC", label: "NEW CALEDONIA" },
    { value: "NZ", label: "NEW ZEALAND" },
    { value: "NI", label: "NICARAGUA" },
    { value: "NE", label: "NIGER" },
    { value: "NG", label: "NIGERIA" },
    { value: "NU", label: "NIUE" },
    { value: "MP", label: "NORTHERN MARIANA ISLANDS" },
    { value: "NO", label: "NORWAY" },
    { value: "OM", label: "OMAN" },
    { value: "PK", label: "PAKISTAN" },
    { value: "PW", label: "PALAU" },
    { value: "PS", label: "PALESTINIAN TERRITORY, OCCUPIED" },
    { value: "PA", label: "PANAMA" },
    { value: "PG", label: "PAPUA NEW GUINEA" },
    { value: "PY", label: "PARAGUAY" },
    { value: "PE", label: "PERU" },
    { value: "PH", label: "PHILIPPINES" },
    { value: "PN", label: "PITCAIRN" },
    { value: "PL", label: "POLAND" },
    { value: "PT", label: "PORTUGAL" },
    { value: "PR", label: "PUERTO RICO" },
    { value: "QA", label: "QATAR" },
    { value: "RE", label: "REUNION ISLANDS" },
    { value: "RO", label: "ROMANIA" },
    { value: "RU", label: "RUSSIAN FEDERATION (RUSSIA)" },
    { value: "RW", label: "RWANDA" },
    { value: "PM", label: "SAINT PIERRE AND MIQUELON" },
    { value: "WS", label: "SAMOA" },
    { value: "ST", label: "SAO TOME / PRINCIPE" },
    { value: "SA", label: "SAUDI ARABIA" },
    { value: "SN", label: "SENEGAL" },
    { value: "RS", label: "SERBIA" },
    { value: "SC", label: "SEYCHELLES" },
    { value: "SL", label: "SIERRA LEONE" },
    { value: "SG", label: "SINGAPORE" },
    { value: "SK", label: "SLOVAKIA" },
    { value: "SI", label: "SLOVENIA" },
    { value: "SB", label: "SOLOMON ISLANDS" },
    { value: "SO", label: "SOMALIA" },
    { value: "XS", label: "SOMALILAND, REP OF (NORTH SOMALIA)" },
    { value: "ZA", label: "SOUTH AFRICA" },
    { value: "SH", label: "SOUTH AFRICA (ST HELENA)" },
    { value: "GS", label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
    { value: "SS", label: "SOUTH SUDAN" },
    { value: "ES", label: "SPAIN" },
    { value: "LK", label: "SRI LANKA" },
    { value: "XY", label: "ST BARTHELEMY" },
    { value: "XE", label: "ST EUSTATIUS (NETHERLANDS ANTILLES)" },
    { value: "KN", label: "ST KITTS AND NEVIS" },
    { value: "LC", label: "ST LUCIA" },
    { value: "XM", label: "ST MAARTEN (NETHERLANDS ANTILLES)" },
    { value: "VC", label: "ST VINCENT / GRENADINE" },
    { value: "SD", label: "SUDAN" },
    { value: "SR", label: "SURINAME (SURINAM)" },
    { value: "SJ", label: "SVALBARD AND JAN MAYEN" },
    { value: "SZ", label: "SWAZILAND" },
    { value: "SE", label: "SWEDEN" },
    { value: "CH", label: "SWITZERLAND" },
    { value: "SY", label: "SYRIA" },
    { value: "TW", label: "TAIWAN" },
    { value: "TJ", label: "TAJIKISTAN" },
    { value: "TZ", label: "TANZANIA, UNITED REPUBLIC OF" },
    { value: "TH", label: "THAILAND" },
    { value: "TG", label: "TOGO" },
    { value: "TK", label: "TOKELAU" },
    { value: "TO", label: "TONGA" },
    { value: "TT", label: "TRINIDAD / TOBAGO" },
    { value: "TN", label: "TUNISIA" },
    { value: "TR", label: "TURKEY" },
    { value: "TM", label: "TURKMENISTAN" },
    { value: "TC", label: "TURKS / CAICOS ISLANDS" },
    { value: "TV", label: "TUVALU" },
    { value: "UG", label: "UGANDA" },
    { value: "UA", label: "UKRAINE" },
    { value: "AE", label: "UNITED ARAB EMIRATES" },
    { value: "GB", label: "UNITED KINGDOM" },
    { value: "US", label: "UNITED STATES" },
    { value: "UM", label: "UNITED STATES MINOR OUTLYING ISLANDS" },
    { value: "UY", label: "URUGUAY" },
    { value: "UZ", label: "UZBEKISTAN" },
    { value: "VU", label: "VANUATU" },
    { value: "VE", label: "VENEZUELA" },
    { value: "VN", label: "VIETNAM" },
    { value: "VG", label: "VIRGIN ISLANDS (BRITISH)" },
    { value: "VI", label: "VIRGIN ISLANDS (USA)" },
    { value: "EH", label: "WESTERN SAHARA" },
    { value: "YE", label: "YEMEN" },
    { value: "ZM", label: "ZAMBIA" },
    { value: "ZW", label: "ZIMBABWE" },
  ];
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [integration, setIntegration] = useState(null)
  const [productId, setProductId] = useState(null)
  const handleClose = () => setShow(false);

  const [contacts, setContact] = useState([])
  const [label, setLabel] = useState([])
  const [name, setName] = useState([])
  const [company, setCompany] = useState([])
  const [address1, setAddress1] = useState([])
  const [address2, setAddress2] = useState([])
  const [city, setCity] = useState([])
  const [state, setState] = useState([])
  const [zip, setZip] = useState([])
  const [country, setCountry] = useState([])
  const [email, setEmail] = useState([])
  const [phone, setPhone] = useState([])
  const [extra, setExtra] = useState([])
  const [response, setResponse] = useState([])
  const [commId,setCommId] = useState(null)

  const handleShow = (id,records) => {
    if (id) {
      const getCountry = receiverCountryOptions.find(ele => ele.value == records.country)
      setLabel(records.label)
      setName(records.name)
      setCompany(records.company)
      setAddress1(records.address1)
      setAddress2(records.address2)
      setCity(records.city)
      setState(records.state)
      setZip(records.zip)
      setCountry(getCountry)
      setEmail(records.email)
      setPhone(records.phone)
      setExtra(records.extra)
        setCommId(id)
    }
    setShow(true)
};






  // const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [year, setyear] = useState([
  //   { value: 1, label: "2022" },
  //   { value: 2, label: "2021" },
  //   { value: 3, label: "2020" },
  //   { value: 4, label: "2019" },
  // ]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Handle file upload logic here, for example, send the file to the server
    console.log('Selected file:', selectedFile);
    // Reset selected file after upload
    setSelectedFile(null);
  };

  const handleSubmit = async (id) => {
    if (id) {
      const requestData = {
        label: label,
        name: name,
        company: company,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip:zip,
        country: country?.value,
        phone: phone,
        email: email,
        extra: extra,
      }
      try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-importer-records/${id}`;
        fetch(apiUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`
          },
          body: JSON.stringify(requestData)
        })
          .then(response => response.json())
          .then(data => {
            console.log(data, "dataa=>")
            setResponse(data.data);
            handleClose()
          })
          .catch(error => {
            console.error('Error while calling API:', error);
          });
      }
      catch (err) {
        if(err?.status == 401) {
          navigate('/login')
      }
        console.log(err)
      }
    }
    else {
      try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-importer-records`;
        const requestData = {
          label: label,
          name: name,
          company: company,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zip:zip,
          country: country?.value,
          phone: phone,
          email: email,
          extra: extra,
          user:Cookies.get('id')
        }

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData)
        })
          .then(response => response.json())
          .then(data => {

            getCommodities()
            handleClose()
          })
          .catch(error => {
            console.error('Error while calling API:', error);
          });
      }
      catch (err) {
        if(err?.status == 401) {
          navigate('/login')
      }
        console.log(err)
      }
    }

  }
  const handleDelete = (id) => {
  
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/delete-ecomm-csv/${id}`;
      fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
        
      })
        .then(response => response.json())
        .then(data => { 
          getCommodities()
        })
        .catch(error => {
          console.error('Error while calling API:', error);
        });
    }
    catch (err) {
      if(err?.status == 401) {
        navigate('/login')
    }
      console.log(err)
    }
  }

  const getCommodities = async () => {
    try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-ecomm-csv`;


        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('token')}`
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data, "dataa=>")
                setResponse(data.data);
                handleClose()
            })
            .catch(error => {
                console.error('Error while calling API:', error);
            });
    }
    catch (err) {
      if(err?.status == 401) {
        navigate('/login')
    }
        console.log(err)
    }
}

useEffect(() => {
    getCommodities()
}, [])

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recorder Of Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Label:</label>
              <input onChange={(e) => setLabel(e.target.value)} type="text" value={label} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Name:</label>
              <input onChange={(e) => setName(e.target.value)} type="text" value={name} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Company:</label>
              <input onChange={(e) => setCompany(e.target.value)} type="text" value={company} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Address1:</label>
              <input onChange={(e) => setAddress1(e.target.value)} type="text" value={address1} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Address2:</label>
              <input onChange={(e) => setAddress2(e.target.value)} type="text" value={address2} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>City:</label>
              <input onChange={(e) => setCity(e.target.value)} type="text" value={city} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>State:</label>
              <input onChange={(e) => setState(e.target.value)} type="text" value={state} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Zip:</label>
              <input onChange={(e) => setZip(e.target.value)} type="text" value={zip} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Country:</label>
              <Select
                value={country} // Ensure this matches one of the options
                onChange={(selectedOption) => setCountry(selectedOption)}
                options={receiverCountryOptions}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Email: </label>
              <input onChange={(e) => setEmail(e.target.value)} type="text" value={email} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Phone: </label>
              <input onChange={(e) => setPhone(e.target.value)} type="text" value={phone} className="form-control" required />

            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label>Extra: </label>
              <input onChange={(e) => setExtra(e.target.value)} type="text" value={extra} className="form-control" required />

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmit(commId)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Csv Integrations </Link>
                    </li>
                    {/* <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li> */}
                  </ul>
                </div>
              </div>

            </div>
            {/* /Page Header */}
            {/* <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      <span><i className="fa fa-cube" />Get Started with Commodities</span>
                    </h2>
                    
                    <button type="button" className="btn btn-primary btn-lg me-1" onClick={handleShow}>Add Commodity</button>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                      alt="#" />
                  </div>
                </div>
              </div>
            </div> */}
             <div className="good-morning-blk">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="morning-user">
                                        
                                        {/* <p>When you start booking shipments, data about them will display here</p> */}
                                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => navigate('/csv-integration')}>Add Entry</button>
                                    </div>
                                </div>
                                <div className="col-md-6 position-blk">
                                    <div className="morning-img">
                                        <img src={morning_img_01}
                                            alt="#" />
                                    </div>
                                </div>
                            </div>
                        </div>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                 
                    {/* <th>Description</th> */}
                  </tr>
                </thead>
                <tbody>
                  {response?.length && response?.map(commodity => (
                    <tr key={commodity._id}>
                      <td>{commodity.integrationName}</td>

                      <td>
                        <Button variant="primary" onClick={() => navigate(`/csv-integration/${commodity._id}`)}>Edit</Button>{' '}
                        <Button variant="danger" onClick={() => handleDelete(commodity.id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div id="delete_patient" className="modal fade delete-modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="#" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="delete_patient" className="modal fade delete-modal" role="dialog">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src={imagesend} alt="#" width={50} height={46} />
                    <h3>Are you sure want to delete this ?</h3>
                    <div className="m-t-20">
                      {" "}
                      <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                        Close
                      </Link>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    </>
  );
};

export default CsvInterationList;

/* eslint-disable */
import React, { useEffect, useState } from "react";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Select from "react-select";
// import PatientChart from "./PaitentChart";
// import Select from "react-select";
import {
    // Avatar2,
    // Avatar3,
    // Avatar4,
    // Avatar5,
    // calendar,
    // dep_icon1,
    // dep_icon2,
    // dep_icon3,
    // dep_icon4,
    // dep_icon5,
    // empty_wallet,
    imagesend,
    morning_img_01,
    // profile_add,
    // scissor,
    // user001,
} from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Cookies from "js-cookie";

// import CountUp from "react-countup";

const CsvUpload = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [integration, setIntegration] = useState(null)
    const [productId, setProductId] = useState(null)
    const [sku, setSku] = useState(null)
    const [pickLocation, setPickLocation] = useState(null)
    const [country, setCountry] = useState(null)
    const [hts, setHts] = useState(null)
    const [productName, setProductName] = useState(null);
    const [weight, setWeight] = useState(null);
    const [price, setPrice] = useState(null)
    const [commId, setCommId] = useState(null)
    const [response, setResponse] = useState([])
    const handleClose = () => setShow(false);
    const receiverCountryOptions = [
        { value: "AF", label: "AFGHANISTAN" },
        { value: "AX", label: "ALAND ISLANDS" },
        { value: "AL", label: "ALBANIA" },
        { value: "DZ", label: "ALGERIA" },
        { value: "AS", label: "AMERICAN SAMOA" },
        { value: "AD", label: "ANDORRA" },
        { value: "AO", label: "ANGOLA" },
        { value: "AI", label: "ANGUILLA" },
        { value: "AQ", label: "ANTARCTICA" },
        { value: "AG", label: "ANTIGUA / BARBUDA" },
        { value: "AR", label: "ARGENTINA" },
        { value: "AM", label: "ARMENIA" },
        { value: "AW", label: "ARUBA" },
        { value: "AU", label: "AUSTRALIA" },
        { value: "CX", label: "AUSTRALIA (CHRISTMAS ISLANDS)" },
        { value: "CC", label: "AUSTRALIA (COCOS KEELING ISLANDS)" },
        { value: "NF", label: "AUSTRALIA (NORFOLK ISLANDS)" },
        { value: "AT", label: "AUSTRIA" },
        { value: "AZ", label: "AZERBAIJAN" },
        { value: "BS", label: "BAHAMAS" },
        { value: "BH", label: "BAHRAIN" },
        { value: "BD", label: "BANGLADESH" },
        { value: "BB", label: "BARBADOS" },
        { value: "BY", label: "BELARUS" },
        { value: "BE", label: "BELGIUM" },
        { value: "BZ", label: "BELIZE" },
        { value: "BJ", label: "BENIN" },
        { value: "BM", label: "BERMUDA" },
        { value: "BT", label: "BHUTAN" },
        { value: "BO", label: "BOLIVIA" },
        { value: "XB", label: "BONAIRE (NETHERLANDS ANTILLES)" },
        { value: "BA", label: "BOSNIA / HERZEGOVINA" },
        { value: "BW", label: "BOTSWANA" },
        { value: "BV", label: "BOUVET ISLAND" },
        { value: "BR", label: "BRAZIL" },
        { value: "IO", label: "BRITISH INDIAN OCEAN TERRITORY" },
        { value: "BN", label: "BRUNEI" },
        { value: "BG", label: "BULGARIA" },
        { value: "BF", label: "BURKINA FASO" },
        { value: "BI", label: "BURUNDI" },
        { value: "KH", label: "CAMBODIA" },
        { value: "CM", label: "CAMEROON" },
        { value: "CA", label: "CANADA" },
        { value: "IC", label: "CANARY ISLANDS" },
        { value: "CV", label: "CAPE VERDE" },
        { value: "KY", label: "CAYMAN ISLANDS" },
        { value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
        { value: "TD", label: "CHAD" },
        { value: "CL", label: "CHILE" },
        { value: "CN", label: "CHINA" },
        { value: "CO", label: "COLOMBIA" },
        { value: "KM", label: "COMOROS" },
        { value: "CG", label: "CONGO" },
        { value: "CD", label: "CONGO, DEMOCRATIC REPUBLIC OF THE" },
        { value: "CK", label: "COOK ISLANDS" },
        { value: "CR", label: "COSTA RICA" },
        { value: "HR", label: "CROATIA" },
        { value: "CU", label: "CUBA" },
        { value: "CW", label: "CURACAO" },
        { value: "CY", label: "CYPRUS" },
        { value: "CZ", label: "CZECH REPUBLIC" },
        { value: "DK", label: "DENMARK" },
        { value: "DJ", label: "DJIBOUTI" },
        { value: "DM", label: "DOMINICA" },
        { value: "DO", label: "DOMINICAN REPUBLIC" },
        { value: "TL", label: "EAST TIMOR" },
        { value: "EC", label: "ECUADOR" },
        { value: "EG", label: "EGYPT" },
        { value: "SV", label: "EL SALVADOR" },
        { value: "ER", label: "ERITREA" },
        { value: "EE", label: "ESTONIA" },
        { value: "ET", label: "ETHIOPIA" },
        { value: "FK", label: "FALKLAND ISLANDS (MALVINAS)" },
        { value: "FO", label: "FAROE ISLANDS" },
        { value: "FJ", label: "FIJI" },
        { value: "WF", label: "FIJI (WALLIS / FUTUNA ISLANDS)" },
        { value: "FI", label: "FINLAND" },
        { value: "FR", label: "FRANCE" },
        { value: "GF", label: "FRENCH GUYANA" },
        { value: "PF", label: "FRENCH POLYNESIA" },
        { value: "TF", label: "FRENCH SOUTHERN TERRITORIES" },
        { value: "GA", label: "GABON" },
        { value: "GM", label: "GAMBIA" },
        { value: "GE", label: "GEORGIA" },
        { value: "DE", label: "GERMANY" },
        { value: "GH", label: "GHANA" },
        { value: "GI", label: "GIBRALTAR" },
        { value: "GR", label: "GREECE" },
        { value: "GL", label: "GREENLAND" },
        { value: "GD", label: "GRENADA" },
        { value: "GP", label: "GUADELOUPE" },
        { value: "GU", label: "GUAM" },
        { value: "GT", label: "GUATEMALA" },
        { value: "GG", label: "GUERNSEY" },
        { value: "GQ", label: "GUINEA (EQUATORIAL GUINEA)" },
        { value: "GW", label: "GUINEA BISSAU" },
        { value: "GN", label: "GUINEA REPUBLIC (GUINEA)" },
        { value: "GY", label: "GUYANA (BRITISH)" },
        { value: "HT", label: "HAITI" },
        { value: "HM", label: "HEARD ISLAND AND MCDONALD ISLANDS" },
        { value: "HN", label: "HONDURAS" },
        { value: "HK", label: "HONG KONG" },
        { value: "HU", label: "HUNGARY" },
        { value: "IS", label: "ICELAND" },
        { value: "IN", label: "INDIA" },
        { value: "ID", label: "INDONESIA" },
        { value: "IR", label: "IRAN (ISLAMIC REPUBLIC OF)" },
        { value: "IQ", label: "IRAQ" },
        { value: "IE", label: "IRELAND, REPUBLIC OF (IRELAND)" },
        { value: "IL", label: "ISRAEL" },
        { value: "IT", label: "ITALY" },
        { value: "SM", label: "ITALY (SAN MARINO)" },
        { value: "VA", label: "ITALY (VATICAN CITY)" },
        { value: "CI", label: "IVORY COAST" },
        { value: "JM", label: "JAMAICA" },
        { value: "JP", label: "JAPAN" },
        { value: "JE", label: "JERSEY" },
        { value: "JO", label: "JORDAN" },
        { value: "KZ", label: "KAZAKHSTAN" },
        { value: "KE", label: "KENYA" },
        { value: "KI", label: "KIRIBATI" },
        { value: "KR", label: "KOREA, REPUBLIC OF (KOREA SOUTH)" },
        { value: "KP", label: "KOREA, THE D.P.R. OF (KOREA NORTH)" },
        { value: "KV", label: "KOSOVO" },
        { value: "KW", label: "KUWAIT" },
        { value: "KG", label: "KYRGYZSTAN" },
        { value: "LA", label: "LAOS" },
        { value: "LV", label: "LATVIA" },
        { value: "LB", label: "LEBANON" },
        { value: "LS", label: "LESOTHO" },
        { value: "LR", label: "LIBERIA" },
        { value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
        { value: "LI", label: "LIECHTENSTEIN" },
        { value: "LT", label: "LITHUANIA" },
        { value: "LU", label: "LUXEMBOURG" },
        { value: "MO", label: "MACAO" },
        { value: "MK", label: "MACEDONIA, REPUBLIC OF (FYROM)" },
        { value: "MG", label: "MADAGASCAR" },
        { value: "MW", label: "MALAWI" },
        { value: "MY", label: "MALAYSIA" },
        { value: "MV", label: "MALDIVES" },
        { value: "ML", label: "MALI" },
        { value: "MT", label: "MALTA" },
        { value: "MH", label: "MARSHALL ISLANDS" },
        { value: "MQ", label: "MARTINIQUE" },
        { value: "MR", label: "MAURITANIA" },
        { value: "MU", label: "MAURITIUS" },
        { value: "YT", label: "MAYOTTE" },
        { value: "MX", label: "MEXICO" },
        { value: "FM", label: "MICRONESIA, FEDERATED STATES OF" },
        { value: "MD", label: "MOLDOVA, REPUBLIC OF" },
        { value: "MC", label: "MONACO" },
        { value: "MN", label: "MONGOLIA" },
        { value: "ME", label: "MONTENEGRO" },
        { value: "MS", label: "MONTSERRAT" },
        { value: "MA", label: "MOROCCO" },
        { value: "MZ", label: "MOZAMBIQUE" },
        { value: "MM", label: "MYANMAR" },
        { value: "NA", label: "NAMIBIA" },
        { value: "NR", label: "NAURU, REPUBLIC OF" },
        { value: "NP", label: "NEPAL" },
        { value: "NL", label: "NETHERLANDS" },
        { value: "AN", label: "NETHERLANDS ANTILLES" },
        { value: "NC", label: "NEW CALEDONIA" },
        { value: "NZ", label: "NEW ZEALAND" },
        { value: "NI", label: "NICARAGUA" },
        { value: "NE", label: "NIGER" },
        { value: "NG", label: "NIGERIA" },
        { value: "NU", label: "NIUE" },
        { value: "MP", label: "NORTHERN MARIANA ISLANDS" },
        { value: "NO", label: "NORWAY" },
        { value: "OM", label: "OMAN" },
        { value: "PK", label: "PAKISTAN" },
        { value: "PW", label: "PALAU" },
        { value: "PS", label: "PALESTINIAN TERRITORY, OCCUPIED" },
        { value: "PA", label: "PANAMA" },
        { value: "PG", label: "PAPUA NEW GUINEA" },
        { value: "PY", label: "PARAGUAY" },
        { value: "PE", label: "PERU" },
        { value: "PH", label: "PHILIPPINES" },
        { value: "PN", label: "PITCAIRN" },
        { value: "PL", label: "POLAND" },
        { value: "PT", label: "PORTUGAL" },
        { value: "PR", label: "PUERTO RICO" },
        { value: "QA", label: "QATAR" },
        { value: "RE", label: "REUNION ISLANDS" },
        { value: "RO", label: "ROMANIA" },
        { value: "RU", label: "RUSSIAN FEDERATION (RUSSIA)" },
        { value: "RW", label: "RWANDA" },
        { value: "PM", label: "SAINT PIERRE AND MIQUELON" },
        { value: "WS", label: "SAMOA" },
        { value: "ST", label: "SAO TOME / PRINCIPE" },
        { value: "SA", label: "SAUDI ARABIA" },
        { value: "SN", label: "SENEGAL" },
        { value: "RS", label: "SERBIA" },
        { value: "SC", label: "SEYCHELLES" },
        { value: "SL", label: "SIERRA LEONE" },
        { value: "SG", label: "SINGAPORE" },
        { value: "SK", label: "SLOVAKIA" },
        { value: "SI", label: "SLOVENIA" },
        { value: "SB", label: "SOLOMON ISLANDS" },
        { value: "SO", label: "SOMALIA" },
        { value: "XS", label: "SOMALILAND, REP OF (NORTH SOMALIA)" },
        { value: "ZA", label: "SOUTH AFRICA" },
        { value: "SH", label: "SOUTH AFRICA (ST HELENA)" },
        { value: "GS", label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
        { value: "SS", label: "SOUTH SUDAN" },
        { value: "ES", label: "SPAIN" },
        { value: "LK", label: "SRI LANKA" },
        { value: "XY", label: "ST BARTHELEMY" },
        { value: "XE", label: "ST EUSTATIUS (NETHERLANDS ANTILLES)" },
        { value: "KN", label: "ST KITTS AND NEVIS" },
        { value: "LC", label: "ST LUCIA" },
        { value: "XM", label: "ST MAARTEN (NETHERLANDS ANTILLES)" },
        { value: "VC", label: "ST VINCENT / GRENADINE" },
        { value: "SD", label: "SUDAN" },
        { value: "SR", label: "SURINAME (SURINAM)" },
        { value: "SJ", label: "SVALBARD AND JAN MAYEN" },
        { value: "SZ", label: "SWAZILAND" },
        { value: "SE", label: "SWEDEN" },
        { value: "CH", label: "SWITZERLAND" },
        { value: "SY", label: "SYRIA" },
        { value: "TW", label: "TAIWAN" },
        { value: "TJ", label: "TAJIKISTAN" },
        { value: "TZ", label: "TANZANIA, UNITED REPUBLIC OF" },
        { value: "TH", label: "THAILAND" },
        { value: "TG", label: "TOGO" },
        { value: "TK", label: "TOKELAU" },
        { value: "TO", label: "TONGA" },
        { value: "TT", label: "TRINIDAD / TOBAGO" },
        { value: "TN", label: "TUNISIA" },
        { value: "TR", label: "TURKEY" },
        { value: "TM", label: "TURKMENISTAN" },
        { value: "TC", label: "TURKS / CAICOS ISLANDS" },
        { value: "TV", label: "TUVALU" },
        { value: "UG", label: "UGANDA" },
        { value: "UA", label: "UKRAINE" },
        { value: "AE", label: "UNITED ARAB EMIRATES" },
        { value: "GB", label: "UNITED KINGDOM" },
        { value: "US", label: "UNITED STATES" },
        { value: "UM", label: "UNITED STATES MINOR OUTLYING ISLANDS" },
        { value: "UY", label: "URUGUAY" },
        { value: "UZ", label: "UZBEKISTAN" },
        { value: "VU", label: "VANUATU" },
        { value: "VE", label: "VENEZUELA" },
        { value: "VN", label: "VIETNAM" },
        { value: "VG", label: "VIRGIN ISLANDS (BRITISH)" },
        { value: "VI", label: "VIRGIN ISLANDS (USA)" },
        { value: "EH", label: "WESTERN SAHARA" },
        { value: "YE", label: "YEMEN" },
        { value: "ZM", label: "ZAMBIA" },
        { value: "ZW", label: "ZIMBABWE" },
    ];
    const integrationsOption = [
        {
            value: 'amazonz',
            label: 'amazonz'
        },
        {
            value: 'csv',
            label: 'CSV'
        }
    ]
    const handleShow = (id, commodity) => {
        if (id && commodity) {
            const getIntegration = integrationsOption.find(ele => ele.value == commodity.integration)
            const getCountryOption = receiverCountryOptions.find(ele => ele.value == commodity.country)
            console.log(commodity.integration,"commodity.integration=>")
            console.log(commodity.productName,"commodity.productName=>")
            setProductId(commodity.productId)
            setSku(commodity.sku)
            setIntegration(getIntegration)
            setProductName(commodity.productName)
            setHts(commodity.hts)
            setPickLocation(commodity.pickLocation)
            setPrice(commodity.price)
            setCommId(id)
            setWeight(commodity.weight)
            setCountry(getCountryOption)
        }
        setShow(true)
    };
    function handleFileSelect(event) {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('status', 'uploaded');
            formData.append('uploaded', new Date().toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }));
            formData.append('acceptedOrders', '');
            formData.append('submittedOrders', '');
            formData.append('user', Cookies.get('id'));
    
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/upload-csv`, {
                method: 'POST',
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        }
    }
    
    
    function handleUploadCsvButtonClick() {

        document.getElementById('csvFileInput').click();
    }


    

    // const [selectedOption, setSelectedOption] = useState(null);
    // eslint-disable-next-line no-unused-vars
    // const [year, setyear] = useState([
    //   { value: 1, label: "2022" },
    //   { value: 2, label: "2021" },
    //   { value: 3, label: "2020" },
    //   { value: 4, label: "2019" },
    // ]);

    

   

    const handleSubmit = async (id) => {
        console.log(id,"id==>>>123")
        if (id) {
            const requestData = {
                integration: integration?.value,
                productId: productId,
                sku: sku,
                pickLocation: pickLocation,
                country: country?.value,
                hts: hts,
                productName: productName,
                weight: weight,
                price: price,
                user:Cookies.get('id')
            }
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-commodity/${id}`;
                fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {
                        getCommodities()
                        handleClose()
                    })
                    .catch(error => {
                        console.error('Error while calling API:', error);
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                console.log(err)
            }
        }
        else {
            try {
                const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-commodity`;
                const requestData = {
                    integration: integration?.value,
                    productId: productId,
                    sku: sku,
                    pickLocation: pickLocation,
                    country: country?.value,
                    hts: hts,
                    productName: productName,
                    weight: weight,
                    price: price,
                    user:Cookies.get('id')
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {


                        handleClose()
                    })
                    .catch(error => {
                        console.error('Error while calling API:', error);
                    });
            }
            catch (err) {
                if(err?.status == 401) {
                    navigate('/login')
                }
                console.log(err)
            }
        }
    }
    const handleEdit = async (id) => {

    }
    const getCommodities = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-csv`;


            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data, "dataa=>")
                    setResponse(data.data);
                    handleClose()
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }
        catch (err) {
            if(err?.status == 401) {
                navigate('/login')
            }
            console.log(err)
        }
    }

    useEffect(() => {
        getCommodities()
    }, [])
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {console.log(integration,"integration=>")}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Integration :</label>
                            <Select
                                value={integration} // Ensure this matches one of the options
                                onChange={(selectedOption) => setIntegration(selectedOption)}
                                options={integrationsOption}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Product Id:</label>
                            <input onChange={(e) => setProductId(e.target.value)} type="text" value={productId} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>SKU:</label>
                            <input onChange={(e) => setSku(e.target.value)} type="text" value={sku} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Pick Location:</label>
                            <input onChange={(e) => setPickLocation(e.target.value)} type="text" value={pickLocation} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Country of Origin :</label>
                            <Select
                                value={country} // Ensure this matches one of the options
                                onChange={(selectedOption) => setCountry(selectedOption)}
                                options={receiverCountryOptions}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>HTS#/B#: </label>
                            <input onChange={(e) => setHts(e.target.value)} type="text" value={hts} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Product Name: </label>
                            <input onChange={(e) => setProductName(e.target.value)} type="text" value={productName} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Weight: </label>
                            <input onChange={(e) => setWeight(e.target.value)} type="text" value={weight} className="form-control" required />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Price: </label>
                            <input onChange={(e) => setPrice(e.target.value)} type="text" value={price} className="form-control" required />

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit(commId)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header />
            <Sidebar
                id="menu-item"
                id1="menu-items"
                activeClassName="admin-dashboard"
            />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Commodities </Link>
                                        </li>
                                        {/* <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Admin Dashboard</li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        {/* /Page Header */}
                        <div className="good-morning-blk">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="morning-user">
                                       
                                        {/* <p>When you start booking shipments, data about them will display here</p> */}
                                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => {handleUploadCsvButtonClick()}}>Upload CSV</button>
                                        
                                        <button type="button" className="btn btn-primary btn-lg me-1" onClick={() => {navigate('/csv-integration')}}>Add Integration</button>
                                        <input type="file" id="csvFileInput" style={{display:'none' }} accept=".csv" onChange={(e) => handleFileSelect(e)} />

                                    </div>
                                </div>
                                <div className="col-md-6 position-blk">
                                    <div className="morning-img">
                                        <img src={morning_img_01}
                                            alt="#" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>FileName</th>
                                        <th>Status</th>
                                        <th>Uploaded</th>
                                        <th>Rows Submitted</th>
                                        <th>Orders Submitted</th>
                                        <th>Order Accepted</th>
                                       
                                        {/* <th>Description</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {response.map(commodity => (
                                        <tr key={commodity._id}>
                                            <td>{commodity.file}</td>

                                            <td>{commodity.status}</td>
                                            <td>{commodity.uploaded}</td>
                                            <td>{commodity.rows}</td>
                                            <td>{commodity.submittedOrders}</td>
                                            <td>{commodity.acceptedOrders}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => handleShow(commodity._id,commodity)}>Edit</Button>{' '}
                                                <Button variant="danger" onClick={() => handleDelete(commodity.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={imagesend} alt="#" width={50} height={46} />
                                    <h3>Are you sure want to delete this ?</h3>
                                    <div className="m-t-20">
                                        {" "}
                                        <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                            Close
                                        </Link>
                                        <button type="submit" className="btn btn-danger">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <img src={imagesend} alt="#" width={50} height={46} />
                                        <h3>Are you sure want to delete this ?</h3>
                                        <div className="m-t-20">
                                            {" "}
                                            <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                                Close
                                            </Link>
                                            <button type="submit" className="btn btn-danger">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </>
    );
};

export default CsvUpload;



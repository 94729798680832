/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import axios from 'axios'
import Select from "react-select";
import { Button } from 'react-bootstrap'
import statesData from '../../states.json'
import Cookies from 'js-cookie'

const BillingAddress = () => {
    const SenderCountryOptions = [
        { value: 'US', label: 'UNITED STATES' }
    ];
    const [senderCountry, setSenderCountry] = useState(SenderCountryOptions[0]);
    const [senderName, setSenderName] = useState(null);
    const [isResidential,setIsResidential] = useState(false)
    const [senderCompany, setSenderCompany] = useState(null);
    const [senderZipCode, setSenderZipCode] = useState(null);
    const [senderCity, setSenderCity] = useState(null);
    const [senderState, setSenderState] = useState(null);
    const [senderStateList, setSenderStateList] = useState(null);
    const [senderAddress1, setSenderAddress1] = useState(null);
    const [senderAddress2, setSenderAddress2] = useState(null);
    const [senderEmail, setSenderEmail] = useState(null);
    const [senderPhone, setSenderPhone] = useState(null);
    const [addId, setAddId] = useState(null);
    useEffect(() => {
        if (senderCountry) {
            const Data = statesData.filter(ele => senderCountry.value == ele.country_code)
            console.log(Data,"Data==>>")
            setSenderStateList(Data);
        }
    }, [senderCountry])


    const getSenderDetails = async () => {
        const headersList = {
            "Authorization": `Bearer ${Cookies.get('token')}`,
            "Content-Type": "application/json"
        };


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-default-address`, {
                method: "GET", // Use "GET" method for sending parameters in URL
                headers: headersList
            });

            const data = await response.json();
            console.log(data, "data of sender users");
            if (data) {

                const getCountry = SenderCountryOptions.find(ele => ele.value == data.country)
                const stateData = statesData.filter(ele => getCountry.value == ele.country_code)
                setAddId(data._id)
                setSenderName(data.name)
                if (data.user.email) {
                    setSenderEmail(data.user.email)
                }
                else {
                    setSenderEmail(user.data.email)
                }
                if (data.user.companyName) {
                    setSenderCompany(data.user.companyName)
                }
                else {
                    setSenderCompany(user.data.companyName)
                }
                setSenderAddress1(data.address1)
                setSenderAddress2(data.address2)
                setSenderCountry(getCountry);
                setSenderCity(data.city)
                setIsResidential(data.isResidential)
                setSenderZipCode(data.zip)
                setSenderPhone(data.phone)
                const getState = stateData.find(ele => ele.value == data.state)
                console.log(getState, "getState")
                setSenderState(getState)
            }

            // Handle the data or update the component state as needed
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors as needed
        }
    }

    useEffect(() => {
        getSenderDetails()
    }, [])

    const updateSenderDetails = async () => {
        if (addId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/update-address/${addId}`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // You may need to include additional headers such as authorization headers
                    },
                    body: JSON.stringify({
                        type: 'sender',
                        name: senderName, // Replace with the actual field names from your form
                        company: senderCompany,
                        zipCode: senderZipCode,
                        city: senderCity,
                        state: senderState?.value,
                        address1: senderAddress1,
                        address2: senderAddress2,
                        email: senderEmail,
                        phone: senderPhone,
                    isResidential:isResidential,
                        country: senderCountry?.value, // Assuming senderCountry is an object with value and label properties
                    }),
                });

                if (!response.ok) {
                    // Handle the error, e.g., show an error message
                    console.error(`HTTP error! Status: ${response.status}`);
                    return;
                }

                const data = await response.json();
                console.log(data); // Log the response data
                // Handle success, e.g., update state or show a success message
            } catch (error) {
                // Handle other errors, e.g., network error
                console.error(error);
            }
        }
        else {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/create-address`;
            const requestData = {
                type: 'sender',
                country: senderCountry?.value,
                zip: senderZipCode,
                state: senderState?.value,
                city: senderCity,
                address1: senderAddress1,
                address2: senderAddress2,
                user: Cookies.get('id'),
                phone: senderPhone,
                name: senderName,
                defaultAddress: true
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {

                    setResponse(data);
                })
                .catch(error => {
                    console.error('Error while calling API:', error);
                });
        }

    };
    return (

        <div>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="index.html">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="settings-menu-links">
                        <ul className="nav nav-tabs menu-tabs">
                            <li className="nav-item">
                                <Link className="nav-link " to="/settingschangepassword">
                                    Security
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link" to="/billing-address">
                                    Billing Address
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                <Link className="nav-link" to="/localization">
                  Localization
                </Link>
              </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/paymentsetting">
                                    Payment Settings
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link className="nav-link" to="/commodities">
                                    Commodities
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" to="/custome-package">
                                    Custom Package Types
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/paperless-signature">
                                    Paperless Signature
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/importer-setting">
                                    Importer Of Record
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/notification-setting">
                                    Notifications
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/other-setting">
                                    Other Settings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/csv-integration-list">
                                    Add CSV Integration
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                            <div className="row">
                            <label>
                                                                        <input type="checkbox" checked={isResidential}  onChange={()  => setIsResidential(!isResidential)} className="form-check-input" />
                                                                        Residential
                                                                    </label>
                            </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Country:</label>
                                                    {console.log(senderCountry,"senderCountry=>")}
                                                    <Select
                                                        value={senderCountry} // Ensure this matches one of the options
                                                        onChange={(selectedOption) => setSenderCountry(selectedOption)}
                                                        options={SenderCountryOptions}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        {console.log(senderName,"sendername--")}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Name:</label>
                                                    <input onChange={(e) => setSenderName(e.target.value)} type="text" value={senderName} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Company:</label>
                                                    <input disabled={isResidential ? true : false}  type="text" onChange={(e) => setSenderCompany(e.target.value)} className="form-control" value={senderCompany} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>ZIP code:</label>
                                                    <input type="text" className="form-control" value={senderZipCode} onChange={(e) => setSenderZipCode(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>City:</label>
                                                    <input type="text" className="form-control" value={senderCity} onChange={(e) => setSenderCity(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>State:</label>
                                                    <Select
                                                        value={senderState}
                                                        onChange={(selectedOption) => setSenderState(selectedOption)}
                                                        options={senderStateList}
                                                    />
                                                    {/* <Autocomplete
            id="combo-box-demo"
             renderInput={(params) => <TextField {...params} variant="outlined"/>}
             
                value={senderState}
                disablePortal
                disableClearable
                options={senderStateList}
                sx={{
                    '.MuiOutlinedInput-root' : {
                        padding:'2px'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #e9eaf6;',
                    },
                  }}
            /> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Address1:</label>
                                                    <input type="text" className="form-control" value={senderAddress1} onChange={(e) => setSenderAddress1(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Address2:</label>
                                                    <input type="text" className="form-control" value={senderAddress2} onChange={(e) => setSenderAddress2(e.target.value)} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Address3:</label>
                                                    <input type="text" className="form-control" value={senderAddress3} onChange={(e) => setSenderAddress3(e.target.value)} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Email:</label>
                                                    <input type="email" className="form-control" value={senderEmail} onChange={(e) => setSenderEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Phone:</label>
                                                    <input type="phone" className="form-control" value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <Button onClick={updateSenderDetails}>Save</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Content */}
                <div className="notification-box">
                    <div className="msg-sidebar notifications msg-noti">
                        <div className="topnav-dropdown-header">
                            <span>Messages</span>
                        </div>
                        <div className="drop-scroll msg-list-scroll" id="msg_list">
                            <ul className="list-box">
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Richard Miles </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item new-message">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">John Doe</span>
                                                <span className="message-time">1 Aug</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Tarah Shropshire </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Mike Litorus</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Catherine Manseau </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">D</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Domenic Houston </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">B</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Buster Wigton </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">R</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Rolland Webber </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">C</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Claire Mapes </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">M</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Melita Faucher</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">J</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Jeffery Lalor</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">L</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Loren Gatlin</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="chat.html">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">T</span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Tarah Shropshire</span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix" />
                                                <span className="message-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="chat.html">See all messages</Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}
export default BillingAddress